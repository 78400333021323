import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { error } from "@multimediallc/logging"
import { Button, ButtonSize, Textarea, Typography } from "../../common"
import { BlackClose } from "../../common/atoms/Icons/Chat"
import { IconButton } from "../common/IconButton/IconButton"
import { toast } from "../common/Toast/ToastManager"
import { classNames } from "../utils"
import { sendMessagingFeedback } from "./api"
import styles from "./UserFeedback.module.scss"

enum Phases {
    Bottom,
    Feedback,
    Closing,
    ThankYou,
}

interface UserFeedbackProps {
    onClose: () => void
    isUserInitiated: boolean
}

const MAX_FEEDBACK_LENGTH_CHARS = 5000
const THANK_YOU_OPAQUE_MS = 3000
const FADE_TIME_MS = 500 // matched with css transition time

export function UserFeedback({
    onClose,
    isUserInitiated = true,
}: UserFeedbackProps) {
    const [selectedRating, setSelectedRating] = useState<number | null>(null)
    const [comments, setComments] = useState("")
    const [phase, setPhase] = useState<Phases>(Phases.Bottom)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [isClosing, setIsClosing] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string | null>(null)

    useEffect(() => {
        const newValue = selectedRating !== null
        setCanSubmit(newValue)
    }, [selectedRating])

    useEffect(() => {
        const newValue = phase === Phases.Closing || phase === Phases.ThankYou
        setIsClosing(newValue)
    }, [phase])

    const handleSubmitClick = () => {
        if (!canSubmit) {
            return
        }
        setSubmitError(null)
        sendMessagingFeedback(selectedRating, comments).then((response) => {
            if (response.success) {
                setPhase(Phases.ThankYou)
                toast.fire({
                    message: t`Thank you for your feedback!`,
                    duration: THANK_YOU_OPAQUE_MS,
                    preset: "dark",
                    showIcon: false,
                    onClose: () => {
                        setPhase(Phases.Closing)
                        onClose()
                    },
                })
            } else {
                setSubmitError(response.errorMessage || null)
            }
        })
    }

    const handleCancelClick = () => {
        setPhase(Phases.Closing)
        setTimeout(() => onClose(), FADE_TIME_MS)
    }

    useEffect(() => {
        if (phase === Phases.Bottom) {
            // Transition from bottom to feedback immediately
            const timer = setTimeout(() => setPhase(Phases.Feedback), 0)
            return () => clearTimeout(timer)
        }
    }, [phase])

    const getHeaderText = () => {
        if (isUserInitiated) {
            return t`Send Feedback`
        }
        return t`We'd love your feedback on Messaging!`
    }

    return (
        <div data-testid="user-feedback-modal">
            {!isUserInitiated && !isClosing && (
                <div
                    className={styles.clickCatcher}
                    onClick={handleCancelClick}
                    data-testid="user-feedback-click-catcher"
                />
            )}
            <div
                className={classNames(styles.backdrop, {
                    [styles.hidden]: !isUserInitiated,
                    [styles.backdropFadeOut]: isClosing,
                })}
                onClick={handleCancelClick}
                data-testid="user-feedback-backdrop"
            >
                <div
                    className={classNames(styles.outerOverlay, {
                        [styles.slideUp]: phase === Phases.Bottom,
                        [styles.systemPrompted]: !isUserInitiated,
                        [styles.moveUp]: canSubmit,
                        [styles.fadeOut]: isClosing,
                    })}
                    onClick={(e) => e.stopPropagation()}
                >
                    <IconButton
                        className={styles.closeX}
                        icon={<BlackClose height={24} width={24} />}
                        onClick={handleCancelClick}
                        data-testid="note-close-button"
                    />
                    <div
                        className={classNames(styles.contentHeader, {
                            [styles.systemHeader]: !isUserInitiated,
                        })}
                        data-testid="user-feedback-header"
                    >
                        {getHeaderText()}
                    </div>
                    <div
                        className={classNames(styles.innerOverlay, {
                            [styles.systemPromptedInner]: !isUserInitiated,
                        })}
                    >
                        <div className={styles.content}>
                            <Typography
                                className={styles.contentSubtext}
                                component="p"
                            >
                                {t`Overall, how was your messaging experience today?`}
                            </Typography>
                            <div className={styles.sentimentSelect}>
                                {[0, 1, 2, 3, 4].map(
                                    (
                                        rating, // backend sentiment scores are 0 - 4
                                    ) => {
                                        const numberRating = rating + 1
                                        return (
                                            <Button
                                                key={rating}
                                                size={ButtonSize.None}
                                                className={classNames(
                                                    styles.ratingButton,
                                                    {
                                                        [styles.selected]:
                                                            selectedRating ===
                                                            rating,
                                                    },
                                                )}
                                                onClick={() => {
                                                    setSelectedRating(rating)
                                                }}
                                                aria-label={t`Rate ${numberRating} out of 5`}
                                                text={numberRating}
                                            />
                                        )
                                    },
                                )}
                            </div>
                            <div className={styles.ratingLabels}>
                                <Typography
                                    component="span"
                                    className={styles.ratingLabelsText}
                                >
                                    {t`Very dissatisfied`}
                                </Typography>
                                <Typography
                                    component="span"
                                    className={styles.ratingLabelsText}
                                >
                                    {t`Very satisfied`}
                                </Typography>
                            </div>
                            <div
                                className={classNames(
                                    styles.slideInContent,
                                    styles.show,
                                )}
                            >
                                <Typography
                                    className={classNames(
                                        styles.contentSubtext,
                                        styles.second,
                                    )}
                                    component="p"
                                >
                                    {t`Additional comments (optional):`}
                                </Typography>
                                <div className={styles.textAreaWrapper}>
                                    <Textarea
                                        className={styles.textInput}
                                        data-testid="user-feedback-text-input"
                                        maxLength={MAX_FEEDBACK_LENGTH_CHARS}
                                        value={comments}
                                        onChange={(e) => {
                                            let newComments = e.target.value
                                            if (
                                                newComments.length >
                                                MAX_FEEDBACK_LENGTH_CHARS
                                            ) {
                                                newComments = newComments.slice(
                                                    0,
                                                    MAX_FEEDBACK_LENGTH_CHARS,
                                                )
                                            }
                                            setComments(newComments)
                                        }}
                                    />
                                </div>
                                {submitError && (
                                    <Typography
                                        className={styles.errorMessage}
                                        component="p"
                                        data-testid="feedback-error-message"
                                    >
                                        {submitError}
                                    </Typography>
                                )}
                                <Button
                                    className={classNames(styles.submitButton, {
                                        [styles.inactive]: !canSubmit,
                                    })}
                                    size={ButtonSize.Medium}
                                    data-testid="user-feedback-save"
                                    onClick={handleSubmitClick}
                                    text={t`Submit`}
                                    disabled={!canSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
