import "./MessageMenu.scss"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { Copy, Report } from "../../common/atoms/Icons/Chat"
import type { Message } from "../types"

type Props = {
    message: Message
    onClose: () => void
    triggerRef: React.RefObject<HTMLElement>
}

const NEAR_TOP_THRESHOLD = 100
const MENU_OFFSET = 105

export const MessageMenu = ({ message, onClose, triggerRef }: Props) => {
    const menuRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const isReceived = message?.from_user?.username === message.other_user
    const rect = triggerRef.current?.getBoundingClientRect()
    const isNearTop = rect ? rect.top < NEAR_TOP_THRESHOLD : false

    const handleCopyClick = () => {
        void navigator.clipboard.writeText(message.m)
        onClose()
    }

    const handleReportClick = () => {
        onClose()
    }

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
            if (
                !menuRef.current?.contains(e.target as Node) &&
                !triggerRef.current?.contains(e.target as Node)
            ) {
                onClose()
            }
        }
        document.addEventListener("click", handleOutsideClick)
        document.addEventListener("touchstart", handleOutsideClick)
        return () => {
            document.removeEventListener("click", handleOutsideClick)
            document.removeEventListener("touchstart", handleOutsideClick)
        }
    }, [])

    const menuContent = (
        <div
            role="menu"
            ref={menuRef}
            style={{
                position: "fixed",
                top: rect
                    ? isNearTop
                        ? rect.bottom
                        : rect.top - MENU_OFFSET
                    : 0,
                left: isReceived ? rect?.left : "auto",
                right: !isReceived
                    ? rect
                        ? window.innerWidth - rect.right
                        : "auto"
                    : "auto",
            }}
            className={`message-menu ${isNearTop ? "top" : "bottom"} ${isReceived ? "received" : "sent"}`}
        >
            <div className="menu-item" onClick={handleCopyClick}>
                <Copy height={16} width={16} />
                <span>Copy</span>
            </div>
            <div className="menu-item red" onClick={handleReportClick}>
                <Report height={16} width={16} />
                <span>Report</span>
            </div>
        </div>
    )

    return createPortal(menuContent, document.body)
}
