import { useEffect, useRef, useState } from "react"
import { MemoryRouter, Route, Routes } from "react-router-dom"
import { useAppContext } from "../../hooks/appContext"
import { ToastManager } from "./common/Toast/ToastManager"
import { VideoPlayerProvider } from "./context/VideoPlayerContext"
import { Inbox } from "./Inbox/Inbox"
import { MessageList } from "./MessageList/MessageList"
import { MessageProfile } from "./MessageProfile/MessageProfile"
import styles from "./Messaging.module.scss"
import { MessagingProvider } from "./MessagingContext"
import { SearchUsers } from "./SearchUsers/SearchUsers"
import { Settings } from "./Settings/Settings"
import { UnifiedChat } from "./UnifiedChat/UnifiedChat"
import { classNames } from "./utils"
import type { EventRouters, Interfaces, Topics, View } from "./types"

const ANIMATION_DURATION_MS = 300

type Props = {
    view: View
    topics: Topics
    interfaces: Interfaces
    eventRouters: EventRouters
    onClose: () => void
    onMinimize?: (minimized: boolean) => void
    initialEntry?: string
    isMinimized: boolean
    sessionId?: string
}

export function Messaging({
    view,
    topics,
    interfaces,
    eventRouters,
    onClose,
    onMinimize,
    initialEntry,
    isMinimized,
}: Props) {
    const { context } = useAppContext()
    const [sessionKey, setSessionKey] = useState<number>(0)
    const [animationClass, setAnimationClass] = useState<string>(styles.slideUp)
    const animationEndTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const isMobile = view === "mobile"

    useEffect(() => {
        // reset router when initialEntry changes
        setSessionKey((prev) => prev + 1)
    }, [initialEntry])

    useEffect(() => {
        return () => {
            if (animationEndTimeoutRef.current) {
                clearTimeout(animationEndTimeoutRef.current)
            }
        }
    }, [])

    const handleClose = () => {
        if (isMobile) {
            if (animationEndTimeoutRef.current) {
                clearTimeout(animationEndTimeoutRef.current)
            }

            setAnimationClass(styles.slideDown)

            animationEndTimeoutRef.current = setTimeout(() => {
                onClose()
                setAnimationClass(styles.slideUp)
                animationEndTimeoutRef.current = null
            }, ANIMATION_DURATION_MS)
        } else {
            onClose()
        }
    }

    const handleOrientationChange = () => {
        if (screen.orientation.type.includes("landscape")) {
            handleClose()
        }
    }

    useEffect(() => {
        // close messaging when orientation changes to landscape
        screen.orientation.addEventListener("change", handleOrientationChange)
        return () => {
            screen.orientation.removeEventListener(
                "change",
                handleOrientationChange,
            )
        }
    }, [handleOrientationChange])

    if (!context.logged_in_user) {
        return null
    }

    const initialEntries = initialEntry ? [initialEntry] : undefined

    return (
        <div
            className={classNames(styles.container, {
                [animationClass]: isMobile,
            })}
            id="messaging-container"
        >
            <MessagingProvider
                view={view}
                topics={topics}
                interfaces={interfaces}
                eventRouters={eventRouters}
            >
                <VideoPlayerProvider>
                    <MemoryRouter
                        key={sessionKey}
                        initialEntries={initialEntries}
                    >
                        <Routes>
                            <Route
                                path="/messages/:username"
                                element={
                                    <MessageList
                                        onClose={handleClose}
                                        onMinimize={onMinimize}
                                        isMinimized={isMinimized}
                                    />
                                }
                            />
                            <Route path="/settings" element={<Settings />} />
                            <Route
                                path="/settings/message-profile"
                                element={<MessageProfile />}
                            />
                            <Route
                                path="/unified-chat/:username?"
                                element={<UnifiedChat />}
                            />
                            <Route
                                path="/search-users"
                                element={<SearchUsers />}
                            />
                            <Route
                                path="/"
                                element={<Inbox onClose={handleClose} />}
                            />
                            <Route path="*" element={<div>Not Found</div>} />
                        </Routes>
                    </MemoryRouter>
                    <ToastManager />
                </VideoPlayerProvider>
            </MessagingProvider>
        </div>
    )
}
