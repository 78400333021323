import { t } from "@lingui/macro"
import styles from "./RenderedMessage.module.scss"
import { parseEmoticon } from "./utils"
import type { Message } from "../types"

type Props = {
    message: Message
}

export function RenderedMessage({ message }: Props) {
    const messageContent = message.m

    let lastIndex = 0
    const parts = []
    let keyIndex = 0
    let match
    const regex = /%%%\[emoticon .*?\]%%%/g

    while ((match = regex.exec(messageContent)) !== null) {
        if (match.index > lastIndex) {
            const text = messageContent.substring(lastIndex, match.index)
            parts.push(<span key={`text-${keyIndex++}`}>{text}</span>)
        }

        const emoticonString = match[0]
        const emoticonData = parseEmoticon(emoticonString)

        if (emoticonData) {
            const src = emoticonData.thumbUrl || emoticonData.imgUrl
            parts.push(
                <img
                    key={`img-${keyIndex++}`}
                    src={src}
                    alt={emoticonData.name}
                    width={emoticonData.width}
                    height={emoticonData.height}
                />,
            )
        } else {
            // should never happen
            parts.push(
                <span
                    key={`text-${keyIndex++}`}
                >{t`Failed to parse emoticon`}</span>,
            )
        }
        lastIndex = regex.lastIndex
    }

    if (lastIndex < messageContent.length) {
        parts.push(
            <span key={`text-${keyIndex++}`}>
                {messageContent.substring(lastIndex)}
            </span>,
        )
    }

    return <div className={styles.container}>{parts}</div>
}
