import "./CombinedOverlay.scss"
import "../filterSections/filterSection.scss"
import type React from "react"
import { type ReactNode, useRef, useState } from "react"
import { t } from "@lingui/macro"
import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
    isLanguageFilterActive,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { useAppContext } from "../../../../../hooks/appContext"
import { useUrlStateContext } from "../../../../../hooks/urlStateContext"
import { useFiltersContext } from "../FiltersContext"
import { AgeSectionContent } from "../filterSections/AgeSection/AgeSection"
import { HashtagSectionContent } from "../filterSections/hashtagSection/HashtagSection"
import { LanguageSectionContent } from "../filterSections/languageSection/LanguageSection/"
import { OtherSection } from "../filterSections/OtherSection/OtherSection"
import { PrivatePriceSectionContent } from "../filterSections/PrivatePriceSection/PrivatePriceSection"
import { RegionSectionContent } from "../filterSections/RegionSection/RegionSection"
import { RoomSizeSectionContent } from "../filterSections/RoomSizeSection/RoomSizeSection"
import { SpyPriceSectionContent } from "../filterSections/SpyPriceSection/SpyPriceSection"
import { getActiveFilterCount, preventFilterDrawerDrag } from "../helpers"
import { ActiveFiltersSection } from "../types"
import type { EventRouter } from "../../../../messaging/types"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

interface CombinedOverlayProps {
    showLocationRouter: EventRouter<boolean>
    setThumbnailAnimations: (animate: boolean) => void
    reloadRooms: (onSuccess: () => void) => void
}

export const CombinedOverlay = ({
    showLocationRouter,
    setThumbnailAnimations,
    reloadRooms,
}: CombinedOverlayProps) => {
    const { setOverlayedSection, setTagSearchInput, setLanguageSearchInput } =
        useFiltersContext()
    const urlStateContext = useUrlStateContext()
    const appContext = useAppContext()

    const initialOptionsRef = useRef({
        showLocation: appContext.context.show_location,
        animateThumbnails:
            appContext.context.logged_in_user?.is_supporter &&
            appContext.context.animate_thumbnails,
    })
    const [options, setOptions] = useState({
        showLocation: appContext.context.show_location,
        animateThumbnails:
            appContext.context.logged_in_user?.is_supporter &&
            appContext.context.animate_thumbnails,
    })
    const showLanguageFilters = isLanguageFilterActive()

    const defaultAge =
        (urlStateContext.ageMin === undefined ||
            urlStateContext.ageMin === DEFAULT_MIN_AGE) &&
        (urlStateContext.ageMax === undefined ||
            urlStateContext.ageMax === DEFAULT_MAX_AGE)

    const onClear = () => {
        if (nSelections === 0) {
            return
        }
        UrlState.current.setPartialState({
            regions: undefined,
            roomSize: undefined,
            privatePrices: undefined,
            spyShowPrices: undefined,
            tags: undefined,
            ageMin: DEFAULT_MIN_AGE,
            ageMax: DEFAULT_MAX_AGE,
            spokenLanguages: undefined,
        })
        setTagSearchInput("")
        setLanguageSearchInput("")
    }

    const clearAgeFilters = () => {
        if (!defaultAge) {
            UrlState.current.setPartialState({
                ageMin: DEFAULT_MIN_AGE,
                ageMax: DEFAULT_MAX_AGE,
            })
        }
    }

    const optionsChanged =
        options.showLocation !== initialOptionsRef.current.showLocation ||
        options.animateThumbnails !==
            initialOptionsRef.current.animateThumbnails
    const nSelections = getActiveFilterCount(urlStateContext)

    return (
        <div data-testid="mobile-filters-combined-overlay">
            <div
                className="FilterSection__ScrollableContent noScrollbar"
                onMouseDown={(e) => preventFilterDrawerDrag(e, true)}
                onTouchStart={(e) => preventFilterDrawerDrag(e, true)}
            >
                <FilterSectionCombined
                    title={t`Tags`}
                    count={(urlStateContext.tags ?? []).length ? 1 : 0}
                >
                    <HashtagSectionContent combinedOverlay={true} />
                </FilterSectionCombined>
                <FilterSectionCombined
                    title={t`Regions`}
                    count={(urlStateContext.regions ?? []).length}
                >
                    <RegionSectionContent combinedOverlay={true} />
                </FilterSectionCombined>
                <FilterSectionCombined
                    title={t`Room Size`}
                    count={urlStateContext.roomSize === undefined ? 0 : 1}
                >
                    <RoomSizeSectionContent combinedOverlay={true} />
                </FilterSectionCombined>
                <PriceFilterSectionCombined urlStateContext={urlStateContext} />
                <FilterSectionCombined
                    title={t`Ages`}
                    count={defaultAge ? 0 : 1}
                    onClear={() => {
                        if (!defaultAge) {
                            clearAgeFilters()
                        }
                    }}
                    isClearDisabled={defaultAge}
                >
                    <AgeSectionContent />
                </FilterSectionCombined>
                {showLanguageFilters && (
                    <FilterSectionCombined
                        title={t`Languages`}
                        count={
                            (urlStateContext.spokenLanguages ?? []).length
                                ? 1
                                : 0
                        }
                    >
                        <LanguageSectionContent combinedOverlay={true} />
                    </FilterSectionCombined>
                )}
                <FilterSectionCombined
                    title={t`Options`}
                    count={0}
                    isClearDisabled={true}
                >
                    <OtherSection
                        setThumbnailAnimations={setThumbnailAnimations}
                        showLocationRouter={showLocationRouter}
                        reloadRooms={reloadRooms}
                        previewRooms={options.animateThumbnails ?? false}
                        showLocation={options.showLocation}
                        onOptionChange={(name, value) =>
                            setOptions((prev) => ({ ...prev, [name]: value }))
                        }
                    />
                </FilterSectionCombined>
            </div>
            <CombinedOverlayFooter
                onClear={onClear}
                nSelections={nSelections}
                setOverlayedSection={setOverlayedSection}
                optionsChanged={optionsChanged}
            />
        </div>
    )
}

const CombinedOverlayFooter = ({
    onClear,
    nSelections,
    setOverlayedSection,
    optionsChanged,
}: {
    onClear: () => void
    nSelections: number
    setOverlayedSection: (section: ActiveFiltersSection) => void
    optionsChanged: boolean
}) => {
    return (
        <div className="FilterSection__footer">
            <button
                onClick={onClear}
                className={`FilterSection__clear-button${
                    nSelections === 0
                        ? " FilterSection__clear-button-disabled"
                        : ""
                }`}
            >
                {t`Clear all`}
                {`${nSelections === 0 ? "" : ` (${nSelections})`}`}
            </button>
            <button
                onClick={() => setOverlayedSection(ActiveFiltersSection.None)}
                className={`FilterSection__combined-apply${
                    nSelections === 0 && !optionsChanged
                        ? " FilterSection__combined-apply-disabled"
                        : ""
                }`}
            >
                {t`Show Results`}
            </button>
        </div>
    )
}

const PriceFilterSectionCombined = ({
    urlStateContext,
}: {
    urlStateContext: IURLState
}) => {
    const privateCount = (urlStateContext.privatePrices ?? []).length
    const spyCount = (urlStateContext.spyShowPrices ?? []).length
    return urlStateContext.showType !== ShowType.PRIVATE ? (
        <FilterSectionCombined
            title={t`Private Show Tokens Per Minute`}
            count={privateCount}
        >
            <PrivatePriceSectionContent combinedOverlay={true} />
        </FilterSectionCombined>
    ) : (
        <FilterSectionCombined
            title={t`Spy Show Tokens Per Minute`}
            count={spyCount}
        >
            <SpyPriceSectionContent combinedOverlay={true} />
        </FilterSectionCombined>
    )
}

interface FilterSectionCombinedProps {
    title: string
    count?: number
    onClear?: () => void
    children: ReactNode
    isClearDisabled?: boolean
}

const FilterSectionCombined: React.FC<FilterSectionCombinedProps> = ({
    title,
    count = 0,
    onClear,
    children,
    isClearDisabled = false,
}: FilterSectionCombinedProps) => {
    const getTestId = (title: string) => {
        return `mobile-filters-${title.toLowerCase().replaceAll(" ", "-")}-subsection`
    }
    return (
        <div className="FilterSection__section" data-testid={getTestId(title)}>
            <div className="FilterSection__header">
                <span className="FilterSection__header-text">
                    {title}
                    {count > 0 ? ` (${count})` : ""}
                </span>
                {onClear && (
                    <button
                        className={`FilterSection__clear-button${
                            isClearDisabled
                                ? " FilterSection__clear-button-disabled"
                                : ""
                        }`}
                        onClick={onClear}
                        disabled={isClearDisabled}
                    >
                        {t`Reset`}
                    </button>
                )}
            </div>
            {children}
        </div>
    )
}
