import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgScrollToBottom = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.217 2.5a.7.7 0 0 0-1.4 0v5.797L5.25 5.924a.77.77 0 0 0-1.029 0 .637.637 0 0 0 0 .95l3.689 3.41a.77.77 0 0 0 1.028 0l3.743-3.46a.637.637 0 0 0 0-.95.77.77 0 0 0-1.028 0L9.217 8.125zm-5.2 10.353c0-.357.29-.647.647-.647h7.706a.647.647 0 1 1 0 1.294H4.664a.647.647 0 0 1-.647-.647"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgScrollToBottom)
const Memo = memo(ForwardRef)
export default Memo
