import { t } from "@lingui/macro"
import { useParams } from "react-router-dom"
import { Inbox } from "../Inbox/Inbox"
import { MessageList } from "../MessageList/MessageList"
import styles from "./UnifiedChat.module.scss"

export const UnifiedChat: React.FC = () => {
    const { username } = useParams<{ username: string }>()

    return (
        <div className={styles.container}>
            <Inbox onClose={() => {}} />
            {username ? (
                <MessageList onClose={() => {}} isMinimized={false} />
            ) : (
                <div className={styles.emptyText}>
                    <h1>{t`Please select a conversation to start or send a new message.`}</h1>
                    <h2>{t`Caution: The Chaturbate Team will NEVER contact you via chat or ask for your password.`}</h2>
                </div>
            )}
        </div>
    )
}
