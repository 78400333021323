import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import { useUrlStateContext } from "../../../../../../../hooks/urlStateContext"
import { MAX_NUM_LANGUAGES } from "../../../../../LanguageFilterSection"
import { useFiltersContext } from "../../../FiltersContext"
import { FilterSection } from "../../FilterSection"
import { LanguageSearch } from "../LanguageSearch"

export const LanguageSection = () => {
    const { setLanguageSearchInput } = useFiltersContext()

    const urlStateContext = useUrlStateContext()
    const selectedLanguage = urlStateContext.spokenLanguages?.[0] ?? ""

    const clearSelections = () => {
        if (selectedLanguage === "") {
            // button should have no effect when no language is selected
            return
        }
        UrlState.current.setPartialState({ spokenLanguages: undefined })
        setLanguageSearchInput("")
    }

    return (
        <FilterSection
            isClearDisabled={selectedLanguage === ""}
            title="Languages"
            onClear={clearSelections}
            clearButtonText="Clear"
        >
            <LanguageSectionContent />
        </FilterSection>
    )
}

export const LanguageSectionContent = ({
    combinedOverlay = false,
}: {
    combinedOverlay?: boolean | undefined
}) => {
    const { languageOptions, loadingLanguageOptions, errorLanguageOptions } =
        useFiltersContext()
    const shownLanguages = languageOptions.slice(0, MAX_NUM_LANGUAGES)

    const urlStateContext = useUrlStateContext()
    const selectedLanguage = urlStateContext.spokenLanguages?.[0] ?? ""

    const languageInOptions = shownLanguages.find(
        (language) => language.code === selectedLanguage,
    )

    const displayName = SPOKEN_LANGUAGES_MAP.get(selectedLanguage)
    if (languageInOptions === undefined && displayName !== undefined) {
        shownLanguages.unshift({
            code: selectedLanguage,
            display_name: displayName,
        })
    }

    return (
        <div className="LanguageSection">
            <LanguageSearch />
            <div
                className={`FilterSection__filterOptions${combinedOverlay ? "Combined" : ""}`}
            >
                {errorLanguageOptions ? (
                    <div className="filterOptionsStatus--Error">
                        Failed to load language options
                    </div>
                ) : loadingLanguageOptions ? (
                    <div className="filterOptionsStatus--Loading">
                        Loading...
                    </div>
                ) : (
                    shownLanguages.map((language) => (
                        <button
                            key={language.code}
                            className={`FilterSection__filterOption ${
                                selectedLanguage === language.code
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() =>
                                UrlState.current.setPartialState({
                                    spokenLanguages:
                                        language.code === selectedLanguage
                                            ? undefined
                                            : [language.code],
                                })
                            }
                            data-testid={`language-button-${language.code}`}
                        >
                            {language.display_name}
                        </button>
                    ))
                )}
            </div>
        </div>
    )
}
