import { useEffect, useRef, useState } from "react"

interface Visibility {
    ref: React.RefObject<HTMLElement>
    isVisible: boolean
}

export const useVisibility = <T extends HTMLElement>(): Visibility => {
    const [isVisible, setIsVisible] = useState(false)
    const ref = useRef<T>(null!)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true)
                observer.disconnect()
            }
        })

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => observer.disconnect()
    }, [])

    return { ref, isVisible }
}
