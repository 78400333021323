import { t } from "@lingui/macro"
import { useGetProfileQuery } from "../../../store/messagingSlice"
import styles from "./MessageRowDisclaimer.module.scss"

export const MessageRowDisclaimer: React.FC<{ username: string }> = ({
    username,
}) => {
    const { data: profile } = useGetProfileQuery(username)

    return (
        <div className={styles.messageRowDisclaimer} data-testid="notices">
            <div
                className={styles.messageRowNotice}
            >{t`Private conversation with ${username}`}</div>
            <div
                className={styles.messageRowNotice}
            >{t`Caution: The Chaturbate Team will NEVER contact you via chat or ask for your password.`}</div>
            {profile && !profile.can_pm && (
                <div
                    className={styles.messageRowNotice}
                >{t`You must be a supporter, fan club member, or moderator to send this direct message.`}</div>
            )}
        </div>
    )
}
