import { SearchThin } from "../../../common/atoms/Icons/Others"
import "./MobileSearchIcon.scss"

export function MobileSearchIcon({ onClick }: { onClick: () => void }) {
    return (
        <button
            className="MobileSearchIcon"
            data-testid="mobile-search-icon"
            onClick={onClick}
        >
            <SearchThin width={24} height={24} />
        </button>
    )
}
