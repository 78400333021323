import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgRecordConnectingSpinner = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 44 44"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle cx={22} cy={22} r={22} fill="#C60101" fillOpacity={0.5} />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={2}
            d="M43 22A21 21 0 0 1 5.01 34.343"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgRecordConnectingSpinner)
const Memo = memo(ForwardRef)
export default Memo
