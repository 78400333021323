import type { ElementType } from "react"
import { createRef } from "react"
import { Sizes, Weights } from "../../../../interfaces/sizing"
import { getUsernameColorClassDeprecated } from "../../../../utils/css/user"
import { GenderIcon } from "../../../common/atoms/Icons/Gender/GenderIcon"
import { Typography } from "../../../common/atoms/Typography"
import type { IUserInfo } from "../../../../interfaces/userMenu"
import "./UserName.scss"

type UserNameProps = {
    user: IUserInfo
    openMenu: (element: HTMLElement) => void
}

export function UserName({ user, openMenu }: UserNameProps) {
    const colorClass = getUsernameColorClassDeprecated(user)
    const usernameRef = createRef<ElementType>()

    const handleContextMenu = (event: React.MouseEvent) => {
        if (
            usernameRef.current === null ||
            !(usernameRef.current instanceof HTMLElement)
        ) {
            return
        }
        openMenu(usernameRef.current)
        event.preventDefault()
    }

    return (
        <div
            className="username"
            onClick={handleContextMenu}
            onContextMenu={handleContextMenu}
        >
            <div className="genderIcon">
                <GenderIcon gender={user.gender} />
            </div>
            <Typography
                size={Sizes.xs}
                color={colorClass}
                weight={Weights.bold}
                className="usernameText"
                ref={usernameRef}
            >
                {user.username}
            </Typography>
        </div>
    )
}
