import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgReport = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 18 18"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#C00"
            d="M9 14q.424 0 .713-.287A.97.97 0 0 0 10 13a.97.97 0 0 0-.287-.713A.97.97 0 0 0 9 12a.97.97 0 0 0-.713.287A.97.97 0 0 0 8 13q0 .424.287.713Q8.576 14 9 14m-1-4h2V4H8zm-2.75 8L0 12.75v-7.5L5.25 0h7.5L18 5.25v7.5L12.75 18z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgReport)
const Memo = memo(ForwardRef)
export default Memo
