import { useGetProfileQuery } from "../../../store/messagingSlice"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import styles from "./MessageTyping.module.scss"

type MessageTypingProps = {
    username: string
}

export function MessageTyping({ username }: MessageTypingProps) {
    const { data: profile } = useGetProfileQuery(username || "")

    return (
        <div className={styles.container}>
            <div className={styles.avatarContainer}>
                <ProfilePicture user={profile?.user} size={24} />
            </div>
            <div
                className={styles.typingIndicator}
                data-testid="typing-indicator"
            >
                <div className={styles.bubble} />
                <div className={styles.bubble} />
                <div className={styles.bubble} />
            </div>
        </div>
    )
}
