import type { IUserInfo as IUserInfoDeprecated } from "../../interfaces/userMenu"
import type { IUserInfo } from "@multimediallc/web-utils/types"

// Maintains insertion order and so also encodes priority
export const USER_ATTR_TO_CLASS_MAP = new Map<keyof IUserInfo, string>([
    ["isBroadcaster", "broadcaster"],
    ["isMod", "mod"],
    ["inFanclub", "inFanclub"],
    ["tippedTonsRecently", "tippedTonsRecently"],
    ["tippedALotRecently", "tippedALotRecently"],
    ["tippedRecently", "tippedRecently"],
    ["hasTokens", "hasTokens"],
])

export function getUsernameColorClassDeprecated(
    u: IUserInfoDeprecated,
): string {
    return getUsernameColorClass(u as IUserInfo)
}

export function getUsernameColorClass(u: IUserInfo): string {
    for (const [attr, className] of USER_ATTR_TO_CLASS_MAP) {
        if (u[attr] === true) {
            return className
        }
    }
    return "defaultUser"
}
