// Cache to store last tip amounts per user
const lastTipAmounts = new Map<string, string>()

export const getLastTipAmount = (username: string): string | undefined => {
    return lastTipAmounts.get(username)
}

export const setLastTipAmount = (username: string, amount: string): void => {
    lastTipAmounts.set(username, amount)
}
