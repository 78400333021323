import { useEffect, useState } from "react"
import { CheckmarkCircleGreen } from "../../../common/atoms/Icons/Chat"
import { classNames } from "../../utils"
import styles from "./Toast.module.scss"

interface ToastProps {
    message: string
    position?: "top" | "bottom"
    preset?: "light" | "dark"
    duration?: number
    showIcon?: boolean
    className?: string
    onClose: () => void
}

export const Toast: React.FC<ToastProps> = ({
    message,
    position = "top",
    preset = "light",
    duration = 2500,
    showIcon = true,
    className,
    onClose,
}) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(true)
        const timer = setTimeout(() => {
            setVisible(false)
            setTimeout(onClose, 500) // Allow animation to complete before removal
        }, duration)

        return () => clearTimeout(timer)
    }, [duration, onClose])

    return (
        <div
            className={classNames(
                styles.toast,
                styles[position],
                styles[preset],
                {
                    [styles.visible]: visible,
                },
                className,
            )}
            role="alert"
            data-testid="toast-container"
            style={
                { "--toast-duration": `${duration}ms` } as React.CSSProperties
            }
        >
            {showIcon && <CheckmarkCircleGreen className={styles.iconCheck} />}
            {message}
        </div>
    )
}
