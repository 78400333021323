import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useMessagingView } from "../hooks"

/**
 * Returns a function to navigate to the appropriate conversation route based on view type.
 * @returns A function that navigates to the correct conversation route when called.
 */
export const useNavigateConversation = (): ((username?: string) => void) => {
    const navigate = useNavigate()
    const messageView = useMessagingView()

    const navigateToConversation = useCallback(
        (username?: string) => {
            if (!username) return

            if (messageView.isDesktopUnified) {
                navigate(`/unified-chat/${username}`)
                return
            }

            navigate(`/messages/${username}`)
        },
        [messageView],
    )

    return navigateToConversation
}
