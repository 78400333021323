import { plural, t } from "@lingui/macro"
import { useAppContext } from "../../../hooks/appContext"
import { draftCache } from "../DraftCache/DraftCache"
import styles from "./MessagePreview.module.scss"
import type { Thread } from "../types"

const MAX_MESSAGE_DISPLAY_COUNT = 99

type Props = {
    thread: Thread
    isTyping: boolean
}

export function MessagePreview({ thread, isTyping }: Props) {
    const fromUser = thread.from_user
    const otherUser = thread.other_user.username
    const loggedInUsername = useAppContext().context.logged_in_user?.username
    const draft = draftCache.get(thread.other_user.username)

    if (draft && draft.message) {
        return (
            <div>
                <span className={styles.draftPretext}>{t`Draft:`}</span>{" "}
                {draft.message}
            </div>
        )
    }

    if (draft && !draft.message && !thread.m) {
        return <span />
    }

    if (isTyping) {
        return <span>{t`${otherUser} is typing...`}</span>
    }

    if (thread.tip_amount) {
        const amount = thread.tip_amount
        const sender = thread.other_user.username
        const tokenText = plural(amount, {
            one: `${amount} token`,
            other: `${amount} tokens`,
        })

        if (thread.from_user === thread.other_user.username) {
            return <span>{t`${sender} tipped ${tokenText}`}</span>
        }
        return <span>{t`You tipped ${tokenText}`}</span>
    }

    const unread = thread.num_unread

    let content
    if (unread < 2) {
        const isLastMsgByUser = fromUser === loggedInUsername
        content = isLastMsgByUser ? (
            <span>
                {t`You:`} {thread.m}
            </span>
        ) : (
            <span>{thread.m}</span>
        )
    } else {
        const unreadText =
            unread > MAX_MESSAGE_DISPLAY_COUNT
                ? t`${MAX_MESSAGE_DISPLAY_COUNT}+ new messages`
                : t`${unread} new messages`
        content = <span>{unreadText}</span>
    }
    return content
}
