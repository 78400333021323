// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jatrVNhTfkKbVN4gf5ii{padding:4px 0}.D1zWPTiwfgzBRSIwzxX3{display:flex;padding:0 8px}.bknr2snJbBuN_VQtHUTx{width:24px;margin:0 8px}.SD6UxQ3JTwkCHqf9qyKa{padding:4px 0;background:#fff;cursor:pointer;display:flex;align-items:center}.SD6UxQ3JTwkCHqf9qyKa>span{margin-left:12px}.NNt7s20Q1F8ky51o0EMw{margin-top:8px;overflow-y:auto;padding:0 8px;display:flex;flex-direction:column;flex:1}.GMXK3n_AvuPKB971XRgp{padding:10px 16px;color:#6e6f70;font-size:16px;display:flex;justify-content:center}.GMXK3n_AvuPKB971XRgp.kegyOoRsK8TkClvjypyz{color:#c00}.GMXK3n_AvuPKB971XRgp.CmfuDBJ71tWDDYq_7v8p{justify-content:flex-start}.GMXK3n_AvuPKB971XRgp.CLACwcERrIge26C_V08_{display:flex;align-items:center;justify-content:center}.GMXK3n_AvuPKB971XRgp.CLACwcERrIge26C_V08_ .cbr-spinner{width:16px;height:16px;margin-right:8px}.OVm_nUp1cRmNBcJdH5Bk{display:flex;justify-content:center;align-items:center;flex-direction:column;flex:1}.njLSgLCofJDBnnLKCc0r{width:168px;height:168px}.WTzRLFlRQDgbZ4x8TkXZ{margin-top:18px;text-align:center;line-height:17px;width:220px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `jatrVNhTfkKbVN4gf5ii`,
	"header": `D1zWPTiwfgzBRSIwzxX3`,
	"backButton": `bknr2snJbBuN_VQtHUTx`,
	"searchResult": `SD6UxQ3JTwkCHqf9qyKa`,
	"results": `NNt7s20Q1F8ky51o0EMw`,
	"message": `GMXK3n_AvuPKB971XRgp`,
	"error": `kegyOoRsK8TkClvjypyz`,
	"left": `CmfuDBJ71tWDDYq_7v8p`,
	"loading": `CLACwcERrIge26C_V08_`,
	"initialPlaceholderContainer": `OVm_nUp1cRmNBcJdH5Bk`,
	"searchPlaceholderSvg": `njLSgLCofJDBnnLKCc0r`,
	"searchPlaceholderText": `WTzRLFlRQDgbZ4x8TkXZ`
};
export default ___CSS_LOADER_EXPORT___;
