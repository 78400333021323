import type { ButtonHTMLAttributes, ReactElement, ReactNode } from "react"
import { cloneElement, isValidElement } from "react"
import "./IconButton.scss"
import { classNames } from "../../utils"

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: ReactNode
    iconAlt?: string
    className?: string
}

export function IconButton({ icon, iconAlt, className, ...props }: Props) {
    const Icon =
        isValidElement(icon) && iconAlt
            ? cloneElement(icon as ReactElement, { title: iconAlt })
            : icon

    return (
        <button className={classNames("msg-icon-button", className)} {...props}>
            {Icon}
        </button>
    )
}
