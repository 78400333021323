import { useAppSelector } from "../../../store/hooks"
import { ImageAdd, Plus } from "../../common/atoms/Icons/Chat"
import { Female2 } from "../../common/atoms/Icons/Gender"
import { Page } from "../common/Page/Page"
import { Textarea } from "../common/Textarea/Textarea"
import { MessageProfileFormActions } from "../MessageProfileFormActions/MessageProfileFormActions"
import { classNames } from "../utils"
import styles from "./MessageProfile.module.scss"

export const MessageProfile: React.FC = () => {
    const me = useAppSelector((state) => state.user.loggedInUser)
    return (
        <Page title="Message Profile">
            <div className={styles.container}>
                <p className={styles.descriptionText}>
                    This profile helps viewers learn about you before
                    connecting. You will appear in the recommended list when you
                    publish your profile.
                </p>
                <div
                    className={classNames(
                        styles.profileInfo,
                        styles.bottomMargin,
                    )}
                >
                    <button className={styles.addProfileImage}>
                        <span className={styles.iconWrapper}>
                            <ImageAdd height={28} width={36} />
                        </span>
                    </button>
                    <div className={styles.username}>
                        <Female2 height={16} width={10} />
                        <span>{me?.username}</span>
                    </div>
                </div>
                <Textarea
                    placeholder="Introduce yourself to help other connect"
                    label="Bio"
                    maxLength={75}
                    className={styles.bottomMargin}
                />
                <div
                    className={classNames(
                        styles.mediaSelect,
                        styles.bottomMargin,
                    )}
                >
                    <div className={styles.label}>
                        Upload up to 4 media items (photo or video)
                    </div>
                    <div className={styles.previewGrid}>
                        <div
                            className={classNames(
                                styles.preview,
                                styles.upload,
                            )}
                        >
                            <Plus height={28} width={28} />
                        </div>
                        <div className={styles.preview} />
                        <div className={styles.preview} />
                        <div className={styles.preview} />
                    </div>
                </div>
                <Textarea
                    placeholder="When a viewer starts a private conversation, your customized welcome message will be sent."
                    label="Welcome message"
                    maxLength={75}
                    className={styles.bottomMargin}
                />
                <MessageProfileFormActions />
            </div>
        </Page>
    )
}
