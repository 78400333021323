import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgRefresh = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0C6A93"
            d="M9.767 2.233A5.3 5.3 0 0 0 6 .667a5.333 5.333 0 1 0 0 10.666c2.486 0 4.56-1.7 5.153-4H9.767A3.994 3.994 0 0 1 3.17 8.83 4 4 0 0 1 6 2c1.106 0 2.093.46 2.813 1.187L6.666 5.333h4.667V.667z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgRefresh)
const Memo = memo(ForwardRef)
export default Memo
