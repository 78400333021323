import { createContext, useContext } from "react"
import type { BroadcastStatus } from "@multimediallc/web-utils"
import type { IPrivateMessage, IUserInfo } from "@multimediallc/web-utils/types"

export type MobileBroadcastContextData = {
    broadcastStatus: BroadcastStatus
    onRecordButtonClick: () => void
    muted: boolean
    setMute: (mute: boolean) => void
    room: string

    // Tech debt section 😭 Please do not add anything new below this line
    createMediasetThumbnails: (m: IPrivateMessage) => HTMLDivElement | undefined // No jira ticket yet for removing this, but is part of the upcoming MVR+ PRD
    getUserMentionInfo: (username: string) => IUserInfo | undefined // CBD-1949
}

export const MobileBroadcastContext = createContext<MobileBroadcastContextData>(
    undefined as unknown as MobileBroadcastContextData,
)

export function useMobileBroadcastContext(): MobileBroadcastContextData {
    const context = useContext<MobileBroadcastContextData>(
        MobileBroadcastContext,
    )
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!context) {
        throw new Error(
            "useMobileBroadcastContext must be used under MobileBroadcastContext.Provider",
        )
    }
    return context
}
