import React from "react"
import { Typography } from "../Typography"

type LabelProps = Omit<
    React.ComponentPropsWithRef<typeof Typography>,
    "component"
>

/**
 * Label exists as just a thin wrapper around `<Typography>` that guarantees we get a `<label>`
 * One benefit is that we can increase the specificity of the ref typing, which means referencing
 * it properly gives us an HTMLLabelElement.
 */
export const Label = React.forwardRef<React.ElementType<"label">, LabelProps>(
    ({ children, ...props }, ref) => {
        return (
            <Typography component="label" ref={ref} {...props}>
                {children}
            </Typography>
        )
    },
)
