import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgTrash = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#C00"
            d="M7 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 5 19V6a1 1 0 0 1 0-2h4V3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h4a1 1 0 1 1 0 2v13q0 .824-.587 1.413A1.93 1.93 0 0 1 17 21zm2-4h2V8H9zm4 0h2V8h-2z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgTrash)
const Memo = memo(ForwardRef)
export default Memo
