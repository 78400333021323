import { RoomStatusAndFPS } from "../RoomStatusAndFPS"
import { RotatePrompt } from "../RotatePrompt"
import "./MobileBroadcastPortraitUI.scss"

export function MobileBroadcastPortraitUI() {
    // Eventually in the MVR this will also include room status
    return (
        <div
            className="MobileBroadcastPortraitUI"
            data-testid="mobilebroadcast-portrait-ui"
        >
            <RotatePrompt />
            <div className="MobileBroadcastPortraitUI__statusContainer">
                <RoomStatusAndFPS />
            </div>
        </div>
    )
}
