// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IRvDXEU6jvBFUwoNJ1s0{position:relative;width:100%;height:100%}.VuxwRxlXkBBF55H85K_T{position:fixed;top:0;left:0;right:0;bottom:0;width:100dvw;height:100dvh;border-radius:0;background-color:#000;z-index:1010;display:flex;justify-content:center;align-items:center}.k7RDwuJucvlgLDU6rdzr{position:fixed;top:16px;right:16px;z-index:1011;background-color:rgba(0,0,0,.5);border-radius:50%;padding:8px;cursor:pointer;transition:opacity .3s ease}.CTMLunkurz2GoaY7njp_{opacity:0;pointer-events:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatMode": `IRvDXEU6jvBFUwoNJ1s0`,
	"fullscreenMode": `VuxwRxlXkBBF55H85K_T`,
	"closeButton": `k7RDwuJucvlgLDU6rdzr`,
	"hidden": `CTMLunkurz2GoaY7njp_`
};
export default ___CSS_LOADER_EXPORT___;
