import { useEffect, useRef } from "react"
import { t } from "@lingui/macro"
import {
    isMobileFilterPanelActive,
    PageType,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import "./TopTagsBanner.scss"
import { getCurrentGender } from "@multimediallc/gender-utils"

export const EXCLUDED_SHOW_TYPES = [
    ShowType.FOLLOW,
    ShowType.FOLLOW_OFFLINE,
    ShowType.HIDDEN,
    ShowType.PRIVATE,
]

interface TopTagsBannerProps {
    activeHashtag?: string
    showType?: ShowType
    topTags: string[]
    onRender?: () => void
}

export function TopTagsBanner({
    activeHashtag,
    showType,
    topTags,
    onRender,
}: TopTagsBannerProps) {
    // onRender is a bit of a hack to avoid "pop-in" from the react component
    // finishing its render after its placeholder has already been replaced.
    // Instead we just pass the replacing function as a callback so it's only
    // swapped in after rendering has already finished.
    useEffect(() => onRender && onRender(), [])

    // Scroll back to beginning of tags list on props change
    const scrollRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = 0
        }
    }, [activeHashtag, JSON.stringify(topTags)])

    if (showType !== undefined && EXCLUDED_SHOW_TYPES.includes(showType)) {
        return null
    }

    if (topTags.length === 0) {
        // Avoid layout shift if the banner WILL render but the tags haven't loaded yet
        return <div className="TopTagsBanner" />
    }

    const getTagLinkHref = (tagName: string): string => {
        const targetTags = activeHashtag === tagName ? undefined : [tagName]
        if (isMobileFilterPanelActive()) {
            return UrlState.current.getURLForPartialState({ tags: targetTags })
        }
        return UrlState.current.convertStateToUrl(
            {
                pageType: PageType.HOME,
                tags: targetTags,
                genders: [getCurrentGender()],
            },
            false,
        )
    }

    const onTagSelected = (tagName: string): void => {
        const targetTags = activeHashtag === tagName ? undefined : [tagName]
        UrlState.current.setPartialState({ tags: targetTags })
    }

    // Higher-order function to generate click event handlers
    const onTagLinkClick =
        (tagName: string) =>
        (event: React.MouseEvent): void => {
            if (event.ctrlKey || event.metaKey || event.shiftKey) {
                return
            }
            event.preventDefault()
            if (isMobileFilterPanelActive()) {
                onTagSelected(tagName)
            } else {
                UrlState.current.navigateTo(
                    (event.target as HTMLAnchorElement).href,
                )
            }
        }

    return (
        <div className="TopTagsBanner">
            <div className="tag-links-container">
                <a className="hashtag-link all-tags" href="/tags/">
                    {t`All tags`}
                </a>
                <div className="scrollable-tags-container" ref={scrollRef}>
                    {activeHashtag !== undefined &&
                        !topTags.includes(activeHashtag) && (
                            <a
                                className="hashtag-link active"
                                href={getTagLinkHref(activeHashtag)}
                                key={activeHashtag}
                                onClick={onTagLinkClick(activeHashtag)}
                            >
                                {activeHashtag}
                            </a>
                        )}
                    {topTags.map((tagName) => (
                        <a
                            className={`hashtag-link${tagName === activeHashtag ? " active" : ""}`}
                            href={getTagLinkHref(tagName)}
                            key={tagName}
                            onClick={onTagLinkClick(tagName)}
                        >
                            {tagName}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
