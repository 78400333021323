import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMuted = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 42 42"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={42} height={42} fill="#4A4A4A" fillOpacity={0.5} rx={20} />
        <path
            fill="#fff"
            d="M26.75 24.95 25.3 23.5a4.8 4.8 0 0 0 .525-1.2Q26 21.675 26 21h2q0 1.1-.325 2.087a7.3 7.3 0 0 1-.925 1.863m-2.95-3-1.8-1.8V15a.97.97 0 0 0-.288-.713A.97.97 0 0 0 21 14a.97.97 0 0 0-.712.287A.97.97 0 0 0 20 15v3.15l-2-2V15q0-1.25.875-2.125A2.9 2.9 0 0 1 21 12q1.25 0 2.125.875T24 15v6q0 .275-.062.5-.063.225-.138.45M20 31v-3.075q-2.6-.35-4.3-2.325T14 21h2q0 2.075 1.438 3.538T21 26a4.92 4.92 0 0 0 3-1l1.425 1.425a8 8 0 0 1-1.588.975 6.2 6.2 0 0 1-1.837.525V31zm8.8 1.6L10.4 14.2l1.4-1.4 18.4 18.4z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgMuted)
const Memo = memo(ForwardRef)
export default Memo
