import { msg } from "@lingui/macro"
import type { MessageDescriptor } from "@lingui/core"
import type { IChatursafeFilterSettings } from "@multimediallc/web-utils/types"

export type CategoryMapping = {
    title: MessageDescriptor
    description: MessageDescriptor
    enabled_key: keyof IChatursafeFilterSettings
    value_key: keyof IChatursafeFilterSettings
}

export const CHATURSAFE_CATEGORY_MAPPING: Record<string, CategoryMapping> = {
    toxic: {
        title: msg`Toxic`,
        description: msg`Content that is rude, disrespectful, or unreasonable.`,
        enabled_key: "toxic_enabled",
        value_key: "toxic_value",
    },
    firearms_weapons: {
        title: msg`Firearms & Weapons`,
        description: msg`Content that mentions knives, guns, personal weapons, and accessories such as ammunition, holsters, etc.`,
        enabled_key: "firearms_weapons_enabled",
        value_key: "firearms_weapons_value",
    },
    public_safety: {
        title: msg`Public Safety`,
        description: msg`Services and organizations that provide relief and ensure public safety.`,
        enabled_key: "public_safety_enabled",
        value_key: "public_safety_value",
    },
    poaching: {
        title: msg`Poaching`,
        description: msg`Content from other streams attempting to redirect users to their own streams or URLS.`,
        enabled_key: "poaching_enabled",
        value_key: "poaching_value",
    },
    derogatory: {
        title: msg`Derogatory`,
        description: msg`Negative or harmful comments targeting identity and/or protected attributes.`,
        enabled_key: "derogatory_enabled",
        value_key: "derogatory_value",
    },
    violent: {
        title: msg`Violent`,
        description: msg`Describes scenarios depicting violence against an individual or group, or general descriptions of gore.`,
        enabled_key: "violent_enabled",
        value_key: "violent_value",
    },
    sexual: {
        title: msg`Sexual`,
        description: msg`Contains references to sexual acts or other lewd content.`,
        enabled_key: "sexual_enabled",
        value_key: "sexual_value",
    },
    insult: {
        title: msg`Insult`,
        description: msg`Insulting, inflammatory, or negative comment towards a person or a group of people.`,
        enabled_key: "insult_enabled",
        value_key: "insult_value",
    },
    profanity: {
        title: msg`Profanity`,
        description: msg`Obscene or vulgar language such as cursing.`,
        enabled_key: "profanity_enabled",
        value_key: "profanity_value",
    },
    death_harm_tragedy: {
        title: msg`Death, Harm & Tragedy`,
        description: msg`Human deaths, tragedies, accidents, disasters, and self-harm.`,
        enabled_key: "death_harm_tragedy_enabled",
        value_key: "death_harm_tragedy_value",
    },
    health: {
        title: msg`Health`,
        description: msg`Human health includes health conditions, diseases, and disorders, medical therapies, medication, vaccination, medical practices, and resources for healing, including support groups.`,
        enabled_key: "health_enabled",
        value_key: "health_value",
    },
    religion_belief: {
        title: msg`Religion & Belief`,
        description: msg`Belief systems that consider the possibility of supernatural laws and beings include religion, faith, spiritual practice, churches, and places of worship. This category also includes astrology and the occult.`,
        enabled_key: "religion_belief_enabled",
        value_key: "religion_belief_value",
    },
    illicit_drugs: {
        title: msg`Drugs`,
        description: msg`Recreational and illicit drugs, drug paraphernalia and cultivation, headshops, etc. Includes medicinal use of drugs typically used recreationally (e.g., marijuana).`,
        enabled_key: "illicit_drugs_enabled",
        value_key: "illicit_drugs_value",
    },
    war_conflict: {
        title: msg`War & Conflict`,
        description: msg`War, military conflicts, and significant physical conflicts involve many people. This includes discussing military services, even those unrelated to war or conflict.`,
        enabled_key: "war_conflict_enabled",
        value_key: "war_conflict_value",
    },
    legal: {
        title: msg`Legal`,
        description: msg`Law-related content, including law firms, legal information, primary legal materials, paralegal services, legal publications and technology, expert witnesses, litigation consultants, and other legal service providers.`,
        enabled_key: "legal_enabled",
        value_key: "legal_value",
    },
    finance: {
        title: msg`Finance`,
        description: msg`Consumer and business financial services, such as banking, loans, credit, investing, and insurance.`,
        enabled_key: "finance_enabled",
        value_key: "finance_value",
    },
    politics: {
        title: msg`Politics`,
        description: msg`Political news and media; discussions of social, governmental, and public policy.`,
        enabled_key: "politics_enabled",
        value_key: "politics_value",
    },
}

export function convertSettingsDataToStrings(
    settings: IChatursafeFilterSettings,
): Record<string, string> {
    const result: Record<string, string> = {}
    for (const key in settings) {
        result[key] =
            settings[key as keyof IChatursafeFilterSettings]!.toString()
    }
    return result
}
