import { type ComponentPropsWithoutRef } from "react"
import "./OptInFlagDisplayHelpText.scss"
import { Trans } from "@lingui/macro"
import { FlagIcon } from "../../common/molecules/FlagIcon"

interface OptInFlagDisplayHelpTextProps
    extends ComponentPropsWithoutRef<"span"> {
    countryCode: string
    countryName: string
}

export function OptInFlagDisplayHelpText({
    countryCode,
    countryName,
    ...props
}: OptInFlagDisplayHelpTextProps) {
    return (
        <span className="OptInFlagDisplayHelpText" {...props}>
            <Trans>
                Show my country flag{" "}
                {countryCode === "" ? (
                    <></>
                ) : (
                    <span className="thumbnail_flag">
                        <FlagIcon
                            countryCode={countryCode}
                            countryName={countryName}
                            data-testid="opt-in-flag-diplay-help-text-flag-preview"
                        />
                    </span>
                )}{" "}
                on the home page thumbnail
            </Trans>
        </span>
    )
}
