import { t } from "@lingui/macro"
import { error } from "@multimediallc/logging"
import { fetchPost } from "../../../utils/myFetch"

interface FeedbackResponse {
    success: boolean
    errorMessage?: string
}

export const sendMessagingFeedback = async (
    selectedRating: number | null,
    comments: string,
): Promise<FeedbackResponse> => {
    const body: Record<string, string> = {
        url: window.location.origin,
        source: "messaging_feedback",
        prompts: "Additional comments (optional)",
        responses: comments.trim() ? comments : "",
    }

    if (selectedRating !== null) {
        body.sentiment = selectedRating.toString()
    }

    return fetchPost("/feedback/submit/", body)
        .then((resp) => {
            return { success: resp.status === 200 }
        })
        .catch((err) => {
            error("Error occurred while sending feedback request:", err)
            if (!navigator.onLine) {
                return {
                    success: false,
                    errorMessage: t`You are offline. Check your connection and try again.`,
                }
            }
            return {
                success: false,
                errorMessage:
                    err?.message ||
                    t`Something went wrong. Please try again later.`,
            }
        })
}
