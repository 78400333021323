import type { FC, ReactNode } from "react"
import { createContext, useContext, useMemo, useReducer } from "react"

export type VideoId = string

interface VideoPlayerState {
    currentPlaying: VideoId | null
    isMuted: boolean
}

enum ActionType {
    PLAY = "PLAY",
    PAUSE = "PAUSE",
    TOGGLE_MUTE = "TOGGLE_MUTE",
    SET_MUTE = "SET_MUTE",
}

type VideoPlayerAction =
    | { type: ActionType.PLAY; videoId: VideoId }
    | { type: ActionType.PAUSE }
    | { type: ActionType.TOGGLE_MUTE }
    | { type: ActionType.SET_MUTE; isMuted: boolean }

const initialState: VideoPlayerState = {
    currentPlaying: null,
    isMuted: true,
}

function videoPlayerReducer(
    state: VideoPlayerState,
    action: VideoPlayerAction,
): VideoPlayerState {
    switch (action.type) {
        case ActionType.PLAY:
            return {
                ...state,
                currentPlaying: action.videoId,
            }
        case ActionType.PAUSE:
            return {
                ...state,
                currentPlaying: null,
            }
        case ActionType.TOGGLE_MUTE:
            return {
                ...state,
                isMuted: !state.isMuted,
            }
        case ActionType.SET_MUTE:
            return {
                ...state,
                isMuted: action.isMuted,
            }
        default:
            return state
    }
}

interface VideoPlayerContextType {
    state: VideoPlayerState
    play: (videoId: VideoId) => void
    pause: () => void
    toggleMute: () => void
    setMute: (isMuted: boolean) => void
}

const VideoPlayerContext = createContext<VideoPlayerContextType | null>(null)

interface VideoPlayerProviderProps {
    children: ReactNode
}

export const VideoPlayerProvider: FC<VideoPlayerProviderProps> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(videoPlayerReducer, initialState)

    const actions = useMemo(
        () => ({
            play: (videoId: VideoId) =>
                dispatch({ type: ActionType.PLAY, videoId }),
            pause: () => dispatch({ type: ActionType.PAUSE }),
            toggleMute: () => dispatch({ type: ActionType.TOGGLE_MUTE }),
            setMute: (isMuted: boolean) =>
                dispatch({ type: ActionType.SET_MUTE, isMuted }),
        }),
        [],
    )

    const value = {
        state,
        ...actions,
    }

    return (
        <VideoPlayerContext.Provider value={value}>
            {children}
        </VideoPlayerContext.Provider>
    )
}

/** Must be used within a VideoPlayerProvider */
export const useVideoPlayer = () => {
    const context = useContext(VideoPlayerContext)
    if (!context) {
        throw new Error(
            "useVideoPlayer must be used within a VideoPlayerProvider",
        )
    }
    return context
}
