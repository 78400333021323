import { useChatUserMessageEffect } from "../store/hooks/chatHooks"
import {
    usePrivateRequestStatusEffect,
    useRoomStatusEffect,
    useRoomSubjectPushEffect,
} from "../store/hooks/roomHooks"
import {
    useFollowersEffect,
    useTokenUpdateEffect,
} from "../store/hooks/userHooks"
import { useAppContext } from "./appContext"
import type { MobileBroadcastTopics } from "../components/mobile_broadcast/types"

type Props = {
    topics: MobileBroadcastTopics
    roomUid: string | undefined
}

export const useBroadcastPushEffect = ({ topics, roomUid }: Props): void => {
    const { context: appContext } = useAppContext()
    const uuid = appContext.logged_in_user?.user_uid

    const { UserTokenUpdateTopic, UserMessageTopic } = topics

    useTokenUpdateEffect({
        userTokenUpdateTopic: UserTokenUpdateTopic,
        uuid,
    })
    useRoomStatusEffect(topics, roomUid)
    useRoomSubjectPushEffect(topics, roomUid)
    usePrivateRequestStatusEffect(topics, roomUid)
    useChatUserMessageEffect(UserMessageTopic, uuid)
    useFollowersEffect(topics, roomUid)
}
