import type { ComponentPropsWithoutRef } from "react"
import "./RoomStatusAndFPS.scss"
import { t } from "@lingui/macro"
import { RoomStatus } from "@multimediallc/web-utils"
import { selectStreamFPS } from "../../../store/broadcastSlice"
import { useAppSelector } from "../../../store/hooks"
import { selectRoomStatus } from "../../../store/roomSlice"
import { Typography } from "../../common"
import { LineSeparator, RedDot } from "../../common/atoms/Icons/Broadcast"

type RoomStatusAndFPSProps = ComponentPropsWithoutRef<"div">

export function RoomStatusAndFPS({ ...props }: RoomStatusAndFPSProps) {
    const i18n = {
        live: t`LIVE`,
        offline: t`OFFLINE`,
        private: t`Private`,
        public: t`Public`,
        hidden: t`Hidden`,
        away: t`Away`,
        password: t`Password Protected`,
    }

    const roomStatus = useAppSelector(selectRoomStatus)
    const fps = useAppSelector(selectStreamFPS)

    const isOffline = [RoomStatus.Offline, RoomStatus.Unknown].includes(
        roomStatus,
    )

    let statusMessage = ""
    switch (roomStatus) {
        case RoomStatus.Public:
            statusMessage = i18n.public
            break
        case RoomStatus.PrivateWatching:
            statusMessage = i18n.private
            break
        case RoomStatus.Hidden:
            statusMessage = i18n.hidden
            break
        case RoomStatus.Away:
            statusMessage = i18n.away
            break
        case RoomStatus.PasswordProtected:
            statusMessage = i18n.password
            break
        default:
            statusMessage = ""
            break
    }

    if (isOffline) {
        return (
            <div className="RoomStatusAndFPS" {...props}>
                <Typography size="xspx" className="RoomStatusAndFPS__dataItem">
                    {i18n.offline}
                </Typography>
            </div>
        )
    }

    return (
        <div className="RoomStatusAndFPS" {...props}>
            <RedDot className="RoomStatusAndFPS__redDot" />
            <Typography size="xspx" className="RoomStatusAndFPS__dataItem">
                {i18n.live}
            </Typography>
            <LineSeparator className="RoomStatusAndFPS__lineSeparator" />
            <Typography size="xspx" className="RoomStatusAndFPS__dataItem">
                {statusMessage}
            </Typography>
            <LineSeparator className="RoomStatusAndFPS__lineSeparator" />
            <Typography
                size="xspx"
                className="RoomStatusAndFPS__dataItem RoomStatusAndFPS__lastItem"
            >
                {Math.round(fps)} FPS
            </Typography>
        </div>
    )
}
