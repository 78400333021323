import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMagnifyingGlass = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 18"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#B3B3B3"
            d="M7.277 0c1.706 0 3.342.685 4.548 1.904a6.556 6.556 0 0 1 .34 8.826l.268.27h.781l4.949 5-1.485 1.5-4.948-5v-.79l-.267-.27A6.4 6.4 0 0 1 7.277 13a6.4 6.4 0 0 1-4.549-1.904A6.54 6.54 0 0 1 .844 6.5c0-1.724.678-3.377 1.884-4.596A6.4 6.4 0 0 1 7.277 0m0 2C4.803 2 2.823 4 2.823 6.5S4.803 11 7.277 11s4.453-2 4.453-4.5S9.75 2 7.277 2"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgMagnifyingGlass)
const Memo = memo(ForwardRef)
export default Memo
