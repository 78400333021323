import { getUsernameColorClass } from "../../../../utils/css/user"
// import { Avatar, LiveRing } from "../../../common/atoms/Icons/Chat"
import { isDmSettingsActive } from "../../toggleUtils"
import { classNames } from "../../utils"
import "./ProfilePicture.scss"
import type { IUserInfo } from "@multimediallc/web-utils/types"

interface ProfilePictureProps {
    user: IUserInfo
    size?: number // size of the avatar
}

export function ProfilePicture({ user, size = 40 }: ProfilePictureProps) {
    const containerSize = size + 8 // container is 8px larger than avatar
    const colorClass = getUsernameColorClass(user)

    if (!isDmSettingsActive()) {
        return (
            <div
                className="profile-picture"
                style={{ width: containerSize, height: containerSize }}
                data-testid="profile-picture"
            >
                <div
                    className={classNames("letter-avatar", colorClass)}
                    style={{
                        width: size,
                        height: size,
                        fontSize: `${size / 2.1}px`,
                    }}
                >
                    {user?.username?.charAt(0)?.toUpperCase()}
                </div>
            </div>
        )
    }
    return (
        <div
            className="profile-picture"
            style={{ width: containerSize, height: containerSize }}
            data-testid="profile-picture"
        >
            {/* TODO: [MSG-412] TODO: Add live ring and online indicator once implemented on backend */}
            {/* {user?.isLive && (
                <div className="live-ring" data-testid="live-ring">
                    <LiveRing width={containerSize} height={containerSize} />
                </div>
            )}
            {user?.avatarUrl ? (
                <img
                    src={user?.avatarUrl}
                    alt={user?.username}
                    style={{ width: size, height: size }}
                />
            ) : (
                <div
                    className={classNames("default-avatar", colorClass)}
                    style={{ width: size, height: size }}
                >
                    <Avatar />
                </div>
            )}
            {user?.isOnline && !user?.isLive && (
                <div className="online-indicator" role="status" />
            )}
            {user?.isLive && <div className="live-badge">LIVE</div>} */}
        </div>
    )
}
