import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgGallery = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0C6A93"
            fillRule="evenodd"
            d="M5.5 19.488Q6 20 6.714 20h12.572q.713 0 1.214-.512.5-.512.5-1.202V5.714q0-.69-.5-1.202A1.63 1.63 0 0 0 19.286 4H6.714q-.713 0-1.214.512-.5.512-.5 1.202v12.572q0 .69.5 1.202M8.04 17h9.92a.5.5 0 0 0 .39-.812l-2.96-3.7a.5.5 0 0 0-.78 0L12 15.75l-1.61-2.012a.5.5 0 0 0-.78 0l-1.96 2.45a.5.5 0 0 0 .39.812M12 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-7.75-2a.75.75 0 0 0-1.5 0v12a2.75 2.75 0 0 0 2.75 2.75h12a.75.75 0 0 0 0-1.5h-12c-.69 0-1.25-.56-1.25-1.25z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgGallery)
const Memo = memo(ForwardRef)
export default Memo
