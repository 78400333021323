import { useCallback, useState } from "react"
import "./RoomFlagNotification.scss"
import { t, Trans } from "@lingui/macro"
import { FlagIcon } from "../../common/molecules/FlagIcon"

type RoomFlagNotificationProps = {
    countryCode: string
    countryName: string
    onActivate: () => void
    onDismiss: () => void
    positioningEl?: HTMLElement
}

export function RoomFlagNotification({
    countryCode,
    countryName,
    onActivate,
    onDismiss,
    positioningEl,
    ...props
}: RoomFlagNotificationProps) {
    const [bottom, setBottom] = useState(0)
    const [left, setLeft] = useState(0)

    const updatePositioning = useCallback(
        (
            node: HTMLDivElement | null,
            positioningEl: HTMLElement | undefined,
        ): void => {
            if (node !== null && positioningEl !== undefined) {
                setBottom(positioningEl.offsetHeight * 1.25)
                setLeft(
                    positioningEl.offsetLeft -
                        node.getBoundingClientRect().width / 2 +
                        positioningEl.offsetWidth / 2,
                )
            }
        },
        [],
    )

    return (
        <div
            className="RoomFlagNotification"
            data-testid="room-flag-notification"
            ref={(el: HTMLDivElement) => {
                updatePositioning(el, positioningEl)
            }}
            style={{
                bottom: positioningEl !== undefined ? `${bottom}px` : "",
                left: positioningEl !== undefined ? `${left}px` : "",
            }}
            {...props}
        >
            <div className="RoomFlagNotification__subject-container">
                <div className="RoomFlagNotification__subject">
                    <span>🎉</span> {t`Get more viewers!`}
                </div>
                <div
                    className="RoomFlagNotification__close-button"
                    data-testid="room-flag-notification-dismiss"
                    onClick={() => {
                        onDismiss()
                    }}
                    tabIndex={0}
                />
            </div>
            <div className="RoomFlagNotification__message">
                <div className="RoomFlagNotification__flag-display-container">
                    <Trans>
                        You may attract more viewers by showcasing your country
                        flag (
                        <FlagIcon
                            countryCode={countryCode}
                            countryName={countryName}
                            data-testid="room-flag-notification-flag-icon"
                        />
                        ) on the home page.
                    </Trans>
                </div>
                {t`Try this out to see if it boosts your traffic.`}
            </div>
            <div className="RoomFlagNotification__button-container">
                <div
                    className="RoomFlagNotification__button"
                    data-testid="room-flag-notification-activate"
                    onClick={() => {
                        onActivate()
                    }}
                    tabIndex={0}
                >
                    {t`Activate`}
                </div>
            </div>
        </div>
    )
}
