import type { Thread } from "../types"

export const formatTokens = (tokens: number): string => {
    return tokens >= 1000
        ? `${(tokens / 1000).toFixed(1).replace(/\.0$/, "")}k`
        : tokens.toString()
}

export const getFormattedTime = (timestamp: number): string => {
    const date = new Date(timestamp * 1000)
    const now = new Date()
    const diffDays = Math.floor(
        (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
    )

    if (diffDays === 0) {
        // Today - show time
        return date
            .toLocaleString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            })
            .toLowerCase()
    } else if (diffDays < 7) {
        // Less than a week ago - show day name
        return date.toLocaleString("en-US", { weekday: "short" })
    } else {
        // More than a week ago - show Month Day
        return date.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
        })
    }
}

export const getMessagePreview = (
    thread: Thread,
    isTyping: boolean,
): string => {
    if (isTyping) {
        return `${thread.from_user} is typing...`
    }

    if (thread.tip_amount) {
        const amount = thread.tip_amount
        if (thread.from_user === thread.other_user.username) {
            return `${thread.other_user.username} tipped ${amount} tokens`
        }
        return `You tipped ${amount} tokens`
    }

    return thread.num_unread > 1
        ? `${thread.num_unread} new messages`
        : thread.m
}
