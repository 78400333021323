// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mkzo4r26IbxgbdWolo8Q{margin-left:24px}.YDPWfIiJqK491wr1PpMo{width:250px;margin-bottom:2px}.YDPWfIiJqK491wr1PpMo .ZjyfcvP0NiDlDfllE8wT{border-radius:8px 16px 0 0;height:222px;width:100%;display:flex;justify-content:center;align-items:center;background:#666}.YDPWfIiJqK491wr1PpMo .ZjyfcvP0NiDlDfllE8wT .obTbDEkl8Buy86q09fmX{height:40px;width:40px;display:flex;justify-content:center;align-items:center;border-radius:50%;background:rgba(248,249,250,.2)}.YDPWfIiJqK491wr1PpMo .i8jGAZg0OBkekokrmdjE{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#fff;font-size:14px;padding:16px;background:linear-gradient(65.53deg, #033e58 0%, #0686be 100%);border-radius:0 0 16px 8px}.YDPWfIiJqK491wr1PpMo .i8jGAZg0OBkekokrmdjE .yCcbL3OhwO8DC9Fe1joM{color:#e0e0e0;margin-bottom:8px}.YDPWfIiJqK491wr1PpMo .i8jGAZg0OBkekokrmdjE .TGlWqOTdKEKBFwVUbfrD{margin-bottom:16px}.YDPWfIiJqK491wr1PpMo .i8jGAZg0OBkekokrmdjE button{font-family:"UbuntuBold",Arial,Helvetica,sans-serif;color:#0c6a93;border-radius:8px;border:none;width:100%;padding:12px;display:flex;align-items:center;justify-content:center}.U24I8qkCIdOUIYnXM1Ii{background-color:#e0e0e0;border-radius:16px;display:flex;flex-wrap:wrap;width:244px;padding:8px;gap:4px;margin-bottom:2px}.U24I8qkCIdOUIYnXM1Ii .m7C0OLCWvf5dTLKr1iFd{width:120px;height:120px;background-color:#666;border-radius:8px;overflow:hidden;position:relative}.yJ5SYocI2fgRl3oHHbWx{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#a7a7a7;font-size:14px;margin:8px 0 12px}.fSwby7zZM3f4WEpdLn8E{margin:0 4px}.ScxWO4Ap_ndevFsrCbVe{height:20px;width:20px;display:inline-block}.i9WG6eRpBoEjIDDHiwmc{height:12px;width:12px;display:inline-block;margin-right:4px}.oOtCj0dTN9_SwACCyRzs{height:11px;width:11px;display:inline-block;margin-right:4px}.mQ1CGQy5pLRUMSLq35AT{height:18px;width:18px;display:inline-block;margin-right:12px}.S0J9CtSqAYJipP3eCKp4{height:14px;width:14px;display:inline-block;margin-right:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `mkzo4r26IbxgbdWolo8Q`,
	"mediaLockedContainer": `YDPWfIiJqK491wr1PpMo`,
	"preview": `ZjyfcvP0NiDlDfllE8wT`,
	"iconWrapper": `obTbDEkl8Buy86q09fmX`,
	"content": `i8jGAZg0OBkekokrmdjE`,
	"mediaCount": `yCcbL3OhwO8DC9Fe1joM`,
	"text": `TGlWqOTdKEKBFwVUbfrD`,
	"mediaUnlockedContainer": `U24I8qkCIdOUIYnXM1Ii`,
	"item": `m7C0OLCWvf5dTLKr1iFd`,
	"mediaUnlockedText": `yJ5SYocI2fgRl3oHHbWx`,
	"divider": `fSwby7zZM3f4WEpdLn8E`,
	"iconLock": `ScxWO4Ap_ndevFsrCbVe`,
	"iconCamera": `i9WG6eRpBoEjIDDHiwmc`,
	"iconPhoto": `oOtCj0dTN9_SwACCyRzs`,
	"iconLockOutline": `mQ1CGQy5pLRUMSLq35AT`,
	"iconUnlock": `S0J9CtSqAYJipP3eCKp4`
};
export default ___CSS_LOADER_EXPORT___;
