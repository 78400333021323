import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgNewMessage = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 21 21"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#222"
            fillRule="evenodd"
            d="M.854 19.146A.5.5 0 0 1 0 18.793V2.5q0-.825.588-1.412A1.93 1.93 0 0 1 2 .5h16q.824 0 1.413.587Q20 1.676 20 2.5v9.303a6 6 0 0 0-2-.72V2.5H2v12.625L3.15 14h8.653a6 6 0 0 0-.72 2H4zM6 9.5q.424 0 .713-.287A.97.97 0 0 0 7 8.5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 6 7.5a.97.97 0 0 0-.713.287A.97.97 0 0 0 5 8.5q0 .424.287.713.288.287.713.287m4 0q.424 0 .713-.287A.97.97 0 0 0 11 8.5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 10 7.5a.97.97 0 0 0-.713.287A.97.97 0 0 0 9 8.5q0 .424.287.713.288.287.713.287m4.713-.287A.97.97 0 0 1 14 9.5a.97.97 0 0 1-.713-.287A.97.97 0 0 1 13 8.5q0-.424.287-.713A.97.97 0 0 1 14 7.5q.424 0 .713.287.287.288.287.713 0 .424-.287.713M18 16v-3h-2v3h-3v2h3v3h2v-3h3v-2z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgNewMessage)
const Memo = memo(ForwardRef)
export default Memo
