import { useEffect } from "react"
import "./FlagIcon.scss"
import { error } from "@multimediallc/logging"

interface FlagIconProps extends React.ComponentPropsWithoutRef<"span"> {
    countryName: string
    countryCode: string
    square?: boolean
}

export function FlagIcon({
    countryName,
    countryCode,
    square = false,
    ...props
}: FlagIconProps) {
    useEffect(() => {
        const styleId = "cbr-flag-icons"

        if (document.getElementById(styleId) == null) {
            // @ts-ignore has no type definitions
            import("flag-icons/css/flag-icons.min.css")
                .then((css) => {
                    const style = document.createElement("style")
                    style.id = styleId
                    style.textContent = css
                    // check again before adding - possible race
                    if (document.getElementById(styleId) == null) {
                        document.head.appendChild(style)
                    }
                })
                .catch((err) => {
                    error("Error loading flag icon lib", err)
                })
        }
    }, [])
    const styles: React.CSSProperties = {
        ...props.style,
    }
    const classes = [
        "fi",
        `fi-${countryCode}`,
        ...(props.className?.split(" ") || []),
    ]
    if (square) {
        styles.width = "1em"
        classes.push("fis")
    }

    return (
        <span
            // props need to be spread before all so that they can be overridden
            {...props}
            className={classes.join(" ")}
            title={countryName}
            style={styles}
        />
    )
}
