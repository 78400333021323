// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XoYaExIRAYKph0jQjHQ7{position:absolute;top:-8px;right:-8px;background-color:#f47321;color:#fff;border-radius:50%;display:flex;flex-shrink:0;align-items:center;justify-content:center;font-family:"UbuntuBold",Arial,Helvetica,sans-serif;text-align:center;letter-spacing:-0.4px;aspect-ratio:1;z-index:2}.XoYaExIRAYKph0jQjHQ7.TtgGhDowMfZsn7X9PP6d{width:16px;height:16px;font-size:12px;line-height:16px}.XoYaExIRAYKph0jQjHQ7._6sQcte4Z9I1LYGh3Q3Q{width:18px;height:18px;font-size:12px;line-height:18px}.XoYaExIRAYKph0jQjHQ7.yfrdyKFTyMrRspApapXW{width:20px;height:20px;font-size:11px;line-height:20px}.XoYaExIRAYKph0jQjHQ7.YMu3BFMFR2Lsbt_AaL2M{font-size:10px;line-height:12px;top:-4px;right:-6px;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif}.XoYaExIRAYKph0jQjHQ7.YMu3BFMFR2Lsbt_AaL2M.TtgGhDowMfZsn7X9PP6d{width:12px;height:12px}.XoYaExIRAYKph0jQjHQ7.YMu3BFMFR2Lsbt_AaL2M._6sQcte4Z9I1LYGh3Q3Q{width:13px;height:13px;font-size:9px}.XoYaExIRAYKph0jQjHQ7.YMu3BFMFR2Lsbt_AaL2M.yfrdyKFTyMrRspApapXW{width:14px;height:14px;font-size:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unreadBadge": `XoYaExIRAYKph0jQjHQ7`,
	"small": `TtgGhDowMfZsn7X9PP6d`,
	"medium": `_6sQcte4Z9I1LYGh3Q3Q`,
	"large": `yfrdyKFTyMrRspApapXW`,
	"compact": `YMu3BFMFR2Lsbt_AaL2M`
};
export default ___CSS_LOADER_EXPORT___;
