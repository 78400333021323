import "./MobileSort.scss"
import {
    isStateOnlyUsingSortSupportedKeys,
    SortOption,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import {
    getCookieOrUndefined,
    setCookieWithExpiration,
} from "@multimediallc/web-utils/storage"
import { featureFlagIsActive } from "../../../../hooks/appContext"
import {
    UrlStateContextProvider,
    useUrlStateContext,
} from "../../../../hooks/urlStateContext"
import { addPageAction } from "../../../../utils/newrelic"

const ROOMLIST_SORT_COOKIE_NAME = "rlsrt"

export const isLanguageSubdomainActive = (): boolean => {
    const continuityCookie = getCookieOrUndefined(
        "language_subdomain_continuity",
    )
    return featureFlagIsActive("LanguageSubdomain") || continuityCookie === "1"
}

export const setRoomListSortCookie = (value: SortOption) => {
    // This can probably be replaced with storage eventually here and in homepageFiltersUtil.ts
    setCookieWithExpiration(
        ROOMLIST_SORT_COOKIE_NAME,
        value,
        { days: 3 },
        "/",
        isLanguageSubdomainActive(),
    )
}

export function MobileSort() {
    return (
        <UrlStateContextProvider>
            <MobileSortContent />
        </UrlStateContextProvider>
    )
}

export function MobileSortContent() {
    const urlState = useUrlStateContext()
    const selectedSort = urlState.sort ?? SortOption.Popular
    const clickPageAction = "CamSortIconClicked"
    const changePageAction = "CamSortIconChanged"
    const handleClick = (e: React.MouseEvent) => {
        addPageAction(clickPageAction)
    }

    const handleChange = (e: React.ChangeEvent) => {
        const selectElement = e.target as HTMLSelectElement
        const selectValue = selectElement.value as SortOption
        setRoomListSortCookie(selectValue)
        addPageAction(changePageAction, {
            option_change_from: selectedSort,
            option_change_to: selectValue,
        })
        UrlState.current.setPartialState({ sort: selectValue })
    }

    return (
        <div
            id="mobile-sort-container"
            className={`MobileSort ${isStateOnlyUsingSortSupportedKeys(urlState) ? "" : "hidden"}`}
            data-testid="mobile-sort"
        >
            <div id="mobile-sort-form-container">
                <button
                    className={`MobileSortButton ${selectedSort !== SortOption.Popular ? "active" : ""}`}
                >
                    Sort Options
                </button>
                <form id="mobile-sorting-form">
                    <select
                        onChange={handleChange}
                        onClick={handleClick}
                        id="mobile-sorting-options"
                        className="MobileSortOption"
                        data-testid="mobile-sort-select"
                        value={selectedSort}
                    >
                        <option value={SortOption.Popular}>
                            Popular (Default)
                        </option>
                        <option value={SortOption.Viewers}>
                            Viewers (Low to High)
                        </option>
                        <option value={SortOption.Random}>Randomized</option>
                    </select>
                </form>
            </div>
        </div>
    )
}
