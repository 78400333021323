import { t } from "@lingui/macro"
import { Sizes } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import { addPageAction } from "../../../utils/newrelic"
import { Typography } from "../../common"
import { ArrowCollapseLeft } from "../../common/atoms/Icons/Broadcast"
import { StylelessButton } from "../../common/atoms/StylelessButton"
import "./ReturnFromAwayUI.scss"
import { returnFromAway } from "../api"

export function ReturnFromAwayUI() {
    const handleReturnFromAway = () => {
        addPageAction("BroadcasterReturnFromAwayClicked")
        void returnFromAway()
    }

    return (
        <StylelessButton
            data-testid="return-away-ui-pill"
            className="ReturnFromAwayUI"
            id="ReturnFromAwayUIPill"
            onClick={handleReturnFromAway}
        >
            <ArrowCollapseLeft
                className="PillIcon"
                width="16px"
                height="16px"
            />
            <div className="PillTextContainer">
                <Typography
                    size={Sizes.sm}
                    color={colorClass.whiteFontColor}
                    className="PillText"
                >
                    {t`You're in Away mode, and your camera is off.`}
                </Typography>
                <Typography
                    size={Sizes.sm}
                    color={colorClass.whiteFontColor}
                    className="PillText"
                >
                    {t`Tap here to return to Public Chat.`}
                </Typography>
            </div>
        </StylelessButton>
    )
}
