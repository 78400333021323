// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Thb_5RVTdKFMAbk3mCtQ{width:100%;height:100%;overflow:hidden auto;z-index:9999;position:fixed;top:0;background:#fff}.KEjiS7GuvniniNjSxVVG{animation:xdxRA5BzNqqp0NflMwx4 .3s ease-out forwards}.pAP8oMOggGOjcLSILGFi{animation:CZlRn2kESJTgaY5pemcT .3s ease-in forwards}@keyframes xdxRA5BzNqqp0NflMwx4{from{transform:translateY(100%)}to{transform:translateY(0)}}@keyframes CZlRn2kESJTgaY5pemcT{from{transform:translateY(0)}to{transform:translateY(100%)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Thb_5RVTdKFMAbk3mCtQ`,
	"slideUp": `KEjiS7GuvniniNjSxVVG`,
	"slideUpAnimation": `xdxRA5BzNqqp0NflMwx4`,
	"slideDown": `pAP8oMOggGOjcLSILGFi`,
	"slideDownAnimation": `CZlRn2kESJTgaY5pemcT`
};
export default ___CSS_LOADER_EXPORT___;
