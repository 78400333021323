import { useCallback, useEffect, useRef, useState } from "react"
import type { ComponentPropsWithoutRef } from "react"
import { checkDevicePermsGranted } from "@multimediallc/web-utils"
import { useBroadcastPushEffect } from "../../../hooks/useBroadcastPushEffect"
import { useAppDispatch } from "../../../store/hooks"
import { useStreamStatusUpdate } from "../../../store/hooks/broadcastHooks"
import {
    useLogMessageEffect,
    useRemoveUserMessagesEffect,
    useRoomMessageEffect,
    useRoomNoticeEffect,
    useRoomShortcodeEffect,
} from "../../../store/hooks/chatHooks"
import { setRoomSubject } from "../../../store/roomSlice"
import { updateChatSettings } from "../../../store/userSlice"
import { addPageAction } from "../../../utils/newrelic"
import {
    BroadcasterAgreementModal,
    hasBroadcasterAgreed,
} from "../BroadcasterAgreementModal/BroadcasterAgreementModal"
import { DevicePermissionsPrompt } from "../DevicePermissionsPrompt"
import { MobileBroadcastContext } from "../mobileBroadcastContext"
import { MobileBroadcastLandscapeUI } from "../MobileBroadcastLandscapeUI"
import { MobileBroadcastPortraitUI } from "../MobileBroadcastPortraitUI"
import { useIsPortrait } from "./hooks"
import type {
    MobileBroadcastEventRouters,
    MobileBroadcastTopics,
} from "../types"
import type { MobileBroadcastProps } from "@multimediallc/web-utils"

import "./MobileBroadcast.scss"

export interface ActualMobileBroadcastProps extends MobileBroadcastProps {
    topics: MobileBroadcastTopics
    eventRouters: MobileBroadcastEventRouters
}

export function MobileBroadcast({
    readyForStreamer,
    broadcastStatus,
    onRecordButtonClick,
    muted,
    setMute,
    videoEl,
    topics,
    eventRouters,
    roomUid,
    roomSubject,
    room,
    userChatSettings,
    createMediasetThumbnails,
    getUserMentionInfo,
}: ActualMobileBroadcastProps) {
    useBroadcastPushEffect({ topics, roomUid })
    useStreamStatusUpdate(eventRouters)
    useRoomNoticeEffect(eventRouters.roomEvents?.notice)
    useRoomMessageEffect(eventRouters.roomEvents?.message)
    useRoomShortcodeEffect(eventRouters.roomEvents?.shortcode)
    useRemoveUserMessagesEffect(eventRouters.roomEvents?.removeMessages)
    useLogMessageEffect(eventRouters.logMessage)

    const [broadcastTermsAccepted, setBroadcastTermsAccepted] =
        useState<boolean>(hasBroadcasterAgreed())
    const [devicePermsGrantedOrAcked, setDevicePermsGrantedOrAcked] = useState<
        boolean | undefined
    >(undefined)
    const streamerInitialized = useRef<boolean>(false)

    const onTermsAgreement = useCallback(() => {
        addPageAction("BroadcasterAgreedToTerms")
        setBroadcastTermsAccepted(true)
    }, [])

    const onPermsAck = useCallback(() => {
        addPageAction("BroadcasterDevicePermissionAcked")
        setDevicePermsGrantedOrAcked(true)
    }, [])
    const isPortrait = useIsPortrait()

    useEffect(() => {
        void checkDevicePermsGranted().then((permsInitiallyGranted) => {
            setDevicePermsGrantedOrAcked(permsInitiallyGranted)
        })
    }, [])

    // In general, room subject changes will come through push in `useBroadcastPushEffect`,
    // but the initial room subject must be set from props
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (roomSubject !== undefined) {
            dispatch(setRoomSubject(roomSubject))
        }
    }, [roomSubject])

    useEffect(() => {
        if (userChatSettings !== undefined) {
            dispatch(updateChatSettings(userChatSettings))
        }
    }, [userChatSettings])

    if (!broadcastTermsAccepted) {
        return (
            <MobileBroadcastWrapper>
                <BroadcasterAgreementModal onAgreement={onTermsAgreement} />
            </MobileBroadcastWrapper>
        )
    }

    if (devicePermsGrantedOrAcked !== true) {
        return (
            <MobileBroadcastWrapper>
                {devicePermsGrantedOrAcked === false && (
                    <DevicePermissionsPrompt onPermsAck={onPermsAck} />
                )}
            </MobileBroadcastWrapper>
        )
    }

    return (
        <MobileBroadcastWrapper>
            <MobileBroadcastContext.Provider
                value={{
                    broadcastStatus,
                    onRecordButtonClick,
                    muted,
                    setMute,
                    room: room ?? "",
                    createMediasetThumbnails,
                    getUserMentionInfo,
                }}
            >
                <div
                    className="MobileBroadcast__videoWrapper"
                    data-testid="mobilebroadcast-video-wrapper"
                    ref={(ref) => {
                        if (!streamerInitialized.current) {
                            ref?.appendChild(videoEl)
                            // Init MobileStreamer when and only when the videoEl is first added to the DOM.
                            // MobileStreamer init errors if the videoEl is not in DOM.
                            readyForStreamer()
                            streamerInitialized.current = true
                        }
                    }}
                />
                {isPortrait ? (
                    <MobileBroadcastPortraitUI />
                ) : (
                    <MobileBroadcastLandscapeUI />
                )}
            </MobileBroadcastContext.Provider>
        </MobileBroadcastWrapper>
    )
}

function MobileBroadcastWrapper(props: ComponentPropsWithoutRef<"div">) {
    return <div className="MobileBroadcast" {...props} />
}
