// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o31LD_WeNkDp1LEynXof{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.2);display:flex;align-items:center;justify-content:center;z-index:1001}.pDykoglQlvpeiReROlzf{background:#fff;border-radius:8px;padding:24px;width:100%;max-width:320px}.Q8WifpJQppHvC84bHeM7{color:#222;text-align:center;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:0 0 24px 0;line-height:24px}.d7NSk02bawzFb1DOHKHa{display:flex;gap:16px}.NEEFksM6Hp6v05UIkYM4,.L9ATwEuZ1u6a52ClMb4D,.mzb4wzRJXS1YSTN6ysnK{flex:1;padding:12px;border-radius:4px;border:none;font-size:16px;height:48px;cursor:pointer;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif}.mzb4wzRJXS1YSTN6ysnK{background-color:#fff;border:1px solid #0c6a93;color:#0c6a93}.L9ATwEuZ1u6a52ClMb4D{background-color:#dc3545;color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `o31LD_WeNkDp1LEynXof`,
	"modalContent": `pDykoglQlvpeiReROlzf`,
	"modalTitle": `Q8WifpJQppHvC84bHeM7`,
	"modalButtons": `d7NSk02bawzFb1DOHKHa`,
	"modalButton": `NEEFksM6Hp6v05UIkYM4`,
	"modalButtonDelete": `L9ATwEuZ1u6a52ClMb4D`,
	"modalButtonCancel": `mzb4wzRJXS1YSTN6ysnK`
};
export default ___CSS_LOADER_EXPORT___;
