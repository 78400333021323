import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgPlus = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 28 28"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#1C749A"
            fillRule="evenodd"
            d="M14 0a1 1 0 0 0-1 1v12H1a1 1 0 1 0 0 2h12v12a1 1 0 1 0 2 0V15h12a1 1 0 1 0 0-2H15V1a1 1 0 0 0-1-1"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgPlus)
const Memo = memo(ForwardRef)
export default Memo
