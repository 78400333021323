import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { plural, t } from "@lingui/macro"
import { isAndroid } from "react-device-detect"
import { useParams } from "react-router-dom"
import AutoSizer from "react-virtualized-auto-sizer"
import { useAppSelector } from "../../../store/hooks"
import {
    DM_THREAD_PAGE_LIMIT,
    messagingApi,
    useGetThreadsQuery,
    useLazyGetHistoryQuery,
    useMarkAsReadMutation,
} from "../../../store/messagingSlice"
import { Spinner } from "../../common"
import { ScrollButton } from "../../common/molecules/ScrollButton"
import { draftCache } from "../DraftCache/DraftCache"
import { useMessagingView } from "../hooks"
import { MessageHeader } from "../MessageHeader/MessageHeader"
import { MessageInputBar } from "../MessageInputBar/MessageInputBar"
import { MessageMedia } from "../MessageMedia/MessageMedia"
import { MessageRow } from "../MessageRow/MessageRow"
import { findNonLogMessage } from "../MessageRow/utils"
import { MessageRowDisclaimer } from "../MessageRowDisclaimer/MessageRowDisclaimer"
import { useMessagingContext } from "../MessagingContext"
import { ReportMessage } from "../ReportMessage/ReportMessage"
import { UserNote } from "../UserNote/UserNote"
import { classNames } from "../utils"
import { VirtualList } from "../VirtualList/VirtualList"
import { useMessagingMetaTags } from "./useMessagingMetaTags"
import type { MessageRowData } from "../MessageRow/MessageRow"
import type { Message } from "../types"
import type { ListChildComponentProps, VariableSizeList } from "react-window"
import "./MessageList.scss"

const TEST_MEDIA_MESSAGE = false

const MESSAGES_BATCH_SIZE = 50
const OVERSCAN_COUNT = 5
const SCROLL_THRESHOLD = 84
const SCROLL_THROTTLE_DELAY = 500
const LOADER_HEIGHT = 32
const NEAR_BOTTOM_THRESHOLD = 70
const TYPING_INDICATOR_HEIGHT = 42

const Row = (props: ListChildComponentProps<MessageRowData>) => (
    <MessageRow {...props} />
)

/** Prevents scroll jank by pre-measuring dynamic message heights for react-window */
const HeightPreCalculator = ({
    messages,
    messageHeights,
    itemData,
}: {
    messages: Message[]
    messageHeights: React.MutableRefObject<Record<string, number>>
    itemData: MessageRowData
}) => {
    const unmeasured = messages.filter((msg) => !messageHeights.current[msg.i])

    if (unmeasured.length === 0) return null

    return (
        <div
            style={{
                position: "absolute",
                opacity: 0,
                visibility: "hidden",
                pointerEvents: "none",
            }}
        >
            {unmeasured.map((message) => (
                <MessageRow
                    key={`shadow-${message.i}`}
                    index={messages.indexOf(message) + 1}
                    style={{ position: "relative" }}
                    data={{
                        ...itemData,
                    }}
                />
            ))}
        </div>
    )
}

type VisibleItems = {
    startIndex: number
    stopIndex: number
}

interface MessageListProps {
    onClose: () => void
    onMinimize?: (minimized: boolean) => void
    isMinimized: boolean
}

export function MessageList({
    onClose,
    onMinimize,
    isMinimized,
}: MessageListProps) {
    const messagingView = useMessagingView()
    const { username = "" } = useParams<{ username: string }>()
    const { dmUnreadData } = useMessagingContext()
    const [offsetData, setOffsetData] = useState<{
        username: string
        offset: string
    } | null>(null)
    const [
        triggerGetHistory,
        { data, isLoading, isFetching, error, isUninitialized },
    ] = useLazyGetHistoryQuery()

    const [markAsRead] = useMarkAsReadMutation()
    const messages = useMemo(() => data?.messages || [], [data?.messages])

    const [showMenuMessageId, setShowMenuMessageId] = useState<string | null>(
        null,
    )
    const [isAllHistoryLoaded, setIsAllHistoryLoaded] = useState(false)
    const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false)
    const [visibleItems, setVisibleItems] = useState<VisibleItems>({
        startIndex: 0,
        stopIndex: 0,
    })
    const [showReport, setShowReport] = useState(false)
    const [showUserNote, setShowUserNote] = useState(false)

    const listRef = useRef<VariableSizeList>(null)
    const listOuterRef = useRef<HTMLDivElement>(null)
    const messageHeights = useRef<Record<string, number>>({})
    const initialLoadDone = useRef(false)
    const oldMessagesLengthRef = useRef<number>(0)
    const isAdjustingScroll = useRef(false)
    const targetScrollIndex = useRef<number | null>(null)
    const scrollOffsetBeforeLoad = useRef<number>(0)
    const loadTimeoutRef = useRef<NodeJS.Timeout>()
    const restoreTimeoutRef = useRef<NodeJS.Timeout>()
    const myUsername = useAppSelector(
        (state) => state.user.loggedInUser?.username,
    )
    const isNearBottom = useRef(true)
    const [showScrollDownButton, setShowScrollDownButton] = useState(false)
    const isUserTyping = useAppSelector((state) => state.userTyping[username])
    const [previousTypingState, setPreviousTypingState] = useState(false)

    const lastQuery = useAppSelector(
        (state) =>
            messagingApi.endpoints.getThreads.select({
                offset: 0,
                limit: DM_THREAD_PAGE_LIMIT,
            })(state).originalArgs,
    )

    const { data: threadsData } = useGetThreadsQuery({
        offset: lastQuery?.offset ?? 0,
        limit: DM_THREAD_PAGE_LIMIT,
    })
    const itemCount = messages.length + 1 + (isUserTyping ? 1 : 0)

    const numUnread = useMemo(() => {
        return (
            threadsData?.threads.find(
                (thread) => thread.other_user.username === username,
            )?.num_unread ?? 0
        )
    }, [threadsData, username])

    const getItemSize = useCallback(
        (index: number) => {
            if (index === 0) return isAllHistoryLoaded ? 0 : LOADER_HEIGHT
            if (index === messages.length + 1 && isUserTyping) {
                return TYPING_INDICATOR_HEIGHT
            }
            const messageId = messages[index - 1]?.i
            return messageHeights.current[messageId] || 0
        },
        [messages, isAllHistoryLoaded, isUserTyping],
    )

    const restoreScrollPosition = useCallback(
        (index: number) => {
            if (!listRef.current || !listOuterRef.current) return

            clearTimeout(restoreTimeoutRef.current)
            isAdjustingScroll.current = true
            const newContentHeight = Array.from({ length: index }, (_, i) =>
                getItemSize(i),
            ).reduce((sum, height) => sum + height, 0)

            const finalPosition =
                scrollOffsetBeforeLoad.current < SCROLL_THRESHOLD
                    ? newContentHeight
                    : newContentHeight + scrollOffsetBeforeLoad.current

            listRef.current.scrollTo(finalPosition - LOADER_HEIGHT)

            restoreTimeoutRef.current = setTimeout(() => {
                isAdjustingScroll.current = false
                targetScrollIndex.current = null
                restoreTimeoutRef.current = undefined
            }, 0)
        },
        [getItemSize],
    )

    const scrollToBottom = useCallback(() => {
        if (!listRef.current || !messages.length) return

        // if a typing indicator row is present, scroll to that.
        // otherwise, scroll to the last message.
        const targetIndex = messages.length + (isUserTyping ? 1 : 0)
        listRef.current.scrollToItem(targetIndex, "end")

        const SCROLL_DELAY_MS = messages[messages.length - 1]
            .is_temporary_tip_message
            ? 250
            : 100
        setTimeout(() => {
            requestAnimationFrame(() => {
                listRef?.current?.scrollToItem(targetIndex, "end")
            })
        }, SCROLL_DELAY_MS)
    }, [messages, isUserTyping])

    const handleHeightChange = useCallback(
        (index: number, height: number, messageId: string) => {
            if (height === 0 || messageHeights.current[messageId] === height)
                return

            messageHeights.current[messageId] = height

            if (listRef.current) {
                listRef.current.resetAfterIndex(index)

                if (
                    isAdjustingScroll.current &&
                    targetScrollIndex.current !== null
                ) {
                    restoreScrollPosition(targetScrollIndex.current)
                }
            }
        },
        [restoreScrollPosition],
    )

    const handleMarkAsRead = useCallback(() => {
        if (numUnread > 0 && !isMinimized) {
            markAsRead(username).catch(() => {})
            dmUnreadData.removeUnreadRecipient(username)
        }
    }, [markAsRead, username, numUnread, isMinimized, dmUnreadData])

    const handleScroll = useCallback(
        ({ scrollOffset }: { scrollOffset: number }) => {
            if (!isAdjustingScroll.current) {
                scrollOffsetBeforeLoad.current = scrollOffset
            }

            if (listOuterRef.current) {
                const listOuter = listOuterRef.current
                const scrolledPosition =
                    listOuter.scrollHeight - listOuter.scrollTop
                const isListScrollable =
                    listOuter.clientHeight > 0 &&
                    listOuter.scrollHeight > listOuter.clientHeight
                const nearBottom =
                    !isListScrollable ||
                    scrolledPosition <=
                        listOuter.clientHeight + NEAR_BOTTOM_THRESHOLD

                isNearBottom.current = nearBottom
                setShowScrollDownButton(!nearBottom)

                if (nearBottom) {
                    handleMarkAsRead()
                }
            }

            if (
                !initialLoadDone.current ||
                !messages.length ||
                isFetching ||
                isAllHistoryLoaded ||
                isAdjustingScroll.current ||
                loadTimeoutRef.current ||
                isMinimized
            )
                return

            if (scrollOffset < SCROLL_THRESHOLD) {
                clearTimeout(loadTimeoutRef.current)
                loadTimeoutRef.current = setTimeout(() => {
                    setIsLoadingOlderMessages(true)
                    // Find first non temporary tip message for pagination offset
                    const firstNonTemporaryMessage = messages.find(
                        (msg) => !msg?.is_temporary_tip_message,
                    )
                    if (firstNonTemporaryMessage) {
                        setOffsetData({
                            username,
                            offset: firstNonTemporaryMessage.i,
                        })
                    }
                    loadTimeoutRef.current = undefined
                }, SCROLL_THROTTLE_DELAY)
            }
        },
        [
            messages,
            isFetching,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
            handleMarkAsRead,
            username,
            numUnread,
            isMinimized,
        ],
    )

    const resetScrollState = useCallback(() => {
        initialLoadDone.current = false
        oldMessagesLengthRef.current = 0
        setIsAllHistoryLoaded(false)
        setOffsetData(null)
        messageHeights.current = {}
        scrollOffsetBeforeLoad.current = 0
        targetScrollIndex.current = null
        isAdjustingScroll.current = false

        if (listRef.current) {
            listRef.current.scrollTo(0)
        }
    }, [])

    useEffect(() => {
        setOffsetData(null)
        triggerGetHistory({ username, offset: undefined })
    }, [username, resetScrollState, triggerGetHistory])

    useEffect(() => {
        if (offsetData && offsetData.username === username) {
            triggerGetHistory({ username, offset: offsetData.offset })
        }
    }, [username, offsetData, triggerGetHistory])

    useLayoutEffect(() => {
        if (!messages.length || !listRef.current) return

        if (!initialLoadDone.current) {
            initialLoadDone.current = true
            oldMessagesLengthRef.current = messages.length
            setIsAllHistoryLoaded(messages.length < MESSAGES_BATCH_SIZE)
            scrollToBottom()
        }
    }, [messages, scrollToBottom])

    useLayoutEffect(() => {
        if (!messages.length || !listRef.current || !listOuterRef.current)
            return

        const newLength = messages.length
        const oldLength = oldMessagesLengthRef.current
        const diff = newLength - oldLength

        if (isLoadingOlderMessages && diff > 0 && oldLength > 0) {
            isAdjustingScroll.current = true
            targetScrollIndex.current = diff + 1
            listRef?.current?.resetAfterIndex(0, true)
            restoreScrollPosition(diff + 1)
            setIsLoadingOlderMessages(false)
        }

        const latestMessage = messages[newLength - 1]
        const isFromMe = latestMessage?.from_user.username === myUsername
        const isSingleNewMessage = diff === 1

        const shouldScrollToBottom =
            isSingleNewMessage &&
            !isLoadingOlderMessages &&
            (isNearBottom.current || isFromMe)

        if (isSingleNewMessage && !isFromMe && isNearBottom.current) {
            handleMarkAsRead()
        }

        if (shouldScrollToBottom) {
            scrollToBottom()
        }
        oldMessagesLengthRef.current = newLength
    }, [
        messages,
        restoreScrollPosition,
        myUsername,
        isLoadingOlderMessages,
        handleMarkAsRead,
    ])

    useEffect(() => {
        if (!data) return
        setIsAllHistoryLoaded(!data.has_more)
    }, [data?.has_more])

    useEffect(() => {
        // scroll down to typing indicator if other user starts typing after MessageList has mounted
        if (
            messages.length > 0 &&
            listRef.current &&
            isUserTyping &&
            !previousTypingState
        ) {
            if (isNearBottom.current) {
                const typingIndicatorIndex = messages.length + 1
                listRef.current?.scrollToItem(typingIndicatorIndex, "end")
            }
        }
        setPreviousTypingState(isUserTyping)
    }, [
        isUserTyping,
        messages,
        listRef.current,
        isNearBottom.current,
        previousTypingState,
    ])

    /** Force react-window to recalculate heights when messages change since they're dynamically sized */
    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0, true)
        }
    }, [messages])

    useEffect(() => {
        // create ghost draft
        if (!isUninitialized && data?.messages.length === 0) {
            const draft = draftCache.get(username)
            if (!draft) {
                draftCache.set(username, {
                    message: null,
                    timestamp: Date.now(),
                })
            }
        }
    }, [data, isUninitialized, username])

    const itemData = useMemo(
        () => ({
            messages,
            showMenuMessageId,
            setShowMenuMessageId,
            username,
            onHeightChange: handleHeightChange,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
            isUserTyping,
        }),
        [
            messages,
            showMenuMessageId,
            username,
            handleHeightChange,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
            isUserTyping,
        ],
    )

    const handleMinimize = () => {
        onMinimize?.(!isMinimized)
    }
    const showLoading = (isFetching || isLoading) && isNearBottom.current
    const showError = !isLoading && error
    const showEmpty = !isUninitialized && !messages.length

    const getScrollButtonText = (): string => {
        const hasUnread = numUnread > 0
        const hasManyUnread = numUnread >= 10
        let text = t`Scroll to bottom`

        if (hasUnread) {
            text = hasManyUnread
                ? t`10+ new messages`
                : plural(numUnread, {
                      one: `${numUnread} new message`,
                      other: `${numUnread} new messages`,
                  })
        }
        return text
    }

    const onItemsRendered = ({
        visibleStopIndex,
        visibleStartIndex,
    }: {
        visibleStartIndex: number
        visibleStopIndex: number
    }) => {
        setVisibleItems({
            startIndex: visibleStartIndex,
            stopIndex: visibleStopIndex,
        })
    }

    const onViewportHeightChange = () => {
        requestAnimationFrame(() => {
            if (visibleItems.startIndex === 0) return
            if (listRef.current) {
                listRef.current.scrollToItem(visibleItems.stopIndex, "end")
            }
        })
    }

    const otherUserMessages = useMemo(
        () =>
            messages?.filter(
                (msg: Message) => msg.from_user.username === username,
            ) ?? [],
        [messages, username],
    )

    const messageToReport = useMemo(
        () =>
            otherUserMessages.length === 0
                ? undefined
                : findNonLogMessage(
                      otherUserMessages,
                      otherUserMessages.length - 1,
                      -1,
                  ),
        [otherUserMessages],
    )

    const isReportEnabled = otherUserMessages.length > 0

    const handleReportClick = useCallback(() => {
        setShowReport(true)
    }, [])

    const handleNoteClick = useCallback(() => {
        setShowUserNote(true)
    }, [])

    useMessagingMetaTags()

    return (
        <div className="messages-list-container">
            <div
                className={classNames("messages-list", {
                    mobile: messagingView.isMobile,
                    ["desktop-unified"]: messagingView.isDesktopUnified,
                    desktop: messagingView.isDesktopConversation,
                    minimized: isMinimized,
                    android: isAndroid,
                })}
            >
                <MessageHeader
                    username={username}
                    onClose={onClose}
                    isMinimized={isMinimized}
                    onMinimize={handleMinimize}
                    numUnread={numUnread}
                    onReportClick={handleReportClick}
                    onNoteClick={handleNoteClick}
                    isReportEnabled={isReportEnabled}
                />
                <div
                    className={classNames("messages-content", {
                        hidden: isMinimized,
                    })}
                >
                    {showScrollDownButton && (
                        <ScrollButton
                            onClick={scrollToBottom}
                            text={getScrollButtonText()}
                        />
                    )}
                    {showLoading && (
                        <div className="messages-status loading">
                            <Spinner size="md" />
                        </div>
                    )}
                    {showError ? (
                        <div className="messages-status">
                            Error loading messages
                        </div>
                    ) : showEmpty ? (
                        <MessageRowDisclaimer username={username} />
                    ) : (
                        <>
                            <HeightPreCalculator
                                messages={messages}
                                messageHeights={messageHeights}
                                itemData={itemData}
                            />
                            <AutoSizer doNotBailOutOnEmptyChildren>
                                {({ height, width }) => (
                                    <VirtualList<MessageRowData>
                                        className="virtual-list"
                                        height={height}
                                        width={width}
                                        itemCount={itemCount}
                                        itemSize={getItemSize}
                                        overscanCount={OVERSCAN_COUNT}
                                        onScroll={handleScroll}
                                        itemData={itemData}
                                        ref={listRef}
                                        outerRef={listOuterRef}
                                        onItemsRendered={onItemsRendered}
                                        useIsScrolling
                                    >
                                        {Row}
                                    </VirtualList>
                                )}
                            </AutoSizer>
                        </>
                    )}
                </div>
                {!isMinimized && TEST_MEDIA_MESSAGE && (
                    <>
                        <MessageMedia mockSufficientTokens />
                        <MessageMedia mockSufficientTokens={false} />
                    </>
                )}
                {!isMinimized && (
                    <MessageInputBar
                        scrollToBottom={scrollToBottom}
                        onViewportHeightChange={onViewportHeightChange}
                    />
                )}
                {showReport && (
                    <ReportMessage
                        onClose={() => setShowReport(false)}
                        message={messageToReport ?? undefined}
                    />
                )}
                {showUserNote && (
                    <UserNote
                        onClose={() => {
                            setShowUserNote(false)
                        }}
                    />
                )}
            </div>
        </div>
    )
}
