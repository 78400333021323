import type React from "react"
import { useCallback, useEffect, useState } from "react"
import { Toast } from "./Toast"

interface ToastProps {
    message: string
    position?: "top" | "bottom"
    preset?: "light" | "dark"
    duration?: number
    showIcon?: boolean
    className?: string
    onClose?: () => void
}

interface ToastState extends ToastProps {
    timestamp: number
}

type ToastManagerApi = {
    fire: (props: ToastProps) => void
}

let toastManagerApi: ToastManagerApi | null = null

export const ToastManager: React.FC = () => {
    const [toast, setToast] = useState<ToastState | null>(null)

    const handleClose = useCallback(() => {
        if (toast?.onClose) {
            toast.onClose()
        }
        setToast(null)
    }, [toast])

    const fire = useCallback((props: ToastProps) => {
        setToast({ ...props, timestamp: Date.now() })
    }, [])

    useEffect(() => {
        toastManagerApi = { fire }
        return () => {
            toastManagerApi = null
        }
    }, [fire])

    return toast ? (
        <Toast key={toast.timestamp} {...toast} onClose={handleClose} />
    ) : null
}

export const toast = {
    fire: (props: ToastProps) => {
        if (!toastManagerApi) {
            return
        }
        toastManagerApi.fire(props)
    },
}
