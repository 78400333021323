import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgProfile = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 20 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#222429"
            d="M4.874 14.25a8.7 8.7 0 0 1 2.375-1.281A8.1 8.1 0 0 1 10 12.5q1.438 0 2.75.469t2.375 1.281a6.4 6.4 0 0 0 1.136-1.937q.406-1.085.406-2.313 0-2.77-1.948-4.719Q12.77 3.334 10 3.333q-2.77 0-4.718 1.948T3.333 10q0 1.23.406 2.313t1.135 1.937M10 10.833q-1.23 0-2.073-.843-.843-.844-.843-2.073t.843-2.073T10 5q1.23 0 2.073.844.844.843.844 2.073t-.844 2.073-2.073.843m0 7.5a8.1 8.1 0 0 1-3.25-.656 8.4 8.4 0 0 1-2.645-1.781 8.4 8.4 0 0 1-1.782-2.646A8.1 8.1 0 0 1 1.666 10q0-1.73.656-3.25a8.4 8.4 0 0 1 1.782-2.646 8.4 8.4 0 0 1 2.645-1.781A8.1 8.1 0 0 1 10 1.667q1.73 0 3.25.656a8.4 8.4 0 0 1 2.646 1.781 8.4 8.4 0 0 1 1.781 2.646 8.1 8.1 0 0 1 .657 3.25 8.1 8.1 0 0 1-.657 3.25 8.4 8.4 0 0 1-1.78 2.646 8.4 8.4 0 0 1-2.647 1.781 8.1 8.1 0 0 1-3.25.656"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgProfile)
const Memo = memo(ForwardRef)
export default Memo
