import { type ComponentPropsWithoutRef, useEffect } from "react"
import "./PrivateShowAcceptDenyModal.scss"
import { t, Trans } from "@lingui/macro"
import { Sizes } from "../../../interfaces/sizing"
import { useAppSelector } from "../../../store/hooks"
import { selectRoomPrivateRequestStatus } from "../../../store/roomSlice"
import { colorClass } from "../../../utils/css"
import { addPageAction } from "../../../utils/newrelic"
import { Button, ButtonColor, ButtonSize, Typography } from "../../common"
import { approvePrivateShow, declineOrEndPrivateShow } from "../api"

type PrivateShowAcceptDenyModalProps = ComponentPropsWithoutRef<"div">

export function PrivateShowAcceptDenyModal({
    ...props
}: PrivateShowAcceptDenyModalProps) {
    const privateShowRequestStatus = useAppSelector(
        selectRoomPrivateRequestStatus,
    )
    const i18n = {
        accept: t`Accept`,
        deny: t`Deny`,
        isRequesting: t`is requesting a Private Show`,
    }
    const user = privateShowRequestStatus?.requester ?? ""

    useEffect(() => {
        addPageAction("PrivateShowModalDisplayed")
    }, [])

    const handleAccept = () => {
        addPageAction("PrivateShowAccepted")
        void approvePrivateShow()
    }

    const handleDeny = () => {
        addPageAction("PrivateShowDenied")
        void declineOrEndPrivateShow()
    }

    return (
        <div className="PrivateShowAcceptDenyModal" {...props}>
            <Typography
                size={Sizes.smpx}
                className={`PrivateShowAcceptDenyModal__text ${colorClass.whiteFontColor}`}
            >
                <Trans>
                    <span>{user}</span> is requesting a private show
                </Trans>
            </Typography>
            <div className="PrivateShowAcceptDenyModal__buttons">
                <Button
                    className="PrivateShowAcceptDenyModal__buttons__deny"
                    onClick={handleDeny}
                    size={ButtonSize.Small}
                    color={ButtonColor.Clear}
                    text={i18n.deny}
                />
                <Button
                    className="PrivateShowAcceptDenyModal__buttons__accept"
                    onClick={handleAccept}
                    size={ButtonSize.Small}
                    color={ButtonColor.Orange}
                    text={i18n.accept}
                />
            </div>
        </div>
    )
}
