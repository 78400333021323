import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMute = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#222429"
            d="M5 19a1 1 0 1 1 0-2h1v-7q0-.825.212-1.625A6 6 0 0 1 6.85 6.85L10 10H7.2L2.1 4.9a.99.99 0 1 1 1.4-1.4l16.998 16.998a.993.993 0 0 1-1.4 1.41L16.15 19zm13-3.85-9.8-9.8q.5-.4 1.075-.7T10.5 4.2v-.7q0-.625.437-1.062A1.45 1.45 0 0 1 12 2q.625 0 1.062.438.438.436.438 1.062v.7q2 .5 3.25 2.113T18 10zM12 22q-.824 0-1.413-.587A1.93 1.93 0 0 1 10 20h4q0 .824-.588 1.413A1.93 1.93 0 0 1 12 22"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgMute)
const Memo = memo(ForwardRef)
export default Memo
