// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RzjzChfbdRL4BPJk1yJg{width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `RzjzChfbdRL4BPJk1yJg`
};
export default ___CSS_LOADER_EXPORT___;
