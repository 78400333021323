import "./SearchAutocomplete.scss"
import { Sizes } from "../../../../../../interfaces/sizing"
import { Typography } from "../../../../../common"
import { preventFilterDrawerDrag } from "../../helpers"

export interface IAutocompleteItem {
    value: string
    displayName: string
}

interface SearchAutocompleteProps {
    items: IAutocompleteItem[]
    onItemClick: (value: string) => void
    loading: boolean
    maxShownSuggestions?: number
}

export function SearchAutocomplete({
    items,
    onItemClick,
    loading,
    maxShownSuggestions,
}: SearchAutocompleteProps) {
    return (
        <div
            className="SearchAutocomplete"
            data-testid="search-autocomplete"
            onMouseDown={preventFilterDrawerDrag}
            onTouchStart={preventFilterDrawerDrag}
        >
            {items.length > 0 ? (
                <>
                    {items
                        .slice(
                            0,
                            maxShownSuggestions
                                ? maxShownSuggestions
                                : undefined,
                        )
                        .map((item, index) => (
                            <div
                                className="SearchAutocomplete__item"
                                data-testid={`search-autocomplete-item-${index}`}
                                onClick={(ev) => {
                                    ev.stopPropagation()
                                    // give some time for the :active style to apply background color to selection after user taps it
                                    setTimeout(() => {
                                        onItemClick(item.value)
                                    }, 100)
                                }}
                                key={item.value}
                            >
                                {item.displayName}
                            </div>
                        ))}
                </>
            ) : (
                <Typography
                    className="SearchAutocomplete__item SearchAutocomplete__notFound SearchAutocomplete__result"
                    component="div"
                    size={Sizes.xs}
                >
                    {loading ? "Loading..." : "Not Found"}
                </Typography>
            )}
        </div>
    )
}
