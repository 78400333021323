import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgSetup = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 20 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="url(#setup_svg__a)"
            d="m13.5 14.9 1.4-1.4a.48.48 0 0 0 0-.7l-3.4-3.425q.1-.275.15-.55a3.5 3.5 0 0 0 .05-.625q0-1.425-1.012-2.437A3.32 3.32 0 0 0 8.25 4.75a3.3 3.3 0 0 0-.85.112q-.425.113-.825.313l2.35 2.35-1.4 1.4-2.35-2.35a4.3 4.3 0 0 0-.312.825 3.3 3.3 0 0 0-.113.85q0 1.424 1.013 2.438A3.32 3.32 0 0 0 8.2 11.7q.325 0 .613-.05.287-.05.562-.15l3.425 3.4a.48.48 0 0 0 .7 0M10 20a9.7 9.7 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137Q1.575 15.725.788 13.9A9.7 9.7 0 0 1 0 10q0-2.074.788-3.9a10.1 10.1 0 0 1 2.137-3.175Q4.275 1.575 6.1.788A9.7 9.7 0 0 1 10 0q2.075 0 3.9.788a10.1 10.1 0 0 1 3.175 2.137q1.35 1.35 2.137 3.175A9.7 9.7 0 0 1 20 10a9.7 9.7 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175q-1.35 1.35-3.175 2.137A9.7 9.7 0 0 1 10 20"
        />
        <defs>
            <linearGradient
                id="setup_svg__a"
                x1={4}
                x2={17}
                y1={2}
                y2={17.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F4A321" />
                <stop offset={1} stopColor="#F47321" />
            </linearGradient>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgSetup)
const Memo = memo(ForwardRef)
export default Memo
