// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PHld8rqOGZWmB1UxZx0k{display:flex;flex-direction:column;color:#000;height:100%}.PHld8rqOGZWmB1UxZx0k ._o6vgF2aftUQWMPK8L79{flex:1;overflow-y:auto;display:flex;flex-direction:column;position:relative}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA{z-index:101;display:flex;justify-content:space-between;align-items:center;padding:12px 16px;position:relative;top:0;left:0;right:0;opacity:1;visibility:visible;height:auto;transition:all .5s}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA.CH_DVIustAYhG3JhXigC{padding:0;opacity:0;visibility:hidden;height:0}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA .jUtZavKuWATNOz_BS_PA{font-family:"UbuntuMedium",sans-serif;font-size:20px;font-weight:700;margin:0;color:#222429;line-height:16px}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA .NJveATqbusmcgOhCP7ug{margin-right:4px}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA .A3fwG4P3SgYTgBZH7Y5V{display:flex;gap:16px}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA .TIILZmZOsKlbcgAVLiGq{background:none;border:none;cursor:pointer;display:flex;align-items:center;justify-content:center;padding:0}.PHld8rqOGZWmB1UxZx0k .jRDDh51J9muxDaWbwBiA .TIILZmZOsKlbcgAVLiGq svg{flex-shrink:0}.PHld8rqOGZWmB1UxZx0k .J_77QZnRXgmMOX_pzIYG{display:flex;align-items:center;padding:4px 16px 0 16px;border-bottom:4px solid #f9f9f9;gap:16px}.PHld8rqOGZWmB1UxZx0k .PA28W_kuusU5RvdGghGR{padding:5px 16px 0 16px}.PHld8rqOGZWmB1UxZx0k .uJVgJxsPQeX2VHQAWdWU{height:28px;width:28px;display:flex;justify-content:center;align-items:center;border:none;background:none}.ifoRW3GP5f4JGzTudy93.PHld8rqOGZWmB1UxZx0k{width:368px;height:100vh}.ifoRW3GP5f4JGzTudy93 .J_77QZnRXgmMOX_pzIYG{border-bottom:none}.ifoRW3GP5f4JGzTudy93 .jRDDh51J9muxDaWbwBiA{padding:18px 16px}.T1x_UYsea40YR8lTtTfG.PHld8rqOGZWmB1UxZx0k{background-color:#fff;position:fixed;top:0;z-index:1002;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inbox": `PHld8rqOGZWmB1UxZx0k`,
	"inboxContent": `_o6vgF2aftUQWMPK8L79`,
	"inboxHeader": `jRDDh51J9muxDaWbwBiA`,
	"searchFocused": `CH_DVIustAYhG3JhXigC`,
	"inboxHeaderTitle": `jUtZavKuWATNOz_BS_PA`,
	"alertCircle": `NJveATqbusmcgOhCP7ug`,
	"inboxHeaderButtons": `A3fwG4P3SgYTgBZH7Y5V`,
	"closeButton": `TIILZmZOsKlbcgAVLiGq`,
	"inboxFilter": `J_77QZnRXgmMOX_pzIYG`,
	"focused": `PA28W_kuusU5RvdGghGR`,
	"filterButton": `uJVgJxsPQeX2VHQAWdWU`,
	"desktopUnified": `ifoRW3GP5f4JGzTudy93`,
	"mobile": `T1x_UYsea40YR8lTtTfG`
};
export default ___CSS_LOADER_EXPORT___;
