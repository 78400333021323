import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useThrottle } from "../../../hooks/useThrottle"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { useSearchByUsernameQuery } from "../../../store/accountSlice"
import { Typography } from "../../common"
import {
    ChevronLeftBlue,
    SearchPlaceholder,
} from "../../common/atoms/Icons/Chat"
import { Spinner } from "../../common/atoms/Spinner"
import { IconButton } from "../common/IconButton/IconButton"
import { Page } from "../common/Page/Page"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import { SearchInput } from "../common/SearchInput/SearchInput"
import { classNames } from "../utils"
import styles from "./SearchUsers.module.scss"

const THROTTLE_DELAY_MS = 500

export function SearchUsers() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const qParam = searchParams.get("q") ?? ""
    const [search, setSearch] = useState(qParam)

    useEffect(() => {
        if (search !== qParam) {
            setSearchParams({ q: search }, { replace: true })
        }
    }, [search, qParam, setSearchParams])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const throttledQParam = useThrottle(qParam, THROTTLE_DELAY_MS)

    const { data, isFetching, error } = useSearchByUsernameQuery(
        { term: throttledQParam },
        {
            skip: !throttledQParam,
        },
    )

    return (
        <Page className={styles.container}>
            <div className={styles.header}>
                <IconButton
                    icon={<ChevronLeftBlue height={16} width={10} />}
                    iconAlt="Back"
                    onClick={() => navigate(-1)}
                    className={styles.backButton}
                />
                <SearchInput
                    inputProps={{
                        value: search,
                        onChange: handleSearchChange,
                        autoFocus: true,
                        maxLength: 30,
                        placeholder: t`Search creators`,
                    }}
                    onClear={search ? () => setSearch("") : undefined}
                />
            </div>

            <div className={styles.results}>
                {isFetching && (
                    <div
                        className={classNames(styles.message, styles.loading)}
                        data-testid="results-loading"
                    >
                        <Spinner size="xspx" />
                        Loading...
                    </div>
                )}
                {error && (
                    <div
                        className={classNames(styles.message, styles.error)}
                        data-testid="results-error"
                    >
                        Error loading results
                    </div>
                )}

                {!isFetching &&
                    search.trim() &&
                    data?.users.map((result) => (
                        <div
                            key={result.username}
                            className={styles.searchResult}
                            onClick={() =>
                                navigate(`/messages/${result.username}`)
                            }
                        >
                            <ProfilePicture
                                user={{
                                    //  TODO: [MSG-413] We currently don't have the user data from the search endpoint so they will appear as default user color
                                    username: result.username,
                                    inFanclub: false,
                                    hasTokens: false,
                                    isMod: false,
                                    tippedRecently: false,
                                    tippedALotRecently: false,
                                    tippedTonsRecently: false,
                                }}
                                size={40}
                            />
                            <span>{result.username}</span>
                        </div>
                    ))}

                {!isFetching &&
                    !error &&
                    search.trim() &&
                    data?.users.length === 0 && (
                        <div
                            className={classNames(styles.message, styles.left)}
                            data-testid="results-empty"
                        >
                            {t`No results found`}
                        </div>
                    )}

                {!isFetching && search.trim() === "" && (
                    <div className={styles.initialPlaceholderContainer}>
                        <SearchPlaceholder
                            className={styles.searchPlaceholderSvg}
                        />
                        <Typography
                            size={Sizes.mdpx}
                            weight={Weights.medium}
                            className={styles.searchPlaceholderText}
                        >{t`Enter a creator's name to send a message`}</Typography>
                    </div>
                )}
            </div>
        </Page>
    )
}
