import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}

const SvgSendArrow = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#F47321"
            fillRule="evenodd"
            d="M21.6 11.7a.64.64 0 0 1-.383.589l-14.364 6.273a.642.642 0 0 1-.863-.814l2.281-5.083h5.594a.965.965 0 0 0 0-1.928h-5.594l-2.282-5.082a.643.643 0 0 1 .865-.814l14.364 6.273a.64.64 0 0 1 .382.588"
            clipRule="evenodd"
        />
    </svg>
)

const ForwardRef = forwardRef(SvgSendArrow)
const Memo = memo(ForwardRef)
export default Memo
