import { useState } from "react"
import { t } from "@lingui/macro"
import { error } from "@multimediallc/logging"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/hooks"
import { useIgnoreMutation } from "../../../store/ignoredUsersSlice"
import { removeThread } from "../../../store/messagingSlice"
import { BlackClose } from "../../common/atoms/Icons/Chat"
import { Overlay } from "../../common/atoms/Overlay"
import { ConfirmModal } from "../common/ConfirmModal/ConfirmModal"
import { sendReportMessage } from "./api"
import styles from "./ReportMessage.module.scss"
import type { ISendReportMessageResponse } from "./api"
import type { Message } from "../types"

interface ReportMessageProps {
    onClose: () => void
    message?: Message
}

type ReportStep = "form" | "confirmation"

export enum ChatMessageReportCategory {
    None = "",
    Inappropriate = "Inappropriate message",
    Rude = "Rude message",
    Spam = "Spam message",
    Other = "Other (please describe)",
}

function getTranslatedCategory(category: ChatMessageReportCategory): string {
    switch (category) {
        case ChatMessageReportCategory.None:
            return ""
        case ChatMessageReportCategory.Inappropriate:
            return t`Inappropriate message`
        case ChatMessageReportCategory.Rude:
            return t`Rude message`
        case ChatMessageReportCategory.Spam:
            return t`Spam message`
        case ChatMessageReportCategory.Other:
            return t`Other (please describe)`
    }
}

export function ReportMessage({ onClose, message }: ReportMessageProps) {
    const dispatch = useAppDispatch()
    const [ignore] = useIgnoreMutation()
    const [reportStep, setReportStep] = useState<ReportStep>("form")
    const [category, setCategory] = useState<string>(
        ChatMessageReportCategory.None,
    )
    const [comments, setComments] = useState<string>("")
    const { username = "" } = useParams<{ username: string }>()
    const [reportError, setReportError] = useState<string>("")

    const handleSubmitReport = () => {
        if (username === "") {
            error(
                "could not get username of user to report from memory router params",
            )
            return
        }

        if (message === undefined) {
            return
        }

        sendReportMessage(message, category, comments)
            .then((response: ISendReportMessageResponse) => {
                if (!response.success) {
                    error("Failed to submit report")
                } else {
                    setReportStep("confirmation")
                }
            })
            .catch((err: Error) => {
                setReportError(err.message)
                error("Error occurred while sending report message", err)
            })
    }

    const handleIgnoreUser = async () => {
        if (username === "") {
            error(
                "could not get username of user to ignore from memory router params",
            )
            return
        }
        await ignore({ username })
        onClose()
    }

    const disableSubmit =
        category === ChatMessageReportCategory.None ||
        (category === ChatMessageReportCategory.Other && comments.trim() === "")

    return (
        <>
            <Overlay
                onClick={onClose}
                data-testid="report-backdrop"
                className={styles.backdrop}
            />
            <div className={styles.overlay}>
                {!reportError && (
                    <div
                        className={styles.outerOverlay}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            className={styles.closeX}
                            onClick={onClose}
                            data-testid="report-close-button"
                        >
                            <BlackClose height={24} width={24} />
                        </button>

                        <div
                            className={styles.contentHeader}
                            data-testid="report-message-header"
                        >
                            {t`Report Message`}
                        </div>
                        <div className={styles.innerOverlay}>
                            {reportStep === "form" && !reportError && (
                                <div className={styles.content}>
                                    <p className={styles.contentSubtext}>
                                        {t`Your report will be reviewed as soon as
                                    practicable.`}{" "}
                                        <a
                                            className={styles.terms}
                                            href="/terms/#room-reports"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t`Terms`}
                                        </a>
                                    </p>

                                    <div className={styles.inputLabel}>
                                        {t`Choose a category`}
                                    </div>
                                    <select
                                        className={styles.categorySelect}
                                        value={category}
                                        onChange={(e) =>
                                            setCategory(e.target.value)
                                        }
                                    >
                                        {Object.values(
                                            ChatMessageReportCategory,
                                        ).map((cat) => (
                                            <option
                                                key={cat}
                                                value={cat}
                                                disabled={
                                                    cat ===
                                                    ChatMessageReportCategory.None
                                                }
                                            >
                                                {getTranslatedCategory(cat) ||
                                                    "--"}
                                            </option>
                                        ))}
                                    </select>

                                    <div className={styles.inputLabel}>
                                        {t`Additional comments:`}
                                    </div>
                                    <textarea
                                        className={styles.textInput}
                                        data-testid="report-comments"
                                        value={comments}
                                        onChange={(e) =>
                                            setComments(e.target.value)
                                        }
                                    />
                                    <button
                                        className={styles.submitButton}
                                        data-testid="submit-report-btn"
                                        onClick={handleSubmitReport}
                                        disabled={disableSubmit}
                                    >
                                        {t`Report`}
                                    </button>
                                </div>
                            )}

                            {reportStep === "confirmation" && (
                                <div className={styles.content}>
                                    <h2 className={styles.title}>
                                        {t`We've received your report.`}
                                    </h2>
                                    <p className={styles.contentSubtext}>
                                        {t`Would you like to ignore this user?`}
                                        <br />
                                        {t`You can unignore them later from your settings.`}
                                    </p>

                                    <button
                                        className={styles.ignoreButton}
                                        onClick={handleIgnoreUser}
                                        data-testid="ignore-user-btn"
                                    >
                                        {t`Ignore`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {reportError && (
                <div className={styles.errorModal}>
                    <ConfirmModal
                        isOpen={reportError !== ""}
                        onClose={() => onClose()}
                        onConfirm={() => onClose()}
                        title={reportError}
                        confirmText={t`OK`}
                        cancelText={null}
                        confirmButtonStyle={{
                            backgroundColor: "#0C6A93",
                        }}
                    />
                </div>
            )}
        </>
    )
}
