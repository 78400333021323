import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgPopout = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 18 18"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#222"
            d="M2 18q-.824 0-1.412-.587A1.93 1.93 0 0 1 0 16V2Q0 1.176.588.588A1.93 1.93 0 0 1 2 0h7v2H2v14h14V9h2v7q0 .824-.587 1.413A1.93 1.93 0 0 1 16 18zm4.7-5.3-1.4-1.4L14.6 2H11V0h7v7h-2V3.4z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgPopout)
const Memo = memo(ForwardRef)
export default Memo
