import "./SearchResultsHeader.scss"
import { Trans } from "@lingui/macro"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import {
    UrlStateContextProvider,
    useUrlStateContext,
} from "../../../hooks/urlStateContext"
import Chevron from "../../common/atoms/Icons/Others/Chevron"
import { getKeywordString } from "../categoryHeaderUtils"

export function SearchResultsHeader() {
    return (
        <UrlStateContextProvider>
            <SearchResultsHeaderContent />
        </UrlStateContextProvider>
    )
}
export function SearchResultsHeaderContent() {
    const { keywords } = useUrlStateContext()

    const urlSansKeywords = UrlState.current.getURLForPartialState({
        keywords: undefined,
    })

    const navigateToURLSansKeywords = (e: React.MouseEvent) => {
        e.preventDefault()
        UrlState.current.navigateTo(urlSansKeywords)
    }

    const keywordString = getKeywordString(keywords)

    return (
        <a
            className={`SearchResultsHeader${keywordString === "" ? " SearchResultsHeader__hidden" : ""}`}
            onClick={(e) => navigateToURLSansKeywords(e)}
            href={urlSansKeywords}
        >
            <span className="SearchResultsHeader__chevron">
                <Chevron />
            </span>
            <span className="SearchResultsHeader__text">
                <Trans>
                    Search results for <b>&quot;{keywordString}&quot;</b>
                </Trans>
            </span>
        </a>
    )
}
