import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgImageAdd = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 36 27"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0C6A93"
            fillRule="evenodd"
            d="M28.5 1.5a1.5 1.5 0 0 1 3 0V4H34a1.5 1.5 0 0 1 0 3h-2.5v2.5a1.5 1.5 0 0 1-3 0V7H26a1.5 1.5 0 0 1 0-3h2.5zm-9-.5 1.749 1.89A9 9 0 0 0 30 14v9.5q0 1.237-.881 2.119A2.9 2.9 0 0 1 27 26.5H3a2.9 2.9 0 0 1-2.119-.881A2.9 2.9 0 0 1 0 23.5V7q0-1.237.881-2.119A2.9 2.9 0 0 1 3 4h4.725L10.5 1zM15 22q2.813 0 4.781-1.969 1.97-1.968 1.969-4.781t-1.969-4.781Q17.813 8.499 15 8.5t-4.781 1.969q-1.97 1.968-1.969 4.781t1.969 4.781Q12.187 22.001 15 22m0-3q-1.575 0-2.662-1.087-1.088-1.088-1.088-2.663t1.088-2.662T15 11.5t2.663 1.088 1.087 2.662-1.087 2.663T15 19"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgImageAdd)
const Memo = memo(ForwardRef)
export default Memo
