import {
    DEFAULT_MAX_AGE,
    DEFAULT_MIN_AGE,
    isLanguageFilterActive,
} from "@multimediallc/cb-roomlist-prefetch"
import { SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import {
    FilterSectionToStateKeys,
    PrivatePriceToLabel,
    RegionToLabel,
    RoomSizeToLabel,
    SECTION_ORDER,
    SpyPriceToLabel,
} from "./constants"
import { ActiveFiltersSection } from "./types"
import type { IURLState, RoomSize } from "@multimediallc/cb-roomlist-prefetch"

function stateKeyValueCount(key: keyof IURLState, state: IURLState): number {
    const value = state[key]
    return Array.isArray(value) ? value.length : Number(Boolean(value))
}

export function getActiveFilterCount(urlState: IURLState): number {
    // Counts the number of active filters that affect the current page.
    const sectionsToNotCount = [
        urlState.showType !== "private"
            ? ActiveFiltersSection.SpyPrices
            : ActiveFiltersSection.PrivatePrices,
    ]
    if (!isLanguageFilterActive()) {
        sectionsToNotCount.push(ActiveFiltersSection.Languages)
    }
    const sectionsToSum = SECTION_ORDER.filter(
        (item) => !sectionsToNotCount.includes(item),
    )
    return sectionsToSum
        .map((section) => filterCountForSection(section, urlState))
        .reduce((accum, count) => accum + count)
}

export function filterCountForSection(
    section: ActiveFiltersSection,
    state: IURLState,
): number {
    const sectionKeys = FilterSectionToStateKeys.get(section) ?? []
    // The only section with multiple associated keys, Ages, is considered active if either has a value, hence the max()
    return Math.max(...sectionKeys.map((key) => stateKeyValueCount(key, state)))
}

export function getLabelForFilterSection(
    section: ActiveFiltersSection,
    state: IURLState,
): string | undefined {
    if (filterCountForSection(section, state) === 0) {
        return undefined
    }
    switch (section) {
        case ActiveFiltersSection.Tags:
            return `#${state.tags?.[0]}`
        case ActiveFiltersSection.Regions:
            return (state.regions ?? [])
                .map((r) => RegionToLabel.get(r))
                .join(", ")
        case ActiveFiltersSection.RoomSize:
            return RoomSizeToLabel.get(state.roomSize as RoomSize)
        case ActiveFiltersSection.PrivatePrices:
            return (state.privatePrices ?? [])
                .map((p) => PrivatePriceToLabel.get(p))
                .join(", ")
        case ActiveFiltersSection.SpyPrices:
            return (state.spyShowPrices ?? [])
                .map((p) => SpyPriceToLabel.get(p))
                .join(", ")
        case ActiveFiltersSection.Ages:
            const ageMin = state.ageMin ?? DEFAULT_MIN_AGE
            const ageMax = state.ageMax ?? DEFAULT_MAX_AGE
            if (ageMin === ageMax - 1) {
                return ageMin.toString()
            } else if (ageMax < DEFAULT_MAX_AGE) {
                return `${ageMin} - ${ageMax - 1}`
            } else {
                return `${ageMin} +`
            }
        case ActiveFiltersSection.Languages:
            return (state.spokenLanguages ?? [])
                .map((l) => SPOKEN_LANGUAGES_MAP.get(l))
                .filter((l) => l)
                .join(", ")
        default:
            return undefined
    }
}

// Used as a mousedown/touchstart event handler to prevent scrollable elements
// from also dragging the filter drawer. Does so by stopping propagation of the
// initial pointer event. `dragAtScrollTop` allows the event to still propagate
// IF it starts at the top of the scrollable area, so dragging from the middle
// of the area still closes the drawer if scrolled all the way up to begin with.
export function preventFilterDrawerDrag(
    e: React.MouseEvent | React.TouchEvent,
    dragAtScrollTop: boolean = false,
): void {
    if (dragAtScrollTop && e.currentTarget.scrollTop === 0) {
        return
    }
    e.stopPropagation()
}
