import { Fragment, useMemo } from "react"
import { parsedResult } from "@multimediallc/web-utils/messageParsing"
import { MessagePartType } from "@multimediallc/web-utils/types"
import { EmoticonPart } from "./EmoticonPart"
import { ShortcodePart } from "./ShortcodePart"
import { StringPart } from "./StringPart"
import type {
    IEmoticon,
    IParsePart,
    IShortcode,
    IShortcodePart,
    IStringPart,
} from "@multimediallc/web-utils/types"

interface MessagePartsProps {
    message: string
    shortcodes: IShortcode[] | undefined
}

export function MessageParts({ message, shortcodes }: MessagePartsProps) {
    const messageParts = useMemo(
        () => parsedResult(message, shortcodes),
        [message, shortcodes],
    )

    const renderPart = (part: IParsePart) => {
        switch (part.partType) {
            case MessagePartType.string:
                return <StringPart {...(part as IStringPart)} />
            case MessagePartType.emoticon:
                return <EmoticonPart {...(part as IEmoticon)} />
            case MessagePartType.shortcode:
                return <ShortcodePart {...(part as IShortcodePart)} />
            default:
                return null
        }
    }

    return (
        <span>
            {messageParts.map((part: IParsePart, idx: number) => {
                return <Fragment key={idx}>{renderPart(part)}</Fragment>
            })}
        </span>
    )
}
