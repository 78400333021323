import { useEffect, useRef } from "react"

interface LongPressOptions {
    onLongPress: () => void
    onPressRelease?: () => void
    delay?: number
}

export function useLongPress({
    onLongPress,
    onPressRelease,
    delay = 500,
}: LongPressOptions): { ref: React.RefObject<HTMLDivElement> } {
    const timerRef = useRef<NodeJS.Timeout | null>(null)
    const elementRef = useRef<HTMLDivElement | null>(null)
    const touchPosition = useRef<{ x: number; y: number } | null>(null)

    const clear = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
            timerRef.current = null
        }
        if (onPressRelease) {
            onPressRelease()
        }
    }

    const handleTouchStart = (e: TouchEvent) => {
        touchPosition.current = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
        }
        timerRef.current = setTimeout(() => {
            onLongPress()
        }, delay)
    }

    const handleTouchMove = (e: TouchEvent) => {
        // if touch moves too far, cancel the long press. used for scrolling.
        if (touchPosition.current) {
            const deltaX = Math.abs(
                e.touches[0].clientX - touchPosition.current.x,
            )
            const deltaY = Math.abs(
                e.touches[0].clientY - touchPosition.current.y,
            )
            if (deltaX > 10 || deltaY > 10) {
                clear()
            }
        }
    }

    const handleTouchEnd = () => {
        clear()
    }

    useEffect(() => {
        const element = elementRef.current
        if (!element) return

        element.addEventListener("touchstart", handleTouchStart, {
            passive: true,
        })
        element.addEventListener("touchmove", handleTouchMove, {
            passive: true,
        })
        element.addEventListener("touchend", handleTouchEnd)

        return () => {
            element.removeEventListener("touchstart", handleTouchStart)
            element.removeEventListener("touchmove", handleTouchMove)
            element.removeEventListener("touchend", handleTouchEnd)
        }
    }, [onLongPress, onPressRelease, delay])

    return {
        ref: elementRef,
    }
}
