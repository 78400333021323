import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgTokens = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 10 10"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.833 1.349A6.4 6.4 0 0 0 5.61.873a.11.11 0 0 0 .058-.097V.592C5.667.265 4.399 0 2.833 0 1.268 0 0 .265 0 .592v.184a.11.11 0 0 0 .058.097c.88.362 1.827.525 2.775.476M5.61 2.624a6.4 6.4 0 0 1-2.776.476 6.4 6.4 0 0 1-2.775-.476A.11.11 0 0 1 0 2.528V1.497a.11.11 0 0 1 .047-.09.11.11 0 0 1 .099-.012 8 8 0 0 0 2.687.394c.91.023 1.819-.11 2.686-.394a.107.107 0 0 1 .135.05.1.1 0 0 1 .013.052v1.03a.11.11 0 0 1-.058.097M3.437 8.24a.1.1 0 0 1-.074.034q-.257.01-.53.01C1.268 8.284 0 8.022 0 7.694v-.942a.11.11 0 0 1 .046-.092.11.11 0 0 1 .1-.012 8 8 0 0 0 2.687.394q.26 0 .52-.012a.11.11 0 0 1 .105.066.1.1 0 0 1 .008.043v1.026a.1.1 0 0 1-.03.075m-.074-1.649q-.252.013-.53.013A6.4 6.4 0 0 1 .058 6.13.11.11 0 0 1 0 6.033V5a.11.11 0 0 1 .047-.09.11.11 0 0 1 .1-.012 8 8 0 0 0 2.686.394q.294 0 .59-.016a.108.108 0 0 1 .106.147 1 1 0 0 0-.063.388v.67a.1.1 0 0 1-.03.076.1.1 0 0 1-.073.034m-.53-1.739a9 9 0 0 0 2.774-.476.11.11 0 0 0 .058-.097V3.25a.11.11 0 0 0-.048-.091.11.11 0 0 0-.1-.012 8 8 0 0 1-2.687.395 8 8 0 0 1-2.686-.395.11.11 0 0 0-.131.053.1.1 0 0 0-.013.05v1.033a.11.11 0 0 0 .058.096c.88.362 1.827.524 2.775.474m1.595 1.753a.1.1 0 0 1 .052.007 8 8 0 0 0 2.686.394c.91.023 1.82-.11 2.686-.394a.107.107 0 0 1 .135.051.1.1 0 0 1 .013.051v1.033a.11.11 0 0 1-.058.097 6.4 6.4 0 0 1-2.776.476 6.4 6.4 0 0 1-2.776-.476.11.11 0 0 1-.058-.097V6.714a.11.11 0 0 1 .047-.09.1.1 0 0 1 .05-.019m2.738 2.153a8 8 0 0 1-2.686-.395.107.107 0 0 0-.135.051.1.1 0 0 0-.013.051v.943c0 .327 1.268.592 2.834.592 1.565 0 2.833-.263 2.833-.592v-.942a.11.11 0 0 0-.047-.09.11.11 0 0 0-.1-.012 8 8 0 0 1-2.686.394M4.333 5.995v-.184c0-.327 1.268-.592 2.834-.592 1.565 0 2.833.265 2.833.592v.184a.11.11 0 0 1-.058.097 6.4 6.4 0 0 1-2.775.476 6.4 6.4 0 0 1-2.776-.476.11.11 0 0 1-.058-.097"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgTokens)
const Memo = memo(ForwardRef)
export default Memo
