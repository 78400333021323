import type React from "react"
import "./Skeleton.scss"
import { mergeClasses } from "../../../../utils/css"
import type { Sizes } from "../../../../interfaces/sizing"

interface SkeletonProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * Defines the sizing of the skeleton based on typography styles
     */
    size?: keyof typeof Sizes
    /**
     * Width of the block. Defaults to 100% of the container
     */
    width?: string
}

export const Skeleton = ({
    className,
    size = "md",
    width = "100%",
    ...props
}: SkeletonProps) => {
    const classList = ["cbr-skeleton", `cbr-skeleton--${size}`]

    return (
        <div
            className={mergeClasses(classList, className)}
            {...props}
            style={{ width: width, ...props.style }}
        />
    )
}
