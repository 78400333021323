import { t } from "@lingui/macro"
import { ShortcodeParser } from "@multimediallc/web-utils/shortcodeParsing"
import { Shortcode } from "@multimediallc/web-utils/types"
import { useAppContext } from "../../../../hooks/appContext"
import { useAlert } from "../../../../hooks/useAlert"
import { normalizeResource } from "../../../../routes/util"
import { addPageAction } from "../../../../utils/newrelic"
import { AlertType } from "../../../common"
import { useMobileBroadcastContext } from "../../mobileBroadcastContext"
import type { IShortcodePart, IUserInfo } from "@multimediallc/web-utils/types"
import "./ShortcodePart.scss"

type ActualShortcodeProps = IShortcodePart & {
    fromUser?: IUserInfo
}

export function ShortcodePart(props: ActualShortcodeProps) {
    const { code, msg, signupLink, amt, fromUser } = props
    const { room } = useMobileBroadcastContext()
    const language = useAppContext().context.language_code
    const addAlert = useAlert()
    // TODO (CBD-1226) ensure this plays well with MobileStreamer's beforeunload handler
    const confirmNavigation = (e: React.MouseEvent): void => {
        e.preventDefault()
        addAlert({
            type: AlertType.confirm,
            title: t`Are you sure?`,
            contents: t`Navigating away from this page will immediately stop your broadcast.`,
            yesCallback: () => {
                const anchor = e.currentTarget as HTMLAnchorElement
                window.open(anchor.href)

                const isMessage = fromUser !== undefined
                const shortCodeAction = {
                    from_user: isMessage ? fromUser.username : "",
                    shortcode: code,
                    room: room,
                    user_clicked: room,
                    tip: amt !== undefined && code === Shortcode.Tip ? amt : 0,
                    message: msg,
                    from_notice: !isMessage,
                }
                addPageAction("MBR ShortcodeLinkClicked", shortCodeAction)
            },
        })
    }
    let linkText, linkUrl
    let callback: ((e: React.MouseEvent<HTMLAnchorElement>) => void) | undefined
    switch (code) {
        case Shortcode.Fanclub:
            linkText = t`Join ${room}'s Fan Club`
            linkUrl = signupLink
            callback = confirmNavigation
            break
        case Shortcode.Supporter:
            linkText = t`Become a supporter`
            linkUrl = "/supporter/upgrade/"
            callback = confirmNavigation
            break
        case Shortcode.Signup:
            linkText = t`Join Chaturbate`
            linkUrl = signupLink
            callback = confirmNavigation
            break
        // No callback for Tip or Follow shortcodes, no effect for broadcaster
        case Shortcode.Tip:
            linkText = msg ?? ""
            break
        case Shortcode.Follow:
            linkText = t`Follow ${room}`
            break
        case Shortcode.Help: // Only relevant for outgoing messages
        default:
            return null
    }

    return (
        <a
            href={linkUrl && normalizeResource(language, linkUrl)}
            className="shortcode-link"
            title={ShortcodeParser.getShortcodeTitle(props)}
            data-testid="shortcode-link"
            onClick={(e) => {
                if (callback) {
                    callback(e)
                }
            }}
        >
            {linkText}
        </a>
    )
}
