import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import {
    useFollowMutation,
    useIsFollowingQuery,
    useUnfollowMutation,
} from "../../../store/followSlice"
import { useAppSelector } from "../../../store/hooks"
import Star from "../../common/atoms/Icons/Chat/Star"
import { toast } from "../common/Toast/ToastManager"
import { classNames } from "../utils"
import styles from "./FollowStar.module.scss"

interface FollowStarProps {
    username: string
}

export const FollowStar = ({ username }: FollowStarProps) => {
    const [isUpdating, setIsUpdating] = useState(false)
    const [expectedFollowState, setExpectedFollowState] = useState<
        boolean | null
    >(null)
    const loggedInUser = useAppSelector((state) => state.user.loggedInUser)

    const { data: followStatus, isLoading } = useIsFollowingQuery(username, {
        skip: !loggedInUser,
    })
    const [follow] = useFollowMutation()
    const [unfollow] = useUnfollowMutation()

    useEffect(() => {
        if (
            expectedFollowState !== null &&
            followStatus?.following === expectedFollowState
        ) {
            setExpectedFollowState(null)
            setIsUpdating(false)
        }
    }, [followStatus?.following, expectedFollowState])

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()

        if (!loggedInUser) {
            window.location.href = `/auth/login/?next=/${username}/`
            return
        }

        if (isUpdating || isLoading) return

        const newFollowState = !followStatus?.following
        setExpectedFollowState(newFollowState)
        setIsUpdating(true)

        try {
            if (!newFollowState) {
                unfollow({ username: username, location: "FollowStar" })
                    .then(() => {
                        toast.fire({
                            message: t`No longer following ${username}.`,
                            position: "top",
                            preset: "dark",
                            showIcon: false,
                        })
                    })
                    .catch((error) => {
                        error("Failed to update follow status:", error)
                        setExpectedFollowState(null)
                        setIsUpdating(false)
                    })
            } else {
                follow({ username: username, location: "FollowStar" })
                    .then(() => {
                        toast.fire({
                            message: t`Following ${username}.`,
                            position: "top",
                            preset: "dark",
                            showIcon: false,
                        })
                    })
                    .catch((error) => {
                        error("Failed to update follow status:", error)
                        setExpectedFollowState(null)
                        setIsUpdating(false)
                    })
            }
        } catch (error) {
            error("Failed to update follow status:", error)
            setExpectedFollowState(null)
            setIsUpdating(false)
        }
    }

    const isFollowing =
        expectedFollowState !== null
            ? expectedFollowState
            : followStatus?.following

    return (
        <div
            onClick={handleClick}
            className={classNames(styles.followStar, {
                [styles.updating]: Boolean(isUpdating),
                [styles.following]: Boolean(isFollowing),
            })}
            title={
                isUpdating || isLoading
                    ? ""
                    : isFollowing
                      ? t`Unfollow`
                      : t`Follow`
            }
            data-testid="follow-star"
        >
            <Star width={24} height={24} />
        </div>
    )
}
