import { useState } from "react"
import { t } from "@lingui/macro"
import { BlackClose } from "../../../common/atoms/Icons/Chat"
import styles from "./MessageMediaTokenModal.module.scss"

type Props = {
    albumPrice: number
    setAlbumPrice: (tokenPrice: number) => void
    setShowTokenModal: (show: boolean) => void
}

export const MessageMediaTokenModal: React.FC<Props> = ({
    albumPrice,
    setAlbumPrice,
    setShowTokenModal,
}) => {
    const [tokens, setTokens] = useState<number>(albumPrice)

    const handleSubmit = () => {
        setAlbumPrice(tokens)
        setShowTokenModal(false)
    }

    const handleTokenPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value

        if (value.length > 1 && value.startsWith("0")) {
            value = value.slice(1)
        }

        setTokens(Number(value))
    }

    const handleTokenPriceKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (e.key === "0" && tokens === 0) {
            e.preventDefault()
        }

        if (e.key === "-" || e.key === ".") {
            e.preventDefault()
        }
    }

    const handleClose = () => {
        setShowTokenModal(false)
    }

    return (
        <div className={styles.overlay} onClick={handleClose} role="dialog">
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h2>{t`Add Token Price`}</h2>
                    <button
                        onClick={handleClose}
                        className={styles.closeBtn}
                        data-testid="close-btn"
                    >
                        <BlackClose height={24} width={24} />
                    </button>
                </div>
                <div className={styles.inputWrapper}>
                    <input
                        type="number"
                        placeholder={t`Token Price`}
                        value={tokens.toString()}
                        onKeyDown={handleTokenPriceKeyDown}
                        onChange={handleTokenPriceChange}
                        min={0}
                    />
                    <span className={styles.inputSuffix}>{t`Tokens`}</span>
                </div>
                <div className={styles.footer}>
                    <button onClick={handleSubmit}>{t`Set Token price`}</button>
                </div>
            </div>
        </div>
    )
}
