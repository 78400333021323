// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JwPpqzaGk7TFSHJZ67Gp{display:flex;flex:1;flex-direction:column;height:100%;width:100%;background-color:#fff;box-sizing:border-box}.JwPpqzaGk7TFSHJZ67Gp.qC1BjACPREXUbBF_vCJw{position:fixed;top:0;z-index:1002}.dIL7fHMc6ZtmyvGJH9Q9{padding:16px;margin-bottom:4px;display:flex;justify-content:center;position:relative;background-color:#fff}.dIL7fHMc6ZtmyvGJH9Q9 h1{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;align-self:center;color:#222429;margin:0 auto;font-size:20px}.dIL7fHMc6ZtmyvGJH9Q9 .WwSZ2mWCawD1_q0koCFv{background:none;border:none;padding:0;position:absolute;left:12px;top:20px}.dIL7fHMc6ZtmyvGJH9Q9 .WwSZ2mWCawD1_q0koCFv img{height:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `JwPpqzaGk7TFSHJZ67Gp`,
	"mobile": `qC1BjACPREXUbBF_vCJw`,
	"header": `dIL7fHMc6ZtmyvGJH9Q9`,
	"backButton": `WwSZ2mWCawD1_q0koCFv`
};
export default ___CSS_LOADER_EXPORT___;
