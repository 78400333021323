import { useEffect } from "react"

export const useMessagingMetaTags = (): void => {
    useEffect(() => {
        const metaViewport = document.querySelector(
            'meta[name="viewport"]',
        ) as HTMLMetaElement
        if (!metaViewport) return

        const originalContent = metaViewport.content

        const newContent = `${originalContent}, interactive-widget=resizes-content, user-scalable=no`
        metaViewport.content = newContent

        return () => {
            metaViewport.content = originalContent
        }
    }, [])
}
