import { useEffect, useState } from "react"

export const useOrientation = (): boolean => {
    const [isLandscape, setIsLandscape] = useState(
        window.innerWidth > window.innerHeight,
    )

    useEffect(() => {
        const handleResize = () => {
            setIsLandscape(window.innerWidth > window.innerHeight)
        }

        window.addEventListener("resize", handleResize)

        // Some mobile browsers also support orientationchange
        window.addEventListener("orientationchange", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("orientationchange", handleResize)
        }
    }, [])

    return isLandscape
}
