import type React from "react"
import { useEffect, useRef, useState } from "react"
import { plural, t } from "@lingui/macro"
import { isAndroid, isIOS } from "react-device-detect"
import { useAppContext } from "../../../hooks/appContext"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { updateTokenBalance } from "../../../store/userSlice"
import { fetchPost } from "../../../utils/myFetch"
import Button, { ButtonSize } from "../../common/atoms/Button"
import { Tokens } from "../../common/atoms/Icons/Chat"
import { BlackClose, Divider } from "../../common/atoms/Icons/Others"
import { Overlay } from "../../common/atoms/Overlay"
import { Textarea } from "../../common/atoms/Textarea/Textarea"
import { TextInput } from "../../common/atoms/TextInput/TextInput"
import { Typography } from "../../common/atoms/Typography"
import { ConfirmModal } from "../common/ConfirmModal/ConfirmModal"
import { getLastTipAmount, setLastTipAmount } from "../tipAmountCache"
import { RoomType, TipType } from "../types"
import { classNames } from "../utils"
import styles from "./SendTip.module.scss"

const SEND_TIP_API_URL = (username: string) => `/tipping/send_tip/${username}/`
const PURCHASE_TOKENS_URL = "/tipping/purchase_tokens/?source=send_tip_dialog"
const MAX_MESSAGE_LENGTH = 255
const MAX_TIP_DIGITS = 16
const DEFAULT_TIP_AMOUNT = "25"
const ZERO_TIP_AMOUNT = "0"

interface SendTipResponse {
    success: boolean
    token_balance?: number
    error?: string
}

interface SendTipProps {
    username: string
    onClose: () => void
    initialTipAmount?: string
}

const KEYBOARD_UPDATE_DELAY_MS = isIOS ? 150 : 350

export const SendTip: React.FC<SendTipProps> = ({
    username,
    onClose,
    initialTipAmount,
}) => {
    const { context } = useAppContext()
    const dispatch = useAppDispatch()
    const tokenBalance = useAppSelector(
        (state) => state.user.loggedInUser?.token_balance ?? 0,
    )
    const [tipAmount, setTipAmount] = useState<string>(() => {
        return (
            initialTipAmount || getLastTipAmount(username) || DEFAULT_TIP_AMOUNT
        )
    })
    const [message, setMessage] = useState<string>("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [show, setShow] = useState(false)

    const tipInputRef = useRef<HTMLInputElement>(null)
    const messageInputRef = useRef<HTMLTextAreaElement>(null)

    const isTipAmountValid = (amount: string) => {
        if (amount === "" || amount === ZERO_TIP_AMOUNT) return false
        const numericAmount = Number(amount)
        return !isNaN(numericAmount) && numericAmount > 0
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true)
        }, KEYBOARD_UPDATE_DELAY_MS)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (
            document.activeElement === tipInputRef.current ||
            document.activeElement === messageInputRef.current
        ) {
            tipInputRef.current?.blur()
            messageInputRef.current?.blur()
        }
    }, [document.activeElement])

    const handleTipAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cleanValue = e.target.value
            .replace(/[^\d]+/g, "")
            .substring(0, MAX_TIP_DIGITS)

        const formattedValue = cleanValue.replace(/^0+/, "") || "0"
        setTipAmount(cleanValue === "" ? "" : formattedValue)
    }

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value)
        const textarea = e.target
        textarea.style.height = `${textarea.scrollHeight}px`
    }

    const handleSubmit = async () => {
        if (isSubmitting) return

        if (!isTipAmountValid(tipAmount)) return
        const numericTipAmount = Number(tipAmount)

        if (numericTipAmount > tokenBalance) {
            setError(t`You do not have enough tokens.`)
            return
        }

        setIsSubmitting(true)
        try {
            const response = await fetchPost(SEND_TIP_API_URL(username), {
                tip_amount: tipAmount,
                message,
                source: "DM",
                tip_room_type: RoomType.DM,
                tip_type: TipType.public,
                video_mode: "",
                from_username: context.logged_in_user?.username || "",
            })

            if (response.error) {
                setError(response.error)
                return
            }

            const data = response.jsonData as SendTipResponse
            if (data.success) {
                if (data.token_balance !== undefined) {
                    dispatch(updateTokenBalance(data.token_balance))
                }
                setLastTipAmount(username, tipAmount)
                onClose()
            } else if (data.error) {
                setError(data.error)
            }
        } catch (e) {
            setError(t`Failed to send tip. Please try again.`)
        } finally {
            setIsSubmitting(false)
        }
    }

    const handlePurchaseTokens = () => {
        window.open(
            PURCHASE_TOKENS_URL,
            "_blank",
            "height=615,width=850,scrollbars=1",
        )
        setError(null)
    }

    const isInsufficientTokens = error === t`You do not have enough tokens.`

    return (
        <>
            <Overlay
                onClick={onClose}
                data-testid="dark-overlay"
                className={styles.darkOverlay}
            />
            <div
                className={classNames(styles.overlay, {
                    [styles.android]: isAndroid,
                    [styles.show]: show,
                })}
            >
                <div
                    className={styles.sendTipContainer}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles.header}>
                        <Typography
                            weight={Weights.medium}
                            className={styles.sendTipTitle}
                        >
                            {t`Send Tip`}
                        </Typography>
                        <div className={styles.headerRightContent}>
                            <Button
                                icon={<BlackClose height={24} width={24} />}
                                onClick={onClose}
                                className={styles.closeButton}
                            />
                        </div>
                    </div>

                    <div className={styles.contentWrapper}>
                        <div className={styles.tipAmountRow}>
                            <div className={styles.tipAmountLabelRow}>
                                <Typography
                                    className={styles.label}
                                    id="tip-amount-label"
                                    size={Sizes.mdpx}
                                >
                                    {t`Enter tip amount`}
                                </Typography>
                                <div className={styles.tokens}>
                                    <Tokens
                                        height={16}
                                        width={16}
                                        className={styles.tokensBlue}
                                    />
                                    <Typography
                                        className={styles.tokensAmount}
                                        size={Sizes.smpx}
                                        weight={Weights.medium}
                                    >
                                        {tokenBalance}
                                    </Typography>
                                    <Divider height={8} width={8} />
                                    <Button
                                        text={t`Add Tokens`}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handlePurchaseTokens()
                                        }}
                                        className={styles.buyMore}
                                    />
                                </div>
                            </div>
                            <div className={styles.tipAmountWrapper}>
                                <TextInput
                                    type="tel"
                                    value={tipAmount}
                                    onChange={handleTipAmountChange}
                                    className={styles.tipAmountInput}
                                    data-testid="tip-amount-input"
                                    autoFocus={true}
                                    disabled={isSubmitting}
                                />
                                <Typography className={styles.tokensLabel}>
                                    {plural(Number(tipAmount), {
                                        one: "Token",
                                        other: "Tokens",
                                    })}
                                </Typography>
                            </div>
                        </div>

                        <div className={styles.messageRow}>
                            <label className={styles.label}>
                                <Textarea
                                    value={message}
                                    onChange={handleMessageChange}
                                    className={styles.optionalMessage}
                                    placeholder={t`Optional message`}
                                    maxLength={MAX_MESSAGE_LENGTH}
                                    disabled={isSubmitting}
                                />
                            </label>
                            <Button
                                text={t`Send`}
                                onClick={handleSubmit}
                                disabled={
                                    !isTipAmountValid(tipAmount) || isSubmitting
                                }
                                className={styles.sendButton}
                                size={ButtonSize.Medium}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.errorModal}>
                <ConfirmModal
                    isOpen={error !== null}
                    onClose={() => setError(null)}
                    onConfirm={
                        isInsufficientTokens
                            ? handlePurchaseTokens
                            : () => setError(null)
                    }
                    title={error || ""}
                    confirmText={
                        isInsufficientTokens ? t`Purchase Tokens` : t`OK`
                    }
                    cancelText={isInsufficientTokens ? t`Cancel` : null}
                    confirmButtonStyle={{
                        backgroundColor: "#0C6A93",
                    }}
                />
            </div>
        </>
    )
}
