import { useEffect, useRef, useState } from "react"
import {
    isLanguageFilterActive,
    ShowType,
} from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../hooks/urlStateContext"
import { HomepageFiltersIcon } from "../../../filters/HomepageFiltersIcon"
import { SECTION_ORDER } from "../constants"
import { useFiltersContext } from "../FiltersContext"
import { filterCountForSection, getActiveFilterCount } from "../helpers"
import { MobileFilterSectionTab } from "../MobileFilterSectionTab"
import { ActiveFiltersSection } from "../types"
import "./MobileFilterNav.scss"

export function MobileFilterNav() {
    const { overlayedSection, setOverlayedSection } = useFiltersContext()
    const urlState = useUrlStateContext()
    const showLanguageFilters = isLanguageFilterActive()
    const showPrivatePriceFilters = urlState.showType !== ShowType.PRIVATE
    const [isCollapsed, setIsCollapsed] = useState(false)
    const lastScrollTop = useRef(0)
    const navRef = useRef<HTMLDivElement>(null)

    // Pull sections with active filters to the front, but otherwise maintain order
    const updatedTabOrder = [
        ...SECTION_ORDER.filter(
            (section) => filterCountForSection(section, urlState) > 0,
        ),
        ...SECTION_ORDER.filter(
            (section) => filterCountForSection(section, urlState) === 0,
        ),
    ]
    const [tabOrder, setTabOrder] =
        useState<ActiveFiltersSection[]>(updatedTabOrder)

    useEffect(() => {
        // Ensure that the ordering of the filtered section tabs is updated to reflect any changes
        // in UrlState, but only AFTER any active overlay is closed--editing filters with an overlay
        // open should not change the ordering of the tabs.
        if (overlayedSection === ActiveFiltersSection.None) {
            setTabOrder(updatedTabOrder)
        }
    }, [urlState, overlayedSection])

    const totalFilterCount = getActiveFilterCount(urlState)

    useEffect(() => {
        const handleScroll = () => {
            if (!navRef.current) {
                return
            }
            const currentScrollTop =
                window.scrollY || document.documentElement.scrollTop
            const navBarHeight = navRef.current.clientHeight

            if (currentScrollTop > lastScrollTop.current) {
                if (currentScrollTop > navBarHeight) {
                    setIsCollapsed(true)
                }
            } else {
                setIsCollapsed(false)
            }

            lastScrollTop.current = currentScrollTop <= 0 ? 0 : currentScrollTop
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <div
            className={`MobileFilterNav ${isCollapsed ? "collapsed" : ""}`}
            data-testid="mobile-filters-nav"
            ref={navRef}
        >
            <div
                className="MobileFilterNav__HomepageFiltersIconContainer"
                data-testid="mobile-filters-button"
                onClick={() => {
                    setOverlayedSection(ActiveFiltersSection.Combined)
                }}
            >
                <HomepageFiltersIcon hasSelectedFilters={false} />
                {totalFilterCount > 0 && (
                    <div
                        className="MobileFilterNav__TotalCount"
                        data-testid="mobile-filter-count"
                    >
                        {totalFilterCount}
                    </div>
                )}
            </div>
            <nav className="MobileFilterNav__Nav">
                {tabOrder.map((section) => {
                    if (
                        section === ActiveFiltersSection.Languages &&
                        !showLanguageFilters
                    )
                        return undefined
                    if (
                        section === ActiveFiltersSection.PrivatePrices &&
                        !showPrivatePriceFilters
                    )
                        return undefined
                    if (
                        section === ActiveFiltersSection.SpyPrices &&
                        showPrivatePriceFilters
                    )
                        return undefined
                    return (
                        <MobileFilterSectionTab
                            sectionId={section}
                            key={section}
                        />
                    )
                })}
                <div className="MobileFilterNavSpacer" />
            </nav>
        </div>
    )
}
