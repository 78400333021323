import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { isScrolledIntoView } from "@multimediallc/web-utils/domUtils"
import { selectMobileBroadcastChat } from "../../../store/chatSlice"
import { useAppSelector } from "../../../store/hooks"
import { mergeClasses } from "../../../utils/css"
import { addPageAction } from "../../../utils/newrelic"
import { ScrollToBottomButton } from "../../chat/ScrollToBottomButton"
import { ChatMessage } from "../ChatMessage"
import type { ChatEntityID } from "../../../store/chatSlice"
import "./MobileBroadcastChat.scss"

const NEAR_BOTTOM_THRESHOLD = 20

export function MobileBroadcastChat() {
    const chatRef = useRef<HTMLDivElement | null>(null)
    const newMessageLineLocationRef = useRef<HTMLDivElement | null>(null)

    // May eventually want to move read state into redux, but for now is only for rendering logic in this one chat component
    const [latestReadID, setLatestReadID] = useState<ChatEntityID | undefined>(
        undefined,
    )
    const [newMessagesLineLocationID, setNewMessagesLineLocationID] = useState<
        ChatEntityID | undefined
    >(undefined)
    const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(true)

    const chatContents = useAppSelector(selectMobileBroadcastChat)

    const latestReadIndex =
        chatContents.findLastIndex(({ id }) => id === latestReadID) ??
        chatContents.length - 1
    const numUnread = chatContents.length - latestReadIndex - 1

    const isChatScrolledToBottom = () => {
        const currentChat = chatRef.current
        if (currentChat === null) {
            return false
        }
        // scrollTop has a weird negative from-the-bottom value due to `flex-direction: column-reverse`
        return Math.abs(currentChat.scrollTop) < NEAR_BOTTOM_THRESHOLD
    }

    const updateLatestRead = useCallback(() => {
        if (chatContents.length === 0) {
            return
        }
        setLatestReadID(chatContents[chatContents.length - 1].id)
    }, [chatContents])

    const onScroll = () => {
        const scrolledToBottom = isChatScrolledToBottom()
        if (scrolledToBottom) {
            updateLatestRead()
        }
        setIsScrolledToBottom(scrolledToBottom)
    }

    const scrollToBottom = useCallback(() => {
        addPageAction("MobileChatScrollToBottomClicked")
        if (chatRef.current === null) {
            return
        }
        chatRef.current.scrollTop = 0
        setIsScrolledToBottom(true)
    }, [])

    useLayoutEffect(() => {
        if (isChatScrolledToBottom() || chatRef.current === null) {
            updateLatestRead()
        } else {
            // Only move the new message line if the current new message line is not visible and we are scrolled up in chat
            const newMessageLineOutOfView =
                !newMessageLineLocationRef.current ||
                !isScrolledIntoView(
                    newMessageLineLocationRef.current,
                    chatRef.current,
                )
            if (newMessageLineOutOfView && numUnread > 0) {
                setNewMessagesLineLocationID(
                    chatContents[chatContents.length - numUnread].id,
                )
            }
        }
    }, [numUnread, updateLatestRead, chatContents])

    return (
        <div
            className={mergeClasses(
                "MobileBroadcastChat",
                isScrolledToBottom ? "MobileBroadcastChat--scrolledDown" : "",
            )}
            ref={chatRef}
            onScroll={onScroll}
            data-testid="mobile-broadcast-chat"
        >
            <div
                className="MobileBroadcastChat__Messages"
                data-testid="mobile-broadcast-chat-messages"
            >
                {chatContents.map((chatEntity) => {
                    const isOldestUnread =
                        chatEntity.id === newMessagesLineLocationID
                    return (
                        <ChatMessage
                            key={chatEntity.id}
                            entityData={chatEntity}
                            {...(isOldestUnread && {
                                isOldestUnread: true,
                                newMessageLineLocationRef,
                            })}
                        />
                    )
                })}
            </div>
            <div className="MobileBroadcastChat__ScrollDownButtonContainer">
                {!isScrolledToBottom && (
                    <ScrollToBottomButton
                        onClick={scrollToBottom}
                        numUnread={numUnread}
                    />
                )}
            </div>
            <div className="MobileBroadcastChat__ScrollAnchor" />
        </div>
    )
}
