import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgPmLock = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#fff"
            d="M8 11.333a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667m4-6a1.333 1.333 0 0 1 1.334 1.334v6.666A1.333 1.333 0 0 1 12 14.667H4a1.333 1.333 0 0 1-1.333-1.334V6.667c0-.74.6-1.334 1.333-1.334h.667V4a3.333 3.333 0 1 1 6.667 0v1.333zM8 2a2 2 0 0 0-2 2v1.333h4V4a2 2 0 0 0-2-2"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgPmLock)
const Memo = memo(ForwardRef)
export default Memo
