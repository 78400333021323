import type React from "react"
import styles from "./ConfirmModal.module.scss"

interface ConfirmModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    title: string
    confirmText?: string
    cancelText?: string | null
    confirmButtonStyle?: {
        backgroundColor?: string
        color?: string
    }
    cancelButtonStyle?: {
        backgroundColor?: string
        color?: string
        borderColor?: string
    }
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    title,
    confirmText = "Delete",
    cancelText = "Cancel",
    confirmButtonStyle,
    cancelButtonStyle,
}) => {
    if (!isOpen) return null

    const handleContentClick = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <div
            data-testid="modal-overlay"
            className={styles.modalOverlay}
            onClick={onClose}
        >
            <div
                data-testid="modal-content"
                className={styles.modalContent}
                onClick={handleContentClick}
            >
                <h2 data-testid="modal-title" className={styles.modalTitle}>
                    {title}
                </h2>
                <div className={styles.modalButtons}>
                    {cancelText !== null && (
                        <button
                            data-testid="modal-cancel-button"
                            className={styles.modalButtonCancel}
                            onClick={onClose}
                            style={
                                {
                                    backgroundColor:
                                        cancelButtonStyle?.backgroundColor,
                                    color: cancelButtonStyle?.color,
                                    borderColor: cancelButtonStyle?.borderColor,
                                } as React.CSSProperties
                            }
                        >
                            {cancelText}
                        </button>
                    )}
                    <button
                        data-testid="modal-confirm-button"
                        className={styles.modalButtonDelete}
                        onClick={onConfirm}
                        style={
                            {
                                backgroundColor:
                                    confirmButtonStyle?.backgroundColor,
                                color: confirmButtonStyle?.color,
                            } as React.CSSProperties
                        }
                    >
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    )
}
