import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgChevronLeftBlue = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 10 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0C6A93"
            d="M9.285 14.818a1 1 0 0 0-.002-1.42L4.538 8.71a1 1 0 0 1 0-1.422L9.282 2.59A1 1 0 0 0 9.28 1.17L8.8.694a1 1 0 0 0-1.406 0L.72 7.29a1 1 0 0 0 0 1.422l6.672 6.592a1 1 0 0 0 1.408-.002z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgChevronLeftBlue)
const Memo = memo(ForwardRef)
export default Memo
