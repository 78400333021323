import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgSearchPlaceholder = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 168 168"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle cx={84} cy={84} r={84} fill="#8DB4D9" fillOpacity={0.1} />
        <circle cx={84} cy={84} r={74.731} fill="#8DB4D9" fillOpacity={0.1} />
        <circle cx={84} cy={84} r={62.356} fill="#8DB4D9" fillOpacity={0.16} />
        <path
            fill="#EDF2F7"
            d="M43.5 56a8 8 0 0 1 8-8h44a8 8 0 0 1 8 8v26a8 8 0 0 1-8 8H71.05a8 8 0 0 0-4.63 1.476l-10.078 7.153c-1.359.964-3.232-.058-3.156-1.722l.219-4.816A2 2 0 0 0 51.407 90h.093a8 8 0 0 1-8-8z"
        />
        <circle cx={61} cy={68} r={4} fill="#CDDDED" />
        <circle cx={74} cy={68} r={4} fill="#CDDDED" />
        <circle cx={87} cy={68} r={4} fill="#CDDDED" />
        <path
            fill="#F8FCFF"
            d="M132 77.24a8.24 8.24 0 0 0-8.242-8.24H72.242A8.24 8.24 0 0 0 64 77.24v31.059a8.24 8.24 0 0 0 8.242 8.239h6.588a2.06 2.06 0 0 1 2.058 2.167l-.317 6.125c-.085 1.65 1.709 2.724 3.123 1.869l16.333-9.864c.322-.194.69-.297 1.066-.297h22.665a8.24 8.24 0 0 0 8.242-8.239z"
        />
        <path
            fill="#8DB4D9"
            d="M108.342 109.312a2.702 2.702 0 0 1 3.819-3.819l10.182 10.183a2.7 2.7 0 0 1-3.818 3.818z"
        />
        <path
            fill="#AFCAE4"
            fillRule="evenodd"
            d="M86.652 83.44c-5.663 5.663-5.663 14.844 0 20.506 5.662 5.663 14.843 5.663 20.506 0 3.595-3.594 4.909-8.607 3.937-13.243a1.5 1.5 0 0 1 2.936-.615c1.171 5.585-.411 11.639-4.752 15.98-6.834 6.834-17.914 6.834-24.748 0-6.835-6.834-6.835-17.915 0-24.749 4.443-4.443 10.68-5.996 16.374-4.665a1.5 1.5 0 0 1-.683 2.922c-4.726-1.105-9.89.185-13.57 3.864"
            clipRule="evenodd"
        />
        <path
            fill="#8DB4D9"
            d="M109.5 88a1.5 1.5 0 0 0 1.5-1.5V83h3.5a1.5 1.5 0 0 0 0-3H111v-3.5a1.5 1.5 0 0 0-3 0V80h-3.5a1.5 1.5 0 0 0 0 3h3.5v3.5a1.5 1.5 0 0 0 1.5 1.5M94 90.942c0 1.2-1.05 2.25-2.25 2.25s-2.25-1.05-2.25-2.25 1.05-2.25 2.25-2.25 2.25 1.05 2.25 2.25m10.5 0c0 1.2-1.05 2.25-2.25 2.25s-2.25-1.05-2.25-2.25 1.05-2.25 2.25-2.25 2.25 1.05 2.25 2.25"
        />
        <path
            fill="#8DB4D9"
            fillRule="evenodd"
            d="M102.621 97.634a1.5 1.5 0 0 1 .745 1.987L102 99c1.366.62 1.365.621 1.365.622l-.001.001-.001.003-.003.008-.009.017-.023.048-.074.144c-.063.115-.152.269-.27.449a7.4 7.4 0 0 1-1.091 1.299c-1.014.956-2.617 1.909-4.893 1.909s-3.879-.953-4.893-1.909a7.3 7.3 0 0 1-1.091-1.299 6 6 0 0 1-.367-.64l-.009-.018-.003-.008-.001-.003v-.001c-.001 0-.001-.001 1.364-.622l-1.365.62a1.5 1.5 0 0 1 2.725-1.253v.001l.025.047a4.32 4.32 0 0 0 .78.994c.577.543 1.474 1.091 2.835 1.091s2.258-.548 2.835-1.091a4.3 4.3 0 0 0 .78-.994l.024-.047.001-.001a1.5 1.5 0 0 1 1.981-.733"
            clipRule="evenodd"
        />
        <path
            fill="#AFCAE4"
            d="M128.562 39.535c.322-.871 1.554-.871 1.876 0l.928 2.508c.101.274.317.49.591.59l2.508.93c.871.321.871 1.553 0 1.875l-2.508.928a1 1 0 0 0-.591.591l-.928 2.508c-.322.871-1.554.871-1.876 0l-.928-2.508a1 1 0 0 0-.591-.59l-2.509-.93c-.87-.321-.87-1.553 0-1.875l2.509-.928a1 1 0 0 0 .591-.59z"
        />
        <path
            fill="#EDF2F7"
            d="M51.54 112.921a.554.554 0 0 1 1.039 0l.889 2.402a.55.55 0 0 0 .327.328l2.402.888a.555.555 0 0 1 0 1.04l-2.402.889a.56.56 0 0 0-.327.327l-.889 2.402a.554.554 0 0 1-1.04 0l-.888-2.402a.56.56 0 0 0-.328-.327l-2.402-.889a.555.555 0 0 1 0-1.04l2.402-.888a.55.55 0 0 0 .328-.328zM118.98 54.362a.555.555 0 0 1 1.04 0l.889 2.402a.55.55 0 0 0 .327.327l2.402.89a.554.554 0 0 1 0 1.039l-2.402.889a.55.55 0 0 0-.327.327l-.889 2.402a.555.555 0 0 1-1.04 0l-.889-2.402a.55.55 0 0 0-.327-.327l-2.402-.89a.554.554 0 0 1 0-1.039l2.402-.889a.55.55 0 0 0 .327-.327z"
        />
        <path
            fill="#AFCAE4"
            d="M38.524 120.68c.335-.907 1.617-.907 1.952 0l1.142 3.086c.106.285.33.51.616.615l3.086 1.143c.907.335.907 1.617 0 1.952l-3.086 1.143a1.04 1.04 0 0 0-.616.615l-1.142 3.086c-.335.907-1.617.907-1.952 0l-1.142-3.086a1.04 1.04 0 0 0-.616-.615l-3.086-1.143c-.907-.335-.907-1.617 0-1.952l3.086-1.143c.285-.105.51-.33.616-.615z"
        />
        <path
            fill="#EDF2F7"
            d="M108.181 35.653c.322-.87 1.553-.87 1.875 0l1.064 2.874a1 1 0 0 0 .59.59l2.874 1.064c.871.322.871 1.553 0 1.875l-2.874 1.064a1 1 0 0 0-.59.59l-1.064 2.874c-.322.87-1.553.87-1.875 0l-1.064-2.874a1 1 0 0 0-.591-.59l-2.873-1.064c-.871-.322-.871-1.553 0-1.875l2.873-1.064a1 1 0 0 0 .591-.59z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgSearchPlaceholder)
const Memo = memo(ForwardRef)
export default Memo
