import { getFollowers } from "../../../store/followersSlice"
import { useAppSelector } from "../../../store/hooks"
import { mergeClasses } from "../../../utils/css"
import { getUsernameColorClass } from "../../../utils/css/user"
import StarIcon from "../../common/atoms/Icons/Chat/Star"
import type { IUserInfo } from "@multimediallc/web-utils/types"
import "./UsernameLabel.scss"

interface UsernameLabelProps {
    user: IUserInfo
}

export function UsernameLabel({ user }: UsernameLabelProps) {
    return (
        <span className="UsernameLabel" data-testid="username-label">
            <UserChatBadges user={user} />
            <span
                className={mergeClasses(
                    "UsernameLabel__text",
                    getUsernameColorClass(user),
                )}
                data-testid="username"
            >
                {user.username}
            </span>
        </span>
    )
}

function UserChatBadges({ user }: UsernameLabelProps) {
    const allFollowers = useAppSelector(getFollowers)
    const isFollowing = allFollowers[user.username] ?? false
    return (
        <span className="UserChatBadges">
            {isFollowing && (
                <span
                    className="ChatBadge ChatBadge__following"
                    data-testid="followerBadge"
                >
                    <StarIcon height="1.4em" width="1.4em" />
                </span>
            )}
        </span>
    )
}
