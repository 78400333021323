import { t } from "@lingui/macro"
import { error } from "@multimediallc/logging"
import {
    BroadcastStatus,
    PrivateRequestStatus,
    RoomStatus,
} from "@multimediallc/web-utils"
import { useAppSelector } from "../../../store/hooks"
import {
    selectRoomPrivateRequestStatus,
    selectRoomStatus,
} from "../../../store/roomSlice"
import { colorClass } from "../../../utils/css"
import { addPageAction } from "../../../utils/newrelic"
import { Typography } from "../../common"
import {
    RecordConnectingSpinner,
    RecordStart,
    RecordStop,
    RecordStopPrivate,
} from "../../common/atoms/Icons/Broadcast"
import { StylelessButton } from "../../common/atoms/StylelessButton"
import { declineOrEndPrivateShow } from "../api"
import { useMobileBroadcastContext } from "../mobileBroadcastContext"
import "./RecordButton.scss"

export function RecordButton() {
    const privateShowRequestStatus = useAppSelector(
        selectRoomPrivateRequestStatus,
    )
    const roomStatus = useAppSelector(selectRoomStatus)

    const roomOffline = [RoomStatus.Offline, RoomStatus.Unknown].includes(
        roomStatus,
    )

    const size = "42px"
    const { broadcastStatus, onRecordButtonClick } = useMobileBroadcastContext()
    const withPageAction = (action: string, callback: () => void) => () => {
        addPageAction(action)
        return callback()
    }

    let content
    let callback = withPageAction("RecordButtonClicked", onRecordButtonClick)
    if (broadcastStatus === BroadcastStatus.Offline) {
        content = <RecordStart height={size} width={size} />
    } else if (broadcastStatus === BroadcastStatus.Connecting || roomOffline) {
        // if broadcast is connected but the room itself is not online yet, still show connecting state
        content = (
            <RecordConnectingSpinner
                className="RecordButton__connectingSpinner"
                height={size}
                width={size}
            />
        )
    } else if (broadcastStatus === BroadcastStatus.Streaming) {
        content = <RecordStop height={size} width={size} />
    } else {
        error("RecordButton unexpected broadcastStatus", broadcastStatus)
    }

    // Override the icon if a private show has been started
    if (
        privateShowRequestStatus &&
        privateShowRequestStatus.status === PrivateRequestStatus.started
    ) {
        const subSize = "24px"
        callback = withPageAction(
            "PrivateShowEndClicked",
            declineOrEndPrivateShow,
        )
        content = (
            <div
                className="RecordButton__endPrivateShow"
                style={{ height: size, background: "#C60101" }}
            >
                <RecordStopPrivate
                    className="RecordButton__endPrivateShow__icon"
                    height={subSize}
                    width={subSize}
                />
                <Typography
                    size="mdpx"
                    className="RecordButton__endPrivateShow__text"
                    color={colorClass.tagFontColor}
                >
                    {t`End Private`}
                </Typography>
            </div>
        )
    }

    return (
        <StylelessButton className="RecordButton" onClick={callback}>
            {content}
        </StylelessButton>
    )
}
