// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrfHovKx4W8MykZj_T1w{position:relative;margin-bottom:2px;max-width:250px;padding:12px;word-break:break-word;overflow-wrap:anywhere;white-space:normal;border:none;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;align-self:flex-end}.DrfHovKx4W8MykZj_T1w.lY1MvCYd4FWA5x7IZY3T{color:#fefefe;border-radius:16px 8px 8px 16px;background:#0c6a93;align-self:flex-end;margin-left:24px;margin-right:8px}.DrfHovKx4W8MykZj_T1w.lY1MvCYd4FWA5x7IZY3T.G5DLAA_dIH1lCT_x0wZB{margin-top:12px}.DrfHovKx4W8MykZj_T1w.R9DWLH8M070d1ydG4EnX{color:#222429;border-radius:8px 16px 16px 8px;background:#e0e0e0;align-self:flex-start;margin-left:40px}.DrfHovKx4W8MykZj_T1w.R9DWLH8M070d1ydG4EnX.kKNaxxEToq73foyYF4Z8{margin-top:12px}.DrfHovKx4W8MykZj_T1w .MjZ1_gzjmqsbkOKvFNXi{position:absolute;bottom:-4px;left:-34px}.DrfHovKx4W8MykZj_T1w.LAfwlLdvXGrQI7jht_Al{padding:0;background:none}.DrfHovKx4W8MykZj_T1w.LAfwlLdvXGrQI7jht_Al.tf7UHiqtnIHsy6oyNESw{padding:0}.DrfHovKx4W8MykZj_T1w.tf7UHiqtnIHsy6oyNESw{font-size:12px;padding:8px 12px;line-height:14px;max-width:206px}.DrfHovKx4W8MykZj_T1w.s09cDlxG_778E5bvgOjl{font-size:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `DrfHovKx4W8MykZj_T1w`,
	"sent": `lY1MvCYd4FWA5x7IZY3T`,
	"afterReceived": `G5DLAA_dIH1lCT_x0wZB`,
	"received": `R9DWLH8M070d1ydG4EnX`,
	"afterSent": `kKNaxxEToq73foyYF4Z8`,
	"avatarContainer": `MjZ1_gzjmqsbkOKvFNXi`,
	"tip": `LAfwlLdvXGrQI7jht_Al`,
	"desktop": `tf7UHiqtnIHsy6oyNESw`,
	"mobile": `s09cDlxG_778E5bvgOjl`
};
export default ___CSS_LOADER_EXPORT___;
