import { plural, t } from "@lingui/macro"
import { Sizes } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import { Typography } from "../../common"
import { ScrollToBottom } from "../../common/atoms/Icons/Chat"
import styles from "./ScrollToBottomButton.module.scss"

interface ScrollToBottomButtonProps {
    numUnread: number
    onClick: () => void
}

export function ScrollToBottomButton({
    numUnread,
    onClick,
}: ScrollToBottomButtonProps) {
    let buttonText = t`Scroll to bottom`
    if (numUnread > 0) {
        buttonText =
            numUnread > 10
                ? t`10+ new messages`
                : plural(numUnread, {
                      one: `${numUnread} new message`,
                      other: `${numUnread} new messages`,
                  })
    }

    return (
        <button
            className={styles.scrollToBottomButton}
            onClick={onClick}
            data-testid="scroll-to-bottom-button"
        >
            <ScrollToBottom width="16" height="" />
            <Typography size={Sizes.xspx} color={colorClass.whiteFontColor}>
                {buttonText}
            </Typography>
        </button>
    )
}
