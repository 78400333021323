import { useEffect } from "react"
import { updateStreamStatus } from "../broadcastSlice"
import { useAppDispatch } from "."
import type { MobileBroadcastEventRouters } from "../../components/mobile_broadcast/types"
import type { IStatusUpdate } from "@multimediallc/web-utils"

export function useStreamStatusUpdate(
    eventRouters: MobileBroadcastEventRouters,
): void {
    const dispatch = useAppDispatch()
    useEffect(() => {
        const { streamStatusUpdate } = eventRouters
        const callback = (event: IStatusUpdate) => {
            dispatch(updateStreamStatus(event))
        }

        streamStatusUpdate.listen(callback)

        return () => {
            streamStatusUpdate.removeListener(callback)
        }
    })
}
