import type { CSSProperties } from "react"
import { classNames } from "../../utils"
import styles from "./UnreadBadge.module.scss"

type BadgeSize = "default" | "compact"

interface UnreadBadgeProps {
    count: number
    maxCount?: number
    className?: string
    sizing?: "static" | "dynamic"
    style?: CSSProperties
    variant?: BadgeSize
}

export function UnreadBadge({
    count,
    maxCount = 99,
    className,
    sizing = "dynamic",
    style,
    variant,
}: UnreadBadgeProps) {
    if (count <= 0) {
        return null
    }

    const displayCount = count > maxCount ? `${maxCount}+` : count.toString()

    const sizeClasses =
        sizing === "dynamic"
            ? {
                  [styles.small]: count <= 9,
                  [styles.medium]: count > 9 && count <= 99,
                  [styles.large]: count > 99,
              }
            : {}

    const variantClass = variant === "compact" ? styles.compact : undefined

    return (
        <div
            className={classNames(
                styles.unreadBadge,
                sizeClasses,
                variantClass,
                className,
            )}
            style={style}
            data-testid="unread-badge"
        >
            {displayCount}
        </div>
    )
}
