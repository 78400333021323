import { useEffect, useRef } from "react"
import { t } from "@lingui/macro"
import {
    createLogMessage,
    messageToThread,
} from "../../components/messaging/MessagingContext"
import { isPersistentTippingActive } from "../../components/messaging/toggleUtils"
import { RoomType } from "../../components/messaging/types"
import { getFollowingUsernames } from "../../components/mobile_broadcast/api"
import { useAppContext } from "../../hooks/appContext"
import { error } from "../../utils/debug"
import { setFollowers, userFollowed, userUnfollowed } from "../followersSlice"
import { useAppDispatch } from "../hooks"
import {
    addIgnoreUser,
    removeIgnoreUser,
    useGetIgnoredUsersQuery,
} from "../ignoredUsersSlice"
import {
    addMessageToHistory,
    DEFAULT_THREAD_PARAMS,
    messagingApi,
    upsertThread,
} from "../messagingSlice"
import { store } from "../store"
import { setUserTyping } from "../typingIndicatorSlice"
import { updateTokenBalance } from "../userSlice"
import type {
    IIgnoreTopic,
    Interfaces,
    IPushUserTyping,
    ITipAlertTopic,
    Message,
    PushPrivateMessage,
    Thread,
    TokenBalanceUpdate,
    UserTopic,
} from "../../components/messaging/types"
import type {
    MobileBroadcastTopics,
    UserFollowerTopic,
} from "../../components/mobile_broadcast/types"

const TYPING_INDICATOR_PERIOD_MS = 5000

type TokenUpdateProps = {
    userTokenUpdateTopic: MobileBroadcastTopics["UserTokenUpdateTopic"]
    uuid: string | undefined
}

type UserMessageProps = {
    userMessageTopic: UserTopic<PushPrivateMessage>
    interfaces: Interfaces
    uuid: string | undefined
}

type UserIgnoreProps = {
    userIgnoreTopic: UserTopic<IIgnoreTopic>
    uuid: string | undefined
}

type UserTipAlertProps = {
    userTipAlertTopic: UserTopic<ITipAlertTopic>
    uuid: string | undefined
}

type UserTypingProps = {
    userTypingTopic: UserTopic<IPushUserTyping>
    uuid: string | undefined
}

export const useTokenUpdateEffect = ({
    userTokenUpdateTopic,
    uuid,
}: TokenUpdateProps): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const dispatchTokenUpdate = (event: TokenBalanceUpdate) => {
            dispatch(updateTokenBalance(event.tokens))
        }

        let userTokenUpdateTopicInstance: InstanceType<
            typeof userTokenUpdateTopic
        >
        if (uuid !== undefined) {
            userTokenUpdateTopicInstance = new userTokenUpdateTopic(uuid)
            userTokenUpdateTopicInstance.onMessage.listen(dispatchTokenUpdate)
        }

        return () => {
            userTokenUpdateTopicInstance?.onMessage.removeListener(
                dispatchTokenUpdate,
            )
        }
    }, [uuid])
}

export const useUserTypingEffect = ({
    userTypingTopic,
    uuid,
}: UserTypingProps): void => {
    const dispatch = useAppDispatch()

    const timers = useRef<{ [username: string]: NodeJS.Timeout }>({})

    useEffect(() => {
        const handleTypingEvent = (typingEvent: IPushUserTyping) => {
            // starts a new timer that will clear the typing state after 5 seconds
            const typingUser = typingEvent.fromUsername

            dispatch(setUserTyping({ username: typingUser, isTyping: true }))

            // if a timer exists for this user, clear it
            if (timers.current[typingUser] !== undefined) {
                clearTimeout(timers.current[typingUser])
            }

            timers.current[typingUser] = setTimeout(() => {
                dispatch(
                    setUserTyping({
                        username: typingUser,
                        isTyping: false,
                    }),
                )
                delete timers.current[typingUser]
            }, TYPING_INDICATOR_PERIOD_MS)
        }

        if (
            uuid !== undefined &&
            userTypingTopic.onMessage.listenerCount() === 0
        ) {
            userTypingTopic.onMessage.listen(handleTypingEvent, false)
        }

        return () => {
            userTypingTopic.onMessage.removeListener(handleTypingEvent)
            Object.values(timers.current).forEach(clearTimeout)
        }
    }, [uuid, userTypingTopic, dispatch])
}

export const useUserMessageEffect = ({
    userMessageTopic,
    interfaces,
    uuid,
}: UserMessageProps): void => {
    const { context: appContext } = useAppContext()
    const dispatch = useAppDispatch()
    const { data: ignoredUsersData } = useGetIgnoredUsersQuery()

    useEffect(() => {
        const dispatchMessage = (m: PushPrivateMessage) => {
            if (m.room !== "") return // Only handle DMs
            const existingThread = messagingApi.endpoints.getThreads
                .select(DEFAULT_THREAD_PARAMS)(store.getState())
                .data?.threads.find(
                    (t: Thread) => t.other_user.username === m.otherUsername,
                )
            const thread = messageToThread(
                m,
                appContext.logged_in_user?.username,
                existingThread,
            )
            const isFromMe =
                m.fromUser.username === appContext.logged_in_user?.username

            const isIgnored = ignoredUsersData?.users.includes(m.otherUsername)
            if (isIgnored) return

            dispatch(upsertThread(thread, isFromMe))

            const otherUsername =
                m.otherUsername === appContext.logged_in_user?.username
                    ? m.fromUser.username
                    : m.otherUsername

            dispatch(
                setUserTyping({ username: otherUsername, isTyping: false }),
            )

            if (!isFromMe) {
                interfaces.dmUnreadData.addUnreadRecipient(otherUsername)
            }

            const cacheState = messagingApi.endpoints.getHistory.select({
                username: otherUsername,
            })(store.getState())

            if (cacheState.data) {
                const newMessage: Message = {
                    i: m.messageID,
                    m: m.message,
                    created_at: (m.createdAt?.getTime() ?? Date.now()) / 1000,
                    media: [],
                    other_user: m.otherUsername,
                    from_user: m.fromUser,
                    tip_amount: m.tipAmount,
                }
                dispatch(addMessageToHistory(otherUsername, newMessage))
            }
        }

        if (
            uuid !== undefined &&
            userMessageTopic.onMessage.listenerCount() === 0
        ) {
            userMessageTopic.onMessage.listen(dispatchMessage, false)
        }

        return () => {
            userMessageTopic.onMessage.removeListener(dispatchMessage)
        }
    }, [uuid, ignoredUsersData])
}

export const useIgnoreUserEffect = ({
    userIgnoreTopic,
    uuid,
}: UserIgnoreProps): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const dispatchIgnoreUpdate = (update: IIgnoreTopic) => {
            const ignoredUsername = update.username
            let message: string

            if (update.isIgnored) {
                message = t`Ignoring ${ignoredUsername}`
                addIgnoreUser(ignoredUsername)
            } else {
                message = t`No longer ignoring ${ignoredUsername}`
                removeIgnoreUser(ignoredUsername)
            }

            dispatch(
                addMessageToHistory(
                    ignoredUsername,
                    createLogMessage(ignoredUsername, message),
                ),
            )

            dispatch(
                messagingApi.util.invalidateTags([
                    { type: "Threads", id: "LIST" },
                ]),
            )
        }

        if (
            uuid !== undefined &&
            userIgnoreTopic.onMessage.listenerCount() === 0
        ) {
            userIgnoreTopic.onMessage.listen(dispatchIgnoreUpdate)
        }

        return () => {
            userIgnoreTopic.onMessage.removeListener(dispatchIgnoreUpdate)
        }
    }, [uuid])
}

export const useTipAlertEffect = ({
    userTipAlertTopic,
    uuid,
}: UserTipAlertProps): void => {
    const { context: appContext } = useAppContext()
    const dispatch = useAppDispatch()
    const { data: ignoredUsersData } = useGetIgnoredUsersQuery()

    useEffect(() => {
        const dispatchTipAlert = (update: ITipAlertTopic) => {
            if (
                isPersistentTippingActive() &&
                update.roomType === RoomType.DM
            ) {
                // DM Tips alerts are handled through UserMessageTopic when PersistentTipping PERFORM toggle is enabled
                return
            }
            // Add temporary tip message to history if user is on conversation page
            const otherUsername =
                update.toUsername === appContext.logged_in_user?.username
                    ? update.fromUser.username
                    : update.toUsername || update.fromUser.username

            const cacheState = messagingApi.endpoints.getHistory.select({
                username: otherUsername,
            })(store.getState())
            const isIgnored = ignoredUsersData?.users.includes(otherUsername)

            if (cacheState.data && !isIgnored) {
                const newMessage: Message = {
                    i: update.tid || `tip_${Date.now()}`,
                    m: update.message || "",
                    created_at: (update.ts || Date.now()) / 1000,
                    media: [],
                    other_user: otherUsername,
                    from_user: update.fromUser,
                    tip_amount: update.amount,
                    is_temporary_tip_message: true,
                }
                dispatch(addMessageToHistory(otherUsername, newMessage))
            }
        }

        if (
            uuid !== undefined &&
            userTipAlertTopic.onMessage.listenerCount() === 0
        ) {
            userTipAlertTopic.onMessage.listen(dispatchTipAlert)
        }

        return () => {
            userTipAlertTopic.onMessage.removeListener(dispatchTipAlert)
        }
    }, [uuid, appContext.logged_in_user?.username, ignoredUsersData])
}

export const useFollowersEffect = (
    topics: MobileBroadcastTopics,
    roomUid: string | undefined,
): void => {
    const dispatch = useAppDispatch()
    const followersRequested = useRef<boolean>(false)
    useEffect(() => {
        if (!followersRequested.current) {
            getFollowingUsernames()
                .then((followingUsernames) => {
                    dispatch(setFollowers(followingUsernames))
                    followersRequested.current = true
                })
                .catch((e) => error(e))
        }
        const dispatchFollowerEvent = (event: UserFollowerTopic) => {
            // only dispatch a follow/unfollow event if its done by a viewer and not broadcaster
            if (event.followedUsername !== event.followerUsername) {
                if (event.isFollowing) {
                    dispatch(userFollowed(event.followerUsername))
                } else {
                    dispatch(userUnfollowed(event.followerUsername))
                }
            }
        }
        if (roomUid !== undefined) {
            const userFollowerTopic = new topics.UserFollowerTopic(roomUid)
            userFollowerTopic.onMessage.listen(dispatchFollowerEvent)
            return () =>
                userFollowerTopic.onMessage.removeListener(
                    dispatchFollowerEvent,
                )
        }
    }, [roomUid])
}
