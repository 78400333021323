type ClassValue =
    | string
    | null
    | undefined
    | false
    | Record<string, boolean>
    | ClassValue[]

export const classNames = (...args: ClassValue[]): string => {
    return args
        .flatMap((arg) => {
            if (typeof arg === "string") {
                return arg
            }
            if (Array.isArray(arg)) {
                return classNames(...arg)
            }
            if (typeof arg === "object" && arg !== null) {
                return Object.keys(arg).filter((key) => arg[key])
            }
            return []
        })
        .join(" ")
}
