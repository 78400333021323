import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgTune = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0C6A93"
            d="M2 11.333v1.334h4v-1.334zm0-8v1.334h6.667V3.333zM8.667 14v-1.333H14v-1.334H8.667V10H7.333v4zm-4-8v1.333H2v1.334h2.667V10H6V6zM14 8.667V7.333H7.333v1.334zM10 6h1.333V4.667H14V3.333h-2.667V2H10z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgTune)
const Memo = memo(ForwardRef)
export default Memo
