import { AppDirConfirmationModal } from "../components/apps_and_bots/AppDirConfirmationModal"
import { AppDirToggle } from "../components/apps_and_bots/AppDirToggle"
import { V3OnboardingModal } from "../components/apps_and_bots/V3OnboardingModal"
import { OneClickFlowNoLink as OneClickFlow } from "../components/chat/OneClickFlow"
import { DesktopFlaggedChat } from "../components/chatursafe/DesktopFlaggedChat"
import { FilterSetting as ChatursafeFilterSetting } from "../components/chatursafe/FilterSetting"
import { MobileFlaggedChat } from "../components/chatursafe/MobileFlaggedChat"
import { GenderIconDefs } from "../components/common/atoms/Icons/Gender"
import {
    CloseButton,
    RemoveFilterIcon,
    Search,
} from "../components/common/atoms/Icons/Others/index"
import CancelConfirmButtonPair from "../components/common/molecules/CancelConfirmButtonPair"
import { FlagIcon } from "../components/common/molecules/FlagIcon"
import { WrappedSharedToastContainer } from "../components/common/organisms/SharedToastContainer"
import { Survey } from "../components/common/organisms/Survey"
import { ContestStats } from "../components/contest/ContestStats"
import { MembershipDurationContainer } from "../components/memberships/MembershipDurationContainer"
import { NPSSentimentSurvey } from "../components/memberships/NPSSentimentSurvey"
import { UnreadBadge } from "../components/messaging/common/UnreadBadge/UnreadBadge"
import { Messaging } from "../components/messaging/Messaging"
import { MobileBroadcast } from "../components/mobile_broadcast/MobileBroadcast/MobileBroadcast"
import { MobileAutoRefillToggle } from "../components/purchase_page/MobileAutoRefillToggle"
import { PurchasePage } from "../components/purchase_page/PurchasePage"
import { AbuseLinkSection } from "../components/room/AbuseLinkSection"
import { DirectiveTooltip } from "../components/room/DirectiveTooltip"
import { MenuLabel } from "../components/room/MenuLabel"
import { OptInFlagDisplayHelpText } from "../components/room/OptInFlagDisplayHelpText"
import { PremiumPrivateRequest } from "../components/room/PremiumPrivateRequest"
import { PrivateCancelEarlyOverlay } from "../components/room/PrivateCancelEarlyOverlay"
import { RoomFlagNotification } from "../components/room/RoomFlagNotification"
import { RoomSubject } from "../components/room/RoomSubject"
import { getApprovedTagsFromTitle } from "../components/room/RoomSubjectSpan/api"
import { UserList } from "../components/room/roomUserList/UserList"
import { UserListContent } from "../components/room/roomUserList/UserListContent"
import { UserNote } from "../components/room/roomUserList/UserNote"
import { SendButton } from "../components/room/SendButton"
import { SpyRequest } from "../components/room/SpyRequest"
import { VideoOverlay } from "../components/room/Video"
import { HomepageFiltersIcon } from "../components/roomlist/filters/HomepageFiltersIcon"
import { TagPagination } from "../components/roomlist/filters/TagPagination"
import { LanguageFilterSection } from "../components/roomlist/LanguageFilterSection"
import { ActiveTagHeader } from "../components/roomlist/mobile/ActiveTagHeader"
import { MobileCategoryHeader } from "../components/roomlist/mobile/MobileCategoryHeader"
import { MobileFollowingNav } from "../components/roomlist/mobile/MobileFollowingNav"
import { MobileGendersBanner } from "../components/roomlist/mobile/MobileGendersBanner"
import { MobileRoomlistReloadMessage } from "../components/roomlist/mobile/MobileRoomlistReloadMessage"
import { MobileSearchIcon } from "../components/roomlist/mobile/MobileSearchIcon/MobileSearchIcon"
import { MobileSearchMessaging } from "../components/roomlist/mobile/MobileSearchMessaging"
import { MobileSort } from "../components/roomlist/mobile/MobileSort"
import { MobilePagination } from "../components/roomlist/mobile/pagination/MobilePagination"
import { RoomlistFilters } from "../components/roomlist/mobile/roomlistFilters/RoomlistFilters"
import { TopTagsBanner } from "../components/roomlist/mobile/TopTagsBanner"
import { NoSearchResultsMessage } from "../components/roomlist/NoSearchResultsMessage"
import { SearchResultsHeader } from "../components/roomlist/SearchResultsHeader"
import { makeAPIRegistry, makeComponentRegistry } from "../utils/registry"
import { injectToastContainer } from "../utils/sharedToast"

makeComponentRegistry({
    AppDirConfirmationModal,
    AppDirToggle,
    OneClickFlow,
    ContestStats,
    ChatursafeFilterSetting,
    UserNote,
    SendButton,
    MembershipDurationContainer,
    MenuLabel,
    UserList,
    VideoOverlay,
    RoomFlagNotification,
    OptInFlagDisplayHelpText,
    NPSSentimentSurvey,
    TagPagination,
    AbuseLinkSection,
    RoomSubject,
    PrivateCancelEarlyOverlay,
    FlagIcon,
    HomepageFiltersIcon,
    LanguageFilterSection,
    SpyRequest,
    Survey,
    RemoveFilterIcon,
    CloseButton,
    Search,
    MobileSearchIcon,
    PurchasePage,
    CancelConfirmButtonPair,
    PremiumPrivateRequest,
    UserListContent,
    GenderIconDefs,
    RoomlistFilters,
    MobilePagination,
    MobileFollowingNav,
    MobileGendersBanner,
    ActiveTagHeader,
    TopTagsBanner,
    MobileRoomlistReloadMessage,
    MobileSearchMessaging,
    MobileSort,
    MobileCategoryHeader,
    Messaging,
    DirectiveTooltip,
    MobileAutoRefillToggle,
    UnreadBadge,
    MobileBroadcast,
    SharedToastContainer: WrappedSharedToastContainer,
    SearchResultsHeader,
    NoSearchResultsMessage,
    DesktopFlaggedChat,
    MobileFlaggedChat,
    V3OnboardingModal,
})

makeAPIRegistry({
    getApprovedTagsFromTitle,
})

injectToastContainer()
