// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg width=%27100%25%27 height=%27100%25%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3crect width=%27100%25%27 height=%27100%25%27 fill=%27none%27 rx=%278%27 ry=%278%27 stroke=%27%230C6A93%27 stroke-width=%271%27 stroke-dasharray=%278%2c 4%27 stroke-dashoffset=%270%27 stroke-linecap=%27round%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g5kSy4yIpY1hpYFhp_i1{background-color:#fff;padding:12px 16px;min-height:100dvh}.G4rpjEJ4u3ZwAsxjU7yt{font-size:12px;line-height:16px;text-align:justify;margin:0 0 16px 0}.NgwqfTlc3l6IebbZeDcw{border:none;background:rgba(0,0,0,0);margin-right:12px}.NgwqfTlc3l6IebbZeDcw .f23GU3C4a4MAIbvLRVwV{background-color:#f8f9fa;height:64px;width:64px;border-radius:50%;display:flex;justify-content:center;align-items:center}.I_WDM1lkNYFgZ2rwNUtb{display:flex;align-items:center}.I_WDM1lkNYFgZ2rwNUtb .kwuFZq1UYaW0xFc4AyFy{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;font-size:16px;display:flex;align-items:center}.I_WDM1lkNYFgZ2rwNUtb .kwuFZq1UYaW0xFc4AyFy>svg{margin-right:6px}.CML8WhElLccWgLZ9ER9w .CivX34tKYVhhl4oRW8My{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;font-size:14px;margin-bottom:8px}.CML8WhElLccWgLZ9ER9w .JPmwq6MKU5WN0Z0eiTAj{display:grid;grid-template-columns:repeat(4, 1fr);gap:10px;justify-items:center}.CML8WhElLccWgLZ9ER9w .JPmwq6MKU5WN0Z0eiTAj .y2H3KduFFp9kLFnE4Zdz{width:100%;aspect-ratio:1;background-color:#f8f9fa;border-radius:8px}.CML8WhElLccWgLZ9ER9w .JPmwq6MKU5WN0Z0eiTAj .y2H3KduFFp9kLFnE4Zdz.gCAMUGfaobpcFFHeiKNA{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});display:flex;justify-content:center;align-items:center}.O10UZc4tC6tPxtmdUX6Q{margin-bottom:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `g5kSy4yIpY1hpYFhp_i1`,
	"descriptionText": `G4rpjEJ4u3ZwAsxjU7yt`,
	"addProfileImage": `NgwqfTlc3l6IebbZeDcw`,
	"iconWrapper": `f23GU3C4a4MAIbvLRVwV`,
	"profileInfo": `I_WDM1lkNYFgZ2rwNUtb`,
	"username": `kwuFZq1UYaW0xFc4AyFy`,
	"mediaSelect": `CML8WhElLccWgLZ9ER9w`,
	"label": `CivX34tKYVhhl4oRW8My`,
	"previewGrid": `JPmwq6MKU5WN0Z0eiTAj`,
	"preview": `y2H3KduFFp9kLFnE4Zdz`,
	"upload": `gCAMUGfaobpcFFHeiKNA`,
	"bottomMargin": `O10UZc4tC6tPxtmdUX6Q`
};
export default ___CSS_LOADER_EXPORT___;
