import { type ChangeEvent, useEffect, useRef, useState } from "react"
import { t } from "@lingui/macro"
import {
    CloseBlue as CloseBlueIcon,
    Pricetag as PricetagIcon,
} from "../../../common/atoms/Icons/Chat"
import { CloseWhite } from "../../../common/atoms/Icons/Others"
import { classNames } from "../../utils"
import { MessageMediaTokenModal } from "../MessageMediaTokenModal/MessageMediaTokenModal"
import styles from "./MessageMediaSelect.module.scss"

type Props = {
    selectedMedia: File[]
    setSelectedMedia: (selectedMedia: File[]) => void
    selectedMediaInputRef: React.RefObject<HTMLInputElement>
    scrollToBottom: () => void
}

export const MessageMediaSelect: React.FC<Props> = ({
    selectedMedia,
    setSelectedMedia,
    selectedMediaInputRef,
    scrollToBottom,
}) => {
    const [albumPrice, setAlbumPrice] = useState(0)
    const [showTokenModal, setShowTokenModal] = useState(false)
    const [previews, setPreviews] = useState<{ file: File; src: string }[]>([])
    const [hasOverflow, setHasOverflow] = useState(false)
    const previewRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const newPreviews = selectedMedia.map((file) => ({
            file,
            src: URL.createObjectURL(file),
        }))
        setPreviews(newPreviews)

        return () => {
            newPreviews.forEach((preview) => URL.revokeObjectURL(preview.src))
        }
    }, [selectedMedia])

    useEffect(() => {
        const checkOverflow = () => {
            if (previewRef.current) {
                const { scrollWidth, clientWidth, scrollHeight, clientHeight } =
                    previewRef.current
                setHasOverflow(
                    scrollWidth > clientWidth || scrollHeight > clientHeight,
                )
            }
        }

        checkOverflow()

        window.addEventListener("resize", checkOverflow)
        return () => window.removeEventListener("resize", checkOverflow)
    }, [previews])

    const isPreviewVisible = previewRef?.current?.checkVisibility()
    useEffect(() => {
        // scroll to bottom on visibility change
        scrollToBottom()
    }, [isPreviewVisible])

    const handleMediaSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedMedia([...selectedMedia, ...Array.from(e.target.files)])
            e.target.value = ""
        }
    }

    const handleMediaRemove = (file: File) => {
        const mediaFiltered = selectedMedia.filter((media) => media !== file)
        setSelectedMedia(mediaFiltered)
        if (mediaFiltered.length === 0) {
            setAlbumPrice(0)
        }
    }

    const handleClickPriceRemove = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation()
        setAlbumPrice(0)
    }

    return (
        <>
            <input
                ref={selectedMediaInputRef}
                type="file"
                accept="image/*,video/*"
                multiple
                style={{ display: "none" }}
                onChange={handleMediaSelect}
                data-testid="file-input"
            />

            {selectedMedia.length > 0 && (
                <>
                    <div className={styles.mediaSelect}>
                        <div className={styles.preview} ref={previewRef}>
                            {previews.map(({ file, src }, idx) => (
                                <div
                                    key={file.name}
                                    className={styles.previewItem}
                                >
                                    <button
                                        className={styles.closeBtn}
                                        onClick={() => handleMediaRemove(file)}
                                        data-testid={`remove-media-${idx}`}
                                    >
                                        <CloseWhite />
                                    </button>
                                    {file.type.startsWith("image/") ? (
                                        <img
                                            src={src}
                                            alt={file.name}
                                            className={styles.imagePreview}
                                        />
                                    ) : (
                                        <div className={styles.filePreview}>
                                            {file.type.split("/")[0]}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className={styles.selectedInfo}>
                            {albumPrice ? (
                                <button
                                    className={classNames(
                                        styles.priceButton,
                                        styles.update,
                                    )}
                                    onClick={() => setShowTokenModal(true)}
                                >
                                    <PricetagIcon height={13} width={13} />
                                    {albumPrice} tokens
                                    <span onClick={handleClickPriceRemove}>
                                        <CloseBlueIcon height={14} width={14} />
                                    </span>
                                </button>
                            ) : (
                                <button
                                    className={classNames(
                                        styles.priceButton,
                                        styles.add,
                                    )}
                                    onClick={() => setShowTokenModal(true)}
                                    data-testid="add-price-btn"
                                >
                                    {t`Add Price`}
                                </button>
                            )}
                            <span className={styles.priceText}>
                                {hasOverflow
                                    ? `${selectedMedia.length}/50`
                                    : `${selectedMedia.length} selected`}
                            </span>
                        </div>
                    </div>
                    {showTokenModal && (
                        <MessageMediaTokenModal
                            albumPrice={albumPrice}
                            setAlbumPrice={setAlbumPrice}
                            setShowTokenModal={setShowTokenModal}
                        />
                    )}
                </>
            )}
        </>
    )
}
