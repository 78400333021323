// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cHH32L2bRFeYLqBXLWZ0{padding:8px;border-top:1px solid #e0e0e0;background-color:#f8f9fa}.er8EH4d8zIU3OqGMxAg_{display:flex;gap:8px;overflow-x:auto;scrollbar-width:none}.bxRnshgqf8_exrWS53gv{position:relative;width:64px;height:64px;display:flex;justify-content:center;align-items:center;background-color:#f9f9f9}.C38rJZfU_vqh47MMi77E{position:absolute;top:4px;right:4px;background-color:#000;opacity:.76;border-radius:50%;width:20px;height:20px;display:flex;justify-content:center;align-items:center;cursor:pointer;border:none}.C38rJZfU_vqh47MMi77E>svg{opacity:1}.Z5gSA0mUz9D6X_LGTgYC{width:64px;height:64px;object-fit:cover;border-radius:4px}.zQXx6LNgYayaR17_ETS4{display:flex;justify-content:center;align-items:center;font-size:12px;color:#555;background-color:#ddd;width:100%;height:100%}.C0F1cggezSyNjvFfvTah{display:flex;justify-content:space-between;align-items:center;margin-top:8px}.GsOdQgANWlRZ_qNRpTWS{border-radius:22px;padding:4px 8px;display:flex;align-items:center}.GsOdQgANWlRZ_qNRpTWS svg{fill:currentColor}.GsOdQgANWlRZ_qNRpTWS.KTLmprg7k4VQifce69HP{background-color:rgba(0,0,0,0);border:1px solid #525252;color:#525252}.GsOdQgANWlRZ_qNRpTWS.mjXwMZG3uvb4M5cmdtR7{background-color:#d6f0ff;border:none;color:#033e58}.GsOdQgANWlRZ_qNRpTWS.mjXwMZG3uvb4M5cmdtR7>svg{margin-right:4px}.GsOdQgANWlRZ_qNRpTWS.mjXwMZG3uvb4M5cmdtR7>span{display:flex;padding:3px;margin-left:4px}.lRGaHpc627ZqPnOQepqm{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#a7a7a7;font-size:14px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaSelect": `cHH32L2bRFeYLqBXLWZ0`,
	"preview": `er8EH4d8zIU3OqGMxAg_`,
	"previewItem": `bxRnshgqf8_exrWS53gv`,
	"closeBtn": `C38rJZfU_vqh47MMi77E`,
	"imagePreview": `Z5gSA0mUz9D6X_LGTgYC`,
	"filePreview": `zQXx6LNgYayaR17_ETS4`,
	"selectedInfo": `C0F1cggezSyNjvFfvTah`,
	"priceButton": `GsOdQgANWlRZ_qNRpTWS`,
	"add": `KTLmprg7k4VQifce69HP`,
	"update": `mjXwMZG3uvb4M5cmdtR7`,
	"priceText": `lRGaHpc627ZqPnOQepqm`
};
export default ___CSS_LOADER_EXPORT___;
