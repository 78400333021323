import type { CSSProperties } from "react"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { t } from "@lingui/macro"
import { createPortal } from "react-dom"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import {
    ButtonColor,
    ButtonsFloatDirection,
    ButtonSize,
    CancelConfirmButtonPair,
    Overlay,
    Typography,
} from "../../common"
import { Checkbox } from "../../common/atoms/Checkbox"
import { CategoryTable } from "../CategoryTable"
import { CHATURSAFE_CATEGORY_MAPPING } from "../common"
import { FilterPreview } from "../FilterPreview"
import type { CategoryRowData } from "../CategoryRow/CategoryRow"
import type { IChatursafeFilterSettings } from "@multimediallc/web-utils/types"
import "./FilterModal.scss"

export interface FilterModalProps {
    isOpen: boolean
    formData: IChatursafeFilterSettings
    handleInputChange: (name: string, value: boolean | number) => void
    onClose: () => void
    onSave: () => void
}

export const FilterModal = ({
    isOpen,
    formData,
    handleInputChange,
    onClose,
    onSave,
}: FilterModalProps) => {
    const [tableData, setTableData] = useState([] as CategoryRowData[])
    const [isChanged, setIsChanged] = useState(false)
    const [initialFormData, setInitialFormData] = useState(
        formData ?? ({} as IChatursafeFilterSettings),
    )
    const ref = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState({} as CSSProperties)

    const checkFormDataChanged = (newData: IChatursafeFilterSettings) => {
        for (const key in newData) {
            if (
                newData[key as keyof IChatursafeFilterSettings] !==
                initialFormData[key as keyof IChatursafeFilterSettings]
            ) {
                setIsChanged(true)
                return
            }
        }
        setIsChanged(false)
    }

    useLayoutEffect(() => {
        setIsChanged(false)
        setInitialFormData(formData)
        function setModalPosition() {
            if (isOpen && ref.current) {
                const left =
                    (document.documentElement.clientWidth -
                        ref.current.offsetWidth) /
                    2
                const top =
                    (document.documentElement.clientHeight -
                        ref.current.offsetHeight) /
                    2
                setPosition({
                    top: `${Math.max(top + window.scrollY, 0)}px`,
                    left: `${Math.max(left + window.scrollX, 0)}px`,
                })
            }
        }

        setModalPosition()

        window.addEventListener("resize", setModalPosition)

        return () => {
            window.removeEventListener("resize", setModalPosition)
        }
    }, [isOpen])

    useEffect(() => {
        function updateTableData(
            newFilterSettings: IChatursafeFilterSettings,
        ): void {
            const result: CategoryRowData[] = []
            for (const key in CHATURSAFE_CATEGORY_MAPPING) {
                const enabled = newFilterSettings[
                    CHATURSAFE_CATEGORY_MAPPING[key].enabled_key
                ] as boolean
                const value = newFilterSettings[
                    CHATURSAFE_CATEGORY_MAPPING[key].value_key
                ] as number

                if (enabled !== undefined && value !== undefined) {
                    const row: CategoryRowData = {
                        name: key,
                        enabled: enabled,
                        value: value * 100,
                    }
                    result.push(row)
                }
            }
            setTableData(result)
        }

        if (formData) {
            updateTableData(formData)
            checkFormDataChanged(formData)
        }
    }, [formData])

    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose()
            }
        }
        document.addEventListener("keydown", keyHandler)
        return () => {
            document.removeEventListener("keydown", keyHandler)
        }
    }, [onClose])

    if (!isOpen) return null

    const handleContentClick = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    const onChange = (name: string, value: boolean | number) => {
        handleInputChange(name, value)
    }

    return createPortal(
        <>
            <Overlay
                className="FilterModal__overlay"
                dimmed={true}
                onClick={onClose}
            />
            <div
                className="FilterModal__container"
                onClick={handleContentClick}
                ref={ref}
                style={position}
            >
                <div className="FilterModal__header">
                    <Typography
                        component="h2"
                        size={Sizes.lgpx}
                        weight={Weights.bold}
                        color={colorClass.defaultTooltipColor}
                    >
                        {t`Adjust Filter Strictness`}
                    </Typography>
                    <Typography
                        component="h2"
                        size={Sizes.smpx}
                        color={colorClass.defaultTooltipColor}
                    >
                        {t`Our safety filters protect your space by detecting inappropriate content. Adjust strictness by category below to suit your preferences.`}
                    </Typography>
                </div>
                <div className="FilterModal__checkboxes">
                    <Typography
                        component="h2"
                        size={Sizes.smpx}
                        color={colorClass.defaultTooltipColor}
                    >
                        {t`Apply safety filters to:`}
                    </Typography>
                    <div className="FilterModal__checkboxes-div">
                        <Checkbox
                            onToggle={(value) =>
                                onChange("chat_enabled", value)
                            }
                            checked={formData.chat_enabled ?? false}
                            label={t`Public Chat`}
                            name="chat_enabled"
                        />
                        <Checkbox
                            onToggle={(value) => onChange("pm_enabled", value)}
                            // commented out while this functionality is disabled
                            // checked={formData.pm_enabled ?? false}
                            checked={false}
                            label={t`Private Messages`}
                            name="pm_enabled"
                            disabled={true}
                            tooltipText={t`Coming Soon`}
                        />
                        <Checkbox
                            onToggle={(value) =>
                                onChange("messaging_enabled", value)
                            }
                            // commented out while this functionality is disabled
                            // checked={formData.messaging_enabled ?? false}
                            checked={false}
                            label={t`Direct Messages`}
                            name="messaging_enabled"
                            disabled={true}
                            tooltipText={t`Coming Soon`}
                        />
                        <Checkbox
                            onToggle={(value) => onChange("tip_enabled", value)}
                            // commented out while this functionality is disabled
                            // checked={formData.tip_enabled ?? false}
                            checked={false}
                            label={t`Tip Messages`}
                            name="tip_enabled"
                            disabled={true}
                            tooltipText={t`Coming Soon`}
                        />
                    </div>
                </div>
                <div className="FilterModal__controls">
                    <div className="FilterModal__table-div">
                        <Typography
                            component="h2"
                            size={Sizes.mdpx}
                            weight={Weights.bold}
                            color={colorClass.defaultTooltipColor}
                            className="FilterModal__table-title"
                        >
                            {t`Filter Categories`}
                        </Typography>
                        <CategoryTable
                            data={tableData}
                            handleInputChange={onChange}
                        />
                    </div>
                    <div className="FilterModal__preview-div">
                        <FilterPreview currentSettings={formData} />
                    </div>
                </div>
                <div className="FilterModal__footer">
                    <CancelConfirmButtonPair
                        cancelText={t`Cancel`}
                        actionText={t`Save Changes`}
                        actionColor={ButtonColor.Orange}
                        cancelColor={ButtonColor.Link}
                        size={ButtonSize.Medium}
                        floatDirection={ButtonsFloatDirection.Right}
                        cancelClickHandler={onClose}
                        actionClickHandler={onSave}
                        actionProps={{
                            className: "FilterModal__footer-action",
                            disabled: !isChanged,
                        }}
                        cancelProps={{
                            className: "FilterModal__footer-cancel",
                        }}
                    />
                </div>
            </div>
        </>,
        document.body,
    )
}
