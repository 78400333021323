import { useEffect } from "react"
import {
    newChatMessage,
    newChatNotice,
    newChatPm,
    newLog,
    userChatDeleted,
} from "../chatSlice"
import { useAppDispatch } from "."
import type { EventRouter } from "../../components/messaging/types"
import type { MobileBroadcastTopics } from "../../components/mobile_broadcast/types"
import type {
    IPrivateMessage,
    IRemoveMessagesNotification,
    IRoomMessage,
    IRoomNotice,
    IShortcodeMessage,
    LogMessageType,
} from "@multimediallc/web-utils/types"

export const useRoomMessageEffect = (
    roomMessageEvent?: EventRouter<IRoomMessage>,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (roomMessageEvent === undefined) {
            return
        }

        const dispatchRoomMessage = (event: IRoomMessage) => {
            dispatch(newChatMessage(event))
        }

        roomMessageEvent.listen(dispatchRoomMessage)
        return () => roomMessageEvent.removeListener(dispatchRoomMessage)
    }, [dispatch, roomMessageEvent])
}

export const useRoomNoticeEffect = (
    roomNoticeEvent?: EventRouter<IRoomNotice>,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (roomNoticeEvent === undefined) {
            return
        }

        const dispatchRoomNotice = (event: IRoomNotice) => {
            dispatch(newChatNotice(event))
        }

        roomNoticeEvent.listen(dispatchRoomNotice)
        return () => roomNoticeEvent.removeListener(dispatchRoomNotice)
    }, [dispatch, roomNoticeEvent])
}

export const useRoomShortcodeEffect = (
    roomShortcodeEvent?: EventRouter<IShortcodeMessage>,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (roomShortcodeEvent === undefined) {
            return
        }

        const dispatchRoomShortcode = (event: IShortcodeMessage) => {
            dispatch(newChatMessage(event))
        }

        roomShortcodeEvent.listen(dispatchRoomShortcode)
        return () => roomShortcodeEvent.removeListener(dispatchRoomShortcode)
    }, [dispatch, roomShortcodeEvent])
}

export const useChatUserMessageEffect = (
    userMessageTopic: MobileBroadcastTopics["UserMessageTopic"],
    uuid: string | undefined,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const dispatchUserMessage = (event: IPrivateMessage) => {
            dispatch(newChatPm(event))
        }

        if (uuid !== undefined) {
            const userMessageTopicInstance = new userMessageTopic(uuid)
            userMessageTopicInstance.onMessage.listen(dispatchUserMessage)
            return () =>
                userMessageTopicInstance.onMessage.removeListener(
                    dispatchUserMessage,
                )
        }
    }, [uuid, dispatch, userMessageTopic])
}

export const useRemoveUserMessagesEffect = (
    removeMessagesEvent?: EventRouter<IRemoveMessagesNotification>,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (removeMessagesEvent === undefined) {
            return
        }

        const dispatchUserChatDeleted = (
            event: IRemoveMessagesNotification,
        ) => {
            dispatch(userChatDeleted(event.username))
        }

        removeMessagesEvent.listen(dispatchUserChatDeleted)
        return () => removeMessagesEvent.removeListener(dispatchUserChatDeleted)
    }, [dispatch, removeMessagesEvent])
}

export const useLogMessageEffect = (
    logMessageEvent?: EventRouter<LogMessageType>,
): void => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (logMessageEvent === undefined) {
            return
        }

        const dispatchLogMessage = (event: LogMessageType) => {
            dispatch(newLog(event))
        }

        logMessageEvent.listen(dispatchLogMessage)
        return () => logMessageEvent.removeListener(dispatchLogMessage)
    }, [dispatch, logMessageEvent])
}
