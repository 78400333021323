// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jfURpUJuRhIKNb3y81VI{position:absolute;top:100%;right:0;margin-top:4px;background:#fff;border-radius:8px;box-shadow:0px 4px 12px rgba(0,0,0,.1);min-width:200px;z-index:1;padding:8px 0}.EEbqxw3hIwlBC86hAAz_{display:flex;align-items:center;padding:8px 12px;margin:0 12px;cursor:pointer;color:#333;border-radius:8px}.EEbqxw3hIwlBC86hAAz_:hover:not(.gOrB2TAt5cGRDaF04e2F){background-color:#e0e0e0}.EEbqxw3hIwlBC86hAAz_.dCpCS8rSaeXiZJy0wvpQ{color:#c00}.EEbqxw3hIwlBC86hAAz_.gOrB2TAt5cGRDaF04e2F{color:#b3b3b3;cursor:not-allowed}.EEbqxw3hIwlBC86hAAz_.jV9Ao1lFG40HAc40IqPy{margin-left:14px}.EEbqxw3hIwlBC86hAAz_.jV9Ao1lFG40HAc40IqPy svg{margin-right:14px}.EEbqxw3hIwlBC86hAAz_.jV9Ao1lFG40HAc40IqPy svg path{fill:currentColor}.EEbqxw3hIwlBC86hAAz_ svg{margin-right:12px}.EEbqxw3hIwlBC86hAAz_ span{font-size:14px;white-space:nowrap}.qH3QzOO3RZ21Gcaqnrrf{position:relative;display:inline-block;margin-left:auto}.WX3zbaFURjsm5kPqOIUe{display:flex;align-items:center;gap:8px}.YI2u7GCa9aDJPpqpbSF0{border-radius:4px;padding:2px}.YI2u7GCa9aDJPpqpbSF0.UvFMJBNbP3FjyeTsXo6U{background-color:#c9c9c9}.YI2u7GCa9aDJPpqpbSF0.YtGrGotjMDokp494doV6:hover{background-color:#c9c9c9}.QBBnjhZ8rx7sgSWkpzl4{border-bottom:1px solid #e0e0e0;margin:8px 16px}.AwG1gw7OeLbskN6WlwYf{position:static;width:24px;height:24px;font-size:14px;line-height:24px;font-family:"UbuntuMedium",Arial,Helvetica,sans-serif}.bJUBT26CbTckCMlw2e_T{padding:2px;margin-left:4px}.vEZjcZspMmHX8a8sNLqX.EGdEO2zPQjY8LXhR7ctY{right:0;width:190px;font-size:14px}.vEZjcZspMmHX8a8sNLqX .QBBnjhZ8rx7sgSWkpzl4{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `jfURpUJuRhIKNb3y81VI`,
	"menuItem": `EEbqxw3hIwlBC86hAAz_`,
	"gray": `gOrB2TAt5cGRDaF04e2F`,
	"red": `dCpCS8rSaeXiZJy0wvpQ`,
	"report": `jV9Ao1lFG40HAc40IqPy`,
	"container": `qH3QzOO3RZ21Gcaqnrrf`,
	"iconsContainer": `WX3zbaFURjsm5kPqOIUe`,
	"menuButton": `YI2u7GCa9aDJPpqpbSF0`,
	"open": `UvFMJBNbP3FjyeTsXo6U`,
	"desktop": `YtGrGotjMDokp494doV6`,
	"divider": `QBBnjhZ8rx7sgSWkpzl4`,
	"headerBadge": `AwG1gw7OeLbskN6WlwYf`,
	"reportIcon": `bJUBT26CbTckCMlw2e_T`,
	"desktopUnified": `vEZjcZspMmHX8a8sNLqX`,
	"msgHeaderMenu": `EGdEO2zPQjY8LXhR7ctY`
};
export default ___CSS_LOADER_EXPORT___;
