import type { ComponentPropsWithoutRef } from "react"
import "./MobileAutoRefillToggle.scss"
import { t } from "@lingui/macro"
import { Skeleton } from "../../common"
import { InputToggle } from "../../common/atoms/InputToggle"
import { useAutoRefillToggle } from "../useAutoRefillToggle"

interface MobileAutoRefillToggleProps extends ComponentPropsWithoutRef<"div"> {
    hidden: boolean
}

export function MobileAutoRefillToggle({
    hidden,
    ...props
}: MobileAutoRefillToggleProps) {
    const {
        isActive,
        isLoading,
        isSaving,
        handleAutoRefillChange,
        autoRefillSettings,
    } = useAutoRefillToggle({
        skip: hidden,
    })

    if (hidden) {
        return null
    }

    const hadPlanActiveBeforeOrNow = Boolean(
        autoRefillSettings?.plan?.product_id,
    )

    const tokenProductToShow = hadPlanActiveBeforeOrNow
        ? autoRefillSettings?.plan
        : autoRefillSettings?.last_purchased_plan

    return (
        <div className="MobileAutoRefillToggle" {...props}>
            <div className="MobileAutoRefillToggle__label">
                {" "}
                <div>{t`Tokens Auto Refill`}</div>
                <div>
                    {isLoading ? (
                        <Skeleton style={{ height: 15 }} />
                    ) : tokenProductToShow ? (
                        <>
                            {`(${tokenProductToShow?.tokens} ${t`tokens`} - $${tokenProductToShow?.cost})`}
                        </>
                    ) : null}
                </div>
            </div>
            <div className="MobileAutoRefillToggle__toggle">
                {" "}
                <InputToggle
                    onToggle={(v) => void handleAutoRefillChange(v)}
                    checked={isActive}
                    disabled={isLoading || isSaving}
                />
            </div>
        </div>
    )
}
