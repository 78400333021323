import React, { useLayoutEffect, useMemo, useRef } from "react"
import { Spinner } from "../../common/atoms/Spinner/Spinner"
import { Message as MessageComponent } from "../Message/Message"
import { MessageRowDisclaimer } from "../MessageRowDisclaimer/MessageRowDisclaimer"
import { MessageTyping } from "../MessageTyping/MessageTyping"
import { classNames } from "../utils"
import { findNonLogMessage, getTimeStamp, showAvatar } from "./utils"
import type { Message } from "../types"
import "./MessageRow.scss"

export interface MessageRowData {
    messages: Message[]
    showMenuMessageId: string | null
    setShowMenuMessageId: (id: string | null) => void
    username: string
    onHeightChange: (index: number, height: number, messageId: string) => void
    isAllHistoryLoaded: boolean
    isLoadingOlderMessages: boolean
    isUserTyping: boolean // react-window is handling rerenders, so we should
    //  not get userTyping state from redux as part of this memoized presentational component
}

interface MessageRowProps {
    index: number
    style: React.CSSProperties
    data: MessageRowData
}

const useMessageHeight = (
    rowRef: React.RefObject<HTMLDivElement>,
    messageIndex: number,
    message: Message | null,
    onHeightChange: (index: number, height: number, messageId: string) => void,
) => {
    useLayoutEffect(() => {
        if (!rowRef.current || !message) return
        onHeightChange(messageIndex, rowRef.current.clientHeight, message.i)
    }, [messageIndex, message?.m, onHeightChange])
}

export const MessageRow = React.memo(
    ({ index, style, data }: MessageRowProps) => {
        const rowRef = useRef<HTMLDivElement>(null)
        const {
            messages,
            showMenuMessageId,
            setShowMenuMessageId,
            username,
            onHeightChange,
            isAllHistoryLoaded,
            isLoadingOlderMessages,
            isUserTyping,
        } = data

        const messageIndex = index - 1
        const message =
            messageIndex >= 0 && messageIndex < messages.length
                ? messages[messageIndex]
                : null

        useMessageHeight(rowRef, messageIndex, message, onHeightChange)
        const isLoading = !isAllHistoryLoaded && isLoadingOlderMessages
        const { previousMessage, nextMessage } = useMemo(() => {
            if (!message || messageIndex < 0) {
                return { previousMessage: null, nextMessage: null }
            }

            return {
                previousMessage: findNonLogMessage(
                    messages,
                    messageIndex - 1,
                    -1,
                ),
                nextMessage: findNonLogMessage(messages, messageIndex + 1, 1),
            }
        }, [messages, messageIndex])

        if (index === 0) {
            return (
                <div
                    style={style}
                    className={classNames("messages-loader", {
                        visible: isLoading,
                    })}
                >
                    <Spinner size="xspx" />
                </div>
            )
        }

        if (index === messages.length + 1 && isUserTyping) {
            return (
                <div ref={rowRef} style={style} className="message-row">
                    <MessageTyping username={username} />
                </div>
            )
        }

        if (!message || messageIndex >= messages.length) return null
        if (message.is_log_message) {
            return (
                <div ref={rowRef} style={style} className="message-row">
                    {index === 1 && isAllHistoryLoaded && (
                        <MessageRowDisclaimer username={username} />
                    )}
                    <div className="message-row-notice">{message.m}</div>
                </div>
            )
        }

        const timestamp = getTimeStamp(
            message,
            previousMessage,
            isAllHistoryLoaded,
        )

        return (
            <div ref={rowRef} style={style} className="message-row">
                {index === 1 && isAllHistoryLoaded && (
                    <MessageRowDisclaimer username={username} />
                )}
                {timestamp && (
                    <div className="message-timestamp-container">
                        {timestamp}
                    </div>
                )}
                <MessageComponent
                    message={message}
                    showMenuMessageId={showMenuMessageId}
                    setShowMenuMessageId={setShowMenuMessageId}
                    showAvatar={showAvatar(
                        username,
                        message,
                        nextMessage || undefined,
                    )}
                    previousMessage={previousMessage}
                    hasTimestamp={Boolean(timestamp)}
                />
            </div>
        )
    },
)

MessageRow.displayName = "MessageRow"
