import { useEffect, useRef, useState } from "react"

/**
 * A custom hook that throttles a value, updating it only after a specified delay.
 *
 * @template T The type of the value to be throttled.
 * @param {T} value The value to be throttled.
 * @param {number} delay The delay in milliseconds before the value is updated.
 * @param {((val: T) => boolean)=} bypassCondition An optional function that, if returns true,
 * bypasses the throttling logic and immediately updates the throttled value.  This is useful
 * to prevent initial and possibly null values from starting throttle timers.
 * @returns {T} The throttled value.
 *
 * @example
 * const [inputValue, setInputValue] = useState('');
 * const throttledValue = useThrottle(inputValue, 500);
 *
 * useEffect(() => {
 * // Use throttledValue here, which updates less frequently than inputValue.
 * console.log('Throttled value:', throttledValue);
 * }, [throttledValue]);
 *
 * // Example with bypassCondition
 * const [searchQuery, setSearchQuery] = useState('');
 * const throttledSearchQuery = useThrottle(searchQuery, 300, (val) => val.trim() === "");
 *
 * useEffect(() => {
 * if(searchQuery){
 * console.log('Searching:', throttledSearchQuery);
 * }
 * }, [throttledSearchQuery])
 */
function useThrottle<T>(
    value: T,
    delay: number,
    bypassCondition?: (val: T) => boolean,
): T {
    const [throttledValue, setThrottledValue] = useState<T>(value)
    const lastExecuted = useRef(0)

    useEffect(() => {
        if (bypassCondition && bypassCondition(value)) {
            return
        }
        const now = Date.now()
        const timeSinceLastExecution = now - lastExecuted.current

        if (timeSinceLastExecution >= delay) {
            setThrottledValue(value)
            lastExecuted.current = now
        } else {
            const timeout = setTimeout(() => {
                setThrottledValue(value)
                lastExecuted.current = Date.now()
            }, delay - timeSinceLastExecution)

            return () => clearTimeout(timeout)
        }
    }, [value, delay])

    return throttledValue
}

export { useThrottle }
