// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._ZTIoDMHTCPSmlSi0y_C{font-size:12px;color:#b3b3b3;margin-top:8px;margin-bottom:8px;text-align:center;margin-left:24px;margin-right:24px}.aslc3aEXgiTSF38t5FFQ{max-height:110px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageRowNotice": `_ZTIoDMHTCPSmlSi0y_C`,
	"messageRowDisclaimer": `aslc3aEXgiTSF38t5FFQ`
};
export default ___CSS_LOADER_EXPORT___;
