import { useEffect, useRef, useState } from "react"
import { t } from "@lingui/macro"
import { useNavigate } from "react-router-dom"
import { useVisibility } from "../../../hooks/useVisibility"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { useAppSelector } from "../../../store/hooks"
import {
    DM_THREAD_PAGE_LIMIT,
    messagingApi,
    useLazyGetThreadsQuery,
} from "../../../store/messagingSlice"
import { Button, ButtonColor, ButtonSize, Typography } from "../../common"
import { Envelope } from "../../common/atoms/Icons/Chat"
import { Spinner } from "../../common/atoms/Spinner"
import { ScrollButton } from "../../common/molecules/ScrollButton"
import { ScrollButtonDirection } from "../../common/molecules/ScrollButton/ScrollButton"
import { draftCache } from "../DraftCache/DraftCache"
import { useMessagingView } from "../hooks"
import { useOrientation } from "../hooks/useOrientation"
import { MessageThread } from "../MessageThread/MessageThread"
import { classNames } from "../utils"
import styles from "./ThreadList.module.scss"
import { useInfiniteScroll } from "./useInfiniteScroll"
import { draftMessageToThread } from "./utils"
import type { Thread } from "../types"

type Props = {
    filteredName: string
    searchFocused: boolean
    permissionUpdate: (allow: boolean) => void
}

export const ThreadList: React.FC<Props> = ({
    filteredName,
    searchFocused,
    permissionUpdate,
}: Props) => {
    const navigate = useNavigate()
    const { ref: visibilityRef, isVisible } = useVisibility()
    const messagingView = useMessagingView()
    const isLandscape = useOrientation()
    const ENVELOPE_ICON_LANDSCAPE_SIZE = 112
    const ENVELOPE_ICON_PORTRAIT_SIZE = 168
    const ENVELOPE_ICON_SIZE = isLandscape
        ? ENVELOPE_ICON_LANDSCAPE_SIZE
        : ENVELOPE_ICON_PORTRAIT_SIZE
    const [showScrollUpButton, setShowScrollUpButton] = useState(false)
    const scrollRef = useRef<HTMLDivElement>(null)
    useInfiniteScroll(scrollRef)

    const lastQuery = useAppSelector(
        (state) =>
            messagingApi.endpoints.getThreads.select({
                offset: 0,
                limit: DM_THREAD_PAGE_LIMIT,
            })(state).originalArgs,
    )

    // Get the current cached data
    const cachedData = useAppSelector(
        (state) =>
            messagingApi.endpoints.getThreads.select({
                offset: lastQuery?.offset ?? 0,
                limit: DM_THREAD_PAGE_LIMIT,
            })(state).data,
    )

    const [triggerGetThreads, { data, isLoading, isFetching, error }] =
        useLazyGetThreadsQuery()
    const effectiveData = data || cachedData
    const hasNoMessages = effectiveData?.threads?.length === 0
    const hasConversationFeatures = effectiveData?.has_conversation_features

    const updateThreads = () => {
        triggerGetThreads({
            offset: lastQuery?.offset ?? 0,
            limit: DM_THREAD_PAGE_LIMIT,
            search_username: filteredName,
        })
    }

    useEffect(() => {
        if (isVisible && !cachedData && !data) {
            updateThreads()
        }
    }, [isVisible, lastQuery, triggerGetThreads, cachedData, data])

    useEffect(() => {
        if (isVisible) {
            updateThreads()
        }
    }, [filteredName])

    useEffect(() => {
        permissionUpdate(hasConversationFeatures ?? false)
    }, [hasConversationFeatures])

    const getMessage = (): string | null => {
        if (isLoading) return "Loading..."
        if (error) return "Error loading conversations"
        return null
    }

    const conversationUsers =
        effectiveData?.threads?.map((thread) => thread.other_user.username) ||
        []
    const draftThreads = [...draftCache.getAll()]
        .filter(([username, _]) => !conversationUsers.includes(username))
        .map(([username, draft]) => draftMessageToThread(username, draft))
        .sort((a, b) => b.created_at - a.created_at)

    const message = getMessage()
    if (message) {
        return (
            <div
                ref={visibilityRef as React.RefObject<HTMLDivElement>}
                className={classNames(styles.threadListMessage, {
                    [styles.mobile]: messagingView.isMobile,
                })}
            >
                {message}
            </div>
        )
    }
    if (isFetching && searchFocused) {
        return (
            <div
                ref={visibilityRef as React.RefObject<HTMLDivElement>}
                className={classNames(styles.loadingMessage, {
                    [styles.mobile]: messagingView.isMobile,
                })}
            >
                <div
                    className={classNames(
                        styles.loadingIndicator,
                        styles.filterLoading,
                    )}
                >
                    <Spinner size="xspx" />
                </div>
                <div>{t`Loading...`}</div>
            </div>
        )
    }
    if (isVisible && hasNoMessages && !isFetching) {
        if (filteredName) {
            return (
                <div
                    className={styles.noConversations}
                    data-testid="no-conversations"
                >
                    {t`No results found`}
                </div>
            )
        }
        return (
            <div
                ref={visibilityRef as React.RefObject<HTMLDivElement>}
                className={styles.threadListMessage}
            >
                <div className={styles.noMessagesContainer}>
                    <Envelope
                        height={ENVELOPE_ICON_SIZE}
                        width={ENVELOPE_ICON_SIZE}
                    />
                    <Typography
                        size={Sizes.mdpx}
                        weight={Weights.medium}
                        className={styles.title}
                    >{t`No Messages`}</Typography>
                    <Typography
                        size={Sizes.smpx}
                        className={styles.message}
                    >{t`Caution: The Chaturbate Team will NEVER contact you via chat or ask for your password.`}</Typography>
                </div>
                <Button
                    text={t`Send New Message`}
                    color={ButtonColor.Blue}
                    size={ButtonSize.Medium}
                    className={styles.button}
                    onClick={() => navigate("/search-users")}
                />
            </div>
        )
    }
    return (
        <div
            ref={visibilityRef as React.RefObject<HTMLDivElement>}
            className={styles.threadListContent}
        >
            <div
                ref={scrollRef}
                className={styles.threadListScrollable}
                data-testid="threadListScrollable"
                onScroll={() => {
                    if (!scrollRef.current) return
                    const scrollButtonThreshold =
                        scrollRef.current.clientHeight / 4
                    setShowScrollUpButton(
                        scrollRef.current.scrollTop > scrollButtonThreshold,
                    )
                }}
            >
                {draftThreads.map((thread: Thread) => (
                    <MessageThread
                        key={`${thread.from_user}-${thread.created_at}`}
                        thread={thread}
                        conversationListRef={scrollRef}
                    />
                ))}
                {effectiveData?.threads?.map((thread: Thread) => (
                    <MessageThread
                        key={`${thread.from_user}-${thread.created_at}`}
                        thread={thread}
                        conversationListRef={scrollRef}
                    />
                ))}
                {isFetching && (
                    <div className={styles.loadingIndicator}>
                        <Spinner size="xspx" />
                    </div>
                )}
            </div>
            {showScrollUpButton && (
                <ScrollButton
                    text={t`Top`}
                    scrollToDirection={ScrollButtonDirection.top}
                    onClick={() => {
                        if (!scrollRef.current) return
                        scrollRef.current.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        })
                    }}
                />
            )}
        </div>
    )
}
