import { useMemo } from "react"
import { removeAllChildren } from "@multimediallc/web-utils/domUtils"
import { PmLock } from "../../common/atoms/Icons/Chat"
import { MessageParts } from "../MessageParts"
import { useMobileBroadcastContext } from "../mobileBroadcastContext"
import { UsernameLabel } from "../UsernameLabel"
import type { ReactRoomMessage } from "../../../store/chatSlice"
import type { IPrivateMessage } from "@multimediallc/web-utils/types"
import "./UserMessage.scss"

interface UserMessageProps {
    messageData: ReactRoomMessage | IPrivateMessage
    isPm: boolean
}

export function UserMessage({ messageData, isPm }: UserMessageProps) {
    const { message, fromUser, isPrivateShowMessage } = messageData
    const shortcodes =
        "shortcodes" in messageData ? messageData.shortcodes : undefined
    const { createMediasetThumbnails } = useMobileBroadcastContext()

    const thumbnails = useMemo(() => {
        return isPm
            ? createMediasetThumbnails(messageData as IPrivateMessage)
            : undefined
    }, [isPm, createMediasetThumbnails, messageData])

    return (
        <span className="UserMessage">
            {(isPm || isPrivateShowMessage) && (
                <PmLock
                    className="UserMessage__PmBadge"
                    data-testid="pm-badge"
                    width="1.2em"
                    height="1.2em"
                />
            )}
            <span className="UserMessage__UsernameContainer">
                <UsernameLabel user={fromUser} />
            </span>
            <MessageParts message={message} shortcodes={shortcodes} />
            {isPm && thumbnails && (
                <div
                    className="UserMessage__Media"
                    data-testid="chat-media-container"
                    ref={(div) => {
                        if (div) {
                            removeAllChildren(div)
                            div.appendChild(thumbnails)
                        }
                    }}
                />
            )}
        </span>
    )
}
