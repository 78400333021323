// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T2b8IztN89kfzB2OQ5cb{position:fixed;left:50%;transform:translateX(-50%);border-radius:16px;opacity:0;z-index:1010;display:flex;align-items:center;white-space:nowrap;pointer-events:none}.T2b8IztN89kfzB2OQ5cb.vuSRFAxLIGYWgB9iZgit{font-family:"UbuntuBold",Arial,Helvetica,sans-serif;color:#222;background:#fff;padding:16px;box-shadow:0px 0px 16px 0px rgba(34,36,41,.0784313725);transition:transform .5s ease,opacity .5s ease}.T2b8IztN89kfzB2OQ5cb.PsU6qN0GxBEWhG2iJtgP{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#fff;background-color:rgba(0,0,0,.5);backdrop-filter:blur(4px);padding:8px 16px;border-radius:32px;font-size:14px;line-height:22px}.T2b8IztN89kfzB2OQ5cb.zXQR5YIuwJuY43GVSab3{top:72px}.T2b8IztN89kfzB2OQ5cb.T4GoLXmdwrmG9maE_GyJ{bottom:8px}.T2b8IztN89kfzB2OQ5cb.i0gPvU3jGT5SW9Os0u3f{opacity:1}.T2b8IztN89kfzB2OQ5cb.i0gPvU3jGT5SW9Os0u3f.PsU6qN0GxBEWhG2iJtgP{animation:UGFkd28FPpbdSJQZIIDR var(--toast-duration) ease forwards}.ma2r6m_vIYTx2_cy6dQT{height:20px;width:20px;display:inline-block;margin-right:10px}@keyframes UGFkd28FPpbdSJQZIIDR{0%{opacity:0}15%{opacity:1}85%{opacity:1}100%{opacity:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `T2b8IztN89kfzB2OQ5cb`,
	"light": `vuSRFAxLIGYWgB9iZgit`,
	"dark": `PsU6qN0GxBEWhG2iJtgP`,
	"top": `zXQR5YIuwJuY43GVSab3`,
	"bottom": `T4GoLXmdwrmG9maE_GyJ`,
	"visible": `i0gPvU3jGT5SW9Os0u3f`,
	"fadeInOut": `UGFkd28FPpbdSJQZIIDR`,
	"iconCheck": `ma2r6m_vIYTx2_cy6dQT`
};
export default ___CSS_LOADER_EXPORT___;
