import { addPageAction } from "../../../utils/newrelic"
import { Muted, Unmuted } from "../../common/atoms/Icons/Broadcast"
import { StylelessButton } from "../../common/atoms/StylelessButton"
import { useMobileBroadcastContext } from "../mobileBroadcastContext"
import "./MuteUnmuteButton.scss"

export function MuteUnmuteButton() {
    const { muted, setMute } = useMobileBroadcastContext()
    return (
        <StylelessButton
            className="MuteUnmuteButton"
            onClick={() => {
                const newMuteStatus = !muted
                setMute(newMuteStatus)
                addPageAction("BroadcasterMuteToggled", {
                    muteStatus: newMuteStatus ? "enabled" : "disabled",
                })
            }}
        >
            {muted ? (
                <Muted
                    className="MuteUnmuteButton__VolumeIcon"
                    width="42px"
                    height="42px"
                    data-testid="muted-icon"
                />
            ) : (
                <Unmuted
                    className="MuteUnmuteButton__VolumeIcon"
                    width="42px"
                    height="42px"
                    data-testid="unmuted-icon"
                />
            )}
        </StylelessButton>
    )
}
