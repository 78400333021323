import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgEnvelope = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 168 168"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle cx={84} cy={84} r={84} fill="#8DB4D9" fillOpacity={0.1} />
        <circle cx={84} cy={84} r={74.731} fill="#8DB4D9" fillOpacity={0.1} />
        <circle cx={84} cy={84} r={62.356} fill="#8DB4D9" fillOpacity={0.16} />
        <rect
            width={79.203}
            height={49.282}
            x={38.559}
            y={71.679}
            fill="#F7F7F7"
            stroke="#8DB4D9"
            strokeWidth={3}
            rx={5}
            transform="rotate(-15 38.56 71.679)"
        />
        <path
            stroke="#8DB4D9"
            strokeLinecap="round"
            strokeWidth={3}
            d="m39.957 76.309 42.627 10.407a2 2 0 0 0 1.85-.49l31.863-30.169"
        />
        <path
            fill="#AFCAE4"
            d="M33.118 41.63a.5.5 0 0 1 .816.291l3.135 17.08a.5.5 0 0 1-.66.562L20.05 53.738a.5.5 0 0 1-.156-.852z"
        />
        <path
            fill="#EDF2F7"
            d="M124.693 124.444a.5.5 0 0 1-.321.804l-12.281 1.765a.5.5 0 0 1-.535-.681l4.611-11.518a.5.5 0 0 1 .857-.123zM140.262 97.207a.5.5 0 0 1 .077.862l-4.212 2.961a.5.5 0 0 1-.785-.364l-.459-5.129a.5.5 0 0 1 .709-.498zM63.625 42.167a.5.5 0 0 1-.496.71l-5.059-.434a.5.5 0 0 1-.367-.784l2.905-4.164a.5.5 0 0 1 .863.074zM47.707 61.506a.5.5 0 0 1-.822-.275l-2.316-11.38a.5.5 0 0 1 .649-.574l11.013 3.684a.5.5 0 0 1 .173.849z"
        />
        <path
            fill="#AFCAE4"
            d="M127.256 114.88a.5.5 0 0 1 .184-.846l16.155-5.164a.5.5 0 0 1 .641.583l-3.606 16.572a.5.5 0 0 1-.825.264z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgEnvelope)
const Memo = memo(ForwardRef)
export default Memo
