import { useState } from "react"
import { t, Trans } from "@lingui/macro"
import { createPortal } from "react-dom"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import {
    Button,
    ButtonColor,
    ButtonSize,
    Overlay,
    Typography,
} from "../../common"
import "./V3OnboardingModal.scss"

interface V3OnboardingModalProps {
    isOpen: boolean
    onClose: () => void
    onSave: () => void
    slides: {
        title: string
        description: string
        imgUrl: string
    }[]
}

export const V3OnboardingModal = ({
    isOpen,
    onClose,
    onSave,
    slides,
}: V3OnboardingModalProps) => {
    const [activeSlide, setActiveSlide] = useState(0)

    const handleNext = () => {
        if (activeSlide === slides.length - 1) {
            onSave()
            return
        }
        setActiveSlide(activeSlide + 1)
    }

    const handlePrev = () => {
        if (activeSlide === 0) {
            return
        }
        setActiveSlide(activeSlide - 1)
    }

    if (!isOpen) return null

    return createPortal(
        <>
            <Overlay
                className="V3OnboardingModal__overlay"
                dimmed={true}
                onClick={onClose}
            />
            <div className="V3OnboardingModal__container">
                <img
                    className="V3OnboardingModal__image"
                    src={slides[activeSlide].imgUrl}
                    alt={`Onboarding image ${activeSlide + 1}`}
                />
                <div className="V3OnboardingModal__content">
                    <Typography
                        component="header"
                        size={Sizes.lgpx}
                        weight={Weights.bold}
                        color={colorClass.defaultTooltipColor}
                        className="V3OnboardingModal__header"
                    >
                        {slides[activeSlide].title}
                    </Typography>
                    <Typography
                        component="p"
                        size={Sizes.smpx}
                        weight={Weights.normal}
                        color={colorClass.defaultTooltipColor}
                        className="V3OnboardingModal__description"
                    >
                        {slides[activeSlide].description}
                    </Typography>
                    <footer className="V3OnboardingModal__footer">
                        {activeSlide > 0 && (
                            <Button
                                onClick={handlePrev}
                                className="V3OnboardingModal__btn V3OnboardingModal__btn--prev"
                                text={t`Back`}
                                color={ButtonColor.Link}
                            />
                        )}
                        <Button
                            onClick={handleNext}
                            className="V3OnboardingModal__btn V3OnboardingModal__btn--next"
                            text={
                                activeSlide === slides.length - 1
                                    ? t`Discover`
                                    : t`Next`
                            }
                            size={ButtonSize.Medium}
                            color={ButtonColor.Blue}
                        />
                    </footer>
                </div>
                <Typography
                    component="p"
                    size={Sizes.smpx}
                    weight={Weights.normal}
                    color={colorClass.tagFontColor}
                    className="V3OnboardingModal__skip"
                    onClick={onClose}
                >
                    <Trans>Skip for now</Trans>
                </Typography>
            </div>
        </>,
        document.body,
    )
}
