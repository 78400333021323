import { useEffect, useRef, useState } from "react"
import type React from "react"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { isAndroid } from "@multimediallc/web-utils/modernizr"
import { useUrlStateContext } from "../../../../../../../hooks/urlStateContext"
import { useDebounce } from "../../../../../../../hooks/useDebounce"
import { error } from "../../../../../../../utils/debug"
import { Search } from "../../../../../../common/atoms/Icons/Others"
import "../../filterSearch.scss"
import { useFiltersContext } from "../../../FiltersContext"
import { SearchAutocomplete } from "../../SearchAutocomplete"
import { loadTagsFromSearch } from "../hashtagApiUtils"
import type { IAutocompleteItem } from "../../SearchAutocomplete/SearchAutocomplete"

const MAX_INPUT_LENGTH = 50
const MAX_SHOWN_SUGGESTIONS = 10

export const HashtagSearch: React.FC = () => {
    const { topTags, tagSearchInput, setTagSearchInput } = useFiltersContext()

    const [tagsFromSearch, setTagsFromSearch] = useState<string[]>([])
    const [autocompleteItems, setAutocompleteItems] = useState<
        IAutocompleteItem[]
    >([])
    const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const searchInputElement = useRef<HTMLInputElement>(null)

    const urlStateContext = useUrlStateContext()
    const selectedHashtag = urlStateContext.tags?.[0] ?? ""

    const autoCompleteRef = useRef<HTMLDivElement>(null)

    const uaIsAndroid = isAndroid()

    const getSearchWord = (): string => {
        // Use this to get sanitized input value to be submitted to api or as set hashtag value.
        return tagSearchInput.replace(/#/g, "").toLowerCase()
    }
    const debouncedSearchValue = useDebounce<string>(getSearchWord(), 100)

    useEffect(() => {
        setIsLoading(true)
        loadTagsFromSearch(debouncedSearchValue)
            .then((tags: string[]) => {
                const shownTags = tags
                    .filter((tagFromSearch) => topTags.includes(tagFromSearch))
                    .reverse()
                setTagsFromSearch(shownTags)
            })
            .catch((err) => {
                error(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [topTags, showAutoComplete, debouncedSearchValue, setTagsFromSearch])

    useEffect(() => {
        setAutocompleteItems(
            tagsFromSearch.map((tag) => {
                return { displayName: `#${tag}`, value: tag }
            }),
        )
    }, [tagsFromSearch])

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        // Prevents invalid characters from being typed into search input.

        // Allow # key if it's the 1st char in the search input and no other # key exists
        if (
            e.key === "#" &&
            searchInputElement.current?.selectionStart === 0 &&
            !tagSearchInput.includes("#") // Use includes for simplicity
        ) {
            return
        }

        if (e.key === "-") {
            return
        }

        if (e.key === " " && uaIsAndroid) {
            return
        }

        // Prevent non-alphanumeric characters (excluding allowed ones above)
        // Check specifically for single character keys that aren't alphanumeric
        if (e.key.length === 1 && !/^[a-zA-Z0-9]$/.test(e.key)) {
            e.preventDefault()
        }

        if (e.key === "Enter") {
            e.preventDefault()
            submitSearch()
        }
    }

    const handleInputBlur = () => {
        const finalCleanedValue = cleanSearchInput(tagSearchInput, false)

        if (finalCleanedValue !== tagSearchInput) {
            setTagSearchInput(finalCleanedValue)
        }
    }

    const submitSearch = () => {
        const searchWord = getSearchWord()

        if (searchWord !== "") {
            // Noop when tag is already selected

            if (selectedHashtag === searchWord) {
                return
            } else if (tagsFromSearch.includes(searchWord)) {
                applyTag(searchWord)
            }
        }
    }

    const applyTag = (hashtag: string) => {
        setTagSearchInput("")
        UrlState.current.setPartialState({ tags: [hashtag] })
        setShowAutoComplete(false)
    }

    const handleAutocompleteBlur = (e: MouseEvent) => {
        if (
            !autoCompleteRef.current?.contains(e.target as Node) &&
            !searchInputElement.current?.contains(e.target as Node)
        ) {
            setShowAutoComplete(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleAutocompleteBlur)
        return () => {
            document.removeEventListener("click", handleAutocompleteBlur)
        }
    }, [])

    return (
        <div className="FilterSearch">
            <div className="FilterSearch__input-container">
                <div
                    className="FilterSearch__Button"
                    data-testid="filter-tag-search-button"
                    onClick={() => {
                        submitSearch()
                    }}
                >
                    <Search />
                </div>
                <input
                    className="FilterSearch__Input"
                    data-testid="tag-search-input"
                    placeholder="Search #tags"
                    maxLength={MAX_INPUT_LENGTH}
                    value={tagSearchInput}
                    onChange={(e) => {
                        const cleaned = cleanSearchInput(
                            e.target.value,
                            uaIsAndroid,
                        )
                        if (cleaned !== tagSearchInput) {
                            setTagSearchInput(cleaned)
                        }
                    }}
                    onFocus={() => {
                        setShowAutoComplete(true)
                    }}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    ref={searchInputElement}
                />
            </div>

            {showAutoComplete && (
                <div
                    className="FilterSearch__autocomplete-container"
                    ref={autoCompleteRef}
                >
                    <SearchAutocomplete
                        items={autocompleteItems}
                        onItemClick={applyTag}
                        loading={isLoading}
                        maxShownSuggestions={MAX_SHOWN_SUGGESTIONS}
                    />
                </div>
            )}
        </div>
    )
}

HashtagSearch.displayName = "HashtagSearch"

/**
 * Cleans an input string based on allowed characters.
 * @param input The raw input string.
 * @param allowSpaces Whether to allow spaces (typically for Android during typing).
 * @returns The cleaned string.
 */
export const cleanSearchInput = (
    input: string,
    allowSpaces: boolean = false,
): string => {
    let pattern: RegExp
    if (allowSpaces) {
        // Allow alphanumeric, -, #, and spaces
        pattern = /[^a-zA-Z0-9# -]+/g
    } else {
        // Allow only alphanumeric, -, and # (NO spaces)
        pattern = /[^a-zA-Z0-9#-]+/g
    }

    const cleanValue = input.replace(pattern, "")

    // Only allow # character to be at the beginning, remove others.
    // Ensure max length *after* removing extra hashtags
    const startsWithHashtag = cleanValue.startsWith("#")
    let valueWithoutAnyHashtag = cleanValue.replace(/#/g, "")

    if (
        valueWithoutAnyHashtag.length >
        MAX_INPUT_LENGTH - (startsWithHashtag ? 1 : 0)
    ) {
        valueWithoutAnyHashtag = valueWithoutAnyHashtag.substring(
            0,
            MAX_INPUT_LENGTH - (startsWithHashtag ? 1 : 0),
        )
    }

    return `${startsWithHashtag ? "#" : ""}${valueWithoutAnyHashtag}`
}
