import type { ReactNode } from "react"
import type React from "react"
import { createContext, useContext } from "react"
import { useAppContext } from "../../hooks/appContext"
import {
    useIgnoreUserEffect,
    useTipAlertEffect,
    useTokenUpdateEffect,
    useUserMessageEffect,
    useUserTypingEffect,
} from "../../store/hooks/userHooks"
import type {
    DMPopout,
    DMUnreadData,
    EventRouter,
    EventRouters,
    IIgnoreTopic,
    Interfaces,
    IPushUserTyping,
    ITipAlertTopic,
    Message,
    PushPrivateMessage,
    Thread,
    Topics,
    UserTopic,
    View,
} from "./types"

type MessagingContextType = {
    userMessageTopic: UserTopic<PushPrivateMessage>
    createDmWindowRequest: EventRouter<string>
    view: View
    dmUnreadData: DMUnreadData
    dmPopout: DMPopout
}

const MessagingContext = createContext<MessagingContextType | undefined>(
    undefined,
)

type ProviderProps = {
    children: ReactNode
    topics: Topics
    eventRouters: EventRouters
    view: View
    interfaces: Interfaces
}

export const messageToThread = (
    message: PushPrivateMessage,
    loggedInUsername?: string,
    existingThread?: Thread,
): Thread => {
    const { fromUser, otherUsername } = message
    const isFromCurrentUser = fromUser.username === loggedInUsername

    return {
        m: message.message,
        num_unread: 1,
        created_at: Math.floor(Date.now() / 1000), // match api format
        from_user: fromUser.username,
        other_user: isFromCurrentUser
            ? {
                  username: otherUsername,
                  inFanclub: existingThread?.other_user.inFanclub ?? false,
                  isMod: existingThread?.other_user.isMod ?? false,
                  hasTokens: existingThread?.other_user.hasTokens ?? false,
                  tippedRecently:
                      existingThread?.other_user.tippedRecently ?? false,
                  tippedALotRecently:
                      existingThread?.other_user.tippedALotRecently ?? false,
                  tippedTonsRecently:
                      existingThread?.other_user.tippedTonsRecently ?? false,
              }
            : {
                  username: fromUser.username,
                  inFanclub: fromUser.inFanclub,
                  isMod: fromUser.isMod,
                  hasTokens: fromUser.hasTokens,
                  tippedRecently: fromUser.tippedRecently,
                  tippedALotRecently: fromUser.tippedALotRecently,
                  tippedTonsRecently: fromUser.tippedTonsRecently,
              },
        tip_amount: message?.tipAmount,
    }
}

export const createLogMessage = (
    username: string,
    message: string,
): Message => {
    return {
        i: `${Math.random()}`,
        m: message,
        created_at: Date.now() / 1000,
        media: [],
        other_user: username,
        from_user: {
            username: username,
            inFanclub: false,
            hasTokens: false,
            isMod: false,
            tippedRecently: false,
            tippedALotRecently: false,
            tippedTonsRecently: false,
        },
        is_log_message: true,
    }
}

let userMessageTopic: UserTopic<PushPrivateMessage> | undefined
let userIgnoreTopic: UserTopic<IIgnoreTopic> | undefined
let userTipAlertTopic: UserTopic<ITipAlertTopic> | undefined
let userTypingTopic: UserTopic<IPushUserTyping> | undefined

export const MessagingProvider: React.FC<ProviderProps> = ({
    children,
    topics,
    eventRouters,
    view,
    interfaces,
}) => {
    const { context: appContext } = useAppContext()
    const uuid = appContext.logged_in_user?.user_uid
    userMessageTopic =
        userMessageTopic ?? new topics.UserMessageTopic(uuid || "")
    userIgnoreTopic = userIgnoreTopic ?? new topics.UserIgnoreTopic(uuid || "")
    userTipAlertTopic =
        userTipAlertTopic ?? new topics.UserTipAlertTopic(uuid || "")
    userTypingTopic = userTypingTopic ?? new topics.UserTypingTopic(uuid || "")
    const { createDmWindowRequest } = eventRouters
    const { UserTokenUpdateTopic } = topics

    useTokenUpdateEffect({
        userTokenUpdateTopic: UserTokenUpdateTopic,
        uuid,
    })
    useUserMessageEffect({
        userMessageTopic,
        interfaces,
        uuid,
    })
    useIgnoreUserEffect({
        userIgnoreTopic,
        uuid,
    })
    useTipAlertEffect({
        userTipAlertTopic,
        uuid,
    })
    useUserTypingEffect({
        userTypingTopic,
        uuid,
    })

    return (
        <MessagingContext.Provider
            value={{
                userMessageTopic,
                createDmWindowRequest,
                view,
                dmUnreadData: interfaces.dmUnreadData,
                dmPopout: interfaces.dmPopout,
            }}
        >
            {children}
        </MessagingContext.Provider>
    )
}

export const useMessagingContext = (): MessagingContextType => {
    const context = useContext(MessagingContext)
    if (context === undefined) {
        throw new Error(
            "useMessagingContext must be used within a MessagingProvider",
        )
    }
    return context
}
