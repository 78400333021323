import { useNavigate } from "react-router-dom"
import { ChevronLeftBlue } from "../../../common/atoms/Icons/Chat"
import { useMessagingView } from "../../hooks"
import { classNames } from "../../utils"
import { IconButton } from "../IconButton/IconButton"
import styles from "./Page.module.scss"

type Props = {
    title?: string
    className?: string
    onBack?: () => void
    children: React.ReactNode
}

export const Page: React.FC<Props> = ({
    title,
    className,
    onBack,
    children,
}) => {
    const navigate = useNavigate()
    const messagingView = useMessagingView()
    return (
        <div
            className={classNames(
                styles.page,
                { [styles.mobile]: messagingView.isMobile },
                className,
            )}
        >
            {title && (
                <div className={styles.header}>
                    <IconButton
                        icon={<ChevronLeftBlue height={16} width={10} />}
                        iconAlt="Back"
                        onClick={onBack || (() => navigate(-1))}
                        className={styles.backButton}
                    />
                    <h1 className={styles.title}>{title}</h1>
                </div>
            )}
            {children}
        </div>
    )
}
