import { t } from "@lingui/macro"
import {
    getRoomlistSortCookieValue,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import "./ActiveTagHeader.scss"

interface ActiveTagHeaderProps {
    activeHashtag?: string
    returnToTagsIndex?: boolean
}

export function ActiveTagHeader({
    activeHashtag,
    returnToTagsIndex,
}: ActiveTagHeaderProps) {
    if (activeHashtag === undefined) {
        return null
    }

    // Sort isn't supported on tag pages, but we still want to restore the
    // user's most-recently-chosen sort selection when the tag is cleared
    const sortVal = getRoomlistSortCookieValue()
    const targetHref =
        returnToTagsIndex === true
            ? "/tags/"
            : UrlState.current.getURLForPartialState({
                  tags: undefined,
                  sort: sortVal,
              })

    return (
        <a
            className="ActiveTagHeader"
            href={targetHref}
            data-testid="tag-header-link"
            onClick={(event) => {
                if (!returnToTagsIndex) {
                    event.preventDefault()
                    UrlState.current.navigateTo(event.currentTarget.href)
                }
            }}
        >
            <h1 data-testid="mobile-tag-page-header">
                {t`#${activeHashtag} Cams`}
            </h1>
        </a>
    )
}
