import { error } from "@multimediallc/logging"
import { PartType } from "@multimediallc/web-utils/types"
import { Fragment } from "react/jsx-runtime"
import { MessageParts } from "../MessageParts"
import type {
    IRoomNotice,
    IRoomNoticePart,
} from "@multimediallc/web-utils/types"

import "./RoomNotice.scss"

interface RoomNoticeProps {
    noticeData: IRoomNotice
}

export function RoomNotice({
    noticeData: { messages, shortcodes },
}: RoomNoticeProps) {
    const renderPart = (part: IRoomNoticePart) => {
        switch (part.partType) {
            case PartType.message:
                if (part.message === undefined) {
                    return undefined
                }
                return (
                    <MessageParts
                        message={part.message}
                        shortcodes={shortcodes}
                    />
                )
            case PartType.user:
                // TODO CBD-1977
                return <span>{part.user?.username}</span>
                break
            case PartType.hashtag:
                // TODO CBD-1973
                return <span>{part.message}</span>
            default:
                error(`Invalid RoomNotice type: ${part.partType}`, part)
        }
    }

    return (
        <div className="RoomNotice" data-testid="room-notice">
            {messages.map((parts: IRoomNoticePart[], i: number) => {
                return parts.map((part: IRoomNoticePart, j: number) => {
                    return (
                        <Fragment key={`i${i}j${j}`}>
                            {renderPart(part)}
                        </Fragment>
                    )
                })
            })}
        </div>
    )
}
