import type { ChangeEvent } from "react"
import type React from "react"
import { forwardRef, useCallback, useState } from "react"
import { mergeClasses } from "../../../../utils/css"
import "./Slider.scss"

interface SliderProps extends React.ComponentPropsWithRef<"input"> {
    /**
     * Apply any additional classes in addition to the ones provided by this component
     */
    className?: string

    /**
     * Name of input to aid in retrieving form data
     */
    name?: string

    /**
     * Minimum value for the range slider, default is 0
     */
    minVal?: number

    /**
     * Maxmum value for the range slider, default is 100
     */
    maxVal?: number

    /**
     * Default value for the range slider, default is 50
     */
    defaultVal?: number

    /**
     * Default value for the range slider, default is 50
     */
    handleChangeCallback?: (value: number) => void
}

const defaultProps = {
    minVal: 0,
    maxVal: 100,
    defaultVal: 25,
}

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
    (
        {
            className,
            name,
            minVal = defaultProps.minVal,
            maxVal = defaultProps.maxVal,
            defaultVal = defaultProps.defaultVal,
            handleChangeCallback,
            ...props
        },
        ref,
    ) => {
        const [value, setValue] = useState(defaultVal)

        const handleValueChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>): void => {
                setValue(event.target.valueAsNumber)
                handleChangeCallback?.(event.target.valueAsNumber)
            },
            [handleChangeCallback],
        )

        const valueProps = {
            "--min": minVal,
            "--max": maxVal,
            "--val": value,
        } as React.CSSProperties

        return (
            <div>
                <input
                    type="range"
                    min={minVal}
                    max={maxVal}
                    value={value}
                    style={valueProps}
                    onChange={handleValueChange}
                    className={mergeClasses("Slider", className)}
                    name={name}
                    ref={ref}
                    {...props}
                />
            </div>
        )
    },
)
