import "../../filterSection.scss"
import { t } from "@lingui/macro"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { useUrlStateContext } from "../../../../../../../hooks/urlStateContext"
import { useFiltersContext } from "../../../FiltersContext"
import { FilterSection } from "../../FilterSection"
import { HashtagSearch } from "../HashtagSearch"

export const HashtagSection = () => {
    const { setTagSearchInput } = useFiltersContext()

    const urlStateContext = useUrlStateContext()
    const selectedHashtag = urlStateContext.tags?.[0] ?? ""
    const clearSelections = () => {
        if (selectedHashtag === "") {
            // button should have no effect when no tag is selected
            return
        }
        UrlState.current.setPartialState({ tags: undefined })
        setTagSearchInput("")
    }

    return (
        <FilterSection
            isClearDisabled={selectedHashtag === ""}
            title="Tags"
            onClear={clearSelections}
            clearButtonText="Clear"
        >
            <HashtagSectionContent />
        </FilterSection>
    )
}

export const HashtagSectionContent = ({
    combinedOverlay = false,
}: {
    combinedOverlay?: boolean | undefined
}) => {
    const { topTags, errorTopTags } = useFiltersContext()

    const urlStateContext = useUrlStateContext()
    const selectedHashtag: string = urlStateContext.tags?.[0] ?? ""

    const tagOptions = Array.from(
        new Set([...(selectedHashtag ? [selectedHashtag] : []), ...topTags]),
    )
    return (
        <>
            {tagOptions.length > 16 && <HashtagSearch />}
            <div
                className={`FilterSection__filterOptions${combinedOverlay ? "Combined" : ""} Hashtags`}
            >
                {errorTopTags ? (
                    <div
                        className="filterOptionsStatus--Error"
                        data-testid="top-tags-load-failed"
                    >
                        {t`Failed to load top tags`}
                    </div>
                ) : tagOptions.length === 0 ? (
                    <div className="filterOptionsStatus--Empty">
                        {t`No rooms with hashtags match your filters.`}
                    </div>
                ) : (
                    tagOptions.map((hashtag) => (
                        <button
                            key={hashtag}
                            className={`FilterSection__filterOption ${
                                selectedHashtag === hashtag ? "active" : ""
                            }`}
                            onClick={() =>
                                UrlState.current.setPartialState({
                                    tags:
                                        hashtag === selectedHashtag
                                            ? undefined
                                            : [hashtag],
                                })
                            }
                            data-testid={`hashtag-button-${hashtag}`}
                        >
                            {hashtag}
                        </button>
                    ))
                )}
            </div>
        </>
    )
}
