// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoIxG7X6biL63v3rNzXl{display:flex;width:100%}.hoIxG7X6biL63v3rNzXl .z7q9KmLe7OpXMYXjIwHj{margin:0px;opacity:0;visibility:hidden;width:0;transition:all .5s}.hoIxG7X6biL63v3rNzXl .z7q9KmLe7OpXMYXjIwHj.xSmIW6StJif2ygYtTC2M{opacity:1;visibility:visible;width:24px;margin-right:8px}.hoIxG7X6biL63v3rNzXl .vGdzYAFwsF8IlOq9FKN8{display:flex;align-items:center;flex:1;padding-bottom:4px;position:relative}.hoIxG7X6biL63v3rNzXl .vGdzYAFwsF8IlOq9FKN8>svg{position:absolute;cursor:pointer}.hoIxG7X6biL63v3rNzXl .kGSMWpt2l3sac4jPXoUT{background-color:#f8f9fa;font-size:14px;padding:12px 42px 12px 42px;border:none;border-radius:8px;display:flex;flex:1}.hoIxG7X6biL63v3rNzXl .kGSMWpt2l3sac4jPXoUT::placeholder{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#b3b3b3}.hoIxG7X6biL63v3rNzXl .kGSMWpt2l3sac4jPXoUT:focus{outline-color:#033e58}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"threadSearchInput": `hoIxG7X6biL63v3rNzXl`,
	"threadSearchBack": `z7q9KmLe7OpXMYXjIwHj`,
	"focused": `xSmIW6StJif2ygYtTC2M`,
	"filterInputWrapper": `vGdzYAFwsF8IlOq9FKN8`,
	"filterInput": `kGSMWpt2l3sac4jPXoUT`
};
export default ___CSS_LOADER_EXPORT___;
