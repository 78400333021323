// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FN0JgdhPjneBhopN65H4{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.2);display:flex;justify-content:center;align-items:center;z-index:999}.IJ3qidGJ1edHILYp8S3g{padding:16px 24px 24px;background:#fff;border-radius:8px;border:1px solid #e0e0e0;z-index:1000;position:relative;max-width:400px;width:90%;margin:0 16px}.IJ3qidGJ1edHILYp8S3g .xUsUzxAKZcKrMjGS144T{display:flex;justify-content:space-between;margin-bottom:10px}.IJ3qidGJ1edHILYp8S3g .xUsUzxAKZcKrMjGS144T h2{font-family:"UbuntuBold",Arial,Helvetica,sans-serif;font-size:16px;color:#222}.IJ3qidGJ1edHILYp8S3g .Ti1x5yEQCH2FScnB6gVQ{position:relative;display:flex;align-items:center}.IJ3qidGJ1edHILYp8S3g .Ti1x5yEQCH2FScnB6gVQ input{flex:1;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;border-radius:8px;border:1px solid #b3b3b3;padding:12px 60px 16px 12px;margin-bottom:24px}.IJ3qidGJ1edHILYp8S3g .Ti1x5yEQCH2FScnB6gVQ .H4_S4UFibYZBBQ_7HmF3{position:absolute;right:16px;top:14px;color:#b3b3b3;font-size:14px;pointer-events:none}.IJ3qidGJ1edHILYp8S3g .HfCBGFdluzwMekgB_oHT{cursor:pointer;padding:5px;border:none;height:fit-content;background:none}.IJ3qidGJ1edHILYp8S3g .HfCBGFdluzwMekgB_oHT>img{width:14px;height:14px}.IJ3qidGJ1edHILYp8S3g .UG9Oe8VDsUD3TUzwGhzF button{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;padding:16px;width:100%;background-color:#0a5a83;border-radius:4px;border:none;color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `FN0JgdhPjneBhopN65H4`,
	"modal": `IJ3qidGJ1edHILYp8S3g`,
	"header": `xUsUzxAKZcKrMjGS144T`,
	"inputWrapper": `Ti1x5yEQCH2FScnB6gVQ`,
	"inputSuffix": `H4_S4UFibYZBBQ_7HmF3`,
	"closeBtn": `HfCBGFdluzwMekgB_oHT`,
	"footer": `UG9Oe8VDsUD3TUzwGhzF`
};
export default ___CSS_LOADER_EXPORT___;
