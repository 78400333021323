import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgPricetag = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 14 14"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#033E58"
            d="M13.266 8.5 8.5 13.267a1.3 1.3 0 0 1-.45.3q-.25.1-.5.1t-.5-.1a1.3 1.3 0 0 1-.45-.3L.716 7.383a1.316 1.316 0 0 1-.383-.933V1.667q0-.55.392-.942.391-.392.941-.392H6.45q.266 0 .516.109T7.4.733l5.866 5.884q.2.2.292.45t.092.5-.092.491a1.25 1.25 0 0 1-.292.442M3.333 4.333a.96.96 0 0 0 .708-.291.96.96 0 0 0 .292-.709.96.96 0 0 0-.292-.708.96.96 0 0 0-.708-.292.96.96 0 0 0-.708.292.96.96 0 0 0-.292.708q0 .417.292.709.291.291.708.291"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgPricetag)
const Memo = memo(ForwardRef)
export default Memo
