import type { IUserInfo } from "@multimediallc/web-utils/types"

export type View =
    | "mobile"
    | "desktop_inbox"
    | "desktop_conversation"
    | "desktop_unified"

export interface BoundListener<T> {
    removeListener(): void
    addTo(group: ListenerGroup): void
}

interface ListenerGroup {
    add(boundListener: BoundListener<any>): void
    removeAll(): void
}

export interface EventRouter<T> {
    listen(
        listener: (event: T) => void,
        syncHistory?: boolean,
        fireLast?: boolean,
    ): BoundListener<T>
    addListener(
        listener: (event: T) => void,
        listeningSource: HTMLElement,
    ): void
    once(listener: (event: T) => void, syncHistory?: boolean): BoundListener<T>
    fire(event: T): void
    size(): number
    removeListener(listener: (event: T) => void): void
    listenerCount(): number
    historyLength(): number
}

interface PrivateMessage extends RoomMessage {
    otherUsername: string
    mediaList: ChatMedia[]
    createdAt?: Date
    isFirstHistoryUnread?: boolean
    isPrivate: true
    tipAmount?: number
}

interface RoomMessage {
    message: string
    font?: string
    textColor?: string
    messageID: string
    backgroundColor?: string
    fromUser: IUserInfo
    ts?: number
    tid?: string
    isSpam?: boolean
    isPrivateShowMessage?: boolean
}

interface ChatMedia {
    mediaId: number
    url: string
    thumbnailUrl: string
    opened?: boolean
    imageElement?: HTMLImageElement
    fromUserUID: string
}

export interface TopicMessage {
    tid?: string
    ts?: number
}

export interface PushPrivateMessage extends PrivateMessage, TopicMessage {
    room: string
}

export interface TokenBalanceUpdate extends TopicMessage {
    tokens: number
}

export interface IIgnoreTopic extends TopicMessage {
    username: string
    isIgnored: boolean
}

export interface ITipAlertTopic extends TopicMessage {
    fromUser: IUserInfo
    message: string
    amount: number
    isAnonymousTip: boolean
    ts?: number
    tid?: string
    toUsername?: string
    roomType: RoomType
}

export interface IPushUserTyping extends TopicMessage {
    fromUsername: string
}

export interface Topic<T extends TopicMessage = TopicMessage> {
    getId(): string
    parseData(data: any): T
    initData(props: Record<string, any>): void
    getKey(): string
    onMessage: EventRouter<T>
}

export interface UserTopic<T extends TopicMessage = TopicMessage>
    extends Topic<T> {
    userUid: string
    uidCheck(userUid: string): void
    getAuthData?(): Record<string, any>
}

export interface Topics {
    UserMessageTopic: new (userUid: string) => UserTopic<PushPrivateMessage>
    UserTokenUpdateTopic: new (userUid: string) => UserTopic<TokenBalanceUpdate>
    UserIgnoreTopic: new (userUid: string) => UserTopic<IIgnoreTopic>
    UserTipAlertTopic: new (userUid: string) => UserTopic<ITipAlertTopic>
    UserTypingTopic: new (userUid: string) => UserTopic<IPushUserTyping>
}

export interface EventRouters {
    createDmWindowRequest: EventRouter<string>
}

export interface Thread {
    m: string
    num_unread: number
    created_at: number
    from_user: string
    other_user: IUserInfo
    tip_amount?: number
}

export interface Message {
    media: any[]
    m: string
    i: string
    created_at: number
    other_user: string
    from_user: IUserInfo
    tip_amount?: number
    is_log_message?: boolean
    is_temporary_tip_message?: boolean
}

export interface DMUnreadData {
    getUnreadCount(): number
    addUnreadRecipient(username: string): void
    removeUnreadRecipient(username: string): void
    unreadDataUpdated: EventRouter<{ unread: number }>
}

export interface DMPopout {
    show(username?: string): void
}

export interface Interfaces {
    dmUnreadData: DMUnreadData
    dmPopout: DMPopout
}

export enum TipType {
    public = "public",
    anonymous = "anonymous",
}

export enum RoomType {
    Public = "public",
    Private = "private",
    DM = "sitewidePMs",
}
