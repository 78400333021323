import { t, Trans } from "@lingui/macro"
import { createPortal } from "react-dom"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import {
    ButtonsFloatDirection,
    ButtonSize,
    CancelConfirmButtonPair,
    Overlay,
    Typography,
} from "../../common"
import "./AppDirConfirmationModal.scss"

interface AppDirConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
    onSave: () => void
}

export const AppDirConfirmationModal = ({
    isOpen,
    onClose,
    onSave,
}: AppDirConfirmationModalProps) => {
    if (!isOpen) return null

    return createPortal(
        <>
            <Overlay
                className="AppDirConfirmationModal__overlay"
                dimmed={true}
                onClick={onClose}
            />
            <div className="AppDirConfirmationModal__container">
                <Typography
                    component="header"
                    size={Sizes.mdpx}
                    weight={Weights.bold}
                    color={colorClass.defaultTooltipColor}
                    className="AppDirConfirmationModal__header"
                >
                    <Trans>Are you sure?</Trans>
                </Typography>
                <Typography
                    component="p"
                    size={Sizes.smpx}
                    weight={Weights.normal}
                    color={colorClass.defaultTooltipColor}
                    className="AppDirConfirmationModal__content"
                >
                    <Trans>
                        The page will automatically refresh to update the app
                        directory design preferences. If you’re live, your
                        stream will be interrupted.
                    </Trans>
                </Typography>
                <Typography
                    component="p"
                    size={Sizes.smpx}
                    weight={Weights.normal}
                    color={colorClass.defaultTooltipColor}
                    className="AppDirConfirmationModal__additional-content"
                >
                    <Trans>
                        It is recommended to use the Modern Design, as it will
                        eventually replace the current Legacy Design.
                    </Trans>
                </Typography>
                <footer className="AppDirConfirmationModal__footer">
                    <CancelConfirmButtonPair
                        cancelText={t`Cancel`}
                        actionText={t`Update`}
                        size={ButtonSize.None}
                        floatDirection={ButtonsFloatDirection.Right}
                        cancelClickHandler={onClose}
                        actionClickHandler={onSave}
                        actionProps={{
                            className:
                                "AppDirConfirmationModal__footer-button AppDirConfirmationModal__footer-button--action",
                        }}
                        cancelProps={{
                            className:
                                "AppDirConfirmationModal__footer-button AppDirConfirmationModal__footer-button--cancel",
                        }}
                    />
                </footer>
            </div>
        </>,
        document.body,
    )
}
