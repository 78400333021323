import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgUnmuted = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 42 42"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={42} height={42} fill="#4A4A4A" fillOpacity={0.5} rx={20} />
        <path
            fill="#fff"
            d="M21 24a2.9 2.9 0 0 1-2.125-.875A2.9 2.9 0 0 1 18 21v-6q0-1.25.875-2.125A2.9 2.9 0 0 1 21 12q1.25 0 2.125.875T24 15v6q0 1.25-.875 2.125A2.9 2.9 0 0 1 21 24m-1 7v-3.075q-2.6-.35-4.3-2.325T14 21h2q0 2.075 1.462 3.538Q18.926 26 21 26q2.075 0 3.538-1.462Q26 23.074 26 21h2q0 2.625-1.7 4.6T22 27.925V31zm1-9q.424 0 .712-.288A.97.97 0 0 0 22 21v-6a.97.97 0 0 0-.288-.713A.97.97 0 0 0 21 14a.97.97 0 0 0-.712.287A.97.97 0 0 0 20 15v6q0 .424.288.712.287.288.712.288"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgUnmuted)
const Memo = memo(ForwardRef)
export default Memo
