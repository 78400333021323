import type React from "react"
import { t } from "@lingui/macro"
import { _ } from "../../../i18n"
import { Sizes } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import { CHATURSAFE_CATEGORY_MAPPING } from "../common"
import "./FlaggedChatTooltip.scss"

interface TooltipProps {
    reasons: string[]
}

export const FlaggedChatTooltip: React.FC<TooltipProps> = ({ reasons }) => {
    return (
        <Typography
            className="FlaggedChatTooltip__tooltip"
            component="h2"
            size={Sizes.smpx}
        >
            {t`Your message was not sent due to a potential violation based on creator's ChaturSafe settings:`}
            <ul className="FlaggedChatTooltip__reasons">
                {reasons.map((reason) => (
                    <li key={reason} className="FlaggedChatTooltip__reason">
                        <b className="FlaggedChatTooltip__reason-bold">
                            <>{_(CHATURSAFE_CATEGORY_MAPPING[reason].title)}:</>
                        </b>{" "}
                        <>
                            {_(CHATURSAFE_CATEGORY_MAPPING[reason].description)}
                        </>
                    </li>
                ))}
            </ul>
        </Typography>
    )
}
