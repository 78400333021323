import { useMessagingContext } from "../MessagingContext"

type UseMessagingView = {
    isMobile: boolean
    isDesktopInbox: boolean
    isDesktopConversation: boolean
    isDesktopUnified: boolean
}

export const useMessagingView = (): UseMessagingView => {
    const { view } = useMessagingContext()
    return {
        isMobile: view === "mobile",
        isDesktopInbox: view === "desktop_inbox",
        isDesktopConversation: view === "desktop_conversation",
        isDesktopUnified: view === "desktop_unified",
    }
}
