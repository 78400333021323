import { useAppContext } from "../../../../hooks/appContext"
import { mergeClasses } from "../../../../utils/css"
import { getUsernameColorClass } from "../../../../utils/css/user"
import { useMobileBroadcastContext } from "../../mobileBroadcastContext"
import "./UserMention.scss"

interface UserMentionProps {
    username: string
}

export function UserMention({ username }: UserMentionProps) {
    const { getUserMentionInfo } = useMobileBroadcastContext()
    const {
        context: { logged_in_user },
    } = useAppContext()
    const userMentionInfo = getUserMentionInfo(username)

    const classNames = ["UserMention"]
    if (username === logged_in_user?.username) {
        classNames.push("UserMention--self")
    }
    if (userMentionInfo !== undefined) {
        classNames.push("UserMention--valid")
        classNames.push(getUsernameColorClass(userMentionInfo))
    }

    return (
        <span className={mergeClasses(classNames)} data-testid="user-mention">
            @{username}
        </span>
    )
}
