import type React from "react"
import { t } from "@lingui/macro"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import { InfoIconTooltip } from "../../common/molecules/InfoIconTooltip"
import { CategoryRow } from "../CategoryRow"
import type { CategoryRowData } from "../CategoryRow/CategoryRow"
import "./CategoryTable.scss"

interface TableProps {
    data?: CategoryRowData[]
    handleInputChange: (name: string, value: boolean | number) => void
}

export const CategoryTable = ({ data, handleInputChange }: TableProps) => {
    return (
        <div className="CategoryTable">
            <div className="CategoryTable__headers">
                <Typography
                    component="h2"
                    size={Sizes.smpx}
                    weight={Weights.medium}
                    className="CategoryTable__header flex1"
                >
                    {t`Status`}
                </Typography>
                <Typography
                    component="h2"
                    size={Sizes.smpx}
                    weight={Weights.medium}
                    className="CategoryTable__header flex2"
                >
                    {t`Category`}
                </Typography>
                <div className="CategoryTable__header-slider-container flex2">
                    <div className="CategoryTable__header-slider flex1">
                        <Typography
                            component="h2"
                            size={Sizes.smpx}
                            weight={Weights.medium}
                            className="CategoryTable__header"
                        >
                            {t`Permissive`}
                            <InfoIconTooltip
                                tooltipClassName="CategoryTable__tooltip"
                                strokedIcon={true}
                            >
                                <Typography
                                    component="h2"
                                    size={Sizes.smpx}
                                    className="CategoryTable__tooltip-text"
                                >
                                    {t`This setting allows nearly all content related to the category. Choose this if minimal filtering is preferred.`}
                                </Typography>
                            </InfoIconTooltip>
                        </Typography>
                        <Typography
                            component="h2"
                            size={Sizes.xspx}
                            weight={Weights.normal}
                            className="CategoryTable__header-desc"
                        >
                            {t`Allows most content`}
                        </Typography>
                    </div>
                    <div className="CategoryTable__header-slider flex1">
                        <Typography
                            component="h2"
                            size={Sizes.smpx}
                            weight={Weights.medium}
                            className="CategoryTable__header"
                        >
                            {t`Strict`}
                            <InfoIconTooltip
                                tooltipClassName="CategoryTable__tooltip"
                                strokedIcon={true}
                            >
                                <Typography
                                    component="h2"
                                    size={Sizes.smpx}
                                    className="CategoryTable__tooltip-text"
                                >
                                    {t`This setting blocks almost all content related to the category. Choose this for maximum filtering.`}
                                </Typography>
                            </InfoIconTooltip>
                        </Typography>
                        <Typography
                            component="h2"
                            size={Sizes.xspx}
                            weight={Weights.normal}
                            className="CategoryTable__header-desc"
                        >
                            {t`Blocks most content`}
                        </Typography>
                    </div>
                </div>
            </div>
            {data &&
                data.map((rowData) => (
                    <CategoryRow
                        key={rowData.name}
                        name={rowData.name}
                        enabled={rowData.enabled}
                        value={rowData.value}
                        handleInputChange={handleInputChange}
                    />
                ))}
            <Typography
                component="h2"
                size={Sizes.xspx}
                weight={Weights.normal}
                className="CategoryTable__footer"
            >
                {t`Languages supported: English, Spanish, Portuguese, French, German, Chinese, Japanese, Korean, Dutch, Russian, Hindi, Indonesian, and Arabic. Unsupported languages will still return prediction but, we cannot verify the quality of the predictions.`}
            </Typography>
        </div>
    )
}
