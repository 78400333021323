import React from "react"
import { Plural, t, Trans } from "@lingui/macro"
import "./NoSearchResultsMessage.scss"
import {
    getCategorySlug,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import {
    UrlStateContextProvider,
    useUrlStateContext,
} from "../../../hooks/urlStateContext"
import { getKeywordString } from "../categoryHeaderUtils"

export const enum UsageType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
}

interface SearchResultMessageProps {
    appName?: string
    usageType: UsageType
    matchedCount?: number
    totalCount?: number
    path?: string
}

export function NoSearchResultsMessage(props: SearchResultMessageProps) {
    return (
        <UrlStateContextProvider>
            <NoSearchResultsMessageContents {...props} />
        </UrlStateContextProvider>
    )
}

export function NoSearchResultsMessageContents(
    props: SearchResultMessageProps,
) {
    const { keywords, showType } = useUrlStateContext()

    const isPrivateCamsPage = showType === ShowType.PRIVATE

    const navigateToTarget = (e: React.MouseEvent) => {
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
            return
        }
        e.preventDefault()
        UrlState.current.navigateTo((e.currentTarget as HTMLAnchorElement).href)
    }

    const getCurrentUrlWithoutSearchKeyword = () => {
        return UrlState.current.getURLForPartialState(
            { keywords: undefined },
            false,
        )
    }

    const roomType = (categorySlug: string): string => {
        return (
            {
                "exhibitionist-cams": t`exhibitionist cams`,
                "spy-on-cams": t`private rooms`,
                "hidden-cams": t`community controlled rooms`,
                "new-cams": t`new cams`,
                "gaming-cams": t`gaming cams`,
                "followed-cams": t`followed rooms`,
            }[categorySlug] ?? t`live rooms`
        )
    }

    const getSuggestions = () => {
        return keywordString === "" ? (
            <Trans>
                <li>Remove some of the filter options</li>
                <li>Try different filters</li>
                <li>Clear all filters</li>
            </Trans>
        ) : (
            <Trans>
                <li>Make sure words are spelled correctly</li>
                <li>Try more general keywords</li>
                <li>Try different filters or search terms</li>
            </Trans>
        )
    }

    const keywordString = getKeywordString(keywords)

    const getMessageWithKeywordSearch = () => {
        const quotedKeywordString = `"${keywordString}"`
        const searchQuery =
            props.usageType === UsageType.SECONDARY
                ? roomType("hidden-cams")
                : roomType(getCategorySlug(props.path))
        return (
            <Plural
                value={props.totalCount ?? 0}
                one={
                    <Trans>
                        None of the 1 {searchQuery} matched your search for{" "}
                        <b>{quotedKeywordString}</b>.
                    </Trans>
                }
                other={
                    <Trans>
                        None of the # {searchQuery} matched your search for{" "}
                        <b>{quotedKeywordString}</b>.
                    </Trans>
                }
            />
        )
    }

    const getSearchMessagePrefix = () => {
        if (keywordString !== "") {
            return getMessageWithKeywordSearch()
        } else {
            const query =
                isPrivateCamsPage && props.usageType === UsageType.SECONDARY
                    ? roomType("hidden-cams")
                    : roomType(getCategorySlug(props.path))
            return (
                <Plural
                    value={props.totalCount ?? 0}
                    one={`None of the 1 ${query} matched your filters.`}
                    other={`None of the # ${query} matched your filters.`}
                />
            )
        }
    }

    const getSearchResultMessage = () => {
        return (
            <React.Fragment>
                {getSearchMessagePrefix()}{" "}
                {keywordString !== "" ? (
                    <Trans>
                        Please update your search/filter options, or{" "}
                        <a
                            href={getCurrentUrlWithoutSearchKeyword()}
                            onClick={navigateToTarget}
                        >
                            clear search
                        </a>
                        .
                    </Trans>
                ) : (
                    <Trans> Please update your search/filter options.</Trans>
                )}
            </React.Fragment>
        )
    }

    const isOfflineFollowedPage = showType === ShowType.FOLLOW_OFFLINE

    return (
        <div
            className={`NoSearchResultsMessage${props.matchedCount === 0 ? "" : " NoSearchResultsMessage__hidden"}`}
        >
            <div data-testid="no-results-messsage">
                <b>
                    <Trans>No Results Found</Trans>
                </b>
                {!isOfflineFollowedPage && (
                    <React.Fragment>
                        {" "}
                        - {getSearchResultMessage()}
                    </React.Fragment>
                )}
            </div>
            {!isOfflineFollowedPage && (
                <div
                    className="NoSearchResultsMessage__suggestions"
                    data-testid="suggestions"
                >
                    <div className="NoSearchResultsMessage__suggestions-header">{t`Suggestions:`}</div>
                    <ul className="NoSearchResultsMessage__suggestions-list">
                        {getSuggestions()}
                    </ul>
                </div>
            )}
        </div>
    )
}
