// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EMmht11O3A68trwL1McD{display:inline-flex;flex-direction:column;max-width:100%}.EMmht11O3A68trwL1McD.SAFIJc5eLYoFwZs9oEba .znlPzZXacwZtlEdHtiE3{font-size:12px}.EMmht11O3A68trwL1McD.SAFIJc5eLYoFwZs9oEba .kNn9g4GEqVn7xdA3v2id{font-size:12px;padding:8px 12px}.EMmht11O3A68trwL1McD.SAFIJc5eLYoFwZs9oEba .mVrxvTFjBf9z2hkxJgll{padding:8px 12px;line-height:14px}.mVrxvTFjBf9z2hkxJgll{display:flex;align-items:center;vertical-align:middle;gap:8px;line-height:18px;background-color:#ff3;padding:12px}.Xq6SuEVe5kFLJ7xfcW6S.G87H56TKoiWdVAf0Eoi8{border-radius:16px 8px 8px 16px}.Xq6SuEVe5kFLJ7xfcW6S.lMGEjgbTJeIhDVxFDk4v{border-radius:8px 16px 16px 8px}.CCidEv9E9GuAaJ4y6tMG.G87H56TKoiWdVAf0Eoi8{border-radius:16px 8px 0px 0px}.CCidEv9E9GuAaJ4y6tMG.lMGEjgbTJeIhDVxFDk4v{border-radius:8px 16px 0px 0px}.znlPzZXacwZtlEdHtiE3{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;font-size:16px;color:#000}.xxYfY3QYDeYywef37WoF{margin:8px 0;height:1px;background-color:#6e6f70;opacity:.2}.kNn9g4GEqVn7xdA3v2id{display:block;font-weight:normal;padding:12px;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;font-size:16px;word-break:break-word}.kNn9g4GEqVn7xdA3v2id.TEOuAHCBTWE3GpQCgrWQ{background-color:#0c6a93;color:#fff;border-radius:0px 0px 8px 16px}.kNn9g4GEqVn7xdA3v2id.pUs7gZZqPMOLYvW_uiPu{background-color:#e0e0e0;color:#000;border-radius:0px 0px 16px 8px}.noMWTzqpPTPLvzYl5AjJ{color:#000}.R0upGVONsw7A61Ol5JPg{white-space:pre-line}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tipMessage": `EMmht11O3A68trwL1McD`,
	"desktop": `SAFIJc5eLYoFwZs9oEba`,
	"tipText": `znlPzZXacwZtlEdHtiE3`,
	"message": `kNn9g4GEqVn7xdA3v2id`,
	"iconAndTipText": `mVrxvTFjBf9z2hkxJgll`,
	"roundedAll": `Xq6SuEVe5kFLJ7xfcW6S`,
	"currentUser": `G87H56TKoiWdVAf0Eoi8`,
	"otherUser": `lMGEjgbTJeIhDVxFDk4v`,
	"roundedTop": `CCidEv9E9GuAaJ4y6tMG`,
	"divider": `xxYfY3QYDeYywef37WoF`,
	"currentUserMessage": `TEOuAHCBTWE3GpQCgrWQ`,
	"otherUserMessage": `pUs7gZZqPMOLYvW_uiPu`,
	"tokensBlack": `noMWTzqpPTPLvzYl5AjJ`,
	"messageText": `R0upGVONsw7A61Ol5JPg`
};
export default ___CSS_LOADER_EXPORT___;
