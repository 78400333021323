import type { ReactNode } from "react"
import { useState } from "react"
import { t } from "@lingui/macro"
import { error } from "@multimediallc/logging"
import { useParams } from "react-router-dom"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { useAppDispatch } from "../../../store/hooks"
import {
    useGetIgnoredUsersQuery,
    useIgnoreMutation,
    useUnignoreMutation,
} from "../../../store/ignoredUsersSlice"
import {
    removeThread,
    useDeleteConversationMutation,
} from "../../../store/messagingSlice"
import {
    CloseBlue,
    EditNote,
    EllipsisVertical,
    Ignore,
    MinimizeBlue,
    Mute,
    Profile,
    Report,
    Trash,
} from "../../common/atoms/Icons/Chat"
import { ConfirmModal } from "../common/ConfirmModal/ConfirmModal"
import { IconButton } from "../common/IconButton/IconButton"
import { UnreadBadge } from "../common/UnreadBadge/UnreadBadge"
import { FollowStar } from "../FollowStar/FollowStar"
import { useMessagingView } from "../hooks"
import { isMsgFutureRlsActive } from "../toggleUtils"
import { classNames } from "../utils"
import styles from "./MessageHeaderMenu.module.scss"

type MenuItem = {
    icon: ReactNode
    text: string
    onClick: () => void
    className?: string
}

const MenuItem = ({ icon, text, onClick, className, ...props }: MenuItem) => (
    <div
        onClick={onClick}
        className={classNames(styles.menuItem, {
            [styles.red]: Boolean(className?.includes("red")),
            [styles.gray]: Boolean(className?.includes("gray")),
            [styles.report]: Boolean(className?.includes("report")),
        })}
        {...props}
    >
        {icon}
        <span>{text}</span>
    </div>
)

export function MessageHeaderMenu({
    onClose,
    onMinimize,
    isMinimized,
    onConversationDeleted,
    onReportClick,
    isReportEnabled,
    onNoteClick,
    numUnread = 0,
}: {
    onClose: () => void
    onMinimize: () => void
    isMinimized: boolean
    onConversationDeleted: () => void
    onReportClick: () => void
    isReportEnabled: boolean
    onNoteClick: () => void
    numUnread?: number
}) {
    const dispatch = useAppDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const ref = useOutsideClick(() => setIsOpen(false))
    const messagingView = useMessagingView()
    const { username = "" } = useParams<{ username: string }>()
    const [ignore] = useIgnoreMutation()
    const [deleteConversation] = useDeleteConversationMutation()
    const [unignore] = useUnignoreMutation()
    const { data: ignoredUsersData } = useGetIgnoredUsersQuery()
    const isIgnored = ignoredUsersData?.users.includes(username)
    const isFutureRlsEnabled = isMsgFutureRlsActive()

    const toggleMenu = () => {
        setIsOpen((prev) => !prev)
    }

    const onIgnoreMenuClick = async (): Promise<void> => {
        setIsOpen(false)

        if (isIgnored) {
            try {
                await unignore({ username })
            } catch (e) {
                error("Failed to unignore user:", e)
            }
        } else {
            try {
                await ignore({ username })
            } catch (e) {
                error("Failed to ignore user:", e)
            }
        }
    }

    const onDeleteConversationClick = () => {
        setShowDeleteModal(true)
    }

    const handleConfirmDelete = async (): Promise<void> => {
        setShowDeleteModal(false)
        setIsOpen(false)

        try {
            await deleteConversation({ to_username: username })
            dispatch(removeThread({ username }))
            onConversationDeleted()
        } catch (e) {
            error("Failed to delete conversation:", e)
        }
    }

    return (
        <div
            ref={ref}
            className={styles.container}
            data-testid="message-header-menu"
        >
            <div className={styles.iconsContainer}>
                {!isMinimized && (
                    <>
                        <FollowStar username={username} />
                        <IconButton
                            icon={
                                <EllipsisVertical
                                    height={20}
                                    width={20}
                                    color="#222429"
                                />
                            }
                            iconAlt="Menu"
                            onClick={toggleMenu}
                            className={classNames(styles.menuButton, {
                                [styles.open]: isOpen,
                                [styles.desktop]:
                                    messagingView.isDesktopConversation,
                            })}
                            data-testid="message-header-menu-button"
                        />
                    </>
                )}
                {messagingView.isDesktopConversation && (
                    <>
                        {!isMinimized && (
                            <IconButton
                                icon={<MinimizeBlue width={15} height={20} />}
                                iconAlt={t`Minimize`}
                                onClick={onMinimize}
                                data-testid="minimize-button"
                            />
                        )}
                        {isMinimized && numUnread > 0 && (
                            <UnreadBadge
                                count={numUnread}
                                maxCount={9}
                                sizing="static"
                                className={styles.headerBadge}
                            />
                        )}
                        <IconButton
                            icon={<CloseBlue width={24} height={24} />}
                            iconAlt={t`Close`}
                            onClick={(e) => {
                                e.stopPropagation()
                                onClose()
                            }}
                            data-testid="close-button"
                        />
                    </>
                )}
            </div>
            {isOpen && !isMinimized && (
                <div
                    className={styles.menu}
                    data-testid="message-header-menu-items"
                >
                    <MenuItem
                        icon={<Profile height={20} width={20} />}
                        text={t`View Profile`}
                        onClick={() => {
                            setIsOpen(false)
                            const profileUrl = `/${username}/`
                            if (messagingView.isDesktopConversation) {
                                window.open(profileUrl, "_blank")
                            } else {
                                window.location.href = profileUrl
                            }
                        }}
                        data-testid="view-profile-menu-item"
                    />
                    {isFutureRlsEnabled && (
                        <MenuItem
                            icon={<Mute height={20} width={20} />}
                            text={t`Mute`}
                            onClick={() => {}}
                            data-testid="mute-menu-item"
                        />
                    )}
                    <MenuItem
                        icon={<EditNote height={20} width={20} />}
                        text={t`Note`}
                        onClick={() => {
                            setIsOpen(false)
                            onNoteClick()
                        }}
                        data-testid="note-menu-item"
                    />
                    <div className={styles.divider} />
                    <MenuItem
                        icon={<Ignore height={20} width={20} />}
                        text={isIgnored ? t`Unignore User` : t`Ignore User`}
                        onClick={onIgnoreMenuClick} // eslint-disable-line @typescript-eslint/no-misused-promises
                        data-testid="ignore-user-menu-item"
                    />
                    {isFutureRlsEnabled && (
                        <MenuItem
                            icon={<Trash height={20} width={20} />}
                            text={t`Delete Conversation`}
                            onClick={onDeleteConversationClick}
                            className="red"
                            data-testid="delete-menu-item"
                        />
                    )}
                    <MenuItem
                        icon={<Report />}
                        text={t`Report`}
                        onClick={() => {
                            if (!isReportEnabled) {
                                // Do nothing if there is no message to report
                                return
                            }
                            setIsOpen(false)
                            onReportClick()
                        }}
                        data-testid="report-menu-item"
                        className={`report ${isReportEnabled ? "red" : "gray"}`}
                    />
                </div>
            )}
            <ConfirmModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
                title={t`Are you sure you want to delete this chat?`}
            />
        </div>
    )
}
