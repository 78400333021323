// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes cAOMoe7DpyiFP70496eA{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes zYyNrBnqtDIOzDCyPM62{0%,100%{fill:#000;stroke:#fff}50%{fill:#ff0;stroke:#000}}.Aig8u_sqP3thU_2z4mqQ{display:inline-flex;cursor:pointer;-webkit-tap-highlight-color:rgba(0,0,0,0)}.Aig8u_sqP3thU_2z4mqQ svg{transform-origin:center}.Aig8u_sqP3thU_2z4mqQ svg path{fill:#000;stroke:#fff;opacity:.5}.Aig8u_sqP3thU_2z4mqQ.Et1NMiLLM48YM7XhNrz6 svg path{fill:#ff0;stroke:#000}.Aig8u_sqP3thU_2z4mqQ.uywc3rNwhquEgq3NUeVk{pointer-events:none}.Aig8u_sqP3thU_2z4mqQ.uywc3rNwhquEgq3NUeVk svg{animation:cAOMoe7DpyiFP70496eA 2s linear infinite}.Aig8u_sqP3thU_2z4mqQ.uywc3rNwhquEgq3NUeVk svg path{animation:zYyNrBnqtDIOzDCyPM62 2s linear infinite}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"followStar": `Aig8u_sqP3thU_2z4mqQ`,
	"following": `Et1NMiLLM48YM7XhNrz6`,
	"updating": `uywc3rNwhquEgq3NUeVk`,
	"spin": `cAOMoe7DpyiFP70496eA`,
	"colorTransition": `zYyNrBnqtDIOzDCyPM62`
};
export default ___CSS_LOADER_EXPORT___;
