// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QDJ0C3RbK3SWa1v6opgF{flex:1;display:flex;flex-direction:column;position:relative;min-height:0}.vilQQhbxFvTEy5kPvaz2{flex:1;overflow-y:auto;position:relative}.pKaFj3RvcPyasuhsdbJD{text-align:center;padding:16px;display:flex;justify-content:center;align-items:center}.pKaFj3RvcPyasuhsdbJD .cbr-spinner{width:16px;height:16px}.pKaFj3RvcPyasuhsdbJD.Xdxo3T2bVOW2viiTyZqO{padding:0px}.nVYID5MAX721bV8Oh7HK{padding:20px;text-align:center;color:#666;font-size:16px;display:flex;flex-direction:column;flex:1}.nVYID5MAX721bV8Oh7HK.UEJnxrdkZj_savXWfimE{font-size:14px}.AaGRfVL6Id_kSmy9x0GP{display:flex;justify-content:center;align-items:center;flex-direction:column;flex:1}.AaGRfVL6Id_kSmy9x0GP .Z6IqACMqGVzYJfsMKjQb{margin-top:10px;color:#033e58;margin-bottom:8px}.AaGRfVL6Id_kSmy9x0GP .MsFHkNkg6zJAMbNbYJBr{color:#a7a7a7;line-height:16px}.Pc7XZD4MeYBiMIgrLwaQ{width:100%}.Pc7XZD4MeYBiMIgrLwaQ.ButtonSize-medium{padding:16px;outline:none}.MrL3lXyYMltpCy90k0ny{padding:20px;text-align:center;color:#666;font-size:16px;display:flex;flex-direction:row;flex:1;justify-content:center;align-items:flex-start;gap:8px}.MrL3lXyYMltpCy90k0ny.UEJnxrdkZj_savXWfimE{font-size:14px}.nqnOo1vG8FlV1fPlisx1{padding:10px 16px;color:#6e6f70;font-size:16px;display:flex;justify-content:center;justify-content:flex-start}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"threadListContent": `QDJ0C3RbK3SWa1v6opgF`,
	"threadListScrollable": `vilQQhbxFvTEy5kPvaz2`,
	"loadingIndicator": `pKaFj3RvcPyasuhsdbJD`,
	"filterLoading": `Xdxo3T2bVOW2viiTyZqO`,
	"threadListMessage": `nVYID5MAX721bV8Oh7HK`,
	"mobile": `UEJnxrdkZj_savXWfimE`,
	"noMessagesContainer": `AaGRfVL6Id_kSmy9x0GP`,
	"title": `Z6IqACMqGVzYJfsMKjQb`,
	"message": `MsFHkNkg6zJAMbNbYJBr`,
	"button": `Pc7XZD4MeYBiMIgrLwaQ`,
	"loadingMessage": `MrL3lXyYMltpCy90k0ny`,
	"noConversations": `nqnOo1vG8FlV1fPlisx1`
};
export default ___CSS_LOADER_EXPORT___;
