import { CloseBlue, MagnifyingGlass } from "../../../common/atoms/Icons/Chat"
import { classNames } from "../../utils"
import styles from "./SearchInput.module.scss"

type Props = {
    className?: string
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>
    onClear?: () => void
}

export function SearchInput({ className, inputProps, onClear }: Props) {
    const sanitizeUsername = (value: string) =>
        value.replace(/[^a-zA-Z0-9_]/g, "")

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const sanitizedValue = sanitizeUsername(e.target.value)
        e.target.value = sanitizedValue
        inputProps?.onChange?.(e)
    }

    return (
        <div className={classNames(styles.filterInputWrapper, className)}>
            <MagnifyingGlass />
            <input
                type="text"
                className={classNames(styles.filterInput, {
                    [styles.hasClear]: !!onClear,
                })}
                {...inputProps}
                onChange={handleChange}
            />
            {onClear && (
                <span className={styles.clearButton} onClick={onClear}>
                    <CloseBlue height={24} width={24} />
                </span>
            )}
        </div>
    )
}
