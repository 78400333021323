import { t } from "@lingui/macro"
import { shouldShowHomepageFilters } from "@multimediallc/cb-roomlist-prefetch"
import { useAppContext } from "../../../../hooks/appContext"
import "./MobileCategoryHeader.scss"

interface MobileCategoryHeaderProps {
    categorySlug: string
}

export function MobileCategoryHeader({
    categorySlug,
}: MobileCategoryHeaderProps) {
    const headerText = getHeaderText(categorySlug)
    const siteName = useAppContext().context.site_name
    const isSpyOrHiddenCams =
        categorySlug === "spy-on-cams" || categorySlug === "hidden-cams"
    if (headerText.length === 0) {
        return null // Nothing to render for this category
    }
    if (shouldShowHomepageFilters() && !isSpyOrHiddenCams) {
        return null // do not show header when filter panel is active except on spy or hidden
    }
    if (isSpyOrHiddenCams) {
        return (
            <div className="MobileCategoryHeader">
                <h1>{headerText}</h1>
                <p>{getDescriptionText(categorySlug, siteName)}</p>
            </div>
        )
    }
    return (
        <div className="MobileCategoryHeader">
            <h2>{headerText}</h2>
        </div>
    )
}

const getHeaderText = (categorySlug: string): string =>
    ({
        "north-american-cams": t`North American Cams`,
        "south-american-cams": t`South American Cams`,
        "asian-cams": t`Asian Cams`,
        "euro-russian-cams": t`Euro Russian Cams`,
        "other-region-cams": t`Other Region Cams`,
        "teen-cams": t`Teen Cams (18+)`,
        "18to20-cams": t`18 to 20 Cams`,
        "18to21-cams": t`18 to 21 Cams`,
        "20to30-cams": t`20 to 30 Cams`,
        "30to50-cams": t`30 to 50 Cams`,
        "21to35-cams": t`21 to 35 Cams`,
        "mature-cams": t`Mature Cams (50+)`,
        "exhibitionist-cams": t`Exhibitionist Cams`,
        "spy-on-cams": t`Private Shows`,
        "hidden-cams": t`Community Controlled Shows`,
        "new-cams": t`New Cams`,
        "gaming-cams": t`Gaming Cams`,
        "6-tokens-per-minute-private-cams": t`6 Tokens per Minute`,
        "12-tokens-per-minute-private-cams": t`12 Tokens per Minute`,
        "18-tokens-per-minute-private-cams": t`18 Tokens per Minute`,
        "30-tokens-per-minute-private-cams": t`30+ Tokens per Minute`,
        "60-tokens-per-minute-private-cams": t`60+ Tokens per Minute`,
        "12-18-tokens-per-minute-private-cams": t`12 to 18 Tokens per Minute`,
        "30-42-tokens-per-minute-private-cams": t`30 to 42 Tokens per Minute`,
        "60-72-tokens-per-minute-private-cams": t`60 to 72 Tokens per Minute`,
        "90-tokens-per-minute-private-cams": t`90+ Tokens per Minute`,
    })[categorySlug] ?? ""

const getDescriptionText = (categorySlug: string, siteName: string): string => {
    if (categorySlug === "spy-on-cams") {
        return t`Spy on private shows to experience the most exclusive shows on ${siteName}. You won't get to interact, but it's much cheaper than buying a private show yourself.`
    } else if (categorySlug === "hidden-cams") {
        return t`Community controlled cams are using an app which has the ability to hide and show the cam based on viewers feedback.`
    }
    return ""
}
