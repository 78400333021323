import type { ComponentPropsWithoutRef } from "react"
import type React from "react"
import { createPortal } from "react-dom"
import { Button, ButtonColor, Overlay, Typography } from "../.."
import { Weights } from "../../../../interfaces/sizing"
import { colorClass } from "../../../../utils/css"
import { CloseButton } from "../../atoms/CloseButton"
import "./MobileTooltip.scss"

export interface MobileTooltipProps extends ComponentPropsWithoutRef<"div"> {
    title?: string
    contentDiv: JSX.Element | React.ReactNode
    buttonTitle?: string
    buttonColor?: ButtonColor
    buttonAction?: () => void
    onClose: () => void
}

export function MobileTooltip({
    title,
    contentDiv,
    buttonTitle,
    buttonColor = ButtonColor.Red,
    buttonAction,
    onClose,
    ...props
}: MobileTooltipProps) {
    return (
        <>
            <Overlay onClick={onClose} className="MobileTooltip__overlay" />
            {createPortal(
                // need createPortal to break out of purechat canvas z-index
                <div
                    className="MobileTooltip__overlay__body"
                    onClick={(e) => e.stopPropagation()}
                    {...props}
                >
                    <div className="MobileTooltip__header">
                        {title && (
                            <Typography
                                component="h2"
                                className="MobileTooltip__title"
                                weight={Weights.medium}
                                color={colorClass.defaultTooltipColor}
                            >
                                {title}
                            </Typography>
                        )}
                        <CloseButton
                            className="MobileTooltip__close"
                            onClick={onClose}
                            size="14"
                        />
                    </div>

                    <div className="MobileTooltip__body">
                        {contentDiv}
                        {buttonTitle && (
                            <Button
                                className="MobileTooltip__delete"
                                text={buttonTitle}
                                color={buttonColor}
                                onClick={buttonAction}
                            />
                        )}
                    </div>
                </div>,
                document.body,
            )}
        </>
    )
}
