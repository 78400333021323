export type DraftMessage = {
    message: string | null
    timestamp: number
}

const cache = new Map<string, DraftMessage>()

export const draftCache = {
    set: (otherUsername: string, draft: DraftMessage): void => {
        if (!otherUsername) return
        cache.set(otherUsername, draft)
    },
    get: (otherUsername: string): DraftMessage | null => {
        if (!otherUsername) return null
        return cache.get(otherUsername) || null
    },
    getAll: (): Map<string, DraftMessage> => {
        return cache
    },
    clear: (otherUsername: string): void => {
        if (!otherUsername) return
        cache.delete(otherUsername)
    },
    clearAll: (): void => {
        cache.clear()
    },
}
