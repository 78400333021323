import type { ChangeEvent } from "react"
import React from "react"
import { t } from "@lingui/macro"
import { useDebounce } from "../../../hooks/useDebounce"
import { _ } from "../../../i18n"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import { error } from "../../../utils/debug"
import { fetchPost } from "../../../utils/myFetch"
import { Button, ButtonColor, Textarea, Typography } from "../../common"
import { Refresh } from "../../common/atoms/Icons/Others"
import { CHATURSAFE_CATEGORY_MAPPING } from "../common"
import type { IChatursafeFilterSettings } from "@multimediallc/web-utils/types"
import "./FilterPreview.scss"

// This safety margin is part of the PRD, while we gain confidence in the model.
// It is used here for the Filter Preview calls, since we do the eval comparisons
// on the frontend for those preview messages.
// It is replicated in the backend for the "real" message evaluations.
const CHATURSAFE_SAFETY_MARGIN = 0.05

export enum PreviewStatus {
    empty = "empty",
    analyzing = "analyzing",
    accepted = "accepted",
    rejected = "rejected",
    error = "error",
}

interface PreviewModalProps {
    currentSettings: IChatursafeFilterSettings
    defaultText?: string
}

interface categoryRowData {
    toxic: number
    firearms_weapons: number
    public_safety: number
    poaching: number
    derogatory: number
    violent: number
    sexual: number
    insult: number
    profanity: number
    death_harm_tragedy: number
    health: number
    religion_belief: number
    illicit_drugs: number
    war_conflict: number
    legal: number
    finance: number
    politics: number
}

interface IPreviewFilterState {
    status: PreviewStatus
    rejectedReasons: string[]
}

interface IPreviewFilterAction {
    type: PreviewFilterActionTypes
    rejectedReasons: string[]
}

enum PreviewFilterActionTypes {
    setEmpty = "setEmpty",
    setAnalyzing = "setAnalyzing",
    updateReasons = "updateReasons",
    setError = "setError",
}

function previewFilterReducer(
    state: IPreviewFilterState,
    action: IPreviewFilterAction,
): IPreviewFilterState {
    switch (action.type) {
        case PreviewFilterActionTypes.setEmpty:
            return {
                status: PreviewStatus.empty,
                rejectedReasons: [],
            }
        case PreviewFilterActionTypes.setAnalyzing:
            return {
                status: PreviewStatus.analyzing,
                rejectedReasons: [],
            }
        case PreviewFilterActionTypes.updateReasons:
            if (action.rejectedReasons.length === 0) {
                return {
                    status: PreviewStatus.accepted,
                    rejectedReasons: [],
                }
            } else {
                return {
                    status: PreviewStatus.rejected,
                    rejectedReasons: action.rejectedReasons ?? [],
                }
            }
        case PreviewFilterActionTypes.setError:
            return {
                status: PreviewStatus.error,
                rejectedReasons: [],
            }
        default:
            error("Invalid action type")
            return {
                status: PreviewStatus.error,
                rejectedReasons: [],
            }
    }
}

function parseRejectedReasonsFromData(
    flaggedData: categoryRowData,
    formSettings: IChatursafeFilterSettings,
): string[] {
    const flagged = []

    for (const [category, value] of Object.entries(flaggedData)) {
        const enabled_key = CHATURSAFE_CATEGORY_MAPPING[category].enabled_key
        const value_key = CHATURSAFE_CATEGORY_MAPPING[category].value_key

        if (
            (formSettings[enabled_key] as boolean) &&
            value >
                1 -
                    (formSettings[value_key] as number) -
                    CHATURSAFE_SAFETY_MARGIN
        ) {
            flagged.push(category)
        }
    }
    return flagged
}

export const FilterPreview: React.FC<PreviewModalProps> = ({
    currentSettings,
    defaultText = "",
}: PreviewModalProps) => {
    const [previewText, setPreviewText] = React.useState(defaultText)
    const debouncedText = useDebounce(previewText, 100)
    const flaggedData = React.useRef({} as categoryRowData)
    const formSettings = React.useRef(currentSettings)
    const [previewFilter, setPreviewFilter] = React.useReducer(
        previewFilterReducer,
        { status: PreviewStatus.analyzing, rejectedReasons: [] },
    )

    const onTextChange = (value: string) => {
        if (debouncedText === value) {
            return
        }
        setPreviewText(value)
        if (value.length > 0) {
            setPreviewFilter({
                type: PreviewFilterActionTypes.setAnalyzing,
                rejectedReasons: [],
            })
        } else {
            setPreviewFilter({
                type: PreviewFilterActionTypes.setEmpty,
                rejectedReasons: [],
            })
        }
    }

    const getPreviewFilter = React.useCallback(async (newText: string) => {
        if (newText === "") {
            return
        }
        try {
            const res = await fetchPost("/api/ts/chatursafe/filter-preview/", {
                previewText: newText,
            })
            if (res.status === 200) {
                flaggedData.current = res.jsonData as categoryRowData
                const reasons = parseRejectedReasonsFromData(
                    flaggedData.current,
                    formSettings.current,
                )
                setPreviewFilter({
                    type: PreviewFilterActionTypes.updateReasons,
                    rejectedReasons: reasons,
                })
            } else {
                setPreviewFilter({
                    type: PreviewFilterActionTypes.setError,
                    rejectedReasons: [],
                })
            }
        } catch (err) {
            setPreviewFilter({
                type: PreviewFilterActionTypes.setError,
                rejectedReasons: [],
            })
            error(err)
        }
    }, [])

    React.useEffect(() => {
        void getPreviewFilter(debouncedText)
    }, [debouncedText, getPreviewFilter])

    React.useEffect(() => {
        formSettings.current = currentSettings
        const reasons = parseRejectedReasonsFromData(
            flaggedData.current,
            formSettings.current,
        )
        setPreviewFilter({
            type: PreviewFilterActionTypes.updateReasons,
            rejectedReasons: reasons,
        })
    }, [currentSettings, parseRejectedReasonsFromData])

    return (
        <div className="FilterPreview">
            <Typography
                component="h2"
                size={Sizes.mdpx}
                weight={Weights.bold}
                color={colorClass.defaultTooltipColor}
            >
                {t`Filter Preview`}
            </Typography>
            <Textarea
                placeholder={t`Type something...`}
                className="FilterPreview__box"
                rows={6}
                maxLength={300}
                value={previewText}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    onTextChange(e.target.value)
                }
            />
            {previewText.length === 0 && (
                <Typography
                    component="h2"
                    size={Sizes.smpx}
                    className="FilterPreview__info"
                >
                    {t`Type something above to test filtering with current settings.`}
                </Typography>
            )}
            {previewText.length > 0 &&
                previewFilter.status === PreviewStatus.analyzing && (
                    <Typography
                        component="h2"
                        size={Sizes.smpx}
                        className={`FilterPreview__${previewFilter.status}`}
                    >
                        {t`Analyzing...`}
                    </Typography>
                )}
            {previewText.length > 0 &&
                previewFilter.status === PreviewStatus.accepted && (
                    <>
                        <Typography
                            component="h2"
                            size={Sizes.smpx}
                            className={`FilterPreview__${previewFilter.status}`}
                        >
                            {t`This message will not be filtered.`}
                        </Typography>
                        <Button
                            color={ButtonColor.Link}
                            icon={<Refresh />}
                            text={t`Clear`}
                            className="FilterPreview__refresh"
                            onClick={() => onTextChange("")}
                        />
                    </>
                )}
            {previewText.length > 0 &&
                previewFilter.status === PreviewStatus.rejected &&
                previewFilter.rejectedReasons.length > 0 && (
                    <>
                        <Typography
                            component="h2"
                            size={Sizes.smpx}
                            className={`FilterPreview__${previewFilter.status}`}
                        >
                            {t`This message will be filtered due to:`}
                            <ul className="FilterPreview__reasons">
                                {previewFilter.rejectedReasons.map((reason) => (
                                    <li
                                        key={reason}
                                        className="FilterPreview__reason"
                                    >
                                        {_(
                                            CHATURSAFE_CATEGORY_MAPPING[reason]
                                                .title,
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </Typography>
                        <Button
                            color={ButtonColor.Link}
                            icon={<Refresh />}
                            text={t`Clear`}
                            className="FilterPreview__refresh"
                            onClick={() => onTextChange("")}
                        />
                    </>
                )}
            {previewText.length > 0 &&
                previewFilter.status === PreviewStatus.error && (
                    <>
                        <Typography
                            component="h2"
                            size={Sizes.smpx}
                            className={`FilterPreview__${previewFilter.status}`}
                        >
                            {t`Error occurred while analyzing the message. Please try again.`}
                        </Typography>
                        <Button
                            color={ButtonColor.Link}
                            icon={<Refresh />}
                            text={t`Clear`}
                            className="FilterPreview__refresh"
                            onClick={() => onTextChange("")}
                        />
                    </>
                )}
        </div>
    )
}
