import { t } from "@lingui/macro"
import {
    differenceInDays,
    differenceInHours,
    isSameDay,
    isToday,
} from "date-fns"
import type { Message } from "../types"

const WEEK_IN_DAYS = 7
const ONE_HOUR_IN_HOURS = 1

const formatTimeWithAMPM = (
    date: Date,
    options: Intl.DateTimeFormatOptions,
): string => {
    return (
        date
            .toLocaleString([], {
                ...options,
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                hourCycle: "h12",
            })
            // For English locale: remove space and make AM/PM lowercase (e.g., "3:20 PM" -> "3:20pm")
            .replace(
                /(\d{1,2}:\d{2})\s*([AaPp][Mm])\s*/,
                (_, time, ampm) => `${time}${ampm.toLowerCase()}`,
            )
    )
}

const getTimeFormat = (date: Date): string => {
    const today = new Date()
    if (isToday(date)) {
        return formatTimeWithAMPM(date, {})
    }
    if (differenceInDays(today, date) < WEEK_IN_DAYS) {
        return formatTimeWithAMPM(date, { weekday: "short" })
    }
    return date.toLocaleString([], {
        year: "numeric",
        month: "long",
        day: "numeric",
    })
}

export const getFormattedTime = (
    currentMessage: Message,
    previousMessage: Message | null,
): string | null => {
    const currentTime = new Date(currentMessage.created_at * 1000)

    if (!previousMessage) {
        return getTimeFormat(currentTime)
    }

    const previousTime = new Date(previousMessage.created_at * 1000)

    if (!isSameDay(currentTime, previousTime)) {
        return getTimeFormat(currentTime)
    }

    if (
        isToday(currentTime) &&
        differenceInHours(currentTime, previousTime) > ONE_HOUR_IN_HOURS
    ) {
        return getTimeFormat(currentTime)
    }

    return null
}

export const getTimeStamp = (
    currentMessage: Message,
    previousMessage: Message | null,
    isAllHistoryLoaded: boolean,
) => {
    const ts = getFormattedTime(currentMessage, previousMessage || null)

    if (!previousMessage) {
        return isAllHistoryLoaded ? (
            <div
                className="message-row-timestamp"
                data-testid="message-timestamp-container"
            >
                <div
                    className="message-row-timestamp-start"
                    data-testid="message-start-of-conversation"
                >
                    {t`Start of conversation`}
                </div>
                {ts && <div data-testid="message-timestamp-value">{ts}</div>}
            </div>
        ) : null
    }

    return ts ? (
        <div
            className="message-row-timestamp"
            data-testid="message-timestamp-container"
        >
            <div data-testid="message-timestamp-value">{ts}</div>
        </div>
    ) : null
}

export const showAvatar = (
    username: string | undefined,
    message: Message,
    nextMessage: Message | undefined,
): boolean => {
    if (!username || username !== message.from_user.username) {
        return false
    }

    return (
        !nextMessage ||
        nextMessage.from_user.username !== message.from_user.username
    )
}

export const findNonLogMessage = (
    messages: Message[],
    startIndex: number,
    direction: number,
) => {
    if (!messages || messages.length === 0) return null

    let index = startIndex
    while (index >= 0 && index < messages.length) {
        if (!messages[index]?.is_log_message) {
            return messages[index]
        }
        index += direction
    }
    return null
}
