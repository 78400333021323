import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCopy = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#222429"
            d="M6 16q-.824 0-1.412-.588A1.93 1.93 0 0 1 4 14V2q0-.824.588-1.412A1.93 1.93 0 0 1 6 0h9q.824 0 1.413.588Q17 1.175 17 2v12q0 .825-.587 1.412A1.93 1.93 0 0 1 15 16zm-4 4q-.824 0-1.412-.587A1.93 1.93 0 0 1 0 18V4h2v14h11v2z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgCopy)
const Memo = memo(ForwardRef)
export default Memo
