// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--cbr-scroll-down-button-base-bg: rgba(0, 0, 0, 0.8);--cbr-scroll-down-button-highlight-bg: rgba(46, 46, 46, 0.8)}.qHzqCGWBs_WLClMyNO9G{all:unset;box-sizing:border-box;display:inline-flex;padding:8px 16px;align-items:center;gap:8px;border-radius:4px;background:var(--cbr-scroll-down-button-base-bg);backdrop-filter:blur(2px);position:absolute;bottom:6px;left:50%;transform:translateX(-50%);color:#fff;z-index:1;font-family:"UbuntuRegular";font-size:14px;cursor:pointer}.qHzqCGWBs_WLClMyNO9G:hover{background-color:var(--cbr-scroll-down-button-highlight-bg)}.qHzqCGWBs_WLClMyNO9G>svg{width:16px;height:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollButton": `qHzqCGWBs_WLClMyNO9G`
};
export default ___CSS_LOADER_EXPORT___;
