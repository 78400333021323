import { ArgJSONMap } from "@multimediallc/web-utils"
import { fetchPost } from "../../../utils/myFetch"
import type { Message } from "../types"

export interface ISendReportMessageResponse {
    success: boolean
}

export const sendReportMessage = async (
    message: Message,
    category: string,
    comment: string,
): Promise<ISendReportMessageResponse> => {
    const body = {
        spammer: message.from_user.username,
        category: category,
        additional_comments: comment,
    }
    return fetchPost(`/chatmessages/report/${message.i}/`, body).then(
        (resp) => {
            let result
            try {
                result = resp.jsonData
            } catch {
                result = resp
            }
            return parseReportMessageResult(result as object)
        },
    )
}

const parseReportMessageResult = (
    response: object,
): ISendReportMessageResponse => {
    const jsonResponse = new ArgJSONMap(response)
    const reportResponse: ISendReportMessageResponse = {
        success: jsonResponse.getString("result", false) === "success",
    }
    jsonResponse.logUnusedDebugging("parseAbuseReportResult")
    return reportResponse
}
