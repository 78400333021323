import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { useSearchParams } from "react-router-dom"
import { useThrottle } from "../../../hooks/useThrottle"
import { ChevronLeftBlue } from "../../common/atoms/Icons/Chat"
import { IconButton } from "../common/IconButton/IconButton"
import { SearchInput } from "../common/SearchInput/SearchInput"
import { classNames } from "../utils"
import styles from "./ThreadSearch.module.scss"

type Props = {
    className?: string
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>
    onTyping?: (query: string) => void
    focusCallback?: (focused: boolean) => void
}

const TYPING_THROTTLE__MS = 500

export function ThreadSearch({
    className,
    inputProps,
    onTyping,
    focusCallback,
}: Props) {
    const [focused, setFocused] = useState(false)
    const [filter, setFilter] = useSearchParams()
    const qParam = filter.get("q") ?? ""
    const [text, setText] = useState(qParam)
    const throttledFilter = useThrottle(qParam, TYPING_THROTTLE__MS)

    useEffect(() => {
        if (text !== qParam) {
            setFilter({ q: text }, { replace: true })
        }
    }, [text, qParam, setFilter])

    const handleFocus = (newFocused: boolean) => {
        if (focused !== newFocused) {
            setFocused(newFocused)
            focusCallback && focusCallback(newFocused)
            if (!newFocused) {
                setText("")
            }
        }
    }

    useEffect(() => {
        onTyping && onTyping(throttledFilter)
    }, [throttledFilter, onTyping])

    return (
        <div className={classNames(styles.threadSearchInput, className)}>
            <IconButton
                icon={<ChevronLeftBlue height={16} width={10} />}
                iconAlt="Back"
                onClick={() => {
                    handleFocus(false)
                }}
                className={classNames(styles.threadSearchBack, {
                    [styles.focused]: focused,
                })}
            />
            <SearchInput
                className="thread-search-component"
                inputProps={{
                    ...inputProps,
                    value: text,
                    onClick: () => handleFocus(true),
                    onChange: (e) => {
                        setText(e.target.value)
                    },
                    placeholder: t`Search usernames`,
                }}
                onClear={text.trim() ? () => setText("") : undefined}
            />
        </div>
    )
}
