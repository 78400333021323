import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgScrollToTop = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M7.288 13.5a.7.7 0 1 0 1.4 0V7.703l2.567 2.373a.77.77 0 0 0 1.028 0 .637.637 0 0 0 0-.95l-3.689-3.41a.77.77 0 0 0-1.028 0l-3.743 3.46a.637.637 0 0 0 0 .95.77.77 0 0 0 1.028 0l2.436-2.251zm5.2-10.353c0 .358-.29.647-.648.647H4.134a.647.647 0 0 1 0-1.294h7.706c.357 0 .647.29.647.647"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgScrollToTop)
const Memo = memo(ForwardRef)
export default Memo
