import { generateApiPath } from "../../routes/util"
import { error } from "../../utils/debug"
import { fetchGet, fetchPost } from "../../utils/myFetch"

interface GetFollowingUsernamesResponse {
    following_usernames: string[]
}

export const reportError: (e: Error, apiURL: string) => void = (e, apiURL) => {
    error("mobile_broadcast api error", {
        apiURL,
        error: e.message,
    })
}

const approvePrivateShow = (): void => {
    fetchPost("/tipping/private_show_approve/").catch((e) => {
        reportError(e, "/tipping/private_show_approve/")
    })
}

/**
 * This function can be invoked to decline a private show request
 * or end an ongoing private show.
 */
const declineOrEndPrivateShow = (): void => {
    fetchPost("/tipping/private_show_decline/").catch((e) => {
        reportError(e, "/tipping/private_show_decline/")
    })
}

const returnFromAway = (): void => {
    fetchPost("/tipping/return_from_away/").catch((e) => {
        reportError(e, "/tipping/return_from_away/")
    })
}

const getFollowingUsernames = (): Promise<string[]> => {
    const followingUsernamesApiPath = `${generateApiPath(
        "",
        "follow",
        "following_usernames",
    )}`
    return fetchGet(followingUsernamesApiPath).then((resp) => {
        const jsonData = resp.jsonData as GetFollowingUsernamesResponse
        if ("following_usernames" in jsonData) {
            return jsonData.following_usernames
        } else {
            throw new Error(
                "Missing 'following_usernames' key in /follow/following_usernames response",
            )
        }
    })
}

export {
    approvePrivateShow,
    declineOrEndPrivateShow,
    returnFromAway,
    getFollowingUsernames,
}
