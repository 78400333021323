import type React from "react"
import { mergeClasses } from "../../../utils/css"
import "./MenuLabel.scss"

interface MenuLabelProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * The text the menu label should display.
     */
    text?: string
    /**
     * Boolean determining if chevron icon should be visible or not.
     */
    showChevron?: boolean

    /**
     * Boolean determining if label is for back action.
     */
    isBackLabel?: boolean

    /**
     * Any extra classes you might need to provide. Space delimited.
     */
    classNames?: string

    /**
     * The test id for then menu label.
     */
    testid?: string | undefined

    /**
     * The event fired when the label div is clicked.
     */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export function MenuLabel({
    text,
    showChevron,
    isBackLabel,
    classNames,
    testid,
    onClick,
    ...props
}: MenuLabelProps) {
    return (
        <div
            className={mergeClasses(
                "MenuLabel-container",
                classNames,
                isBackLabel ? "back" : "",
            )}
            onClick={onClick}
            {...props}
        >
            <div
                className={mergeClasses(
                    "MenuLabel",
                    showChevron ? "chevron" : "",
                    isBackLabel ? "back" : "",
                )}
                data-testid="chevron"
            >
                <span
                    data-testid={testid ? testid : "menu-label"}
                    className="MenuLabel-span"
                >
                    {text}
                </span>
            </div>
        </div>
    )
}
