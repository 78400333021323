import React from "react"
import { _ } from "../../../i18n"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import { InputToggle } from "../../common/atoms/InputToggle"
import { Slider } from "../../common/atoms/Slider"
import { CHATURSAFE_CATEGORY_MAPPING } from "../common"
import "./CategoryRow.scss"

export interface CategoryRowData {
    name: string
    enabled: boolean
    value: number
}

export interface CategoryRowProps extends CategoryRowData {
    handleInputChange: (name: string, value: boolean | number) => void
}

export const CategoryRow = ({
    name,
    enabled,
    value,
    handleInputChange,
}: CategoryRowProps) => {
    const [checked, setChecked] = React.useState(enabled)

    function onToggle(checked: boolean) {
        setChecked(!checked)
        handleInputChange(
            CHATURSAFE_CATEGORY_MAPPING[name].enabled_key,
            !checked,
        )
    }

    function onSliderChange(value: number) {
        handleInputChange(
            CHATURSAFE_CATEGORY_MAPPING[name].value_key,
            value / 100,
        )
    }

    return (
        <div className="CategoryRow">
            <InputToggle
                className="CategoryRow__toggle"
                onToggle={() => onToggle(checked)}
                checked={checked}
                name={CHATURSAFE_CATEGORY_MAPPING[name].enabled_key}
            />
            <div className="CategoryRow__category">
                <Typography
                    component="h2"
                    size={Sizes.smpx}
                    weight="medium"
                    className="CategoryRow__category-title"
                >
                    {_(CHATURSAFE_CATEGORY_MAPPING[name].title)}
                </Typography>
                <Typography
                    component="h2"
                    size={Sizes.smpx}
                    weight={Weights.normal}
                    className="CategoryRow__category-desc"
                >
                    {_(CHATURSAFE_CATEGORY_MAPPING[name].description)}
                </Typography>
            </div>
            <div className="CategoryRow__slider">
                {checked && (
                    <Slider
                        defaultVal={value}
                        minVal={25}
                        maxVal={75}
                        name={CHATURSAFE_CATEGORY_MAPPING[name].value_key}
                        handleChangeCallback={onSliderChange}
                    />
                )}
            </div>
        </div>
    )
}
