import type { CSSProperties } from "react"
import React, { useId, useLayoutEffect, useRef, useState } from "react"
import { useTooltip } from "../../../../hooks/useTooltip"
import { Sizes } from "../../../../interfaces/sizing"
import { mergeClasses } from "../../../../utils/css"
import { CheckmarkSlim } from "../Icons/Others"
import { Label } from "../Label"
import { Tooltip } from "../Tooltip"
import { Typography } from "../Typography"
import "./Checkbox.scss"

interface CheckboxProps extends React.ComponentPropsWithRef<"input"> {
    label?: string
    checked?: boolean
    className?: string
    tooltipText?: string
    onToggle: (checked: boolean) => void
}
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            label,
            checked,
            className,
            tooltipText,
            onToggle,
            ...props
        }: CheckboxProps,
        ref,
    ) => {
        const uniqueId = useId()
        const labelRef = useRef(null)
        const { isOpen } = useTooltip(labelRef)
        const tooltipMargin = 4
        const [tooltipPosition, setTooltipTooltipPosition] = useState(
            {} as CSSProperties,
        )

        useLayoutEffect(() => {
            if (labelRef.current) {
                const { left, top, height, width } = (
                    labelRef.current as HTMLElement
                ).getBoundingClientRect()
                setTooltipTooltipPosition({
                    position: "fixed",
                    top: `${top - height * 2 + tooltipMargin}px`,
                    left: `${left + width / 2 + tooltipMargin}px`,
                    transform: "translate(-50%, 0)",
                } as CSSProperties)
            }
        }, [isOpen])

        return (
            <>
                <div
                    className={mergeClasses(
                        "Checkbox",
                        className,
                        props.disabled ? "Checkbox__disabled" : "",
                    )}
                    ref={ref}
                >
                    <Label
                        size={Sizes.smpx}
                        className="Checkbox__label-container"
                        htmlFor={`Checkbox__checkbox_${uniqueId}`}
                        ref={labelRef}
                    >
                        <div
                            className={`Checkbox__checkbox-container ${checked ? `checked` : ``}`}
                        >
                            <input
                                type="checkbox"
                                id={`Checkbox__checkbox_${uniqueId}`}
                                onChange={(e) => onToggle(e.target.checked)}
                                checked={checked}
                                {...props}
                            />
                            <CheckmarkSlim
                                className={`Checkbox__checkmark ${checked ? `checked` : ``}`}
                            />
                        </div>
                        {label && (
                            <Typography
                                component="p"
                                size={Sizes.smpx}
                                className="Checkbox__label"
                            >
                                {label}
                            </Typography>
                        )}
                    </Label>
                </div>
                {tooltipText && isOpen && (
                    <Tooltip text={tooltipText} style={tooltipPosition} />
                )}
            </>
        )
    },
)
