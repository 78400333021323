import { useState } from "react"
import { t } from "@lingui/macro"
import { useParams } from "react-router-dom"
import { useAlert } from "../../../hooks/useAlert"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    setNoteCache,
    trimNoteCache,
    useGetNoteForUserQuery,
    useSaveNoteForUserMutation,
} from "../../../store/userList/notesSlice"
import { error } from "../../../utils/debug"
import { AlertType } from "../../common"
import { BlackClose } from "../../common/atoms/Icons/Chat"
import { Overlay } from "../../common/atoms/Overlay"
import { toast } from "../common/Toast/ToastManager"
import { countUnicodeChars, truncateUnicodeChars } from "../textUtils"
import styles from "./UserNote.module.scss"

interface UserNoteProps {
    onClose: () => void
}

const MAX_NOTE_LENGTH_CHARS = 300
const ALLOWED_SPECIAL_KEYS: string[] = [
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Home",
    "End",
    "PageUp",
    "PageDown",
]

export function UserNote({ onClose }: UserNoteProps) {
    const { username = "" } = useParams<{ username: string }>()
    const currentUser = useAppSelector(
        (state) => state.user.loggedInUser?.username,
    )

    const {
        data: note,
        isLoading,
        isError,
    } = useGetNoteForUserQuery(
        { username },
        { skip: currentUser === undefined },
    )
    const addAlert = useAlert()

    const [updateNote] = useSaveNoteForUserMutation()

    const dispatch = useAppDispatch()
    const cachedNote = useAppSelector(
        (state) => state.notes.notesCache[username] ?? "",
    )
    const noteEdited = cachedNote !== (note ?? "")

    const [showDiscardModal, setShowDiscardModal] = useState(false)
    const [saveInProgress, setSaveInProgress] = useState(false)

    const save = () => {
        const trimmedNote = cachedNote.trim()
        if (trimmedNote.length === 0) {
            return
        }
        setSaveInProgress(true)
        updateNote({
            username,
            note: trimmedNote,
        })
            .unwrap()
            .then(() => {
                dispatch(trimNoteCache({ username }))
                setSaveInProgress(false)
                // Close the note modal after saving
                onClose()
                toast.fire({
                    message: t`Note saved.`,
                    position: "top",
                    preset: "dark",
                    showIcon: false,
                })
            })
            .catch((e) => {
                error("Error saving note", e)
                addAlert({
                    type: AlertType.alert,
                    title: "",
                    contents: "Error saving note",
                })
                setSaveInProgress(false)
            })
    }

    const handleSaveClick = () => {
        if (!noteEdited || saveInProgress) {
            return
        }
        save()
    }

    const handleCancelClick = () => {
        if (noteEdited) {
            setShowDiscardModal(true)
        } else {
            onClose()
        }
    }

    const handleDiscardChanges = () => {
        dispatch(setNoteCache({ username, note }))
        onClose()
    }

    const getActiveCharCountStatus = () => {
        return `${countUnicodeChars(cachedNote)}/${MAX_NOTE_LENGTH_CHARS}`
    }

    return (
        <>
            <Overlay
                onClick={handleCancelClick}
                data-testid="usernote-backdrop"
                className={styles.backdrop}
            />
            <div className={styles.overlay}>
                <div
                    className={styles.outerOverlay}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        className={styles.closeX}
                        onClick={handleCancelClick}
                        data-testid="note-close-button"
                    >
                        <BlackClose height={24} width={24} />
                    </button>
                    <div
                        className={styles.contentHeader}
                        data-testid="usernote-header"
                    >
                        {t`Note`}
                    </div>
                    <div className={styles.innerOverlay}>
                        <div className={styles.content}>
                            <div className={styles.textAreaWrapper}>
                                <textarea
                                    className={styles.textInput}
                                    data-testid="usernote-text-input"
                                    value={cachedNote}
                                    placeholder={
                                        isLoading || isError
                                            ? t`Loading...`
                                            : t`Enter notes about this user (only seen by you)`
                                    }
                                    onKeyDown={(e) => {
                                        // This is necessary to prevent the cursor from jumping to the end when entering a key in the middle of a max character note
                                        const textarea =
                                            e.target as HTMLTextAreaElement
                                        if (
                                            textarea.selectionStart !==
                                            textarea.selectionEnd
                                        ) {
                                            return
                                        }

                                        if (
                                            countUnicodeChars(cachedNote) >=
                                                MAX_NOTE_LENGTH_CHARS &&
                                            !e.ctrlKey &&
                                            !e.altKey &&
                                            !e.metaKey &&
                                            !e.shiftKey &&
                                            !ALLOWED_SPECIAL_KEYS.includes(
                                                e.key,
                                            )
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={(e) => {
                                        let newNote = e.target.value
                                        const oldNoteLength =
                                            countUnicodeChars(cachedNote)
                                        const newNoteLength =
                                            countUnicodeChars(newNote)
                                        if (
                                            oldNoteLength >=
                                                MAX_NOTE_LENGTH_CHARS &&
                                            newNoteLength >=
                                                MAX_NOTE_LENGTH_CHARS
                                        ) {
                                            return
                                        }
                                        if (
                                            newNoteLength >
                                            MAX_NOTE_LENGTH_CHARS
                                        ) {
                                            newNote = truncateUnicodeChars(
                                                newNote,
                                                MAX_NOTE_LENGTH_CHARS,
                                            )
                                        }
                                        if (newNote !== cachedNote) {
                                            dispatch(
                                                setNoteCache({
                                                    username,
                                                    note: newNote,
                                                }),
                                            )
                                        }
                                    }}
                                />
                            </div>
                            <div
                                className={styles.charCounter}
                                data-testid="char-count"
                            >
                                {noteEdited && getActiveCharCountStatus()}
                            </div>
                            <div className={styles.buttonsContainer}>
                                <button
                                    className={styles.cancelButton}
                                    onClick={handleCancelClick}
                                >
                                    {t`Cancel`}
                                </button>
                                <button
                                    className={styles.saveButton}
                                    data-testid="usernote-save"
                                    onClick={handleSaveClick}
                                    disabled={!noteEdited || saveInProgress}
                                >
                                    {t`Save`}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showDiscardModal && (
                <div className={styles.discardModalBackdrop}>
                    <div
                        className={styles.discardModal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={styles.discardModalTitle}>
                            {t`If you go back now, you will lose any changes you’ve made.`}
                        </div>
                        <div className={styles.discardModalButtons}>
                            <button
                                onClick={() => setShowDiscardModal(false)}
                                className={styles.keepEditingButton}
                            >
                                {t`Keep Editing`}
                            </button>
                            <button
                                onClick={handleDiscardChanges}
                                className={styles.discardButton}
                            >
                                {t`Discard`}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
