import { RoomListSource } from "@multimediallc/web-utils/types"
import type { DraftMessage } from "../DraftCache/DraftCache"
import type { Thread } from "../types"

export const draftMessageToThread = (
    otherUsername: string,
    draftMessage: DraftMessage,
): Thread => {
    return {
        m: draftMessage.message || "",
        num_unread: 0,
        created_at: Math.floor(draftMessage.timestamp / 1000),
        from_user: "",
        other_user: {
            username: otherUsername,
            inFanclub: false,
            isMod: false,
            isBroadcaster: false,
            hasTokens: false,
            tippedRecently: false,
            tippedALotRecently: false,
            tippedTonsRecently: false,
            exploringHashTag: "",
            sourceName: RoomListSource.Default,
        },
        tip_amount: 0,
    }
}
