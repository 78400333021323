// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r1YMM6TwLSXzMZ1aAY0Y ._nfudRcnXYqehSXS3S0N{display:flex;justify-content:space-between;margin-bottom:8px}.r1YMM6TwLSXzMZ1aAY0Y ._nfudRcnXYqehSXS3S0N h2{font-family:"UbuntuBold",Arial,Helvetica,sans-serif;color:#222;font-size:16px;margin:0}.r1YMM6TwLSXzMZ1aAY0Y ._nfudRcnXYqehSXS3S0N .hxhIuIXUoDYJeTawyNTI{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#0c6a93}.r1YMM6TwLSXzMZ1aAY0Y .vNDDhKXxf_t1W64l1QiK{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;font-size:14px;line-height:22px;color:#222;margin-bottom:24px}.r1YMM6TwLSXzMZ1aAY0Y button.edrxXvKe5LXGHCgpGNhL{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;background-color:#0c6a93;color:#fff;border:none;border-radius:8px;padding:16px;font-size:16px;cursor:pointer;transition:background-color .2s;display:flex;align-items:center;justify-content:center;width:100%;margin-bottom:8px}.r1YMM6TwLSXzMZ1aAY0Y button.edrxXvKe5LXGHCgpGNhL:hover{background-color:#084864}.r1YMM6TwLSXzMZ1aAY0Y button.edrxXvKe5LXGHCgpGNhL:disabled{background-color:#666;cursor:not-allowed}.r1YMM6TwLSXzMZ1aAY0Y button.pPlFzxR7GP2BIbFWCQbg{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;background-color:rgba(0,0,0,0);color:#a7a7a7;border:none;border-radius:8px;padding:16px;font-size:16px;cursor:pointer;transition:background-color .2s;display:flex;align-items:center;justify-content:center;width:100%}.b2QhmYsIJDAIaCxNyZJC{height:14px;width:14px;display:inline-block;margin-right:8px;color:#0c6a93}.z21Jd3pQvOdfJOq0VXEY{width:10px;height:10px;border:2px solid #fff;border-top:2px solid rgba(0,0,0,0);border-radius:50%;animation:p0zeClmguBgbRXkGcg_4 1s linear infinite}@keyframes p0zeClmguBgbRXkGcg_4{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `r1YMM6TwLSXzMZ1aAY0Y`,
	"header": `_nfudRcnXYqehSXS3S0N`,
	"tokenCount": `hxhIuIXUoDYJeTawyNTI`,
	"body": `vNDDhKXxf_t1W64l1QiK`,
	"submit": `edrxXvKe5LXGHCgpGNhL`,
	"cancel": `pPlFzxR7GP2BIbFWCQbg`,
	"iconTokens": `b2QhmYsIJDAIaCxNyZJC`,
	"spinner": `z21Jd3pQvOdfJOq0VXEY`,
	"spin": `p0zeClmguBgbRXkGcg_4`
};
export default ___CSS_LOADER_EXPORT___;
