import { error } from "@multimediallc/logging"

interface IEmoticon {
    width: number
    height: number
    imgUrl: string
    name: string
    abuseUrl: string
    thumbUrl?: string
}

function validDimension(str: string): boolean {
    if (isNaN(parseInt(str, 10))) {
        error("Invalid dimension argument")
        return false
    } else {
        return true
    }
}

function validUrl(str: string): boolean {
    const res = str.match(
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g,
    )
    if (res === null) {
        error("Invalid emoticon URL")
        return false
    } else {
        return true
    }
}

export function parseEmoticon(emoticon: string): IEmoticon | undefined {
    emoticon = emoticon.replace("%%%[emoticon ", "").replace("]%%%", "")
    const emoticonParts = emoticon.split(/\|/)

    if (emoticonParts.length === 6) {
        const [name, thumbUrl, widthStr, heightStr, imgUrl, abuseUrl] =
            emoticonParts
        const width = validDimension(widthStr) ? Number(widthStr) : 80
        const height = validDimension(heightStr) ? Number(heightStr) : 80
        const parsedEmoticon: IEmoticon = {
            name,
            thumbUrl,
            width,
            height,
            imgUrl,
            abuseUrl,
        }
        if (thumbUrl !== undefined && validUrl(thumbUrl)) {
            return parsedEmoticon
        } else {
            return undefined
        }
    } else if (emoticonParts.length === 5) {
        const [name, imgUrl, widthStr, heightStr, abuseUrl] = emoticonParts
        const width = validDimension(widthStr) ? Number(widthStr) : 80
        const height = validDimension(heightStr) ? Number(heightStr) : 80
        const parsedEmoticon: IEmoticon = {
            name,
            imgUrl,
            width,
            height,
            abuseUrl,
        }
        if (validUrl(imgUrl)) {
            return parsedEmoticon
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}
