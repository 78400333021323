import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgArrowCollapseLeft = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#fff"
            d="M7.946 13.28 2.666 8l5.28-5.28.94.947L5.22 7.333h9.446v1.334H5.22l3.673 3.666zM2.666 8V1.333H1.333v13.334h1.333z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgArrowCollapseLeft)
const Memo = memo(ForwardRef)
export default Memo
