import type { ChangeEvent, FormEvent, KeyboardEvent } from "react"
import { useEffect, useRef, useState } from "react"
import Picker from "@emoji-mart/react"
import { t } from "@lingui/macro"
import { ShortcodeParser } from "@multimediallc/web-utils/shortcodeParsing"
import { isIOS, isMobile } from "react-device-detect"
import { useParams } from "react-router-dom"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { useThrottle } from "../../../hooks/useThrottle"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    addMessageToHistory,
    useGetProfileQuery,
    usePublishMessageMutation,
    useTypingIndicatorMutation,
} from "../../../store/messagingSlice"
import { debug } from "../../../utils/debug"
import { Textarea } from "../../common"
import {
    Camera,
    Gallery,
    SendArrow,
    Smile,
    Tokens,
} from "../../common/atoms/Icons/Chat"
import { ConfirmModal } from "../common/ConfirmModal/ConfirmModal"
import { IconButton } from "../common/IconButton/IconButton"
import { draftCache } from "../DraftCache/DraftCache"
import { useMessagingView } from "../hooks"
import { createLogMessage } from "../MessagingContext"
import { SendTip } from "../SendTip/SendTip"
import { isDesktopDmActive, isDmMediaActive } from "../toggleUtils"
import { classNames } from "../utils"
import styles from "./MessageInputBar.module.scss"
import { MessageMediaSelect } from "./MessageMediaSelect/MessageMediaSelect"

const TIP_COMMAND = "/tip"

type Props = {
    scrollToBottom: () => void
    onViewportHeightChange: () => void
}

const MIN_TYPING_INDICATOR_REQUEST_PERIOD_MS = 3000
const PUBLISH_OK_STATUS = "Ok"
const ANDROID_KEYBOARD_TOGGLE_DELAY_MS = 100
const IOS_KEYBOARD_TOGGLE_DELAY_MS = 250
const KEYBOARD_TOGGLE_DELAY = isIOS
    ? IOS_KEYBOARD_TOGGLE_DELAY_MS
    : ANDROID_KEYBOARD_TOGGLE_DELAY_MS

export function MessageInputBar({
    scrollToBottom,
    onViewportHeightChange,
}: Props) {
    const messagingView = useMessagingView()
    const { username } = useParams<{ username: string }>()
    const me = useAppSelector((state) => state.user.loggedInUser)
    const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery(
        username || "",
    )
    const [publishMessage] = usePublishMessageMutation()
    const [text, setText] = useState("")
    const [typingIndicator] = useTypingIndicatorMutation()
    const throttledText = useThrottle(
        text,
        MIN_TYPING_INDICATOR_REQUEST_PERIOD_MS,
        (val) => val.trim() === "",
    )

    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [showSendTip, setShowSendTip] = useState(false)
    const [showCannotTipModal, setShowCannotTipModal] = useState(false)
    const [emojiData, setEmojiData] = useState<any>(null)
    const showSend = text.trim().length > 0
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const selectedMediaInputRef = useRef<HTMLInputElement>(null)
    const smileIconRef = useRef<HTMLDivElement>(null)
    const [selectedMedia, setSelectedMedia] = useState<File[]>([])
    const [initialTipAmount, setInitialTipAmount] = useState<string>("")

    const handleTipCommand = (trimmedText: string) => {
        setText("")
        if (profile?.can_tip) {
            const tipAmount = trimmedText.toLowerCase().split(" ")[1]
            const numericTipAmount = Number(tipAmount)
            if (!isNaN(numericTipAmount) && numericTipAmount > 0) {
                setInitialTipAmount(numericTipAmount.toString())
            }
            setShowSendTip(true)
        } else {
            setShowCannotTipModal(true)
        }
    }

    const emojiPickerRef = useOutsideClick(() => {
        setShowEmojiPicker(false)
    }, [textareaRef, smileIconRef])
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (showEmojiPicker && !emojiData) {
            import("@emoji-mart/data").then((module) =>
                setEmojiData(module.default),
            )
        }
    }, [showEmojiPicker, emojiData])

    useEffect(() => {
        updateTextAreaSize()
    }, [text])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        const trimmedText = text.trim()

        if (trimmedText.toLowerCase().startsWith(TIP_COMMAND)) {
            handleTipCommand(trimmedText)
            return
        }

        setText("")
        setSelectedMedia([])
        setShowEmojiPicker(false)
        draftCache.clear(username || "")

        try {
            if (!username || !me) throw new Error("No from_user or to_user")

            if (ShortcodeParser.isShortcodeSyntax(trimmedText)) {
                dispatch(
                    addMessageToHistory(
                        username,
                        createLogMessage(
                            username,
                            t`Shortcodes are not supported in DMs.`,
                        ),
                    ),
                )
                return
            }

            publishMessage({
                message: trimmedText,
                to_user: username,
                from_user: me?.username,
            })
                .unwrap()
                .then((result) => {
                    if (
                        (result.status !== PUBLISH_OK_STATUS ||
                            !result?.status) &&
                        username
                    ) {
                        dispatch(
                            addMessageToHistory(
                                username,
                                createLogMessage(
                                    username,
                                    result.status || t`Unable to send message`,
                                ),
                            ),
                        )
                    }
                })
                .catch((result) => {
                    dispatch(
                        addMessageToHistory(
                            username,
                            createLogMessage(username, result.data.error),
                        ),
                    )
                })

            if (textareaRef.current) {
                textareaRef.current.focus()
            }
        } catch (e) {
            debug(e)
        }
    }

    useEffect(() => {
        if (text.trim() && username && me && profile?.can_pm) {
            typingIndicator({ to_username: username })
        }
    }, [throttledText, username, me, profile?.can_pm, typingIndicator])

    const handleEmojiSelect = (emoji: any) => {
        setText((prev) => prev + emoji.native)
    }

    const updateTextAreaSize = () => {
        const textarea = textareaRef.current
        if (!textarea) return

        const lineHeight = 20
        const maxLines = 5
        const maxHeight = maxLines * lineHeight

        textarea.style.height = ""
        const newHeight = Math.min(textarea.scrollHeight, maxHeight)
        textarea.style.height = `${newHeight}px`
    }

    const handleTokensClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (isProfileLoading) return

        if (profile?.can_tip) {
            setShowSendTip(true)
        } else {
            setShowCannotTipModal(true)
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (!isMobile && e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            if (text.trim()) {
                handleSubmit(e)
            }
        }
    }

    const handleResize = () => {
        setTimeout(() => {
            window.scrollTo(0, 0)

            // do not update scroll position if the message input isn't focused
            if (textareaRef.current !== document.activeElement) {
                return
            }

            requestAnimationFrame(() => {
                onViewportHeightChange()
            })
        }, KEYBOARD_TOGGLE_DELAY)
    }

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value)
        if (!username) return
        if (e.target.value === "") {
            draftCache.clear(username)
        } else {
            draftCache.set(username, {
                timestamp: Date.now(),
                message: e.target.value,
            })
        }
    }

    useEffect(() => {
        window.visualViewport?.addEventListener("resize", handleResize)
        return () => {
            window.visualViewport?.removeEventListener("resize", handleResize)
        }
    }, [handleResize])

    useEffect(() => {
        const cachedDraft = draftCache.get(username || "")
        if (cachedDraft?.message) {
            setText(cachedDraft.message)
        }
    }, [username])

    const [isTouching, setIsTouching] = useState(false)
    useEffect(() => {
        const handleTouchStart = () => {
            setIsTouching(true)
        }
        const handleTouchEnd = () => {
            setIsTouching(false)
        }

        window.addEventListener("touchstart", handleTouchStart)
        window.addEventListener("touchend", handleTouchEnd)

        return () => {
            window.removeEventListener("touchstart", handleTouchStart)
            window.removeEventListener("touchend", handleTouchEnd)
        }
    }, [])

    // idea taken from typescripts/../mobileDmWindowManager.tsx
    // Some browsers show weird behavior (like scrolling underneath the viewport) when scrolling around
    // the page while the keyboard is open. Blur the input when the viewport scrolls from user interaction.
    useEffect(() => {
        const blurTextareaIfTouching = () => {
            if (textareaRef.current && isTouching) {
                textareaRef.current.blur()
            }
        }

        window.visualViewport?.addEventListener(
            "scroll",
            blurTextareaIfTouching,
        )

        return () => {
            window.visualViewport?.removeEventListener(
                "scroll",
                blurTextareaIfTouching,
            )
        }
    }, [isTouching])

    return (
        <>
            <div
                className={classNames(styles.container, {
                    [styles.hide]: showSendTip,
                })}
            >
                <MessageMediaSelect
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    selectedMediaInputRef={selectedMediaInputRef}
                    scrollToBottom={scrollToBottom}
                />
                <div
                    className={classNames(styles.input, {
                        [styles.mobile]: isMobile,
                        [styles.desktop]: messagingView.isDesktopConversation,
                    })}
                >
                    {isDmMediaActive() && (
                        <IconButton
                            data-testid="camera-btn"
                            className={styles.iconButton}
                            icon={<Camera height={20} width={20} />}
                            iconAlt={t`Take photo`}
                            onClick={() => {
                                selectedMediaInputRef.current?.click()
                            }}
                        />
                    )}
                    {isDmMediaActive() && (
                        <IconButton
                            data-testid="gallery-btn"
                            className={styles.iconButton}
                            icon={<Gallery height={20} width={20} />}
                            iconAlt={t`Choose from gallery`}
                        />
                    )}
                    {showEmojiPicker &&
                        emojiData &&
                        isDesktopDmActive() &&
                        !messagingView.isMobile && (
                            <div
                                className={styles.emojiPicker}
                                ref={emojiPickerRef}
                            >
                                <Picker
                                    data={emojiData}
                                    previewPosition="none"
                                    onEmojiSelect={handleEmojiSelect}
                                />
                            </div>
                        )}
                    {!showSendTip && (
                        <form onSubmit={handleSubmit}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.textareaContainer}>
                                    <Textarea
                                        className={styles.textarea}
                                        placeholder={t`Send a message...`}
                                        value={text}
                                        onChange={handleTextAreaChange}
                                        onKeyDown={handleKeyDown}
                                        rows={1}
                                        ref={textareaRef}
                                    />
                                </div>
                                {isDesktopDmActive() &&
                                    !messagingView.isMobile && (
                                        <div
                                            className={styles.smileIcon}
                                            ref={smileIconRef}
                                        >
                                            <Smile
                                                height={20}
                                                width={20}
                                                onClick={() =>
                                                    setShowEmojiPicker(
                                                        !showEmojiPicker,
                                                    )
                                                }
                                                className={classNames({
                                                    [styles.active]:
                                                        showEmojiPicker,
                                                })}
                                            />
                                        </div>
                                    )}
                            </div>
                            {showSend ? (
                                <IconButton
                                    type="submit"
                                    data-testid="submit-btn"
                                    className={styles.iconButton}
                                    icon={<SendArrow height={36} width={36} />}
                                    iconAlt={t`Send message`}
                                />
                            ) : (
                                <IconButton
                                    className={classNames(styles.iconButton, {
                                        disabled: isProfileLoading,
                                    })}
                                    onClick={(e) => handleTokensClick(e)}
                                    disabled={isProfileLoading}
                                    icon={
                                        profile?.can_tip ? (
                                            <Tokens
                                                data-testid="tokens-icon"
                                                width={20}
                                                height={20}
                                                className={styles.tokensBlue}
                                            />
                                        ) : (
                                            <Tokens
                                                data-testid="tokens-grey-icon"
                                                width={20}
                                                height={20}
                                                className={styles.tokensGrey}
                                            />
                                        )
                                    }
                                    iconAlt={t`Send tokens`}
                                />
                            )}
                        </form>
                    )}
                </div>
            </div>
            {showSendTip && username && (
                <SendTip
                    username={username}
                    onClose={() => {
                        setShowSendTip(false)
                        setInitialTipAmount("")
                    }}
                    initialTipAmount={initialTipAmount}
                />
            )}
            {showCannotTipModal && (
                <div className={styles.cannotTipContainer}>
                    <div className={styles.errorModal}>
                        <ConfirmModal
                            isOpen={showCannotTipModal}
                            onClose={() => setShowCannotTipModal(false)}
                            onConfirm={() => setShowCannotTipModal(false)}
                            title={t`This user may not receive tokens.`}
                            confirmText={t`OK`}
                            cancelText={null}
                            confirmButtonStyle={{
                                backgroundColor: "#0C6A93",
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
