import type React from "react"
import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { _ } from "../../../i18n"
import { Sizes } from "../../../interfaces/sizing"
import { mergeClasses } from "../../../utils/css"
import { ButtonColor, Typography } from "../../common"
import { CloseButton } from "../../common/atoms/CloseButton"
import { InfoOutline } from "../../common/atoms/Icons/Others"
import { MobileTooltip } from "../../common/molecules/MobileTooltip"
import { FlaggedChatTooltip } from "../FlaggedChatTooltip"
import "./MobileFlaggedChat.scss"

interface FlaggedChatProps extends React.ComponentPropsWithoutRef<"div"> {
    chatDiv: HTMLDivElement
    reasons: string[]
    isPureChat?: boolean
    scrollCallback: () => void
    removeCallback: () => void
}

export function MobileFlaggedChat({
    reasons,
    chatDiv,
    isPureChat,
    scrollCallback,
    removeCallback,
    ...props
}: FlaggedChatProps) {
    const [isOpen, setIsOpen] = useState(true)
    const [isTooltipOpen, setTooltipIsOpen] = useState(false)

    useEffect(() => {
        // scroll callback has the logic to scroll chat to bottom based on whether the user has scrolled up manually
        // and if the chat div's height was changed.
        // We need a callback here to let the parent handle scroll calcualtions after this component is finished rendering.
        scrollCallback()
    }, [])

    if (!isOpen) {
        return <></>
    }

    chatDiv.classList.add("MobileFlaggedChat__message")

    const className = mergeClasses(
        "MobileFlaggedChat",
        isPureChat ? "purechat" : "",
    )

    const onClose = () => {
        removeCallback()
        setIsOpen(false)
    }

    return (
        <div className={className} {...props}>
            <div className="MobileFlaggedChat__container">
                <div className="MobileFlaggedChat__header">
                    <InfoOutline onClick={() => setTooltipIsOpen(true)} />
                    <Typography
                        component="h2"
                        size={Sizes.smpx}
                        weight="normal"
                        className="MobileFlaggedChat__info"
                    >
                        {t`This message was rejected due to ChaturSafe filter`}
                    </Typography>
                    <CloseButton
                        className="MobileFlaggedChat__close"
                        onClick={onClose}
                        size="14"
                    />
                </div>
                <div
                    ref={(ref) => {
                        ref?.appendChild(chatDiv)
                    }}
                />
            </div>
            {isTooltipOpen && (
                <MobileTooltip
                    title={t`This message was rejected`}
                    contentDiv={<FlaggedChatTooltip reasons={reasons} />}
                    onClose={() => setTooltipIsOpen(false)}
                    buttonAction={onClose}
                    buttonTitle={t`Delete Message`}
                    buttonColor={ButtonColor.Red}
                />
            )}
        </div>
    )
}
