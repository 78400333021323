import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../store/hooks"
import {
    DM_THREAD_PAGE_LIMIT,
    messagingApi,
    useGetProfileQuery,
    useGetThreadsQuery,
} from "../../../store/messagingSlice"
import { getUsernameColorClass } from "../../../utils/css/user"
import { ChevronLeftBlue, Fanclub } from "../../common/atoms/Icons/Chat"
import { IconButton } from "../common/IconButton/IconButton"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import { useMessagingView } from "../hooks"
import { MessageHeaderMenu } from "../MessageHeaderMenu/MessageHeaderMenu"
import { isMsgFutureRlsActive } from "../toggleUtils"
import { classNames } from "../utils"
import styles from "./MessageHeader.module.scss"

interface MessageHeaderProps {
    username: string
    onClose: () => void
    isMinimized: boolean
    onMinimize: () => void
    numUnread?: number
    inFanclub?: boolean
    onReportClick: () => void
    onNoteClick: () => void
    isReportEnabled: boolean
}

export function MessageHeader({
    username,
    onClose,
    isMinimized,
    onMinimize,
    numUnread = 0,
    onReportClick,
    onNoteClick,
    isReportEnabled,
}: MessageHeaderProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const messagingView = useMessagingView()
    const [showFanclubBadge, setShowFanclubBadge] = useState<boolean>(false)

    // Get other user from thread data query since it will already be cached
    const lastQuery = useAppSelector(
        (state) =>
            messagingApi.endpoints.getThreads.select({
                offset: 0,
                limit: DM_THREAD_PAGE_LIMIT,
            })(state).originalArgs,
    )
    const { data: threadsData } = useGetThreadsQuery({
        offset: lastQuery?.offset ?? 0,
        limit: DM_THREAD_PAGE_LIMIT,
    })
    const thread = threadsData?.threads.find(
        (t) => t.other_user.username === username,
    )

    // Get other user from profile as fallback
    const { data: profile } = useGetProfileQuery(username || "")

    const user = thread?.other_user ||
        profile?.user || {
            // Provide default value if we are coming from search users page before profile query loads
            username,
            isBroadcaster: false,
            inFanclub: false,
            isFollowing: false,
            hasTokens: false,
            isMod: false,
            tippedRecently: false,
            tippedALotRecently: false,
            tippedTonsRecently: false,
            gender: "",
        }

    useEffect(() => {
        const newShowFanclubBadgeValue =
            isMsgFutureRlsActive() && user?.inFanclub
        setShowFanclubBadge(newShowFanclubBadgeValue)
    }, [user])

    const handleBackClick = () => {
        const hasHistory = location.key !== "default"
        if (hasHistory) {
            navigate(-1)
            return
        }
        navigate("/", { replace: true })
    }
    const userColorClass = getUsernameColorClass(user)

    return (
        <div
            className={classNames(styles.header, {
                [styles.mobile]: messagingView.isMobile,
                [styles.desktop]: messagingView.isDesktopConversation,
                [styles.minimized]: isMinimized,
            })}
            onClick={isMinimized ? onMinimize : undefined}
        >
            {messagingView.isMobile && !isMinimized && (
                <IconButton
                    className={styles.backButton}
                    icon={<ChevronLeftBlue height={16} width={10} />}
                    iconAlt="Back"
                    onClick={handleBackClick}
                />
            )}
            <ProfilePicture user={user} size={32} />
            <div
                className={classNames(styles.username, {
                    [styles.mobile]: messagingView.isMobile,
                })}
            >
                <span
                    className={classNames(styles.usernameText, userColorClass)}
                >
                    {username}
                </span>
                {showFanclubBadge && (
                    <span
                        className={styles.fanclubIcon}
                        data-testid="fanclub-icon"
                    >
                        <Fanclub width={16} height={16} />
                    </span>
                )}
            </div>
            <MessageHeaderMenu
                onClose={onClose}
                onMinimize={onMinimize}
                isMinimized={isMinimized}
                onConversationDeleted={handleBackClick}
                onReportClick={onReportClick}
                onNoteClick={onNoteClick}
                isReportEnabled={isReportEnabled}
                numUnread={numUnread}
            />
        </div>
    )
}
