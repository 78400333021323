import { Sizes } from "../../../interfaces/sizing"
import { useAppSelector } from "../../../store/hooks"
import { selectRoomSubject } from "../../../store/roomSlice"
import { colorClass } from "../../../utils/css"
import { shrinkWrap } from "../../../utils/domUtils"
import { Typography } from "../../common"
import "./BroadcastRoomSubject.scss"

export function BroadcastRoomSubject() {
    const roomSubject = useAppSelector(selectRoomSubject)

    if (roomSubject === "") {
        return (
            <div
                className="EmptySubjectSpacer"
                data-testid="broadcast-room-subject-spacer"
            />
        )
    }

    return (
        <div
            className="BroadcastRoomSubject"
            data-testid="broadcast-room-subject"
        >
            <Typography size={Sizes.smpx} color={colorClass.whiteFontColor}>
                <div
                    className="BroadcastRoomSubject__text"
                    ref={(el) => {
                        // When text line-wraps due to a max width, there's no way to achieve {width: fit-content} in css,
                        // and JS like this is required to achieve that
                        if (el !== null) shrinkWrap(el)
                    }}
                >
                    {roomSubject}
                </div>
            </Typography>
        </div>
    )
}
