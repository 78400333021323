import type React from "react"
import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { Sizes } from "../../../interfaces/sizing"
import { mergeClasses } from "../../../utils/css"
import { Typography } from "../../common"
import { CloseButton } from "../../common/atoms/CloseButton"
import { InfoIconTooltip } from "../../common/molecules/InfoIconTooltip"
import { FlaggedChatTooltip } from "../FlaggedChatTooltip"
import "./DesktopFlaggedChat.scss"

interface FlaggedChatProps extends React.ComponentPropsWithoutRef<"div"> {
    chatDiv: HTMLDivElement
    reasons: string[]
    isPureChat?: boolean
    scrollCallback: () => void
    removeCallback: () => void
}

export function DesktopFlaggedChat({
    chatDiv,
    reasons,
    isPureChat,
    scrollCallback,
    removeCallback,
    ...props
}: FlaggedChatProps) {
    const [isOpen, setIsOpen] = useState(true)

    useEffect(() => {
        // scroll callback has the logic to scroll chat to bottom based on whether the user has scrolled up manually
        // and if the chat div's height was changed.
        // We need a callback here to let the parent handle scroll calculations after this component is finished rendering.
        scrollCallback()
    }, [scrollCallback])

    if (!isOpen) {
        return <></>
    }

    const className = mergeClasses(
        "FlaggedChat",
        isPureChat ? "purechat" : "splitchat",
    )

    return (
        <div className={className} {...props}>
            <div>
                <div className="FlaggedChat__header">
                    <InfoIconTooltip
                        className="FlaggedChat__tooltip_icon"
                        tooltipClassName="FlaggedChat__tooltip"
                        strokedIcon={true}
                    >
                        <div className="FlaggedChat__content">
                            <FlaggedChatTooltip reasons={reasons} />
                        </div>
                    </InfoIconTooltip>
                    <Typography
                        component="h2"
                        size={Sizes.smpx}
                        weight="normal"
                        className="FlaggedChat__info"
                    >
                        {t`This message was rejected due to ChaturSafe filter`}
                    </Typography>
                </div>
                <div>
                    <CloseButton
                        className="FlaggedChat__close"
                        onClick={() => {
                            removeCallback()
                            setIsOpen(false)
                        }}
                        size="10"
                    />
                </div>
            </div>
            <div
                ref={(ref) => {
                    ref?.appendChild(chatDiv)
                }}
            />
        </div>
    )
}
