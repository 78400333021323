import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgIgnore = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 20 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#222"
            fillRule="evenodd"
            d="M8.335 9.167a3.2 3.2 0 0 1-2.355-.98 3.2 3.2 0 0 1-.979-2.354q0-1.374.98-2.354A3.2 3.2 0 0 1 8.334 2.5a3.2 3.2 0 0 1 2.354.98 3.2 3.2 0 0 1 .979 2.353 3.2 3.2 0 0 1-.98 2.355 3.2 3.2 0 0 1-2.353.979M2.5 15.833A.833.833 0 0 1 1.668 15v-1.5q0-.708.365-1.302A2.43 2.43 0 0 1 3 11.292a12.4 12.4 0 0 1 2.625-.97 11.5 11.5 0 0 1 4.671-.154 4.98 4.98 0 0 0-1.129 3.165c0 .911.243 1.765.669 2.5zm12.499 0a2.5 2.5 0 0 0 2.15-3.777l-3.019 3.622c.27.1.563.155.869.155m0-5c.306 0 .599.055.87.156l-3.02 3.622A2.5 2.5 0 0 1 15 10.833m0 6.667a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgIgnore)
const Memo = memo(ForwardRef)
export default Memo
