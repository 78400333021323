import { t, Trans } from "@lingui/macro"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Button, ButtonSize, Overlay, Typography } from "../../common"

import "./DevicePermissionsPrompt.scss"

type DevicePermissionsPromptProps = {
    onPermsAck: () => void
}

export function DevicePermissionsPrompt({
    onPermsAck,
}: DevicePermissionsPromptProps) {
    return (
        <Overlay
            className="DevicePermissionsPrompt"
            data-testid="device-permissions-prompt"
            onClick={() => {}}
        >
            <div className="DevicePermissionsPrompt__modal">
                <div className="DevicePermissionsPrompt__promptText">
                    <Typography size={Sizes.mdpx}>
                        <Trans>
                            We need permission to use your camera and microphone
                            in order to proceed. When prompted by the browser,
                            please click &quot;Allow.&quot;
                        </Trans>
                    </Typography>
                    <Typography size={Sizes.mdpx}>
                        <Trans>
                            Visit our{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://support.chaturbate.com/hc/en-us/articles/360040862791/"
                            >
                                support page
                            </a>{" "}
                            for help enabling your devices.
                        </Trans>
                    </Typography>
                </div>
                <Button
                    className="DevicePermissionsPrompt__button"
                    size={ButtonSize.Large}
                    text={
                        <div className="DevicePermissionsPrompt__button__text">{t`Request Device Permissions`}</div>
                    }
                    onClick={onPermsAck}
                />
            </div>
        </Overlay>
    )
}
