import { PrivateRequestStatus, RoomStatus } from "@multimediallc/web-utils"
import { useAppSelector } from "../../../store/hooks"
import {
    selectRoomPrivateRequestStatus,
    selectRoomStatus,
} from "../../../store/roomSlice"
import { BroadcastRoomSubject } from "../BroadcastRoomSubject"
import { MobileBroadcastChat } from "../MobileBroadcastChat"
import { MuteUnmuteButton } from "../MuteUnmuteButton"
import { PrivateShowAcceptDenyModal } from "../PrivateShowAcceptDenyModal"
import { RecordButton } from "../RecordButton"
import { ReturnFromAwayUI } from "../ReturnFromAwayUI"
import { RoomStatusAndFPS } from "../RoomStatusAndFPS"
import { TokenCount } from "../TokenCount"
import "./MobileBroadcastLandscapeUI.scss"

export const MobileBroadcastLandscapeUI = () => {
    const privateShowRequestStatus = useAppSelector(
        selectRoomPrivateRequestStatus,
    )
    const roomStatus = useAppSelector(selectRoomStatus)

    return (
        <div
            className="MobileBroadcastLandscapeUI"
            data-testid="mobilebroadcast-landscape-ui"
        >
            <div className="MobileBroadcastLandscapeUI__TopSection">
                <div className="MobileBroadcastLandscapeUI__TopSection__Left">
                    <RoomStatusAndFPS />
                </div>
                <div className="MobileBroadcastLandscapeUI__TopSection__Center">
                    {roomStatus === RoomStatus.Away && <ReturnFromAwayUI />}
                </div>
                <div className="MobileBroadcastLandscapeUI__TopSection__Right">
                    <BroadcastRoomSubject />
                    <TokenCount />
                    {privateShowRequestStatus &&
                        privateShowRequestStatus.status ===
                            PrivateRequestStatus.requested && (
                            <PrivateShowAcceptDenyModal />
                        )}
                </div>
            </div>
            <div className="MobileBroadcastLandscapeUI__BottomSection">
                <div className="MobileBroadcastLandscapeUI__BottomSection__Left">
                    <MobileBroadcastChat />
                </div>
                <div className="MobileBroadcastLandscapeUI__BottomSection__Right">
                    <RecordButton />
                    <MuteUnmuteButton />
                </div>
            </div>
        </div>
    )
}
