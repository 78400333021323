import type React from "react"
import { Fragment } from "react"
import { useAppContext } from "../../../../../../hooks/appContext"
import { fetchPost } from "../../../../../../utils/myFetch"
import { addPageAction } from "../../../../../../utils/newrelic"
import type { EventRouter } from "../../../../../messaging/types"

export interface IOtherSectionProps {
    showLocationRouter: EventRouter<boolean>
    setThumbnailAnimations: (animate: boolean) => void
    reloadRooms: (onSuccess: () => void) => void
    previewRooms: boolean
    showLocation: boolean
    onOptionChange: (name: string, value: boolean) => void
}

export const OtherSection = (props: IOtherSectionProps) => {
    const appContext = useAppContext()

    const handleShowLocationTouch = (evt: React.ChangeEvent) => {
        const input = evt.target as HTMLInputElement
        props.showLocationRouter.fire(input.checked)
        props.onOptionChange("showLocation", input.checked)
        appContext.context.show_location = input.checked
        addPageAction("ShowLocationClicked", {
            checked: input.checked,
        })

        fetchPost(
            "/ax/update_option_filters/",
            input.checked ? { show_location: "on" } : {},
        )
            .then(() => {})
            .catch(() => {})
    }

    const handlePreviewRoomsTouch = (evt: React.ChangeEvent) => {
        if (appContext.context.logged_in_user?.is_supporter) {
            const input = evt.target as HTMLInputElement
            appContext.context.animate_thumbnails = input.checked
            props.onOptionChange("animateThumbnails", input.checked)
            addPageAction("AnimateRoomImagesClicked", {
                checked: input.checked,
            })
            props.setThumbnailAnimations(input.checked)
            fetchPost(
                "/ax/update_animate_thumbnails_options/",
                input.checked ? { animate_thumbnails: "true" } : {},
            )
                .then(() => {
                    props.reloadRooms(() => {})
                })
                .catch(() => {})
        }
    }

    const getSupporterText = () => {
        return appContext.context.logged_in_user?.is_supporter ? (
            ""
        ) : (
            <Fragment>
                (<a href="/supporter/upgrade/">Upgrade</a> to supporter to use
                this feature.)
            </Fragment>
        )
    }

    return (
        <div className="FilterSection__filterOptions Combined">
            <div className="FilterSection__otherOption">
                <input
                    id="id_show_location"
                    type="checkbox"
                    className="FilterSection__filterOption checkbox"
                    data-testid="other-filter-option-show-locations"
                    checked={props.showLocation}
                    onChange={handleShowLocationTouch}
                />
                <label
                    htmlFor="id_show_location"
                    className="FilterSection__checkboxLabel"
                >
                    Show Locations
                </label>
            </div>
            <div className="FilterSection__otherOption">
                <input
                    id="id_animate_thumbnails"
                    type="checkbox"
                    className={`FilterSection__filterOption checkbox ${
                        !appContext.context.logged_in_user?.is_supporter
                            ? "disabled"
                            : ""
                    }`}
                    data-testid="other-filter-option-preview-rooms"
                    checked={props.previewRooms}
                    disabled={!appContext.context.logged_in_user?.is_supporter}
                    onChange={handlePreviewRoomsTouch}
                />
                <label
                    htmlFor="id_animate_thumbnails"
                    className="FilterSection__checkboxLabel"
                >
                    Preview Rooms {getSupporterText()}
                </label>
            </div>
        </div>
    )
}
