import "./EmoticonPart.scss"
import { useAppSelector } from "../../../../store/hooks"
import { selectChatSettings } from "../../../../store/userSlice"
import type { IEmoticon } from "@multimediallc/web-utils/types"

export function EmoticonPart(emoticon: IEmoticon) {
    const userChatSettings = useAppSelector(selectChatSettings)
    const showEmoticons = userChatSettings?.showEmoticons ?? true

    const { width, height, imgUrl, name, thumbUrl } = emoticon
    if (showEmoticons) {
        return (
            <img
                data-testid="emoticonImg"
                className="emoticonImage"
                src={thumbUrl ?? imgUrl}
                title={`:${name}`}
                //  Mobile renders emoticons at half resolution
                width={width * 0.5}
                height={height * 0.5}
            />
        )
    } else {
        return <span data-testid="emoticonText">{`:${name}`}</span>
    }
}
