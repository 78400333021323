import { performToggleIsActive } from "../../hooks/appContext"

export const isDmSettingsActive = (): boolean =>
    performToggleIsActive("DmSettings")

export const isDmImpOrderingActive = (): boolean =>
    performToggleIsActive("DmImpOrdering")

export const isDmMediaActive = (): boolean => performToggleIsActive("DmMedia")

export const isMobileDmActive = (): boolean => performToggleIsActive("MobileDm")

export const isDesktopDmActive = (): boolean =>
    performToggleIsActive("DesktopDm")

export const isMsgFutureRlsActive = (): boolean =>
    performToggleIsActive("MsgFutureRls")

export const isPersistentTippingActive = (): boolean =>
    performToggleIsActive("PersistentTipping")
