import { t } from "@lingui/macro"
import { colorClass } from "../../../utils/css"
import { Typography } from "../../common"
import "./NewMessagesDivider.scss"

export function NewMessagesDivider() {
    return (
        <div className="NewMessagesDivider" data-testid="new-messages-divider">
            <hr className="NewMessagesDivider__Rule" />
            <Typography
                size="smpx"
                color={colorClass.brandColor}
                className="NewMessagesDivider__Text"
            >
                {t`New`}
            </Typography>
        </div>
    )
}
