import { useState } from "react"
import { Trans } from "@lingui/macro"
import { gettext } from "@multimediallc/web-utils"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { mergeClasses } from "../../../utils/css"
import { Typography } from "../../common"
import { CheckmarkSlim } from "../../common/atoms/Icons/Others"
import "./AppDirToggle.scss"

const i18n = {
    modernDesign: gettext("Modern (New Design)"),
    legacyDesign: gettext("Legacy Design"),
}

type PreviewStatus = "v3" | "legacy"

export interface AppDirToggleProps {
    value: PreviewStatus
    onChange: (value: PreviewStatus) => void
}

interface ToogleOption {
    label: string
    value: PreviewStatus
}

const toggleOptions: ToogleOption[] = [
    {
        label: i18n.modernDesign,
        value: "v3",
    },
    {
        label: i18n.legacyDesign,
        value: "legacy",
    },
]

export const AppDirToggle = ({
    value: toggleValue,
    onChange,
}: AppDirToggleProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState<"top" | "bottom">("top")
    const ref = useOutsideClick(() => setIsOpen(false))

    const toggleMenu = () => {
        if (ref.current) {
            const toggleElement = ref.current
            const rect = toggleElement.getBoundingClientRect()
            const spaceAbove = rect.top
            const spaceBelow = window.innerHeight - rect.bottom
            if (spaceAbove < 100 && spaceBelow > 100) {
                setPosition("bottom")
            } else {
                setPosition("top")
            }
        }
        setIsOpen((prev) => !prev)
    }

    const handleSelectValue =
        (value: PreviewStatus) =>
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation()
            onChange(value)
            setIsOpen(false)
        }

    return (
        <div className="AppDirToggle" ref={ref}>
            <div className="AppDirToggle__title" onClick={toggleMenu}>
                <Typography
                    component="span"
                    size={Sizes.xspx}
                    weight={Weights.normal}
                    className="AppDirToggle__title__label"
                >
                    <Trans>New</Trans>
                </Typography>
                <Typography
                    component="h6"
                    size={Sizes.xspx}
                    weight={Weights.medium}
                    className="AppDirToggle__title__name"
                >
                    <Trans>App Directory Preference</Trans>
                </Typography>
            </div>
            {isOpen && (
                <ul
                    className={mergeClasses(
                        "AppDirToggle__list",
                        position === "bottom"
                            ? "AppDirToggle__list--bottom"
                            : "",
                    )}
                >
                    {toggleOptions.map(({ label, value }) => (
                        <Typography
                            component="li"
                            size={Sizes.smpx}
                            weight={Weights.normal}
                            className={mergeClasses(
                                "AppDirToggle__list__option",
                                toggleValue === value
                                    ? "AppDirToggle__list__option--active"
                                    : "",
                            )}
                            key={value}
                            onClick={handleSelectValue(value)}
                        >
                            {label}
                            {toggleValue === value && (
                                <CheckmarkSlim className="AppDirToggle__list__option__checkmark" />
                            )}
                        </Typography>
                    ))}
                </ul>
            )}
        </div>
    )
}
