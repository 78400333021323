// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NG6mMTRugG77VhPRJBSq{display:flex}.NkcpA9Lh2FMBSEeWGPX4{border-left:1px solid #b3b3b3;display:flex;flex-direction:column;flex-grow:1;height:100vh;justify-content:center;align-items:center}.NkcpA9Lh2FMBSEeWGPX4 h1{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;font-size:16px;color:#525252;margin-bottom:12px}.NkcpA9Lh2FMBSEeWGPX4 h2{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;font-size:14px;color:#a7a7a7;margin:0}.NkcpA9Lh2FMBSEeWGPX4 h1,.NkcpA9Lh2FMBSEeWGPX4 h2{max-width:450px;display:block;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NG6mMTRugG77VhPRJBSq`,
	"emptyText": `NkcpA9Lh2FMBSEeWGPX4`
};
export default ___CSS_LOADER_EXPORT___;
