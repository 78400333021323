import { type MouseEvent } from "react"
import "./RoomlistFilters.scss"
import {
    shouldShowHomepageFilters,
    ShowType,
} from "@multimediallc/cb-roomlist-prefetch"
import {
    UrlStateContextProvider,
    useUrlStateContext,
} from "../../../../../hooks/urlStateContext"
import { CombinedOverlay } from "../CombinedOverlay"
import { FiltersContextProvider, useFiltersContext } from "../FiltersContext"
import { FiltersDrawer } from "../FiltersDrawer"
import { AgeSection } from "../filterSections/AgeSection"
import { HashtagSection } from "../filterSections/hashtagSection/HashtagSection"
import { LanguageSection } from "../filterSections/languageSection/LanguageSection"
import { PrivatePriceSection } from "../filterSections/PrivatePriceSection"
import { RegionSection } from "../filterSections/RegionSection"
import { RoomSizeSection } from "../filterSections/RoomSizeSection"
import { SpyPriceSection } from "../filterSections/SpyPriceSection/SpyPriceSection"
import { MobileFilterNav } from "../MobileFilterNav"
import { ActiveFiltersSection } from "../types"
import type { EventRouter } from "../../../../messaging/types"

export interface IRoomlistFiltersProps {
    showLocationRouter: EventRouter<boolean>
    setThumbnailAnimations: (animate: boolean) => void
    reloadRooms: (onSuccess: () => void) => void
}

export function RoomlistFilters(props: IRoomlistFiltersProps) {
    return (
        <UrlStateContextProvider>
            <FiltersContextProvider>
                <RoomlistFiltersContent
                    setThumbnailAnimations={props.setThumbnailAnimations}
                    showLocationRouter={props.showLocationRouter}
                    reloadRooms={props.reloadRooms}
                />
            </FiltersContextProvider>
        </UrlStateContextProvider>
    )
}

export function RoomlistFiltersContent(props: IRoomlistFiltersProps) {
    const { overlayedSection, setOverlayedSection } = useFiltersContext()
    const { showType, spyShowPrices, privatePrices } = useUrlStateContext()

    if (!shouldShowHomepageFilters()) {
        return
    }

    // If the spy prices drawer was open when navigating *from* /spy-on-cams page, close it or replace with privatePrices drawer
    if (
        overlayedSection === ActiveFiltersSection.PrivatePrices &&
        showType === ShowType.PRIVATE
    ) {
        if (spyShowPrices && spyShowPrices.length > 0) {
            setOverlayedSection(ActiveFiltersSection.SpyPrices)
        } else {
            setOverlayedSection(ActiveFiltersSection.None)
        }
    }
    // If the private prices drawer was open when navigating to /spy-on-cams page, close it or replace with spyPrices drawer
    if (
        overlayedSection === ActiveFiltersSection.SpyPrices &&
        showType !== ShowType.PRIVATE
    ) {
        if (privatePrices && privatePrices.length > 0) {
            setOverlayedSection(ActiveFiltersSection.PrivatePrices)
        } else {
            setOverlayedSection(ActiveFiltersSection.None)
        }
    }

    return (
        <div
            className="RoomlistFilters"
            data-testid="roomlist-filters-component"
        >
            <MobileFilterNav />
            {overlayedSection !== ActiveFiltersSection.None && (
                <div
                    className="RoomlistFilters__Overlay"
                    data-testid="roomlist-filters-overlay"
                    onClick={(e: MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setOverlayedSection(ActiveFiltersSection.None)
                    }}
                />
            )}
            <FiltersDrawer
                hidden={overlayedSection === ActiveFiltersSection.None}
                onHideSection={() =>
                    setOverlayedSection(ActiveFiltersSection.None)
                }
            >
                {overlayedSection === ActiveFiltersSection.Regions && (
                    <RegionSection />
                )}
                {overlayedSection === ActiveFiltersSection.RoomSize && (
                    <RoomSizeSection />
                )}
                {overlayedSection === ActiveFiltersSection.Tags && (
                    <HashtagSection />
                )}
                {overlayedSection === ActiveFiltersSection.PrivatePrices && (
                    <PrivatePriceSection />
                )}
                {overlayedSection === ActiveFiltersSection.SpyPrices && (
                    <SpyPriceSection />
                )}
                {overlayedSection === ActiveFiltersSection.Ages && (
                    <AgeSection />
                )}
                {overlayedSection === ActiveFiltersSection.Languages && (
                    <LanguageSection />
                )}
                {overlayedSection === ActiveFiltersSection.Combined && (
                    <CombinedOverlay
                        showLocationRouter={props.showLocationRouter}
                        setThumbnailAnimations={props.setThumbnailAnimations}
                        reloadRooms={props.reloadRooms}
                    />
                )}
            </FiltersDrawer>
        </div>
    )
}
