let segmenterInstance: Intl.Segmenter | null = null

const getSegmenter = (): Intl.Segmenter | null => {
    if (segmenterInstance === null) {
        try {
            segmenterInstance = new Intl.Segmenter("en", {
                granularity: "grapheme",
            })
        } catch (e) {
            return null
        }
    }
    return segmenterInstance
}

/**
 * Counts the number of Unicode characters in a string, treating emoji sequences as single characters
 */
export const countUnicodeChars = (text: string): number => {
    const segmenter = getSegmenter()
    if (segmenter) {
        return [...segmenter.segment(text)].length
    }
    // Fallback for browsers without Intl.Segmenter support
    return Array.from(text).length
}

/**
 * Truncates a string to a maximum number of Unicode characters, treating emoji sequences as single characters
 */
export const truncateUnicodeChars = (
    text: string,
    maxLength: number,
): string => {
    const segmenter = getSegmenter()
    if (segmenter) {
        return Array.from(segmenter.segment(text))
            .slice(0, maxLength)
            .map((segment) => segment.segment)
            .join("")
    }
    // Fallback for browsers without Intl.Segmenter support
    return Array.from(text).slice(0, maxLength).join("")
}
