import type { ComponentPropsWithoutRef } from "react"
import "./TokenCount.scss"
import { Sizes } from "../../../interfaces/sizing"
import { useAppSelector } from "../../../store/hooks"
import { selectTokenBalance } from "../../../store/userSlice"
import { colorClass } from "../../../utils/css"
import { Typography } from "../../common"
import { Tokens } from "../../common/atoms/Icons/Chat"

type TokenCountProps = ComponentPropsWithoutRef<"div">

export function TokenCount({ ...props }: TokenCountProps) {
    const tokenBalance = useAppSelector(selectTokenBalance)
    return (
        <div {...props} className="TokenCount">
            <Tokens className="TokenCount__icon" width={16} height={16} />
            <Typography
                size={Sizes.smpx}
                component="span"
                className="TokenCount__number"
                color={colorClass.whiteFontColor}
            >
                {tokenBalance}
            </Typography>
        </div>
    )
}
