import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCheckmarkSlim = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 8 8"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <g clipPath="url(#checkmarkSlim_svg__a)">
            <path
                fill="#fff"
                d="M7.791.096a.5.5 0 0 0-.698.115L2.415 6.734.853 5.17a.5.5 0 0 0-.707.707l1.978 1.978a.508.508 0 0 0 .76-.062l5.022-7a.5.5 0 0 0-.115-.698"
            />
        </g>
        <defs>
            <clipPath id="checkmarkSlim_svg__a">
                <path fill="#fff" d="M0 0h8v8H0z" />
            </clipPath>
        </defs>
    </svg>
)
const ForwardRef = forwardRef(SvgCheckmarkSlim)
const Memo = memo(ForwardRef)
export default Memo
