import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCamera = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0C6A93"
            d="M12 17.5q1.875 0 3.188-1.312Q16.5 14.875 16.5 13t-1.312-3.187T12 8.5 8.813 9.813Q7.499 11.125 7.5 13q0 1.875 1.313 3.188Q10.125 17.5 12 17.5m0-2q-1.05 0-1.775-.725T9.5 13t.725-1.775T12 10.5t1.775.725T14.5 13t-.725 1.775T12 15.5m-8 5q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 18.5v-11q0-.824.587-1.412A1.93 1.93 0 0 1 4 5.5h3.15L9 3.5h6l1.85 2H20q.824 0 1.413.588Q22 6.675 22 7.5v11q0 .824-.587 1.413A1.93 1.93 0 0 1 20 20.5z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgCamera)
const Memo = memo(ForwardRef)
export default Memo
