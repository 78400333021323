// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QQThosu2E99iLe2t4Ycz{display:flex;align-items:center;flex:1;padding-bottom:4px;position:relative}.QQThosu2E99iLe2t4Ycz>svg{position:absolute;left:16px;cursor:pointer;pointer-events:none}.WuGryor1ShGbup4a3A2D{background-color:#f8f9fa;font-size:16px;padding:12px 12px 12px 42px;border:none;border-radius:8px;display:flex;flex:1}.WuGryor1ShGbup4a3A2D.WuVv6RtHhTLkLF8PsF_e{padding-right:48px}.WuGryor1ShGbup4a3A2D::placeholder{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;color:#b3b3b3}.WuGryor1ShGbup4a3A2D:focus{outline:2px solid #033e58}.tfvioydG7wedRtmjE1fh{position:absolute;right:8px;height:24px;width:24px}.tfvioydG7wedRtmjE1fh>svg path{fill:#033e58}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterInputWrapper": `QQThosu2E99iLe2t4Ycz`,
	"filterInput": `WuGryor1ShGbup4a3A2D`,
	"hasClear": `WuVv6RtHhTLkLF8PsF_e`,
	"clearButton": `tfvioydG7wedRtmjE1fh`
};
export default ___CSS_LOADER_EXPORT___;
