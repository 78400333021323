import { useCallback, useEffect, useRef, useState } from "react"
import { t } from "@lingui/macro"
import { useNavigate, useParams } from "react-router-dom"
import { Fanclub } from "../../../components/common/atoms/Icons/Chat"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    setConversationListScroll,
    useDeleteConversationMutation,
    useMarkAsReadMutation,
} from "../../../store/messagingSlice"
import { getUsernameColorClass } from "../../../utils/css/user"
import { ConfirmModal } from "../common/ConfirmModal/ConfirmModal"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import { useMessagingView, useNavigateConversation } from "../hooks"
import { MessagePreview } from "../MessagePreview/MessagePreview"
import { useMessagingContext } from "../MessagingContext"
import { isMsgFutureRlsActive } from "../toggleUtils"
import { classNames } from "../utils"
import {
    ACTION_BUTTON_WIDTH,
    TOTAL_ACTIONS_WIDTH,
} from "./MessageActions/MessageActions"
import { formatTokens, getFormattedTime } from "./utils"
import type { Thread } from "../types"
import "./MessageThread.scss"

const MINIMUM_MOVEMENT_THRESHOLD = 5
const HORIZONTAL_MOVEMENT_RATIO = 1.2

interface MessageThreadProps {
    thread: Thread
    conversationListRef: React.RefObject<HTMLDivElement>
}

export function MessageThread({
    thread,
    conversationListRef,
}: MessageThreadProps) {
    const { dmUnreadData, createDmWindowRequest } = useMessagingContext()
    const messagingView = useMessagingView()
    const [markAsRead] = useMarkAsReadMutation()
    const [deleteConversation] = useDeleteConversationMutation()
    const navigate = useNavigate()
    const navigateConversation = useNavigateConversation()
    const dispatch = useAppDispatch()
    const [translateX, setTranslateX] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isVisible, setIsVisible] = useState(true)
    const touchStartXRef = useRef(0)
    const touchStartYRef = useRef(0)
    const currentTranslateXRef = useRef(0)
    const isScrollingRef = useRef(false)
    const hasStartedSwipingRef = useRef(false)
    const isUserTyping = useAppSelector(
        (state) => state.userTyping[thread.other_user?.username],
    )
    const isFutureRlsEnabled = isMsgFutureRlsActive()
    const [showFanclubBadge, setShowFanclubBadge] = useState<boolean>(true)

    const handleTouchStart = useCallback(
        (e: React.TouchEvent) => {
            if (!isFutureRlsEnabled) return
            touchStartXRef.current = e.touches[0].clientX
            touchStartYRef.current = e.touches[0].clientY
            currentTranslateXRef.current = translateX
            isScrollingRef.current = false
            hasStartedSwipingRef.current = false
        },
        [translateX, isFutureRlsEnabled],
    )

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        if (!isFutureRlsEnabled) return
        const touchX = e.touches[0].clientX
        const touchY = e.touches[0].clientY
        const deltaX = touchStartXRef.current - touchX
        const deltaY = touchStartYRef.current - touchY

        // Determine if the gesture is a swipe or scroll
        if (!isScrollingRef.current && !hasStartedSwipingRef.current) {
            const isHorizontalMovement =
                Math.abs(deltaX) > Math.abs(deltaY) * HORIZONTAL_MOVEMENT_RATIO

            if (
                Math.abs(deltaX) > MINIMUM_MOVEMENT_THRESHOLD ||
                Math.abs(deltaY) > MINIMUM_MOVEMENT_THRESHOLD
            ) {
                if (isHorizontalMovement) {
                    hasStartedSwipingRef.current = true
                } else {
                    isScrollingRef.current = true
                }
            }
        }

        // Only handle swipe if we've determined it's a horizontal swipe
        if (hasStartedSwipingRef.current) {
            const newTranslateX = Math.max(
                Math.min(currentTranslateXRef.current - deltaX, 0),
                -TOTAL_ACTIONS_WIDTH,
            )
            setTranslateX(newTranslateX)
        }
    }, [])

    const handleTouchEnd = useCallback(() => {
        if (!isFutureRlsEnabled) return
        const finalPosition =
            translateX < -ACTION_BUTTON_WIDTH ? -TOTAL_ACTIONS_WIDTH : 0
        setTranslateX(finalPosition)
        currentTranslateXRef.current = finalPosition
    }, [translateX, isFutureRlsEnabled])

    const handleMarkAsRead = () => {
        const numUnread = thread.num_unread ?? 0
        const username = thread.other_user?.username
        const hasUnread = numUnread > 0

        if (hasUnread && username) {
            markAsRead(username)
            dmUnreadData.removeUnreadRecipient(username)
        }
    }

    const handleThreadClick = useCallback(() => {
        // If swiped open, close it instead of navigating
        if (translateX < 0) {
            setTranslateX(0)
            currentTranslateXRef.current = 0
            return
        }

        if (conversationListRef.current) {
            dispatch(
                setConversationListScroll(
                    conversationListRef.current.scrollTop,
                ),
            )
        }

        handleMarkAsRead()
        if (messagingView.isDesktopInbox) {
            createDmWindowRequest.fire(thread.other_user.username)
            return
        }
        navigateConversation(thread.other_user?.username)
    }, [
        translateX,
        thread,
        conversationListRef,
        dispatch,
        markAsRead,
        navigate,
        handleMarkAsRead,
    ])

    const handleConfirmDelete = useCallback(() => {
        deleteConversation({ to_username: thread.other_user.username })
        setShowDeleteModal(false)
        setIsVisible(false)
        handleMarkAsRead()
        setTranslateX(0)
        currentTranslateXRef.current = 0
    }, [thread.other_user.username])

    // [MSG-412] TODO: These features are not implemented yet on the backend:
    // - Tokens spent badge display
    // - Muted status icon
    // const showTokensSpentBadge =
    //     isDmSettingsActive() && (thread.other_user?.tokensSpent ?? 0) > 0
    // const showMutedIcon = isDmSettingsActive() && thread.other_user?.isMuted
    // const handleUnmute = useCallback(() => {
    //     setTranslateX(0)
    //     currentTranslateXRef.current = 0
    // }, [thread.other_user.username])
    useEffect(() => {
        const newShowFanclubBadgeValue =
            isMsgFutureRlsActive() && thread.other_user?.inFanclub
        setShowFanclubBadge(newShowFanclubBadgeValue)
    }, [thread.other_user])

    const { username } = useParams<{ username?: string }>()
    const isThreadActive =
        messagingView.isDesktopUnified &&
        thread.other_user?.username === username

    const userColorClass = getUsernameColorClass(thread.other_user)

    return (
        <>
            {isVisible && (
                <div
                    className={classNames("message-thread", {
                        ["active"]: isThreadActive,
                        ["desktop-unified"]: messagingView.isDesktopUnified,
                    })}
                    data-testid="message-thread"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div
                        className="message-item"
                        data-testid="message-item"
                        onClick={handleThreadClick}
                        style={{
                            transform: `translateX(${translateX}px)`,
                        }}
                    >
                        <ProfilePicture user={thread.other_user} />
                        <div
                            className="message-content"
                            data-testid="message-content"
                        >
                            <div className="username-row">
                                <span
                                    className={classNames(
                                        "username",
                                        userColorClass,
                                    )}
                                    data-testid="username"
                                >
                                    <span
                                        className={classNames("username-text", {
                                            unread: thread.num_unread > 0,
                                        })}
                                    >
                                        {thread.other_user?.username}
                                    </span>
                                    {showFanclubBadge && (
                                        <span
                                            className="fanclub-icon"
                                            data-testid="fanclub-icon"
                                        >
                                            <Fanclub width={16} height={16} />
                                        </span>
                                    )}
                                    {/* showTokensSpentBadge feature not implemented yet */}
                                    {/* {showTokensSpentBadge && (
                                        <span
                                            className="tokens-badge"
                                            data-testid="tokens-badge"
                                        >
                                            <span className="tokens-icon">
                                                <Tokens
                                                    width={10}
                                                    height={10}
                                                />
                                            </span>
                                            <span
                                                className="tokens-amount"
                                                data-testid="tokens-amount"
                                            >
                                                {formatTokens(
                                                    thread.other_user
                                                        .tokensSpent,
                                                )}
                                            </span>
                                        </span>
                                    )} */}
                                </span>
                                <span
                                    className="timestamp"
                                    data-testid="timestamp"
                                >
                                    {getFormattedTime(thread.created_at)}
                                </span>
                            </div>
                            <div
                                className="preview-container"
                                data-testid="preview-container"
                            >
                                <div
                                    className={classNames("preview", {
                                        unread: thread.num_unread > 0,
                                    })}
                                    data-testid="message-preview"
                                >
                                    <MessagePreview
                                        thread={thread}
                                        isTyping={isUserTyping}
                                    />
                                </div>
                                {/* Muted status feature not implemented yet */}
                                {/* {showMutedIcon && (
                                    <div
                                        className="muted-icon"
                                        data-testid="muted-icon"
                                        role="status"
                                    >
                                        <MutedMessage width={16} height={16} />
                                    </div>
                                )} */}
                                {thread.num_unread > 0 && (
                                    <div
                                        className="unread-indicator"
                                        data-testid="unread-indicator"
                                        role="status"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Message actions (unmute/delete) feature not implemented yet */}
                    {/* <MessageActions
                        onUnmute={handleUnmute}
                        onDelete={handleDelete}
                        isMuted={thread.other_user?.isMuted ?? false}
                        translateX={translateX}
                    /> */}
                </div>
            )}
            <ConfirmModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
                title={t`Are you sure you want to delete this chat?`}
            />
        </>
    )
}
