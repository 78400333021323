import { Fragment } from "react"
import { UserMention } from "../UserMention"
import type { IStringPart } from "@multimediallc/web-utils/types"
import "./StringPart.scss"

const USERNAME_MENTION_REGEX = /((^|\s)@\w+)/

export function StringPart({ s: text }: IStringPart) {
    if (USERNAME_MENTION_REGEX.test(text)) {
        return (
            <span data-testid="string-part">
                {text.split(USERNAME_MENTION_REGEX).map((chunk, i) => {
                    if (chunk.trim().charAt(0) === "@") {
                        const startsWithSpace = /^\s/.test(chunk)
                        return (
                            <Fragment key={i}>
                                {startsWithSpace && " "}
                                <UserMention
                                    username={chunk.trimStart().slice(1)}
                                />
                            </Fragment>
                        )
                    }
                    if (chunk.trim().length > 0) {
                        return <Fragment key={i}>{chunk}</Fragment>
                    }
                })}
            </span>
        )
    } else {
        return <span data-testid="string-part">{text}</span>
    }
}
