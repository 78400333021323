import type { Ref } from "react"
import { setColors } from "@multimediallc/web-utils/colors"
import { ChatEntityType } from "../../../store/chatSlice"
import { LogMessage } from "../LogMessage"
import { NewMessagesDivider } from "../NewMessagesDivider"
import { RoomNotice } from "../RoomNotice"
import { UserMessage } from "../UserMessage"
import type {
    ReactRoomMessage,
    ReduxChatEntityData,
} from "../../../store/chatSlice"
import type {
    IPrivateMessage,
    IRoomNotice,
} from "@multimediallc/web-utils/types"
import "./ChatMessage.scss"

type IChatMessageProps = {
    entityData: ReduxChatEntityData
    isOldestUnread?: boolean
    newMessageLineLocationRef?: Ref<HTMLDivElement>
}

export function ChatMessage({
    entityData,
    isOldestUnread,
    newMessageLineLocationRef,
}: IChatMessageProps) {
    function renderMessage() {
        switch (entityData.entityType) {
            case ChatEntityType.RoomMessage:
                return (
                    <UserMessage
                        messageData={entityData as ReactRoomMessage}
                        isPm={false}
                    />
                )
            case ChatEntityType.PmMessage:
                return (
                    <UserMessage
                        messageData={entityData as IPrivateMessage}
                        isPm={true}
                    />
                )
            case ChatEntityType.RoomNotice:
                return <RoomNotice noticeData={entityData as IRoomNotice} />
            case ChatEntityType.LogMessage:
                return <LogMessage message={entityData.message} />
            default:
                return null
        }
    }

    const styleOverrides = chatMessageStyleOverrides(entityData)

    return (
        <div
            className="ChatMessage"
            ref={newMessageLineLocationRef}
            {...(isOldestUnread && {
                "data-testid": "mobile-broadcast-chat-oldest-unread",
            })}
        >
            {isOldestUnread === true && <NewMessagesDivider />}
            <div
                className="ChatMessage__contents"
                style={{
                    fontFamily: styleOverrides.fontFamily,
                    fontWeight: styleOverrides.fontWeight,
                }}
                ref={(div) => {
                    if (div) {
                        setColors(
                            div,
                            styleOverrides.textColor,
                            styleOverrides.backgroundColor,
                            true,
                        )
                    }
                }}
            >
                <div className="msg-text">{renderMessage()}</div>
            </div>
        </div>
    )
}

type StyleOverrides = {
    fontFamily?: string
    fontWeight?: string
    textColor?: string
    backgroundColor?: string
}

function chatMessageStyleOverrides(
    entityData: ReduxChatEntityData,
): StyleOverrides {
    const { entityType } = entityData
    switch (entityType) {
        case ChatEntityType.RoomMessage:
        case ChatEntityType.PmMessage:
            const messageData = entityData as ReactRoomMessage
            return {
                fontFamily: messageData.font,
                textColor: messageData.textColor,
                backgroundColor: messageData.backgroundColor,
            }
        case ChatEntityType.RoomNotice:
            const noticeData = entityData as IRoomNotice
            return {
                textColor: noticeData.foreground,
                backgroundColor: noticeData.background,
                fontWeight: noticeData.weight,
            }
        default:
            return {}
    }
}
