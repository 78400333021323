import { useParams } from "react-router-dom"
import { useLongPress } from "../../../hooks/useLongPress"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import { useMessagingView } from "../hooks"
import { MessageMenu } from "../MessageMenu/MessageMenu"
import { RenderedMessage } from "../RenderedMessage/RenderedMessage"
import { TipMessage } from "../TipMessage/TipMessage"
import { isMsgFutureRlsActive } from "../toggleUtils"
import { classNames } from "../utils"
import styles from "./Message.module.scss"
import type { Message } from "../types"

enum MessageType {
    AFTER_RECEIVED = "afterReceived",
    AFTER_SENT = "afterSent",
}

type Props = {
    message: Message
    showAvatar: boolean
    showMenuMessageId: string | null
    setShowMenuMessageId: (messageId: string | null) => void
    previousMessage?: Message | null
    hasTimestamp?: boolean
}

export function Message({
    message,
    showAvatar,
    showMenuMessageId,
    setShowMenuMessageId,
    previousMessage,
    hasTimestamp,
}: Props) {
    const { username } = useParams<{ username: string }>()
    const isReceived = message?.from_user?.username === message?.other_user
    const messagingView = useMessagingView()
    const isFutureRlsEnabled = isMsgFutureRlsActive()

    const { ref } = useLongPress({
        onLongPress: () => {
            if (isFutureRlsEnabled) {
                setShowMenuMessageId(message.i)
            }
        },
    })

    const onCloseMenu = () => {
        setShowMenuMessageId(null)
    }
    const showMenu = showMenuMessageId === message.i

    const prevMessageType =
        !hasTimestamp && previousMessage
            ? previousMessage.from_user?.username === username
                ? MessageType.AFTER_RECEIVED
                : MessageType.AFTER_SENT
            : undefined

    return (
        <div
            className={classNames(styles.message, {
                [styles.received]: isReceived,
                [styles.sent]: !isReceived,
                [styles.mobile]: messagingView.isMobile,
                [styles.desktop]: messagingView.isDesktopConversation,
                [styles.desktopUnified]: messagingView.isDesktopUnified,
                [styles.tip]: Boolean(message.tip_amount),
                ...(prevMessageType && { [styles[prevMessageType]]: true }),
            })}
            ref={ref}
            data-testid="message"
        >
            {showMenu && (
                <MessageMenu
                    message={message}
                    onClose={onCloseMenu}
                    triggerRef={ref}
                />
            )}
            {showAvatar && username && (
                <div className={styles.avatarContainer}>
                    <ProfilePicture user={message.from_user} size={24} />
                </div>
            )}
            {message.tip_amount ? (
                <TipMessage
                    amount={message.tip_amount}
                    isFromCurrentUser={!isReceived}
                    message={message.m}
                />
            ) : (
                <RenderedMessage message={message} />
            )}
        </div>
    )
}
