import { useState } from "react"
import { t } from "@lingui/macro"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../../hooks/appContext"
import {
    BlackClose,
    Filter,
    NewMessage,
    Popout,
} from "../../common/atoms/Icons/Chat"
import { AlertCircle } from "../../common/atoms/Icons/Others"
import { IconButton } from "../common/IconButton/IconButton"
import { ProfilePicture } from "../common/ProfilePicture/ProfilePicture"
import { useMessagingView } from "../hooks"
import { MessageProfileAlert } from "../MessageProfileAlert/MessageProfileAlert"
import { useMessagingContext } from "../MessagingContext"
import { ThreadList } from "../ThreadList/ThreadList"
import { ThreadSearch } from "../ThreadSearch"
import {
    isDesktopDmActive,
    isDmImpOrderingActive,
    isDmSettingsActive,
} from "../toggleUtils"
import { UserFeedback } from "../UserFeedback/UserFeedback"
import { classNames } from "../utils"
import styles from "./Inbox.module.scss"

type Props = {
    onClose: () => void
}

const SHOW_FEEDBACK_MODAL_ON_LOAD = false

export const Inbox: React.FC<Props> = ({ onClose }) => {
    const navigate = useNavigate()
    const { dmPopout } = useMessagingContext()
    const messagingView = useMessagingView()
    const [showProfileAlert, setShowProfileAlert] = useState(true)
    const [userClickedFeedbackIcon, setUserClickedFeedbackIcon] =
        useState(false)
    const [showUserFeedbackModal, setShowUserFeedbackModal] = useState(
        SHOW_FEEDBACK_MODAL_ON_LOAD,
    )
    const loggedInUsername = useAppContext().context.logged_in_user?.username
    const [hideHeader, setHideHeader] = useState(false)
    const [filter, setFilter] = useState("")
    const [useConversationFeatures, setUseConversationFeatures] = useState(true)

    const handleClickPopout = () => {
        dmPopout.show()
        onClose()
    }

    const handleSearchFocus = (focused: boolean) => {
        setHideHeader(focused)
        if (!focused) {
            setFilter("")
        }
    }

    const handleClickUserfeedbackIcon = () => {
        setUserClickedFeedbackIcon(true)
        setShowUserFeedbackModal(true)
    }

    const handleCloseUserFeedback = () => {
        setShowUserFeedbackModal(false)
    }

    return (
        <div
            className={classNames(styles.inbox, {
                [styles.mobile]: messagingView.isMobile,
                [styles.desktopUnified]: messagingView.isDesktopUnified,
            })}
            data-testid="inbox"
        >
            <div
                className={classNames(styles.inboxHeader, {
                    [styles.searchFocused]: hideHeader,
                })}
            >
                <div className={styles.inboxHeaderTitle}>{t`Messages`}</div>
                {messagingView.isMobile && (
                    <div className={styles.inboxHeaderButtons}>
                        <IconButton
                            icon={<AlertCircle height={20} width={20} />}
                            onClick={handleClickUserfeedbackIcon}
                            data-testid="user-feedback-icon"
                            className={styles.alertCircle}
                        />
                        <IconButton
                            icon={
                                <NewMessage
                                    height={21}
                                    width={21}
                                    onClick={() => navigate("/search-users")}
                                />
                            }
                        />
                        <IconButton
                            icon={<BlackClose height={24} width={24} />}
                            onClick={onClose}
                            data-testid="close-button"
                        />
                    </div>
                )}
                {isDesktopDmActive() && messagingView.isDesktopInbox && (
                    <IconButton
                        icon={<Popout height={18} width={18} />}
                        onClick={handleClickPopout}
                    />
                )}
            </div>
            <div className={styles.inboxContent}>
                <div
                    className={classNames(styles.inboxFilter, {
                        [styles.focused]: hideHeader,
                    })}
                >
                    {isDmSettingsActive() && (
                        <ProfilePicture
                            user={{
                                // TODO: [MSG-414] get the actual user data
                                username: loggedInUsername ?? "",
                                inFanclub: false,
                                hasTokens: false,
                                isMod: false,
                                tippedRecently: false,
                                tippedALotRecently: false,
                                tippedTonsRecently: false,
                            }}
                            size={32}
                        />
                    )}
                    {useConversationFeatures && (
                        <ThreadSearch
                            focusCallback={handleSearchFocus}
                            onTyping={setFilter}
                            inputProps={{
                                maxLength: 30,
                            }}
                        />
                    )}
                    {isDmImpOrderingActive() && (
                        <button
                            className={styles.filterButton}
                            data-testid="filter-btn"
                        >
                            <Filter />
                        </button>
                    )}
                </div>
                {messagingView.isMobile && isDmSettingsActive() && (
                    <MessageProfileAlert
                        show={showProfileAlert}
                        onClose={() => setShowProfileAlert(false)}
                    />
                )}
                <ThreadList
                    filteredName={filter}
                    searchFocused={hideHeader}
                    permissionUpdate={setUseConversationFeatures}
                />
                {showUserFeedbackModal && (
                    <UserFeedback
                        onClose={handleCloseUserFeedback}
                        isUserInitiated={userClickedFeedbackIcon}
                    />
                )}
            </div>
        </div>
    )
}
