import type { ComponentPropsWithoutRef } from "react"
import { t } from "@lingui/macro"
import { Sizes } from "../../../../interfaces/sizing"
import { mergeClasses } from "../../../../utils/css"
import { ScrollToBottom, ScrollToTop } from "../../atoms/Icons/Chat"
import { Typography } from "../../atoms/Typography"
import styles from "./ScrollButton.module.scss"

interface ScrollButtonProps extends ComponentPropsWithoutRef<"button"> {
    text?: string
    scrollToDirection?: ScrollButtonDirection
}

export enum ScrollButtonDirection {
    top = "top",
    bottom = "bottom",
}

export function ScrollButton({
    scrollToDirection,
    ...props
}: ScrollButtonProps) {
    return (
        <button
            data-testid="scroll-button"
            {...props}
            className={mergeClasses(styles.scrollButton, props.className)}
        >
            {scrollToDirection === ScrollButtonDirection.top ? (
                <ScrollToTop data-testid="scroll-to-top" />
            ) : (
                <ScrollToBottom data-testid="scroll-to-bottom" />
            )}
            <Typography size={Sizes.smpx} color="">
                {props.text ?? t`Scroll to bottom`}
            </Typography>
        </button>
    )
}
