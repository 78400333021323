import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgAlertCircle = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 20 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#000"
            d="M9 13h2v2H9zm0-8h2v6H9zm1-5C4.47 0 0 4.5 0 10A10 10 0 1 0 10 0m0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgAlertCircle)
const Memo = memo(ForwardRef)
export default Memo
