import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgFemale2 = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 10 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#E37C7C"
            d="M5 .333a5 5 0 0 1 5 5c0 2.476-1.8 4.534-4.167 4.934V12H7.5v1.667H5.833v1.667H4.167v-1.667H2.5V12h1.667v-1.733A5.01 5.01 0 0 1 0 5.334a5 5 0 0 1 5-5M5 2a3.333 3.333 0 1 0 0 6.667A3.333 3.333 0 0 0 5 2"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgFemale2)
const Memo = memo(ForwardRef)
export default Memo
