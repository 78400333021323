import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgStar = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#000"
            stroke="#fff"
            strokeMiterlimit={10}
            d="m12 2 3.333 5.958L22 9.292l-4.625 5.041.792 6.792L12 18.25l-6.167 2.875.792-6.792L2 9.292l6.667-1.334z"
            opacity={0.5}
        />
    </svg>
)
const ForwardRef = forwardRef(SvgStar)
const Memo = memo(ForwardRef)
export default Memo
