import type React from "react"
import { plural, t } from "@lingui/macro"
import { Tokens } from "../../common/atoms/Icons/Chat"
import { useMessagingView } from "../hooks"
import { classNames } from "../utils"
import styles from "./TipMessage.module.scss"

interface TipMessageProps {
    amount: number
    isFromCurrentUser: boolean
    message?: string
}

export const TipMessage: React.FC<TipMessageProps> = ({
    amount,
    isFromCurrentUser,
    message,
}) => {
    const messagingView = useMessagingView()

    const tokenText = plural(amount, {
        one: `${amount} token`,
        other: `${amount} tokens`,
    })

    return (
        <div
            className={classNames(styles.tipMessage, {
                [styles.desktop]: messagingView.isDesktopConversation,
                [styles.mobile]: messagingView.isMobile,
            })}
        >
            <div
                className={classNames(styles.iconAndTipText, {
                    [styles.roundedAll]: !message,
                    [styles.roundedTop]: !!message,
                    [styles.currentUser]: isFromCurrentUser,
                    [styles.otherUser]: !isFromCurrentUser,
                })}
            >
                <Tokens width={16} height={16} className={styles.tokensBlack} />
                <span className={styles.tipText}>
                    {isFromCurrentUser
                        ? t`You tipped ${tokenText}`
                        : t`Tipped ${tokenText}`}
                </span>
            </div>
            {message && (
                <>
                    <div
                        className={classNames(styles.message, {
                            [styles.currentUserMessage]: isFromCurrentUser,
                            [styles.otherUserMessage]: !isFromCurrentUser,
                        })}
                    >
                        <span className={styles.messageText}>{message}</span>
                    </div>
                </>
            )}
        </div>
    )
}
