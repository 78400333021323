import type { ComponentPropsWithoutRef } from "react"
import { useRef, useState } from "react"
import { t, Trans } from "@lingui/macro"
import { useAlert } from "../../../hooks/useAlert"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { fetchPost } from "../../../utils/myFetch"
import { AlertType, Button, ButtonColor, Typography } from "../../common"
import { Tune } from "../../common/atoms/Icons/Others"
import { InputToggle } from "../../common/atoms/InputToggle"
import { InfoIconTooltip } from "../../common/molecules/InfoIconTooltip"
import { convertSettingsDataToStrings } from "../common"
import { FilterModal } from "../FilterModal"
import type { IChatursafeFilterSettings } from "@multimediallc/web-utils/types"
import "./FilterSetting.scss"

interface SettingProps extends ComponentPropsWithoutRef<"div"> {
    filterSettings?: IChatursafeFilterSettings
    saveCallback?: () => void
}

export function FilterSetting({
    filterSettings,
    saveCallback,
    ...props
}: SettingProps) {
    const enabled =
        (filterSettings?.chat_enabled ||
            filterSettings?.messaging_enabled ||
            filterSettings?.pm_enabled ||
            filterSettings?.tip_enabled) ??
        false

    const [checked, setChecked] = useState(enabled)
    const [savedFilterSettings, setSavedFilterSettings] = useState(
        filterSettings ?? ({} as IChatursafeFilterSettings),
    )
    const [formData, setFormData] = useState(
        filterSettings ?? ({} as IChatursafeFilterSettings),
    )
    const [filterModalOpen, setFilterModalOpen] = useState(false)
    const toggleUsed = useRef(false)
    const addAlert = useAlert()

    function updateStateAndRerender(
        newFilterSettings?: IChatursafeFilterSettings,
    ): void {
        const enabled =
            (newFilterSettings?.chat_enabled ||
                newFilterSettings?.messaging_enabled ||
                newFilterSettings?.pm_enabled ||
                newFilterSettings?.tip_enabled) ??
            false
        setChecked(enabled)

        if (newFilterSettings) {
            setSavedFilterSettings(newFilterSettings)
            setFormData(newFilterSettings)
        }
    }

    async function sendForm(
        newData?: IChatursafeFilterSettings,
    ): Promise<boolean> {
        // don't make changes until data has first been loaded
        if (!filterSettings) {
            updateStateAndRerender(savedFilterSettings)
            addAlert({
                type: AlertType.alert,
                title: "",
                contents: t`Please wait for ChaturSafe filter settings to load before attempting to change them.`,
            })
            return false
        }

        // use formData when possible, only use newData param if formData state has not been updated yet
        if (!newData) {
            newData = formData
        }

        const body = convertSettingsDataToStrings(newData)
        try {
            const res = await fetchPost(
                "/api/ts/chatursafe/filter-settings/",
                body,
            )
            if (res.status === 200) {
                updateStateAndRerender(newData)
                saveCallback?.()
                setFilterModalOpen(false)
                return true
            } else {
                onClose()
            }
        } catch (err) {
            addAlert({
                type: AlertType.alert,
                title: "",
                contents: t`Error saving ChaturSafe filter settings.`,
            })
            onClose()
        }
        return false
    }

    function onClose() {
        updateStateAndRerender(savedFilterSettings)
        setFilterModalOpen(false)
    }

    function handleInputChange(name: string, value: boolean | number) {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    async function onToggle(currentlyChecked: boolean) {
        toggleUsed.current = true
        const newFormData = {
            ...formData,
            chat_enabled: !currentlyChecked,
            // commented out while PM, DM, Tip msg functionality is disabled
            // messaging_enabled: !currentlyChecked,
            // pm_enabled: !currentlyChecked,
            // tip_enabled: !currentlyChecked,
        }
        setChecked(!currentlyChecked)
        setFormData(newFormData)

        // setState runs asynchronously, so pass in new data directly instead of waiting for state to update
        await sendForm(newFormData)
    }

    return (
        <div className="FilterSetting" {...props}>
            <div className="FilterSetting__label">
                <Typography
                    component="h2"
                    size={Sizes.xspx}
                    weight={Weights.bold}
                    className="FilterSetting__header"
                >
                    {t`ChaturSafe Filter`}
                </Typography>
                <InfoIconTooltip
                    tooltipClassName="FilterSetting__tooltip"
                    strokedIcon={true}
                >
                    <Typography
                        component="h2"
                        size={Sizes.mdpx}
                        weight={Weights.medium}
                    >
                        {t`What's ChaturSafe Filter?`}
                    </Typography>
                    <div className="FilterSetting__content">
                        <Trans>
                            <p>
                                Text moderation filter helps protect your space
                                by automatically detecting inappropriate
                                content.
                            </p>
                            <p>
                                Adjust the filter&apos;s strictness to your
                                preference by clicking{" "}
                                <b className="FilterSetting__content-bold">
                                    &quot;Customize filter settings&quot;
                                </b>{" "}
                                and test the results.
                            </p>
                            <p>
                                While these AI tools are helpful,{" "}
                                <i>they&apos;re not perfect</i> - Please use
                                them along with your own judgement.
                            </p>
                        </Trans>
                    </div>
                </InfoIconTooltip>
            </div>
            <div className="FilterSetting__control">
                <Typography
                    component="h2"
                    size={Sizes.xspx}
                    className="FilterSetting__control-label"
                    onClick={() => void onToggle(checked)}
                >
                    {t`Enable text moderation filter`}
                </Typography>
                <div className="FilterSetting__control-div">
                    <InputToggle
                        onToggle={() => void onToggle(checked)}
                        checked={checked}
                    />
                    <Button
                        color={ButtonColor.Link}
                        icon={<Tune />}
                        text={t`Customize filter settings`}
                        className="FilterSetting__filterBtn"
                        onClick={() => setFilterModalOpen(true)}
                    />
                </div>
            </div>
            <FilterModal
                isOpen={filterModalOpen}
                formData={formData}
                handleInputChange={handleInputChange}
                onClose={onClose}
                onSave={() => void sendForm()}
            />
        </div>
    )
}
