// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bPah3xW1fQlNP3JCOPFL{padding:8px 12px;display:flex;flex:0 0 auto;align-items:center;box-sizing:border-box;background:#f8f9fa;min-height:48px}.bPah3xW1fQlNP3JCOPFL.KgqgFpK2czQKqbWIGxus{height:65px;position:absolute;top:0;left:0;right:0;z-index:1001;background:#f8f9fa}.bPah3xW1fQlNP3JCOPFL.fy3Tf8jFBZmzx2O76zuo{height:48px;border-radius:8px 8px 0 0}.bPah3xW1fQlNP3JCOPFL.Cdv25DzvgVhaGMgcmVbC{border-bottom:none;cursor:pointer;width:292px}.R9llL_nVS0JII6YjmhSc{font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;margin-left:8px;font-size:14px;display:flex;align-items:center;gap:4px;max-width:65%;min-width:0}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.ExpET8CYMUC2_ubG0gdg{color:#dc5500}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.DT94fBVAjlLVoVI0TUKk{color:#dc0000}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.MVSTDlJw8HcGrvd9FfxB{color:#090}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.YJKbrRgvxtUpHXp97nDR{color:#804baa}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.lXRYbKbpuPlaEHie08rB{color:#be6aff}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.EEl0CENeow6QaFKsVq3C{color:#1e5cfb}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.oA7U6y4jLdfyTCBP9uiG{color:#69a}.R9llL_nVS0JII6YjmhSc .WAJABRdMnviMfJDNmBag.Z2XO15Z_H1FObA9fsDsP{color:#939393}._OvNNSALh3wiZha1HhFW{display:flex;align-items:center}.fnlyajPGeVjiYc4AG351{width:32px;height:32px;border-radius:50%;margin-right:12px;background-color:gray}.YOnp9YSL2aNpX8qew_ZJ{background:none;border:none;padding:0;cursor:pointer;display:flex;align-items:center;margin-right:8px}.YOnp9YSL2aNpX8qew_ZJ img{height:16px}.TYPujIBwz7FdisLQzYny{display:flex;align-items:center;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `bPah3xW1fQlNP3JCOPFL`,
	"mobile": `KgqgFpK2czQKqbWIGxus`,
	"desktop": `fy3Tf8jFBZmzx2O76zuo`,
	"minimized": `Cdv25DzvgVhaGMgcmVbC`,
	"username": `R9llL_nVS0JII6YjmhSc`,
	"usernameText": `WAJABRdMnviMfJDNmBag`,
	"broadcaster": `ExpET8CYMUC2_ubG0gdg`,
	"mod": `DT94fBVAjlLVoVI0TUKk`,
	"inFanclub": `MVSTDlJw8HcGrvd9FfxB`,
	"tippedTonsRecently": `YJKbrRgvxtUpHXp97nDR`,
	"tippedALotRecently": `lXRYbKbpuPlaEHie08rB`,
	"tippedRecently": `EEl0CENeow6QaFKsVq3C`,
	"hasTokens": `oA7U6y4jLdfyTCBP9uiG`,
	"defaultUser": `Z2XO15Z_H1FObA9fsDsP`,
	"fanclubIcon": `_OvNNSALh3wiZha1HhFW`,
	"avatar": `fnlyajPGeVjiYc4AG351`,
	"backButton": `YOnp9YSL2aNpX8qew_ZJ`,
	"iconsContainer": `TYPujIBwz7FdisLQzYny`
};
export default ___CSS_LOADER_EXPORT___;
