import type { RefObject } from "react"
import { useCallback, useEffect, useLayoutEffect } from "react"
import { throttle } from "lodash"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    DM_THREAD_PAGE_LIMIT,
    messagingApi,
} from "../../../store/messagingSlice"

const SCROLL_THRESHOLD = 20
const THROTTLE_DELAY = 150

export const useInfiniteScroll = (
    scrollRef: RefObject<HTMLDivElement>,
): void => {
    const dispatch = useAppDispatch()

    const lastQuery = useAppSelector(
        (state) =>
            messagingApi.endpoints.getThreads.select({
                offset: 0,
                limit: DM_THREAD_PAGE_LIMIT,
            })(state).originalArgs,
    )

    const { data: currentData, isFetching } =
        messagingApi.endpoints.getThreads.useQueryState({
            offset: lastQuery?.offset ?? 0,
            limit: DM_THREAD_PAGE_LIMIT,
        })

    const scrollPosition = useAppSelector(
        (state) => state.messaging.conversationListScroll,
    )

    // Restore scroll position
    useLayoutEffect(() => {
        if (scrollPosition && scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition
        }
    }, [scrollPosition, scrollRef])

    const handleScroll = useCallback(
        throttle(() => {
            const contentElement = scrollRef.current
            if (!contentElement) return

            const { scrollTop, scrollHeight, clientHeight } = contentElement
            if (
                scrollHeight - scrollTop - clientHeight < SCROLL_THRESHOLD &&
                !isFetching &&
                currentData?.has_more
            ) {
                dispatch(
                    messagingApi.endpoints.getThreads.initiate({
                        offset: (lastQuery?.offset ?? 0) + DM_THREAD_PAGE_LIMIT,
                        limit: DM_THREAD_PAGE_LIMIT,
                    }),
                )
            }
        }, THROTTLE_DELAY),
        [
            isFetching,
            currentData?.has_more,
            lastQuery?.offset,
            dispatch,
            scrollRef,
        ],
    )

    useEffect(() => {
        const contentElement = scrollRef.current
        if (!contentElement) return

        contentElement.addEventListener("scroll", handleScroll)
        return () => {
            contentElement.removeEventListener("scroll", handleScroll)
            handleScroll.cancel()
        }
    }, [handleScroll, scrollRef])
}
